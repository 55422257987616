import React, { useEffect, useState } from 'react'
import { deepEqual, formatQAString, formatQuestionnaire } from '@/utils/helper'
import { Button, Card, Empty, notification } from 'antd'
import { QuestionAndAnswerForm } from './QuestionAndAnswerForm'
import { OverViewCardInfo } from '../OverviewCardInfo'
import './QuestionAndAnswerInfo.scss'
import { RefetchOptions, QueryObserverResult, UseMutationResult } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { openModal } from '@/store/modal/slice'
import { EditOutlined } from '@ant-design/icons'
import { DeleteOutline, UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { CustomModal } from '@/components/CustomModal'
import { User } from '@/types/TAccounts'

interface QuestionAndAnswerInfoProps {
  data: any
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  mutation: UseMutationResult<{}, Error, any, unknown>
}

export const QuestionAndAnswerInfo: React.FC<QuestionAndAnswerInfoProps> = ({ data, refetch, mutation }) => {
  const dispatch = useAppDispatch()

  const questionsAndAnswersData = formatQuestionnaire(data?.sample_qa)

  const [expandedItems, setExpandedItems] = useState<number[]>([])
  const [qnaIndex, setQnaIndex] = useState<number>()
  const [formInitialValues, setFormInitialValues] = useState<any>(null)

  const toggleExpandCollapse = (index: number) => {
    setExpandedItems((items) =>
      items.includes(index) ? items.filter((itemIndex) => itemIndex !== index) : [...items, index]
    )
  }

  useEffect(() => {
    const previousQnaData = formInitialValues?.questions_and_answers[0]?.previousQnaData

    if (!deepEqual(previousQnaData, questionsAndAnswersData))
      setFormInitialValues({
        questions_and_answers: [
          {
            previousQnaData: questionsAndAnswersData,
          },
        ],
      })
  }, [questionsAndAnswersData, formInitialValues])

  const handleEditScenario = (qna: any, index: number) => {
    const initialValues = {
      questions_and_answers: [
        {
          question: qna.question,
          answer: qna.answer,
          previousQnaData: questionsAndAnswersData,
        },
      ],
    }

    setFormInitialValues(initialValues)
    setQnaIndex(index)
    dispatch(openModal({ modalId: MODAL_IDS.SAMPLE_QUESTION_AND_ANSWER_EDIT_MODAL }))
  }

  const handleDeleteScenario = async (index: number) => {
    try {
      await mutation.mutateAsync({
        sample_qa: formatQAString(questionsAndAnswersData?.filter((_, i) => i !== index)),
        deleteIndex: index,
      })
      notification.success({ message: `Q&A # ${index + 1} deleted successfully.` })
      refetch()
    } catch (err) {
      notification.error({ message: 'Something went wrong, please try again later!' })
    }
  }
  const baseClass = 'question-and-answer'
  const InfoComponent = () => {
    return (
      <>
        {questionsAndAnswersData && questionsAndAnswersData.length > 0 ? (
          questionsAndAnswersData.map((qna, index) => (
            <Card
              key={index + 1}
              className="mt-4"
              title={
                <div className={`${baseClass}_button-container`}>
                  <span className="font-bold text-base my-2">{`Q&A # ${index + 1}`}</span>
                  <div>
                    <Button
                      loading={mutation.isPending && mutation.variables.deleteIndex === index}
                      type="primary"
                      danger
                      icon={<DeleteOutline />}
                      onClick={() => handleDeleteScenario(index)}
                    >
                      <div>Delete</div>
                    </Button>
                    <Button
                      type="primary"
                      className="ml-2"
                      icon={<EditOutlined />}
                      onClick={() => handleEditScenario(qna, index)}
                    >
                      <div>Edit</div>
                    </Button>
                    <Button
                      type="primary"
                      className="ml-2"
                      icon={expandedItems.includes(index) ? <UnfoldLess /> : <UnfoldMore />}
                      onClick={() => toggleExpandCollapse(index)}
                    >
                      <div>{expandedItems.includes(index) ? 'Collapse' : 'Expand'}</div>
                    </Button>
                  </div>
                </div>
              }
            >
              {expandedItems.includes(index) ? (
                <>
                  <div className="border-b">
                    <LabelWithDescription customClassName="mt-2" label="Question" description="" />
                    <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{qna.question}</span>
                  </div>
                  <div className="border-b">
                    <LabelWithDescription customClassName="mt-2" label="Answer" description="" />
                    <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{qna.answer}</span>
                  </div>
                </>
              ) : (
                <span className="text-base text-gray-300 text-center">Expand To View Details</span>
              )}
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </>
    )
  }

  return (
    <div>
      <OverViewCardInfo
        width={'50%'}
        formActionType="Add"
        form={
          <QuestionAndAnswerForm
            mutation={mutation}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType="ADD"
          />
        }
        formTitle="Add Sample Questions and Answers"
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.SAMPLE_QUESTION_AND_ANSWER_MODAL}
      />
      <CustomModal
        width={'50%'}
        title={<span className="font-bold">Edit Q&A</span>}
        children={
          <QuestionAndAnswerForm
            actionType="EDIT"
            showAddFieldButton={false}
            initialValues={formInitialValues}
            refetch={refetch}
            mutation={mutation}
            qnaIndex={qnaIndex}
          />
        }
        modalId={MODAL_IDS.SAMPLE_QUESTION_AND_ANSWER_EDIT_MODAL}
        footer={null}
      />
    </div>
  )
}
