import React, { useState, useEffect } from 'react'
import Table from './Table'
import styles from '../css/main.module.css'
import { Lead, MainProps } from '@/interfaces/ILeadsView'
import { fetchLeads } from 'src/Hooks/UseHookForCrmData'

const Main: React.FC<MainProps> = ({ username, statuses }) => {
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([])
  const [leadData, setLeadData] = useState<Lead[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    function getLeads() {
      setIsLoading(true)
      setError('')
      try {
        fetchLeads(username as string).then((data) => {
          if (data) {
            setLeadData(data)
          } else {
            setLeadData([])
          }
        })
      } catch (error) {
        setError('Unable to fetch leads data')
      } finally {
        setIsLoading(false)
      }
    }

    getLeads()
  }, [username])

  useEffect(() => {
    setFilteredLeads(leadData?.filter((lead) => statuses?.includes(lead?.current_status)))
  }, [leadData, statuses])

  const fetchDataByStatus = (status: string) => {
    if (status === 'all') {
      setFilteredLeads(leadData?.filter((lead) => statuses?.includes(lead?.current_status)))
    } else {
      setFilteredLeads(leadData?.filter((item) => item?.current_status === status))
    }
  }

  const handleUpdateLead = (updatedLead: Lead) => {
    setLeadData((prevLeads) =>
      prevLeads.map((lead) => (lead.phone_number === updatedLead.phone_number ? updatedLead : lead))
    )
    setFilteredLeads((prevFiltered) =>
      prevFiltered.map((lead) => (lead.phone_number === updatedLead.phone_number ? updatedLead : lead))
    )
  }

  return (
    <main className={`${styles.main_section} ${styles.crm_section}`}>
      <div className={styles.container}>
        {isLoading && <p>Loading...</p>}
        {error && <p className={styles.error}>{error}</p>}
        <Table
          leads={filteredLeads}
          statuses={statuses} // Pass unique statuses
          fetchDataByStatus={fetchDataByStatus}
          onUpdateLead={handleUpdateLead}
        />
      </div>
    </main>
  )
}

export default Main
