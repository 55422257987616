import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Badge, Collapse } from 'antd'
import './OverviewCard.scss'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import {
  ChatOutlined,
  LinkOutlined,
  PhoneForwardedOutlined,
  PinchOutlined,
  PsychologyOutlined,
  QuestionAnswerOutlined,
  TipsAndUpdatesOutlined,
  HandshakeOutlined,
  TerminalOutlined,
  CallEnd,
  Api,
  CalendarMonthOutlined,
} from '@mui/icons-material'
import { VoiceAndLanguageInfo } from './VoiceAndLanguage/VoiceAndLanguageInfo'
import { QuestionAndAnswerInfo } from './QuestionAndAnswer/QuestionAndAnswerInfo'
import { KnowledgeBaseInfo } from './KnowledgeBase/KnowledgeBaseInfo'
import { TweakAdvancedSettingsInfo } from './TweakAdvancedSettings/TweakAdvancedSettingsInfo'
import { TextingWorkflowInfo } from './TextingWorkflow/TextingWorkflowInfo'
import { useMutation, useQuery } from '@tanstack/react-query'
import { deletePostCallWebhook, getUser, updatePostCallWebhook, updateUser, userGrandfathered } from '@/api/user'
import { PhoneWorkflowInfo } from './PhoneWorkflow/PhoneWorkflowInfo'
import { CallTransferingWorkflowInfo } from './CallTransferingWorkflow/CallTransferingWorkflowInfo'
import { GreetingPhraseInfo } from './GreetingPhrase/GreetingPhraseInfo'
import { AiPromptInfo } from './AiPrompt/AiPromptInfo'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import { PostCallWebhook } from './PostCallWebhook/PostCallWebhook'
import { ItemType } from 'antd/es/menu/interface'
import { OverViewCards } from 'src/enums/EOverViewCards'
import { CalendarWorkflowInfo } from './CalendarWorkflow/CalendarWorkflowInfo'
import { useSearchParams } from 'react-router-dom'
import { ApiWorkflowInfo } from './ApiWorkflow/ApiWorkflowInfo'
import { getLoggedInNoviUser, getSelectedCampaignId } from '@/store/novi/user/selector'

interface CustomCollapseItem extends Omit<ItemType, 'children'> {
  key: OverViewCards
  label: React.ReactNode
  children: React.ReactNode
}

type OverviewCardProps = {
  onCompletionChange: (completed: number, total: number) => void
  debrand: boolean
  isNoviUser?: boolean
  apiWorkflowRef?: React.RefObject<HTMLDivElement>
  gcalRef?: React.RefObject<HTMLDivElement>
  intakeRef?: React.RefObject<HTMLDivElement>
}

const OverviewCard: React.FC<OverviewCardProps> = ({
  onCompletionChange,
  debrand,
  isNoviUser,
  apiWorkflowRef,
  gcalRef,
  intakeRef,
}) => {
  const [searchParams] = useSearchParams()
  let loggedInUser
  let username
  if (isNoviUser) {
    loggedInUser = useAppSelector(getLoggedInNoviUser)
    username = useAppSelector(getSelectedCampaignId)
  } else {
    loggedInUser = useAppSelector(getLoggedInUser)
    username = loggedInUser.number
  }
  const tweakAdvancedSettingsRef = useRef<HTMLDivElement>(null)

  const [activeKey, setActiveKey] = useState<string | string[] | undefined>(undefined) // Setting default open key

  const { data, refetch } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  })

  const mutation = useMutation({
    mutationKey: ['update-query'],
    mutationFn: updateUser,
  })

  useEffect(() => {
    const encAcc = searchParams.get('encAcc')
    const encRefr = searchParams.get('encRefr')
    if (encAcc && encRefr) {
      setActiveKey(OverViewCards.CALENDAR_SCHEDULING_WORKFLOW)
    }
  }, [searchParams])

  const { data: grandfatheredData } = useQuery({
    queryKey: ['grandFathered-data', username],
    queryFn: async () => {
      // @ts-ignore
      if (loggedInUser?.subscriptionStatus === 'active') {
        return await userGrandfathered({ username: username as string })
      } else {
        return { success: false, data: { grandfathered: false, oldAmount: 0, newAmount: 0, interval: 0, name: '' } }
      }
    },
  })

  const postCallWebhookMutation = useMutation({
    mutationKey: ['post-call-webhook'],
    mutationFn: updatePostCallWebhook,
  })

  const deletePostCallWebhookMutation = useMutation({
    mutationKey: ['delete-post-call-webhook'],
    mutationFn: deletePostCallWebhook,
  })

  const isNonEmptyArray = (arr: any[] | null | undefined): boolean => {
    return Array.isArray(arr) && arr.length > 0
  }

  const items = useMemo<CustomCollapseItem[]>(
    () => [
      {
        key: OverViewCards.GREETING_PHRASE,
        label: (
          <LabelWithDescription
            icon={<HandshakeOutlined className="mr-1" />}
            badges={<Badge count={'Required'} className="badge-danger" />}
            label="Greeting Phrase"
            description="This is the opening line your receptionist will use when answering the phone."
          />
        ),
        children: <GreetingPhraseInfo refetch={refetch} data={data} />,
      },
      {
        key: OverViewCards.AI_PROMPT,
        label: (
          <LabelWithDescription
            icon={<TerminalOutlined className="mr-1" />}
            badges={<Badge count={'Required'} className="badge-danger" />}
            label="AI Prompt"
            description="A prompt for your AI Receptionist. Generally, this default prompt is fine for most use cases."
          />
        ),
        children: <AiPromptInfo mutation={mutation} refetch={refetch} data={data} />,
      },

      {
        key: OverViewCards.KNOWLEDGE_BASE,
        label: (
          <LabelWithDescription
            icon={<PsychologyOutlined className="mr-1" />}
            badges={<Badge count={'Required'} className="badge-danger" />}
            label="Knowledge Base"
            description="Add up to 20 pages of text for your AI Receptionist to learn from. No special format requred."
          />
        ),
        children: <KnowledgeBaseInfo data={data} refetch={refetch} />,
      },

      {
        key: OverViewCards.CALL_TRANSFERRING_WORKFLOW,
        label: (
          <LabelWithDescription
            icon={<PhoneForwardedOutlined className="mr-1" />}
            badges={
              <>
                <Badge count={'Optional'} className="badge-warning" />
                <Badge className="ml-1 badge-info" count={'Recommended'} />
              </>
            }
            label="Call Transferring Workflow"
            description="Teach your AI Receptionist how to intelligentaly transfer the call."
          />
        ),
        children: <CallTransferingWorkflowInfo mutation={mutation} data={data} refetch={refetch} />,
      },
      {
        key: OverViewCards.TEXTING_WORKFLOW,
        label: (
          <LabelWithDescription
            icon={<ChatOutlined className="mr-1" />}
            badges={
              <>
                <Badge count={'Optional'} className="badge-warning" />
                <Badge className="ml-1 badge-info" count={'Recommended'} />
              </>
            }
            label="Texting Workflow"
            description="Your AI Receptionist can text the caller in real-time during the call. Great for sending Calendly links and menus."
          />
        ),
        children: <TextingWorkflowInfo mutation={mutation} data={data} refetch={refetch} debrand={debrand} />,
      },
      {
        key: OverViewCards.PHONE_FORM_WORKFLOW,
        label: (
          <div ref={intakeRef}>
            <LabelWithDescription
              icon={<TipsAndUpdatesOutlined className="mr-1" />}
              badges={
                <>
                  <Badge count={'Optional'} className="badge-warning" />
                  <Badge className="ml-1 badge-info" count={'Recommended'} />
                </>
              }
              label="Intake Form Workflow"
              description="Your AI receptionist can collect information from your callers and store it separately."
            />
          </div>
        ),
        children: <PhoneWorkflowInfo mutation={mutation} data={data} refetch={refetch} />,
      },
      {
        key: OverViewCards.CALENDAR_SCHEDULING_WORKFLOW,
        label: (
          <div ref={gcalRef}>
            <LabelWithDescription
              icon={<CalendarMonthOutlined className="mr-1" />}
              badges={
                <>
                  <Badge className="badge-success" count="New" />
                  <Badge count={'Optional'} className="badge-warning ml-1" />
                </>
              }
              label="Calendar Scheduling Workflow"
              description="Integrate with Google Calendar for real-time appointment scheduling."
            />
          </div>
        ),
        children: <CalendarWorkflowInfo clientObject={data} />,
      },
      {
        key: OverViewCards.SAMPLE_QUESTIONS_ANSWERS,
        label: (
          <LabelWithDescription
            icon={<QuestionAnswerOutlined className="mr-1" />}
            badges={
              <>
                <Badge count={'Optional'} className="badge-warning" />
                <Badge className="ml-1 badge-info" count={'Recommended'} />
              </>
            }
            label="Questions & Answers section"
            description="Frequently asked questions that your AI Receptionist can reference when answering common questions."
          />
        ),
        children: <QuestionAndAnswerInfo mutation={mutation} refetch={refetch} data={data} />,
      },
      {
        key: OverViewCards.API_WORKFLOW,
        label: (
          <div ref={apiWorkflowRef}>
            <LabelWithDescription
              icon={<Api className="mr-1" />}
              badges={
                <>
                  <Badge count={'Optional'} className="badge-warning" />
                  <Badge className="ml-1 badge-danger" count={'Advanced'} />
                </>
              }
              label="API Workflow"
              description="Your AI Receptionist can make real-time API requests during the phone call. She can retrieve and submit information to your APIs realtime."
            />
          </div>
        ),
        children: <ApiWorkflowInfo mutation={mutation} data={data} refetch={refetch} />,
      },
      {
        key: OverViewCards.POST_CALL_WEBHOOK,
        label: (
          <LabelWithDescription
            icon={<CallEnd className="mr-1" />}
            badges={
              <>
                <Badge count={'Optional'} className="badge-warning" />
                <Badge className="ml-1 badge-danger" count={'Advanced'} />
              </>
            }
            label="Post Call Webhook"
            description="Make request to your webhook after the call ends. Great for logging and analytics. If you'd prefer to use Zapier for this, see advanced settings below."
          />
        ),
        children: (
          <PostCallWebhook
            data={data}
            mutation={postCallWebhookMutation}
            deleteMutation={deletePostCallWebhookMutation}
            refetch={refetch}
          />
        ),
      },
      {
        key: OverViewCards.CUSTOMIZE_VOICE_LANGUAGES,
        label: (
          <LabelWithDescription
            icon={<LinkOutlined className="mr-1 rotate-45" />}
            badges={
              <>
                <Badge count={'Optional'} className="badge-warning" />
                <Badge className="ml-1 badge-disabled" count={'Not Recommended'} />
              </>
            }
            label="Customize languages"
            description="Turn your AI Receptionist into a multi-lingual superstar."
          />
        ),
        children: <VoiceAndLanguageInfo refetch={refetch} data={data} />,
      },
      {
        key: OverViewCards.TWEAK_ADVANCED_SETTINGS,
        label: (
          <LabelWithDescription
            icon={<PinchOutlined className="mr-1" />}
            badges={
              <>
                <Badge count={'Optional'} className="badge-warning" />
                <Badge className="ml-1 badge-danger" count={'Advanced'} />
              </>
            }
            label="Tweak Advanced Settings"
            description="Tweak settings like Zapier, Extension Digits, and Call Recordings."
          />
        ),
        children: (
          <div ref={tweakAdvancedSettingsRef}>
            <TweakAdvancedSettingsInfo data={data} refetch={refetch} debrand={debrand} />
          </div>
        ),
      },
    ],
    [data, mutation, postCallWebhookMutation, deletePostCallWebhookMutation, refetch]
  )

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKey(key)
    const isTweakAdvancedSettingsOpen = Array.isArray(key)
      ? key.includes(OverViewCards.TWEAK_ADVANCED_SETTINGS)
      : key === OverViewCards.TWEAK_ADVANCED_SETTINGS

    if (isTweakAdvancedSettingsOpen) {
      setTimeout(() => {
        if (tweakAdvancedSettingsRef.current) {
          const elementRect = tweakAdvancedSettingsRef.current.getBoundingClientRect()
          const absoluteElementTop = elementRect.top + window.pageYOffset
          const middle = absoluteElementTop - window.innerHeight / 2
          const scrollOffset = 200

          window.scrollTo({
            top: middle + scrollOffset,
            behavior: 'smooth',
          })
        }
      }, 250)
    }
  }

  useEffect(() => {
    const newCompletionStatus = items
      //Note: Please keep adding or removing items from here if we want to skip them from "Add Content" completion badge
      .filter((item) => ![OverViewCards.TWEAK_ADVANCED_SETTINGS].some((notAllowedItem) => notAllowedItem === item.key))
      .map((item) => {
        switch (item.key) {
          case OverViewCards.GREETING_PHRASE:
            return !!data?.system_phrase
          case OverViewCards.AI_PROMPT:
            return !!data?.ai_prompt
          case OverViewCards.SAMPLE_QUESTIONS_ANSWERS:
            return !!data?.sample_qa
          case OverViewCards.KNOWLEDGE_BASE:
            return !!data?.business_information
          case OverViewCards.CALL_TRANSFERRING_WORKFLOW:
            //@ts-ignore
            return data?.callWorkflows && isNonEmptyArray(JSON.parse(data?.callWorkflows))
          case OverViewCards.TEXTING_WORKFLOW:
            //@ts-ignore
            return data?.workflows && isNonEmptyArray(JSON.parse(data.workflows))
          case OverViewCards.CUSTOMIZE_VOICE_LANGUAGES:
            return !!data?.voice_selected && !!data?.selected_languages
          case OverViewCards.PHONE_FORM_WORKFLOW:
            return (
              (data?.intake_questions ?? data?.intake_questions_workflows) &&
              // @ts-ignore
              isNonEmptyArray(JSON.parse(data?.intake_questions ?? data?.intake_questions_workflows))
            )
          case OverViewCards.POST_CALL_WEBHOOK:
            return !!data?.postCallWebhook
          default:
            return false
        }
      })
    const completedCount = newCompletionStatus.filter(Boolean).length
    onCompletionChange(completedCount, newCompletionStatus.length)
  }, [data, items, onCompletionChange])

  return (
    <div className="overview-card-container">
      <Alert className="overview-info" closable description="Set up your AI Receptionist in minutes! 👇" />
      {grandfatheredData?.data?.grandfathered && (
        <Alert
          className="overview-info"
          style={{ marginTop: '0.2rem' }}
          closable
          description={`⭐You are grandfathered. You are paying $${grandfatheredData?.data?.oldAmount}/${grandfatheredData?.data?.interval} for $${grandfatheredData?.data?.name} while it costs ${grandfatheredData?.data?.newAmount}/${grandfatheredData?.data?.interval} now.`}
        />
      )}
      <Collapse accordion items={items} onChange={handleCollapseChange} activeKey={activeKey} />
    </div>
  )
}

export default OverviewCard
