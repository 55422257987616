import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Button, Input, Radio, Select, Form, Progress, Spin, message } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import logo from '@/assets/images/logo.png'
import { checkResellerEmail } from '@/api/resellerQualification'

interface StepFormProps {
  fromAd: boolean
  fromEmail: boolean
}

interface Step {
  id: number
  question: string
  name: string
  type: 'text' | 'radio' | 'select'
  options?: string[] | { value: string; label: string }[]
  validations: string[]
}

const steps: Step[] = [
  {
    id: 1,
    question: "What's your Email?",
    name: 'email',
    type: 'text',
    validations: ['required', 'email'],
  },
  {
    id: 2,
    question: 'What is your monthly budget?',
    name: 'monthlyBudget',
    type: 'radio',
    options: ['0-$250', '$250-$750', '$750+'],
    validations: ['required'],
  },
  {
    id: 3,
    question: 'Do you already have potential clients in the pipeline?',
    name: 'clientsInPipeline',
    type: 'radio',
    options: ['Yes', 'No'],
    validations: ['required'],
  },
  {
    id: 4,
    question: 'Where are you located?',
    name: 'located',
    type: 'select',
    options: [
      { value: 'usa', label: 'United States' },
      { value: 'uk', label: 'United Kingdom' },
      { value: 'australia', label: 'Australia' },
      { value: 'canada', label: 'Canada' },
      { value: 'germany', label: 'Germany' },
      { value: 'france', label: 'France' },
      { value: 'italy', label: 'Italy' },
      { value: 'spain', label: 'Spain' },
      { value: 'netherlands', label: 'Netherlands' },
      { value: 'belgium', label: 'Belgium' },
      { value: 'sweden', label: 'Sweden' },
      { value: 'denmark', label: 'Denmark' },
      { value: 'norway', label: 'Norway' },
      { value: 'finland', label: 'Finland' },
      { value: 'switzerland', label: 'Switzerland' },
      { value: 'austria', label: 'Austria' },
      { value: 'poland', label: 'Poland' },
      { value: 'portugal', label: 'Portugal' },
      { value: 'greece', label: 'Greece' },
      { value: 'ireland', label: 'Ireland' },
      { value: 'other_europe', label: 'Other European Country' },
      { value: 'other', label: 'Other (Non-European)' },
    ],
    validations: ['required'],
  },
  {
    id: 5,
    question: 'Have you tested out the receptionist?',
    name: 'testedReceptionist',
    type: 'radio',
    options: ['Yes', 'No'],
    validations: ['required'],
  },
  {
    id: 6,
    question: 'Do you have a sales strategy for how you would resell our software?',
    name: 'strategyForResell',
    type: 'radio',
    options: ['Yes', 'No'],
    validations: ['required'],
  },
  {
    id: 7,
    question: 'How big is your team?',
    name: 'teamSize',
    type: 'radio',
    options: ['Solo Entrepreneur', '2-5', '5+'],
    validations: ['required'],
  },
]

const StepForm: React.FC<StepFormProps> = ({ fromAd, fromEmail }) => {
  const [form] = Form.useForm()
  const [currentStep, setCurrentStep] = useState(0)
  const [redirecting, setRedirecting] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const checkEmailMutation = useMutation({
    mutationFn: checkResellerEmail,
    onSuccess: (data: any) => {
      if (data.success && data.found) {
        message.success('Information found. Redirecting...')
        setRedirecting(true)
        setTimeout(() => {
          window.location.href = 'https://calendly.com/d/cpnx-hd4-kr7/ai-front-desk-white-label-additional-call'
        }, 2000)
      }
    },
    onError: () => {
      message.success('continuing on next step')
    },
  })

  const submitFormMutation = () => {
    setRedirecting(true)
    setTimeout(() => {
      const formData = form.getFieldsValue()
      const score = calculateScore(formData)
      if (score <= 50 || formData.monthlyBudget === '0-$250') {
        navigate('/affiliate-program')
      } else {
        navigate('/test-receptionist', { state: { fromAd, fromEmail } })
      }
    }, 2000)
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields()
      // const formData = form.getFieldsValue()
      submitFormMutation()
    } catch (error) {
      console.error('Validation failed:', error)
      message.error('Please fill all required fields correctly.')
    }
  }

  const handleNext = async () => {
    try {
      await form.validateFields([steps[currentStep].name])
      if (currentStep === 0) {
        const email = form.getFieldValue('email')
        checkEmailMutation.mutate(email)
      }
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1)
      } else {
        handleSubmit()
      }
    } catch (error) {
      console.error('Validation failed:', error)
    }
  }

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const handleScroll = (event: WheelEvent) => {
    event.preventDefault()
    const delta = Math.sign(event.deltaY)
    if (delta > 0) {
      handleNext()
    } else if (delta < 0) {
      handlePrevious()
    }
  }

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener('wheel', handleScroll as any, { passive: false })
    }
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleScroll as any)
      }
    }
  }, [currentStep])

  const calculateScore = (formData: any): number => {
    const scores = {
      monthlyBudget: {
        '0-$250': 0,
        '$250-$750': 25,
        '$750+': 35,
      },
      clientsInPipeline: {
        Yes: 30,
        No: 0,
      },
      testedReceptionist: {
        Yes: 10,
        No: 0,
      },
      strategyForResell: {
        Yes: 10,
        No: 0,
      },
      teamSize: {
        'Solo Entrepreneur': 10,
        '2-5': 20,
        '5+': 30,
      },
      located: {
        usa: 15,
        uk: 13,
        australia: 13,
        canada: 10,
        germany: 10,
        france: 10,
        italy: 10,
        spain: 10,
        netherlands: 10,
        belgium: 10,
        sweden: 10,
        denmark: 10,
        norway: 10,
        finland: 10,
        switzerland: 10,
        austria: 10,
        poland: 10,
        portugal: 10,
        greece: 10,
        ireland: 10,
        other_europe: 10,
        other: 0,
      },
    }

    let score = 0
    for (const key in formData) {
      if (key === 'email') continue
      // @ts-ignore
      score += scores[key as keyof typeof scores][formData[key]] || 0
    }
    return score
  }

  const renderFormItem = (step: Step) => {
    switch (step.type) {
      case 'text':
        return <Input />
      case 'radio':
        return (
          <Radio.Group>
            {(step.options as string[]).map((option) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        )
      case 'select':
        return (
          <Select>
            {(step.options as { value: string; label: string }[]).map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
      default:
        return null
    }
  }

  return (
    <div className="relative h-screen overflow-hidden" ref={containerRef}>
      <Progress
        percent={((currentStep + 1) / steps.length) * 100}
        showInfo={false}
        className="fixed top-0 left-0 right-0 z-10"
      />
      {redirecting ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <Spin size="large" />
          <p className="mt-4 text-xl">Redirecting to the best option for you...</p>
        </div>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div
            key={steps[currentStep].id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col justify-center items-center min-h-screen w-full px-4 py-8"
          >
            <div className="w-full max-w-3xl bg-white p-8 md:p-12 shadow-lg rounded-lg">
              <div className="mb-6 flex items-center">
                <span className="text-2xl font-bold text-blue-600 mr-3">{steps[currentStep].id}.</span>
                <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">{steps[currentStep].question}</h2>
              </div>
              <Form form={form} layout="vertical">
                <Form.Item
                  name={steps[currentStep].name}
                  rules={steps[currentStep].validations.map((validation) => ({
                    required: validation === 'required',
                    type: validation === 'email' ? 'email' : undefined,
                    message: `Please enter a valid ${validation === 'email' ? 'email address' : 'value'}`,
                  }))}
                >
                  {renderFormItem(steps[currentStep])}
                </Form.Item>
              </Form>
              <Button type="primary" onClick={handleNext} className="mt-4" loading={checkEmailMutation.isPending}>
                {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      <div className="fixed top-8 left-8">
        <img src={logo} alt="logo" className="w-16 h-16" />
      </div>
      <div className="fixed bottom-8 right-8 flex flex-col space-y-4">
        <Button
          onClick={handlePrevious}
          disabled={currentStep === 0}
          icon={<ArrowUpOutlined />}
          shape="circle"
          className="bg-white"
        />
        <Button onClick={handleNext} icon={<ArrowDownOutlined />} shape="circle" className="bg-white" />
      </div>
    </div>
  )
}

export default StepForm
