import { AccountState, User } from '@/types/TAccounts'
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit'
import posthog from 'posthog-js'
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem'

const initialState: AccountState = {
  isAuthenticated: false,
  authToken: undefined,
  user: {
    email: '',
    number: '',
    demo: true,
    business_name: '',
    ai_prompt: '',
    system_phrase: '',
    features: {},
    instruction_steps: [],
    token: '',
    planDuration: '',
    resellerClient: false,
  },
  currentPage: ReceptionistDashboardSiderItem.CONFIGURE,
}

export const slice: Slice<
  AccountState,
  {
    setAccount: (state: AccountState, action: PayloadAction<AccountState>) => void
    setInstructionSteps: (state: AccountState, action: PayloadAction<{ instructionSteps: number[] }>) => void
    setTestingNumber: (state: AccountState, action: PayloadAction<{ testing_number: string }>) => void
    setUserContacts: (state: AccountState, action: PayloadAction<{ userContacts: { [key: string]: string } }>) => void
    setUserTimeZone: (state: AccountState, action: PayloadAction<{ timeZone: string }>) => void
    updateUser: (state: AccountState, action: PayloadAction<{ user: Partial<User> }>) => AccountState
    setCurrentPage: (state: AccountState, action: PayloadAction<ReceptionistDashboardSiderItem>) => void
  },
  'account'
> = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state: AccountState, action: PayloadAction<AccountState>) => {
      state.authToken = action.payload.authToken
      state.user = action.payload.user
      state.isAuthenticated = action.payload.isAuthenticated
      state.user.resellerClient = action.payload.user.reseller_email ? true : false

      // Identify user with PostHog if authenticated
      if (state.isAuthenticated && state.user) {
        posthog.identify(state.user.number, {
          ...state.user,
        })
      }
    },
    setInstructionSteps: (state: AccountState, action: PayloadAction<{ instructionSteps: number[] }>) => {
      state.user.instruction_steps = action.payload.instructionSteps

      const stepCount = action.payload.instructionSteps.length
      if (stepCount < 3) {
        state.currentPage = ReceptionistDashboardSiderItem.GET_STARTED
      } else if (stepCount === 3) {
        state.currentPage = ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS
      } else {
        state.currentPage = ReceptionistDashboardSiderItem.CONFIGURE
      }
    },
    setTestingNumber: (state: AccountState, action: PayloadAction<{ testing_number: string }>) => {
      state.user.testing_number = action.payload.testing_number
    },
    setUserContacts(state: AccountState, action: PayloadAction<{ userContacts: { [key: string]: string } }>) {
      state.user.contacts = action.payload.userContacts
    },
    setUserTimeZone(state: AccountState, action: PayloadAction<{ timeZone: string }>) {
      state.user.time_zone = action.payload.timeZone
    },
    updateUser(state: AccountState, action: PayloadAction<{ user: Partial<User> }>) {
      return { ...state, user: { ...state.user, ...action.payload } }
    },
    setCurrentPage: (state: AccountState, action: PayloadAction<ReceptionistDashboardSiderItem>) => {
      state.currentPage = action.payload
    },
  },
})

export const {
  setAccount,
  setInstructionSteps,
  setTestingNumber,
  setUserContacts,
  setUserTimeZone,
  updateUser,
  setCurrentPage,
} = slice.actions

export default slice.reducer
