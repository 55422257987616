import React, { useState, DragEvent } from 'react'
import { Loading } from '@/components/Loading'
import { useFetchGroups } from 'src/Hooks/UseHookForCrmData'
import LeadView from './LeadView'

interface TaskProps {
  colIndex: number
  taskIndex: number
  statuses: any // Define a proper type for statuses if possible
  username: string
}

const Task: React.FC<TaskProps> = ({ colIndex, taskIndex, statuses, username }) => {
  const { data } = useFetchGroups(username)
  const columns = data?.columns || []
  const col = columns[colIndex]
  const task = col.tasks[taskIndex]

  const [isLeadViewOpen, setIsLeadViewOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOnDrag = (e: DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData(
      'text',
      JSON.stringify({
        taskIndex,
        prevColIndex: colIndex,
        leadId: task.lead_id,
        phone_number: task?.title || task?.phone_number,
      })
    )
  }

  const closeLeadView = () => {
    setIsLeadViewOpen(false)
    setIsLoading(false)
  }

  const openDetailsView = () => {
    setIsLeadViewOpen(true)
    const { refetch } = useFetchGroups(task.username)

    refetch()
      .then((_result) => {
        setIsLoading(true)
        setIsLeadViewOpen(true)
      })
      .catch((err) => {
        console.error('Failed to fetch groups:', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div>
      {!isLeadViewOpen && (
        <div
          onClick={openDetailsView}
          draggable
          onDragStart={handleOnDrag}
          className="w-[280px] first:my-5 rounded-lg py-6 px-3 shadow-sm transition-shadow duration-300 ease-in-out transform hover:shadow-lg hover:scale-102 hover:text-[#635fc7] dark:hover:text-[#635fc7] cursor-pointer"
        >
          <div>
            <span className="tracking-wide">Phone Number:</span>
            <p className="font-bold tracking-wide">{task?.title}</p>
          </div>
          <div>
            <span className="tracking-wide">First Contact:</span>
            <p className="font-bold tracking-wide">{task?.first_contact}</p>
          </div>
          <p className="font-bold text-xs tracking-tighter mt-2 text-gray-500">
            {/* {completed} of {subtasks?.length} completed tasks */}
          </p>
        </div>
      )}

      {isLeadViewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-7xl w-full max-h-[90vh] overflow-y-auto relative">
            <button onClick={closeLeadView} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              ✕
            </button>
            <LeadView
              onClose={closeLeadView}
              rowData={task}
              statuses={statuses}
              onUpdateLead={function (_lead: { id: number; notes: string; [key: string]: any }): void {
                throw new Error('Function not implemented.')
              }}
            />
          </div>
        </div>
      )}

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 dark:bg-[#2b2c37] dark:bg-opacity-75 z-10">
          <Loading />
        </div>
      )}
    </div>
  )
}

export default Task
