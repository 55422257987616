import React from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import './SettingMenu.scss'
import AccountSettings from './AccountSettings/AccountSettings'
import { useQuery } from '@tanstack/react-query'
import { getUser } from '@/api/user'
import ManagePassword from './ManagePassword/ManagePassword'
import ManageSubscription from './ManageSubscription/ManageSubscription'
import { getLoggedInUser } from '@/store/account/selector'
import { useAppSelector } from '@/store/hooks/useAppSelector'

export const SettingMenu: React.FC<{debrand: boolean}> = ({debrand}) => {
  const loggedInUser = useAppSelector(getLoggedInUser)
  const { data, isLoading } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  })

  const onChange = (key: string) => {
    console.log(key)
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Account Settings',
      //@ts-ignore
      children: <AccountSettings isLoading={isLoading} business_name={data?.business_name} />,
    },
    {
      key: '2',
      label: 'Manage Password',
      children: <ManagePassword />,
    },
  ]

  if (!loggedInUser?.reseller_email && !debrand) {
    items.push({
      key: '3',
      label: 'Manage Subscription',
      children: <ManageSubscription />,
    })
  }

  const baseClass = 'setting-menu'

  return <Tabs defaultActiveKey="1" tabPosition="left" items={items} onChange={onChange} className={baseClass} />
}
