import { Button, Card, List, notification } from 'antd'
import { OverViewCardInfo } from '../OverviewCardInfo'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import './PhoneWorkflowInfo.scss'
import { useEffect, useState } from 'react'
import { CustomModal } from '@/components/CustomModal'
import PhoneWorkflowForm from './PhoneWorkflowForm'
import { RefetchOptions, QueryObserverResult, UseMutationResult } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { openModal } from '@/store/modal/slice'
import { deepEqual } from '@/utils/helper'
import { User } from '@/types/TAccounts'
import { TUTORIALS } from 'src/enums/ETutorials'

interface PhoneWorkflowInfoProps {
  data: any
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  mutation: UseMutationResult<{}, Error, any, unknown>
}

export const PhoneWorkflowInfo: React.FC<PhoneWorkflowInfoProps> = ({ data, refetch, mutation }) => {
  const dispatch = useAppDispatch()

  const beginningCallWorkflowQuestions = (data?.intake_questions ? JSON.parse(data?.intake_questions) : []) as string[]
  const phoneWorkflowdata = (
    data?.intake_questions_workflows ? JSON.parse(data?.intake_questions_workflows) : []
  ) as any[]

  const [formInitialValues, setFormInitialValues] = useState<any>(null)
  const [scenarioIndex, setScenarioIndex] = useState<number>()

  useEffect(() => {
    let previousWorkflowData = []

    if (beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0) {
      previousWorkflowData.push({
        customScenario: '',
        description: 'beginning',
        questions: beginningCallWorkflowQuestions,
      })
    }

    previousWorkflowData = [...previousWorkflowData, ...phoneWorkflowdata]

    if (!deepEqual(previousWorkflowData, formInitialValues?.scenarios[0]?.previousWorkflowData)) {
      const initialValues = {
        scenarios: [
          {
            previousWorkflowData,
          },
        ],
      }

      setFormInitialValues(initialValues)
    }
  }, [phoneWorkflowdata, beginningCallWorkflowQuestions, formInitialValues])

  const handleEditScenario = (scenario: any, index: number) => {
    let previousWorkflowData = []

    if (beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0) {
      previousWorkflowData.push({
        customScenario: '',
        description: 'beginning',
        questions: beginningCallWorkflowQuestions,
      })
    }

    previousWorkflowData = [...previousWorkflowData, ...phoneWorkflowdata]

    const initialValues = {
      scenarios: [
        {
          customScenario: scenario.customScenario?.toLowerCase() === 'beginning of call' ? '' : scenario.customScenario,
          description: scenario.customScenario?.toLowerCase() === 'beginning of call' ? 'beginning' : 'custom-scenario',
          questions: scenario.questions?.map((q: string) => ({ question: q })),
          final_collection_phrase: scenario.final_collection_phrase,
          index,
          //@ts-ignore
          previousWorkflowData,
        },
      ],
    }
    setFormInitialValues(initialValues)
    setScenarioIndex(index)
    dispatch(openModal({ modalId: MODAL_IDS.PHONE_WORKFLOW_EDIT_MODAL }))
  }

  const handleDeleteScenario = async (scenarioIndex: number, questionIndex?: number) => {
    try {
      let previousWorkflowData = []

      if (beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0) {
        previousWorkflowData.push({
          customScenario: '',
          description: 'beginning',
          questions: beginningCallWorkflowQuestions,
        })
      }

      previousWorkflowData = [...previousWorkflowData, ...phoneWorkflowdata]
      let formBuilders = previousWorkflowData

      if (questionIndex !== undefined && scenarioIndex !== undefined) {
        //@ts-ignore
        const scenario = formBuilders.find((_, index) => index === scenarioIndex)

        if (scenario && scenario.questions) {
          //@ts-ignore
          const remainingQuestions = scenario.questions.filter((_, qIndex) => qIndex !== questionIndex)

          if (remainingQuestions.length > 0) {
            scenario.questions = remainingQuestions
          } else {
            scenario.questions = []
          }
        }

        formBuilders[scenarioIndex] = scenario
      } else if (!questionIndex) {
        formBuilders.splice(scenarioIndex, 1)
      }

      formBuilders = formBuilders.map((scenario) => ({
        ...scenario,
        description: scenario.customScenario === '' ? 'beginning' : 'custom-scenario',
      }))

      await mutation.mutateAsync({
        formBuilders,
        questionIndex,
        scenarioIndex,
      })

      notification.success({ message: `Scenario ${scenarioIndex} deleted successfully.` })
      refetch()
    } catch (err) {
      console.error(err)
      notification.error({ message: 'Something went wrong, please try again later!' })
    }
  }

  const InfoComponent = () => {
    return (
      <>
        {beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0 && (
          <Card
            title={
              <div className={`${baseClass}_button-container`}>
                <span className="font-bold text-base">Beginning of call</span>
                <span>
                  <Button
                    type="primary"
                    danger
                    loading={mutation.isPending && mutation.variables.scenarioIndex === 0}
                    icon={<DeleteOutline />}
                    onClick={() => handleDeleteScenario(0)}
                  >
                    <div>Delete</div>
                  </Button>
                  <Button
                    type="primary"
                    className="ml-2"
                    icon={<EditOutlined />}
                    onClick={() =>
                      handleEditScenario(
                        {
                          customScenario: 'Beginning of call',
                          questions: beginningCallWorkflowQuestions,
                          final_collection_phrase: data?.beginning_question_final_phrase,
                        },
                        0
                      )
                    }
                  >
                    <div>Edit</div>
                  </Button>
                </span>
              </div>
            }
          >
            <>
              <div className="border-b">
                <LabelWithDescription
                  customClassName="mt-2"
                  label="Scenario Description"
                  description="Describe the scenario in which you would like to ask the caller some questions."
                />
                <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">Beginning of call</span>
              </div>
              <div className="border-b">
                <List
                  className="mt-2 mb-2 "
                  header={<strong>Questions</strong>}
                  dataSource={beginningCallWorkflowQuestions}
                  renderItem={(item, qIndex) => (
                    <List.Item className="!flex pl-1">
                      {item}
                      <Button
                        loading={
                          mutation.isPending &&
                          (qIndex === mutation.variables.questionIndex || mutation.variables.scenarioIndex)
                        }
                        onClick={() => handleDeleteScenario(0, qIndex)}
                        type="primary"
                        danger
                        icon={<DeleteOutline />}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <div>
                <LabelWithDescription customClassName="mt-2" label="Final Phrase" />
                <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{data?.beginning_question_final_phrase}</span>
              </div>
            </>
          </Card>
        )}

        {/* Other Cards: Maps over PhoneWorkflowdata */}
        {phoneWorkflowdata.map((phoneWorkflow, scenarioIndex) => (
          <Card
            key={scenarioIndex + 1}
            className="mt-4"
            title={
              <div className={`${baseClass}_button-container`}>
                <span className="font-bold text-base">{`Custom Scenario ${beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0 ? scenarioIndex + 2 : scenarioIndex + 1}`}</span>
                <span>
                  <Button
                    type="primary"
                    danger
                    icon={<DeleteOutline />}
                    loading={
                      mutation.isPending &&
                      mutation.variables.scenarioIndex ===
                        (beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0
                          ? scenarioIndex + 1
                          : scenarioIndex)
                    }
                    onClick={() =>
                      handleDeleteScenario(
                        beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0
                          ? scenarioIndex + 1
                          : scenarioIndex
                      )
                    }
                  >
                    <div>Delete</div>
                  </Button>
                  <Button
                    type="primary"
                    className="ml-2 text-xs"
                    icon={<EditOutlined />}
                    onClick={() =>
                      handleEditScenario(
                        phoneWorkflow,
                        beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0
                          ? scenarioIndex + 1
                          : scenarioIndex
                      )
                    }
                  >
                    <div>Edit</div>
                  </Button>
                </span>
              </div>
            }
          >
            <>
              <div className="border-b">
                <LabelWithDescription
                  customClassName="mt-2"
                  label="Scenario Description"
                  description={'Describe the scenario in which you would like to ask the caller some questions.'}
                />
                <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{phoneWorkflow.customScenario}</span>
                <List
                  className="mt-2 mb-2"
                  header={<strong>Questions</strong>}
                  dataSource={phoneWorkflow.questions}
                  renderItem={(questions: string, qIndex: number) => (
                    <List.Item className="!flex pl-1">
                      {questions}
                      <Button
                        loading={
                          mutation.isPending &&
                          (qIndex === mutation.variables.questionIndex || mutation.variables.scenarioIndex)
                        }
                        onClick={() =>
                          handleDeleteScenario(
                            beginningCallWorkflowQuestions && beginningCallWorkflowQuestions.length > 0
                              ? scenarioIndex + 1
                              : scenarioIndex,
                            qIndex
                          )
                        }
                        type="primary"
                        danger
                        icon={<DeleteOutline />}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <div>
                <LabelWithDescription customClassName="mt-2" label="Final Phrase" />
                <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{phoneWorkflow.final_collection_phrase}</span>
              </div>
            </>
          </Card>
        ))}
      </>
    )
  }
  const baseClass = 'phone-form'

  return (
    <div className={baseClass}>
      <OverViewCardInfo
        width={'50%'}
        formActionType="Add"
        form={
          <PhoneWorkflowForm mutation={mutation} initialValues={formInitialValues} refetch={refetch} actionType="ADD" />
        }
        formTitle={<span className="font-bold">Add New Scenerio</span>}
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.PHONE_WORKFLOW_MODAL}
        tutorialId={TUTORIALS.INTAKE_FORM_WORKFLOW_TUTORIAL}
        displayTutorialActionBtn={true}
      />
      <CustomModal
        width={'50%'}
        key={MODAL_IDS.PHONE_WORKFLOW_EDIT_MODAL}
        title={<span className="font-bold">Edit Scenerio</span>}
        children={
          <PhoneWorkflowForm
            mutation={mutation}
            showAddFieldButton={false}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType="EDIT"
            scenarioIndex={scenarioIndex}
          />
        }
        modalId={MODAL_IDS.PHONE_WORKFLOW_EDIT_MODAL}
        footer={null}
      />
    </div>
  )
}
