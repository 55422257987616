import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit'
import { Reseller, ResellerInitialState } from '@/types/TReseller'

const initialState: ResellerInitialState = {
  data: {
    voiceSubscriptionStatus: '',
    subscribedProductId: '',
    auto_scale_receptionists: false,
    purchase_quotes: '',
    unreservedReceptionists: 0,
    timestamp: '',
    reseller_type: '',
    theme_color: '',
    email_address: '',
    termsOfUseURL: '',
    subscriptionStatus: '',
    password: '',
    privacyPolicyURL: '',
    voiceSubscriptionId: '',
    totalReceptionists: 0,
    logo_s3: '',
    reseller_name: '',
    reservedReceptionists: '',
    stripe_subscriptionId: '',
    customerId: '',
    branded:true,
    logoUrl: ''
  },
  isAuthenticated: false,
  authToken: undefined,
}

export const slice: Slice<
  ResellerInitialState,
  {
    setReseller: (state: ResellerInitialState, action: PayloadAction<Reseller>) => void
    updateReseller: (state: ResellerInitialState, action: PayloadAction<Reseller>) => void
  },
  'reseller'
> = createSlice({
  name: 'reseller',
  initialState,
  reducers: {
    setReseller: (state: ResellerInitialState, action: PayloadAction<Reseller>) => {
      state.data = action.payload
      state.authToken = action.payload.token
      state.isAuthenticated = !!action.payload.token
    },
    updateReseller(state: ResellerInitialState, action: PayloadAction<Partial<Reseller>>) {
      state.data = { ...state.data, ...action.payload }
    },
  },
})

export const { setReseller, updateReseller, } = slice.actions

export default slice.reducer
