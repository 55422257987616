import { createContext, useContext, useEffect, useState } from "react";

export const GlobalContext = createContext();

export const useGlobal = () => {
  return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 768 ? true : false
  );
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [mobileStepperRightSection, setMobileStepperRightSection] =
    useState(false);
  const [currentSection, setCurrentSection] = useState(null);

  useEffect(() => {
    // add listener for window resize
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
        setIsMobile(true);
      } else {
        setSidebarOpen(true);
        setIsMobile(false);
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth <= 768) {
          setSidebarOpen(false);
          setIsMobile(true);
        } else {
          setSidebarOpen(true);
          setIsMobile(false);
        }
      });
    };
  }, []);

  const toggleSidebar = (value = null) => {
    setSidebarOpen(value !== null ? value : !sidebarOpen);
  };

  const toggleTour = (value) => {
    setIsTourOpen(value || !isTourOpen);
  };

  const toggleMobileStepperRightSection = (value) => {
    setMobileStepperRightSection(value || !mobileStepperRightSection);
  };

  const updateCurrentSection = (value) => {
    setCurrentSection(value);
  };

  return (
    <GlobalContext.Provider
      value={{
        sidebarOpen,
        isMobile,
        isTourOpen,
        mobileStepperRightSection,
        currentSection,
        toggleSidebar,
        toggleTour,
        toggleMobileStepperRightSection,
        updateCurrentSection,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
