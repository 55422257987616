// api.ts
import apiClient from "@/utils/apiClient";

export const fetchLeads = async (campaignId: string | undefined): Promise<any> => {
  if (!campaignId) {
    throw new Error('Campaign ID cannot be undefined');
  }
  const url = `/api/leads/${campaignId}/getLeads`;

  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: url,
    });

    return response

  } catch (error) {
    console.error('Error in fetchLeads:', error);
    throw new Error('Error fetching campaign client by email: ' + (error as Error).message);
  }
};
