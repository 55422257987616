import { getCallRecording } from '@/api/logs';
import { fetchCampaignEvents, fetchTranscript } from '@/api/novi/outbondAnalytics';
import { useQuery, useMutation } from '@tanstack/react-query';

export const useCampaignEvents = (email: string) => {
  return useQuery({
    queryKey: ['campaignEvents', email],
    queryFn: () => fetchCampaignEvents(email)
  });
};

export const useTranscript = () => {
  return useMutation<any, Error, { callSid: string; forwardedFrom: string }>({
    mutationFn: ({ callSid, forwardedFrom }) => fetchTranscript(callSid, forwardedFrom)
  });
};

export const useRecording = () => {
  return useMutation<{ callSid: string; url: string }, Error, string>({
    mutationFn: (callSid) => getCallRecording(callSid)
  });
};
