import { useState } from 'react'
import { Button, Tag, Space, Table, Tooltip, Input, Typography, Layout } from 'antd'
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInNoviUser } from '@/store/novi/user/selector'
//@ts-ignore
import RegisterPageV3 from '../../../../../v1/RegisterPageV3.jsx'
import { Content, Header } from 'antd/es/layout/layout'
import { useDispatch } from 'react-redux'
import { setNoviSelectedCampaign, setNoviSelectedCampaignId } from '@/store/novi/user/slice'
import { fetchToken } from '@/api/user'
import LocalStorageUtility from '@/utils/localStorage'
import { LocalStorageKeys } from 'src/enums/ELocalStorage'
import { useCampaignClient } from './hooks/useCampaignClient'

const { Title } = Typography

const NoviCampaigns: React.FC = () => {
  const dispatch = useDispatch()
  const noviUser = useAppSelector(getLoggedInNoviUser)
  const { data: client, isLoading } = useCampaignClient(noviUser.email_address)

  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [searchText, setSearchText] = useState('');

  const getToken = async (campaign_id: string) => {
    try {
      const response = await fetchToken(campaign_id);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_RECEPTIONIST_AUTH_TOKEN, response.token);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const handleOpenCampaign = async (number: string) => {
    await getToken(number);
    dispatch(setNoviSelectedCampaignId(number))
    dispatch(setNoviSelectedCampaign(client?.items.find(item => item.number === number)))
  }

  const columns = [
    {
      title: 'Business Name',
      dataIndex: 'business_name',
      key: 'business_name',
      render: (text: string) => (
        <Tooltip title={text}>
          <span>{text.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Business Type',
      dataIndex: 'businessType',
      key: 'businessType',
      render: (tag: string) => (
        tag ? <Tag color="blue">{tag}</Tag> : <Tag>No Business Type</Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Open">
            <Button type="primary" onClick={() => handleOpenCampaign(record.number)}>Open</Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null))
    dispatch(setNoviSelectedCampaign(null))
    setShowCreateCampaign(false);
  };

  const handleCreateCampaign = () => {
    setShowCreateCampaign(true);
  };

  const filteredData = client?.items.filter(item =>
    item.business_name.toLowerCase().includes(searchText.toLowerCase())
  );

  if (showCreateCampaign) {
    return (
      <Layout>
        <Header style={{ background: '#fff', padding: '0 16px' }}>
          <Button icon={<ArrowLeftOutlined />} onClick={handleBack} style={{ marginRight: 16 }}>
            Back to Campaigns
          </Button>
        </Header>
        <Content style={{ padding: '24px' }}>
          <RegisterPageV3
            isCampaign
            debrand
            campaign_user_email={noviUser.email_address}
            campaign_user_password={noviUser.password}
            purchase_quotes={noviUser.purchase_quotes}
            campaign_user_name={noviUser.campaign_user_name}
          />
        </Content>
      </Layout>
    );
  }


  return (
    <Space direction="vertical" style={{ width: '100%' }} >
      <Title level={2}>My Campaigns</Title>
      <div className="flex flex-wrap justify-between items-center mb-4">
        <Input.Search
          className="w-72 mb-2 sm:mb-0"
          placeholder="Search campaigns"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          allowClear
        />
        <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateCampaign} className="mb-2 sm:mb-0">
          Create Campaign
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={isLoading}
        rowKey="username"
      />
    </Space >
  )
}

export default NoviCampaigns
