export enum MODAL_IDS {
  // Configuration Modals
  GREETING_FORM_MODAL = 'GREETING_FORM_MODAL',
  AI_PROMPT_MODAL = 'AI_PROMPT_MODAL',
  API_WORKFLOW_MODAL = 'API_WORKFLOW_MODAL',
  EDIT_API_WORKFLOW_MODAL = 'EDIT_API_WORKFLOW_MODAL',
  CUSTOMIZE_VOICE_AND_LANGUAGES_MODAL = 'CUSTOMIZE_VOICE_AND_LANGUAGES_MODAL',
  SAMPLE_QUESTION_AND_ANSWER_MODAL = 'SAMPLE_QUESTION_AND_ANSWER_MODAL',
  SAMPLE_QUESTION_AND_ANSWER_EDIT_MODAL = 'SAMPLE_QUESTION_AND_ANSWER_EDIT_MODAL',
  KNOWLEDGE_BASE_MODAL = 'KNOWLEDGE_BASE_MODAL',
  CALL_TRANSFERING_WORKFLOW_MODAL = 'CALL_TRANSFERING_WORKFLOW_MODAL',
  CALL_TRANSFERING_WORKFLOW_EDIT_MODAL = 'CALL_TRANSFERING_WORKFLOW_EDIT_MODAL',
  TEXTING_WORKFLOW_MODAL = 'TEXTING_WORKFLOW_MODAL',
  TEXTING_WORKFLOW_EDIT_MODAL = 'TEXTING_WORKFLOW_EDIT_MODAL',
  PHONE_WORKFLOW_EDIT_MODAL = 'PHONE_WORKFLOW_EDIT_MODAL',
  PHONE_WORKFLOW_MODAL = 'PHONE_WORKFLOW_MODAL',
  TWEAK_ADVANCE_SETTING_MODAL = 'TWEAK_ADVANCE_SETTING_MODAL',
  SUBSCRIPTION_MODAL = 'SUBSCRIPTION_MODAL',
  PLANS_MODAL = 'PLANS_MODAL',
  POST_CALL_WEBHOOK_MODAL = 'POST_CALL_WEBHOOK_MODAL',
  POST_CALL_WEBHOOK_EDIT_MODAL = 'POST_CALL_WEBHOOK_EDIT_MODAL',
  HANGUP_WORKFLOW_SETTING_MODAL = 'HANGUP_WORKFLOW_SETTING_MODAL',
  ZAPIER_INTEGRATION_MODAL = 'ZAPIER_INTEGRATION_MODAL',
  EXTENSION_DIGIT_MODAL = 'EXTENSION_DIGIT_MODAL',
  TIME_CONTROL_MODAL = 'TIME_CONTROL_MODAL',
  PRONUNCIATION_GUIDE_MODAL = 'PRONUNCIATION_GUIDE_MODAL',
  MAX_USAGE_LIMIT_MODAL = 'MAX_USAGE_LIMIT_MODAL',
  CANCEL_REASON_MODAL = 'CANCEL_REASON_MODAL',
  RESELLER_ZAPIER_INSTRUCTION_MODAL = 'RESELLER_ZAPIER_INSTRUCTION_MODAL',
  CALENDAR_FORM_MODAL = 'CALENDAR_FORM_MODAL',

  // Feature Tutorial Modals
  CALL_TRANSFERING_WORKFLOW_TUTORIAL = 'CALL_TRANSFERING_WORKFLOW_TUTORIAL',
}
