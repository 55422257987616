import loader from '../../assets/images/loader.gif';
const Loader = () => (
    <div className="loader">
      <img src={loader} alt="Loading..." style={{
        width: '100px',
        height: '100px',
        margin: "auto"

      }}   />
    </div>
  );

export default Loader;
  