import { AvailableNumber } from '@/interfaces/IReservedNumber'
import apiClient from '@/utils/apiClient'
import posthog from 'posthog-js'

const GET_PROVISIONED_NUMBER_API_PATH = '/api/v2/receptionist-dashboard/provisioned-number'
const UPDATE_TESTING_NUMBER_API_PATH = '/api/v2/receptionist-dashboard/testing-number'
const GET_TOTAL_CALLS_API_PATH = '/api/v2/receptionist-dashboard/total-calls'
const FETCH_LIST_AVAILABLE_PHONE_NUMBER_LOCAL_API_PATH = '/api/twilio/listAvailablePhoneNumberLocal'
const GET_NON_US_NUMBER_REQUIREMENT = '/api/twilio/regulations'
const POST_PROVISION_NUMBER_API_PATH = '/provision-number'
const FETCH_SUBMIT_COMPLIANCE_DATA_API_PATH = '/api/twilio/twilioPhoneNumberCompliance'
const GET_TWILIO_BUNDLE_STATUS_API_PATH = '/api/twilio/fetchBundle'
const PUT_RESET_BUNDLE_API_PATH = '/api/twilio/resetBundle'

export const getProvisionedNumber = async (): Promise<{ provisioned_number: string | null }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_PROVISIONED_NUMBER_API_PATH,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const updateTestingNumber = async (payload: { testing_number: string }): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: UPDATE_TESTING_NUMBER_API_PATH,
      body: payload,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const makeTestCall = async (): Promise<{ success: boolean }> => {
  posthog.capture('making_a_test_call')
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: '/api/v2/receptionist-dashboard/test-call',
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const getTotalCalls = async (): Promise<{ totalCalls: number }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_TOTAL_CALLS_API_PATH,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const purchaseSubscription = async (payload: {
  rewardfulReferral: string | null
}): Promise<{ success: boolean; redirectPaymentURL: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/v2/receptionist-dashboard/purchase-subscription',
      body: payload,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

// provision number request
export const getNonUsNumberRequirement = async (payload: { countryCodeNumber: string }): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_NON_US_NUMBER_REQUIREMENT}/${payload.countryCodeNumber}`,
    })

    return response // Assuming the data is returned in the "data" key
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const fetchNumbersFn = async (
  selectedCountryCode: string,
  localNumber: string,
  setLoading: (loading: boolean) => void,
  setQueried: (queried: boolean) => void
) => {
  try {
    setLoading(true)

    // Use apiClient instead of axios
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${FETCH_LIST_AVAILABLE_PHONE_NUMBER_LOCAL_API_PATH}/${selectedCountryCode}/${localNumber}`,
    })
    if (response.success) {
      return response
    }
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch numbers')
  } finally {
    setLoading(false)
    setQueried(true)
  }
}

export const provisionNumberRequest = async (payload: {
  username?: string
  countryCode: string
  dryRun: string
  localChoosen?: string | null | AvailableNumber
  bundleId?: string | null
  addressId?: string | null
}): Promise<{
  status: string
  provisionedNumber: string
}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: POST_PROVISION_NUMBER_API_PATH,
      body: payload,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const submitComplianceDataFn = async (formPayload: FormData) => {
  return await apiClient.request({
    method: 'POST',
    endPoint: `${FETCH_SUBMIT_COMPLIANCE_DATA_API_PATH}`,
    body: formPayload,
  })
}

export const getTwilioBundleStatus = async (twilioBundleId: string): Promise<{ status: string }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${GET_TWILIO_BUNDLE_STATUS_API_PATH}/${twilioBundleId}`,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const resetTwilioBundle = async (username?: string): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: `${PUT_RESET_BUNDLE_API_PATH}/${username}`,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}
