import React from "react";
import Fade from "react-reveal/Fade";

const EmailRegisterForm = ({
  onChangeEmail,
  classes,
  setStepState,
  toast,
  emailAddress,
  setEmailAddress,
  onNext,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: "center",
            }}
          >
            Email Address
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: "center",
            }}
          >
            Your email address
          </div>

          <input
            type="email"
            value={emailAddress}
            className={classes.input + " " + classes.centered}
            required
            onChange={onChangeEmail}
            placeholder="e.g. john@ai_dma.com"
          />

          <button
            type="button"
            onClick={() => {
              if (emailAddress) {
                if (onNext) onNext();
                else setStepState(6);
              } else toast.error("Please enter your email address");
            }}
            className={classes.button + " " + classes.centered}
            style={{
              marginTop: "2rem",
            }}
          >
            Continue
          </button>
        </Fade>
      </div>
    </>
  );
};

export default EmailRegisterForm;
