export const options = [
  { value: 'All', label: 'All' },
  // { value: '11labs', label: '11labs' },
  { value: 'deepgram', label: 'deepgram' },
  { value: 'cartesia', label: 'cartesia' },
]

export const genderOptions = [
  { value: 'All', label: 'All' },
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
]

export const languageOptions = [
  { value: 'English', label: 'English' },
  { value: 'Other Language', label: 'Other Language' },
]
