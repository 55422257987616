import { Alert, Layout, Skeleton } from 'antd'
import ResellerSider from './ResellerSider'
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems'
import './ResellerDashboard.scss'
import { FC, ReactNode, useMemo, useState } from 'react'
import ResellerPromo from './ResellerPromo'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'src/enums/ERoutes'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getResellerData } from '@/store/reseller/selector'
import { getResellerLogo } from '@/api/unbrandedReceptionist'
import { useQuery } from '@tanstack/react-query'
import { Loading } from '@/components/Loading'
import { useDispatch } from 'react-redux'
import { updateReseller } from '@/store/reseller/slice'

const menuToRouteMap: Record<ResellerSiderItem, string> = {
  [ResellerSiderItem.MY_RECEPTIONISTS]: AppRoutes.RESELLER_PORTAL,
  [ResellerSiderItem.EMBED]: AppRoutes.RESELLER_EMBED,
  [ResellerSiderItem.BRANDING]: AppRoutes.RESELLER_BRANDING,
  [ResellerSiderItem.TUTORIALS]: AppRoutes.RESELLER_TUTORIALS,
  [ResellerSiderItem.SUBSCRIPTION]: AppRoutes.RESELLER_SUBSCRIPTION,
}
const ResellerDashboard: FC<{ children: ReactNode; activeKey: ResellerSiderItem }> = ({ children, activeKey }) => {
  const navigate = useNavigate()
  const currentReseller = useAppSelector(getResellerData)
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  const handleMenuSelect = (key: ResellerSiderItem) => {
    const route = menuToRouteMap[key]
    if (route) {
      navigate(route)
    }
  }

  const { data: resellerLogo, isLoading } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: currentReseller.logo_s3 }),
    enabled: !!currentReseller.logo_s3,
  })

  const resellerLogoUrl = useMemo(() => {
    if (!resellerLogo) return
    const logoUrl = URL.createObjectURL(resellerLogo)
    //@ts-ignore
    dispatch(updateReseller({ logoUrl }))
    return logoUrl
  }, [resellerLogo])

  const baseClass = 'reseller-dashboard-container'
  return (
    <>
      <Alert className={`${baseClass}_reseller-promo`} description={<ResellerPromo />} closable={true} />
      <Layout className={baseClass}>
        <ResellerSider setLoading={setLoading} activeMenuItem={activeKey} onMenuSelect={handleMenuSelect} />
        {loading ? (
          <Loading />
        ) : (
          <Layout>
            <div className="px-4 pt-4 flex items-center gap-2">
              {isLoading ? (
                <Skeleton.Avatar size={'large'} shape="square" />
              ) : resellerLogoUrl ? (
                <img className="w-16 h-16 rounded-lg" src={resellerLogoUrl} alt="logo" />
              ) : (
                ''
              )}
              <span className="font-semibold text-2xl">{currentReseller.reseller_name}</span>
            </div>
            {children}
          </Layout>
        )}
      </Layout>
    </>
  )
}

export default ResellerDashboard
