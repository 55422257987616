import { Alert, Button, Layout, Tour, TourProps } from 'antd'
import './ReceptionistDashboard.scss'
import ReceptionistDashboardSider from './ReceptionistSider/ReceptionistSider'
import { useCallback, useEffect, useRef, useState } from 'react'
import ContentComponent from './ContentComponent/ContentComponent'
import { Setting } from './Setting'
import Logo from '@/assets/images/mobile-log.png'
import { getLoggedInUser, getUserInstructionSteps, getcurrentPage } from '@/store/account/selector'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { CONTACT_SUPPORT_EMAIL } from 'src/constants'
import { UpgradeSubBanner } from '@/components/UpgradeSubBanner/UpgradeSubBanner'
import { useMutation, useQuery } from '@tanstack/react-query'
import { updateStepsCompleted } from '@/api/user'
import { setInstructionSteps, setCurrentPage } from '@/store/account/slice'
import { useDispatch } from 'react-redux'
import posthog from 'posthog-js'
import SubscriptionUpgradeModal from '@/components/SubscriptionUpgradeModal/SubscriptionUpgradeModal'
import { setReseller } from '@/store/reseller/slice'
import { useSearchParams } from 'react-router-dom'
import { getReceptionistReseller } from '@/api/unbrandedReceptionist'
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem'
import ReceptionistPromo from './ReceptionistPromo'
import { Onboarding } from './TestItOut/v2/Onboarding'

export const ReceptionistDashboard: React.FC<{ debrand: boolean }> = ({ debrand }) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const instructionSteps = useAppSelector(getUserInstructionSteps)
  const loggedInUser = useAppSelector(getLoggedInUser)
  const menuRef = useRef<HTMLDivElement>(null)
  const overviewRef = useRef<HTMLElement | null>(null)
  const testCallBtnRef = useRef<HTMLDivElement | null>(null)
  const logsRef = useRef<HTMLElement | null>(null)
  const crmRef = useRef<HTMLElement | null>(null)
  const gcalRef = useRef<HTMLDivElement>(null)
  const intakeRef = useRef<HTMLDivElement>(null)
  const apiWorkflowRef = useRef<HTMLDivElement>(null)
  const [isPromoVisibile, setPromoVisible] = useState<boolean>(!debrand)
  const [openedOnboarding, setOpenedOnboarding] = useState(false)

  const currentPage = useAppSelector(getcurrentPage)

  const [open, setOpen] = useState(false)
  const [receiveTestCallTourOpen, setReceiveTestCallTourOpen] = useState(false)

  const handleMenuSelect = (key: ReceptionistDashboardSiderItem) => {
    dispatch(setCurrentPage(key))
  }
  const { data } = useQuery({
    queryKey: ['getResellerInfoByReceptionist'],
    queryFn: () => getReceptionistReseller({ resellerEmail: loggedInUser.number || '' }),
    enabled: !!(debrand && loggedInUser.reseller_email), // Only run the query if the debrand is true
  })

  useEffect(() => {
    if (data) {
      dispatch(setReseller(data.reseller))
    }
  }, [data])

  useEffect(() => {
    posthog.capture('dashboard_loaded')
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CONFIGURE))
  }, [searchParams, dispatch, instructionSteps])

  const getOverviewTarget = useCallback(() => overviewRef.current || document.createElement('div'), [overviewRef])
  const getLogsTarget = useCallback(() => logsRef.current || document.createElement('div'), [logsRef])
  const getCrmTarget = useCallback(() => crmRef.current || document.createElement('div'), [crmRef])
  const getGcalTarget = useCallback(() => gcalRef.current || document.createElement('div'), [gcalRef])
  const getIntakeTarget = useCallback(() => intakeRef.current || document.createElement('div'), [intakeRef])
  const getApiWorkflowTarget = useCallback(
    () => apiWorkflowRef.current || document.createElement('div'),
    [apiWorkflowRef]
  )
  const newSteps: TourProps['steps'] = [
    {
      title: 'Welcome to My AI Front Desk!',
      description: 'Our AI Receptionist will bring you more customers. Answer the phone 24/7. Never miss a call again.',
      cover: <img alt="tour.png" src={Logo} style={{ width: '25%', height: '25%', flex: 1, margin: 'auto' }} />,
    },
    {
      title: 'Tweak it!',
      description: "Edit of your Receptionist's settings here. It's easy!",
      target: getOverviewTarget,
    },
    {
      title: 'View Logs',
      description: 'All your logs, in one place.',
      target: getLogsTarget,
    },
    {
      title: 'CRM',
      description: 'Manage, process and collect leads with the power of AI.',
      target: getCrmTarget,
    },
    {
      title: 'Intake Workflow Forms',
      description: 'Intake specific data from the user during the call using Intake Workflows.',
      target: getIntakeTarget,
    },
    {
      title: 'Google Calendar Scheduling Workflow',
      description: 'Schedule meetings through the AI on your Google Calendar.',
      target: getGcalTarget,
    },
    {
      title: 'API Workflows',
      description:
        'Advanced but powerful 💪. Connect to external APIs and utilise the response into the call realtime!',
      target: getApiWorkflowTarget,
    },
    {
      title: '🎉 Tutorial Complete',
      description: `For help, email ${CONTACT_SUPPORT_EMAIL}. This concludes the tutorial. Start by exploring your dashboard. 
    When you're ready to receive your AI receptionist, come back here to start your trial`,
      cover: <img alt="tour.png" src={Logo} style={{ width: '25%', height: '25%', flex: 1, margin: 'auto' }} />,
    },
  ]

  const getReceiveTestCallBtnTarget = useCallback(
    () => testCallBtnRef.current || document.createElement('div'),
    [testCallBtnRef]
  )

  const receiveTestCallButtonSteps: TourProps['steps'] = [
    {
      title: 'Receive a test call.',
      description: 'Use this button to again receive a test call from your AI.',
      target: getReceiveTestCallBtnTarget,
    },
  ]

  const { mutate } = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      setOpen(false)
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }))
    },
  })

  const onTourClose = () => {
    setOpen(false)
    mutate({ stepNumber: 3 })
    setOpenedOnboarding(true)
  }

  const onReceiveTestCallTourClose = () => {
    setReceiveTestCallTourOpen(false)
  }

  const baseClass = 'app-dashboard'
  return (
    <div className={baseClass}>
      {!debrand && (
        <>
          <Alert
            className={`${baseClass}__promo`}
            description={<ReceptionistPromo />}
            onClose={() => setPromoVisible(false)}
            closable={true}
          />
          {loggedInUser?.subscriptionType === 'basic' && loggedInUser?.subscriptionStatus === 'active' && (
            <Alert
              className={`${baseClass}__promo`}
              closable={true}
              description={<UpgradeSubBanner />}
              closeIcon={<Button type="primary">Free Consultation?</Button>}
            />
          )}
        </>
      )}
      <Layout className={`${baseClass}__layout`}>
        <ReceptionistDashboardSider
          activeMenuItem={currentPage}
          onMenuSelect={handleMenuSelect}
          menuRef={menuRef}
          overviewRef={overviewRef}
          logsRef={logsRef}
          crmRef={crmRef}
          debrand={debrand}
          openedOnboarding={openedOnboarding}
          setOpenedOnboarding={setOpenedOnboarding}
          tourOpen={open}
        />
        <Setting isPromoVisibile={isPromoVisibile} debrand={debrand} />
        <Layout>
          <ContentComponent
            currentPage={currentPage}
            setOpened={setOpen}
            debrand={debrand}
            gcalRef={gcalRef}
            intakeRef={intakeRef}
            apiWorkflowRef={apiWorkflowRef}
            setOpenedOnboarding={setOpenedOnboarding}
          />
        </Layout>
      </Layout>
      {!loggedInUser.reseller_email && (
        <>
          <Tour open={open} onClose={onTourClose} steps={newSteps} closeIcon={false} />
          <Tour
            open={receiveTestCallTourOpen}
            onClose={onReceiveTestCallTourClose}
            steps={receiveTestCallButtonSteps}
            closeIcon={false}
          />
        </>
      )}

      <SubscriptionUpgradeModal />
      {!loggedInUser.reseller_email && (
        <Onboarding
          setOpened={setOpen}
          tourOpened={open}
          openedOnboarding={openedOnboarding}
          setOpenedOnboarding={setOpenedOnboarding}
          setReceiveTestCallTourOpen={setReceiveTestCallTourOpen}
          testCallBtnRef={testCallBtnRef}
        />
      )}
    </div>
  )
}
