import { OverViewCardInfo } from '../OverviewCardInfo'
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query'
import { GreetingPhraseForm } from './GreetingPhraseForm'
import { MODAL_IDS } from 'src/enums/EModal'
import { User } from '@/types/TAccounts'

export const GreetingPhraseInfo: React.FC<{
  data: any
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
}> = ({ data, refetch }) => {
  const systemPhrase = data?.system_phrase || ''
  const InfoComponent = () => {
    return (
      <span
        className="pl-4 w-9/12 mt-1 pt-2 pb-4"
        dangerouslySetInnerHTML={{ __html: systemPhrase.replace(/\n/g, '<br />') }}
      ></span>
    )
  }
  return (
    <OverViewCardInfo
      info={<InfoComponent />}
      formActionType="Edit"
      form={<GreetingPhraseForm system_phrase={systemPhrase} refetch={refetch} />}
      width={'80%'}
      footer={null}
      modalId={MODAL_IDS.GREETING_FORM_MODAL}
    />
  )
}
