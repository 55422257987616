import React, { useState } from 'react';
import { Alert, Button, Form, Input, notification, Typography, Upload } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { PictureOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setNoviUser } from '@/store/novi/user/slice';
import LocalStorageUtility from '@/utils/localStorage';
import { registerNewNoviUser } from '@/api/user';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';

const { Title, Text } = Typography;

interface RegisterFormData {
  businessName: string;
  email: string;
  password: string;
}

export const CampaignRegister: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: async (values: RegisterFormData) => {
      if (!logoFile) {
        throw new Error('Please upload a logo');
      }
      return registerNewNoviUser({ ...values, logo: logoFile as File })
    },
    onSuccess: (data: any) => {
      console.log(data);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN, data.campaignUser.auth_token);
      LocalStorageUtility.setLocalData(LocalStorageKeys.NOVI_USER, data.campaignUser);
      dispatch(setNoviUser({ ...data.campaignUser }));
      navigate('/novi-portal/');
    },
    onError: (error: any) => {
      notification.error({
        message: error.message,
        description: 'An error occurred while registering your account',
      });
    }
  });

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      notification.error({
        message: 'Invalid file type',
        description: 'Please upload an image file',
      });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        message: 'File is too large',
        description: 'Image must be smaller than 2MB',
      });
      return false;
    }

    setLogoFile(file);
    const previewURL = URL.createObjectURL(file);
    setLogoPreview(previewURL);
    return false; // Prevent automatic upload
  };

  const handleRemoveLogo = () => {
    if (logoPreview) {
      URL.revokeObjectURL(logoPreview);
    }
    setLogoPreview(null);
    setLogoFile(null);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => mutation.mutate(values)}
            className="space-y-6"
          >
            <div className="text-center mb-8">
              <Title level={2} className="mb-2">Create Account</Title>
              <Text className="text-gray-500">Register for your campaign portal</Text>
            </div>

            {/* Logo Upload */}
            <Form.Item label="Business Logo">
              <div className="flex justify-center">
                <div className="relative">
                  <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    className="cursor-pointer"
                  >
                    <div className="w-32 h-32 rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors">
                      {logoPreview ? (
                        <div className="relative w-full h-full">
                          <img
                            src={logoPreview}
                            alt="Business logo"
                            className="w-full h-full object-cover rounded-lg"
                          />
                          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg">
                            <Text className="text-white">Change Logo</Text>
                          </div>
                        </div>
                      ) : (
                        <>
                          <PictureOutlined style={{ fontSize: '24px', color: '#bfbfbf' }} />
                          <Text className="text-gray-500 mt-2">Upload Logo</Text>
                        </>
                      )}
                    </div>
                  </Upload>
                  {logoPreview && (
                    <Button
                      icon={<CloseOutlined />}
                      onClick={handleRemoveLogo}
                      className="absolute -top-2 -right-2 rounded-full w-8 h-8 p-0 flex items-center justify-center bg-white shadow-md hover:bg-gray-100"
                    />
                  )}
                </div>
              </div>
            </Form.Item>

            <Form.Item
              label="Business Name"
              name="businessName"
              rules={[{ required: true, message: 'Please input your business name!' }]}
            >
              <Input size="large" placeholder="Enter your business name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email!' }
              ]}
            >
              <Input size="large" placeholder="Enter your email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
                { min: 8, message: 'Password must be at least 8 characters!' }
              ]}
            >
              <Input.Password size="large" placeholder="Enter your password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={mutation.isPending}
                className="w-full h-12"
              >
                Register
              </Button>
            </Form.Item>

            {mutation.isError && (
              <Alert
                message={mutation.error?.message || 'An error occurred'}
                type="error"
                showIcon
                className="mb-4"
              />
            )}

            <div className="text-center mt-6">
              <Text className="text-gray-600">
                Already have an account?{' '}
                <Link to="/novi-portal/login" className="text-blue-600 hover:text-blue-500">
                  Login Now
                </Link>
              </Text>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};