import { Navigate, Outlet } from 'react-router-dom'
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { setNoviUser } from '@/store/novi/user/slice';
import { NoviUser } from '../types/NoviUser';

const NoviPublicRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const authenticationStatus = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN)
  const isAuthenticated = !!authenticationStatus;

  if (isAuthenticated) {
    const noviUser = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_USER);
    dispatch(setNoviUser(noviUser as NoviUser));
  }

  return isAuthenticated ? <Navigate to='/novi-portal/' /> : <Outlet />
}

export default NoviPublicRoute;