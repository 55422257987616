import CommonSider from '@/components/Sider/Sider'
import { MenuProps } from 'antd'
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems'
import {
  Groups2Outlined,
  LinkOutlined,
  SellOutlined,
  DescriptionOutlined,
  SubscriptionsOutlined,
  LogoutOutlined,
} from '@mui/icons-material'
import MobileLogo from '@/assets/images/mobile-log.png'
import DesktopLogo from '@/assets/images/desktop-logo.png'
import LocalStorageUtility from '@/utils/localStorage'
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile'
import { useEffect, useRef } from 'react'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getResellerData } from '@/store/reseller/selector'
import ResellerTour from './ResellerTour/ResellerTour'

const ResellerSider: React.FC<{
  onMenuSelect: (key: ResellerSiderItem) => void
  activeMenuItem: ResellerSiderItem
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ onMenuSelect, activeMenuItem, setLoading }) => {
  const { isMobile } = useMobileDeviceCheck()
  const currentReseller = useAppSelector(getResellerData)
  const allReceptionistRef = useRef(null)
  const brandingRef = useRef(null)
  const tutorialRef = useRef(null)

  const items: MenuProps['items'] = [
    {
      key: ResellerSiderItem.MY_RECEPTIONISTS,
      icon: <Groups2Outlined />,
      label: <div ref={allReceptionistRef}>{ResellerSiderItem.MY_RECEPTIONISTS}</div>,
    },

    {
      key: ResellerSiderItem.EMBED,
      icon: <LinkOutlined className="rotate-45" />,
      label: ResellerSiderItem.EMBED,
    },
    {
      key: ResellerSiderItem.BRANDING,
      icon: <SellOutlined />,
      label: <div ref={brandingRef}>{ResellerSiderItem.BRANDING}</div>,
    },
    {
      key: ResellerSiderItem.TUTORIALS,
      icon: <DescriptionOutlined />,
      label: <div ref={tutorialRef}>{ResellerSiderItem.TUTORIALS}</div>,
    },
    {
      key: ResellerSiderItem.SUBSCRIPTION,
      icon: <SubscriptionsOutlined />,
      label: ResellerSiderItem.SUBSCRIPTION,
    },
    {
      key: 'Logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      danger: true,
    },
  ]

  const handleClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'Logout') {
      LocalStorageUtility.clearLocalData()
      window.location.reload()
    } else {
      onMenuSelect(e.key as ResellerSiderItem)
    }
  }

  const AppLogo = (
    <div className="px-4 py-2">
      {isMobile ? <img src={MobileLogo} alt="logo" /> : <img src={DesktopLogo} alt="logo" />}
    </div>
  )

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <>
      <CommonSider
        items={items}
        logo={currentReseller.branded ? AppLogo : ''}
        activeMenuItem={activeMenuItem}
        handleClick={handleClick}
        openedOnboarding={true}
        tourOpen={true}
        setOpenedOnboarding={() => {}}
      />
      {(activeMenuItem === ResellerSiderItem.MY_RECEPTIONISTS ||
        activeMenuItem === ResellerSiderItem.TUTORIALS ||
        activeMenuItem === ResellerSiderItem.BRANDING) && (
        <ResellerTour allReceptionistRef={allReceptionistRef} brandingRef={brandingRef} tutorialRef={tutorialRef} />
      )}
    </>
  )
}

export default ResellerSider
