import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

const accountState = (state: RootState) => state.account

export const getAuthenticationStatus = createSelector([accountState], (accountState) => accountState.isAuthenticated)

export const getLoggedInUser = createSelector([accountState], (accountState) => accountState.user)

export const getUserInstructionSteps = createSelector(
  [accountState],
  (accountState) => accountState.user.instruction_steps
)

export const getUserFeatures = createSelector([accountState], (accountState) => accountState.user.features)

export const getcurrentPage = createSelector([accountState], (accountState) => accountState.currentPage)
