import { ModalState } from '@/types/TModal'
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit'
import { MODAL_IDS } from 'src/enums/EModal'

const initialState: ModalState = {
  isOpen: false,
  modalId: null,
  data: null,
}

export const slice: Slice<
  ModalState,
  {
    openModal: (state: ModalState, action: PayloadAction<{ modalId: MODAL_IDS; data?: unknown }>) => void
    closeModal: (state: ModalState) => void
  },
  'modal'
> = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state: ModalState, action: PayloadAction<{ modalId: MODAL_IDS; data?: unknown }>) => {
      state.modalId = action.payload.modalId
      state.data = action.payload.data
    },
    closeModal: (state: ModalState) => {
      state.modalId = null
      state.data = null
    },
  },
})

export const { openModal, closeModal } = slice.actions
export default slice.reducer
