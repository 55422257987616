import React from 'react'
import { Typography, Button, Card, Space, notification, Spin, Row, Col, Statistic, Divider, Progress } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  StopOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createClientPortalSession,
  cancelSubReason,
  getBillingPortal,
  getBillingOverview,
  textWatermarkRemovalCheckout,
} from '@/api/user'
import './SubscriptionManagement.scss'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import { openModal } from '@/store/modal/slice'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { MODAL_IDS } from 'src/enums/EModal'
import { UpgradeOutlined } from '@mui/icons-material'
import CancelSubReasonModal from '@/components/CancelSubReasonModal/CancelSubReasonModal'
const { Text } = Typography

const debrand = false

const SubscriptionManagementPage: React.FC = () => {
  const loggedInUser = useAppSelector(getLoggedInUser)
  const username = loggedInUser.number as string
  const dispatch = useAppDispatch()

  const { data: overviewData, isLoading: overviewLoading } = useQuery({
    queryKey: ['billing-overview'],
    queryFn: getBillingOverview,
  })

  const updatePaymentMehtodMutation = useMutation({
    mutationKey: ['update-payment-method'],
    mutationFn: createClientPortalSession,
    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error updating payment method' })
      } else {
        notification.success({
          message: 'Redirecting to payment portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
  })

  const CancelReasonMutation = useMutation({
    mutationKey: ['cancel-subscription-reason'],
    mutationFn: cancelSubReason,
    onSuccess: (data) => {
      if (!data?.success) {
        notification.error({ message: 'Error canceling subscription' })
      } else {
        cancelSubMutation.mutate({ username: username, action: 'subscription_cancel' })
      }
    },
    onError: () => {
      notification.error({ message: 'Error canceling subscription' })
    },
  })

  const cancelSubMutation = useMutation({
    mutationKey: ['cancel-subscription'],
    mutationFn: createClientPortalSession,
    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error canceling subscription' })
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
  })

  const upgradeSubMutation = useMutation({
    mutationKey: ['update-subscription'],
    mutationFn: createClientPortalSession,
    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error upgrading subscription' })
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
    onError: () => {
      notification.error({ message: 'Error upgrading subscription' })
    },
  })

  const clientPortalMutation = useMutation({
    mutationKey: ['client-portal'],
    mutationFn: getBillingPortal,
    onSuccess: (data) => {
      if (!data?.success) {
        notification.error({ message: 'Error processing request' })
      } else {
        window.location.href = data.data?.redirectionURL as string
      }
    },
    onError: () => {
      notification.error({ message: 'Error processing request' })
    },
  })

  const watermarkRemovalMutation = useMutation({
    mutationKey: ['watermark-removal'],
    mutationFn: textWatermarkRemovalCheckout,
    onSuccess: (data) => {
      if (!data?.success) {
        notification.error({ message: 'Error removing watermark' })
      } else {
        notification.success({
          message: 'Redirecting to checkout...',
        })
        window.location.href = data?.data?.url as string
      }
    },
    onError: () => {
      notification.error({ message: 'Error removing watermark' })
    },
  })

  const handleUpgrade = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          "You don't have an active subscription to upgrade. Please subscribe to a subscription or contact support.",
      })
      return
    }
    upgradeSubMutation.mutate({ username: username, action: 'subscription_update' })
  }

  const handleUpdatePaymentMethod = () => {
    updatePaymentMehtodMutation.mutate({ username: username, action: 'payment_method_update' })
  }

  const handleCancelSubscription = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          "You don't have an active subscription to cancel. Please subscribe to a subscription or contact support.",
      })
      return
    }
    dispatch(openModal({ modalId: MODAL_IDS.CANCEL_REASON_MODAL }))
  }

  const handleWatermarkRemoval = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          'You don’t have an active subscription to remove watermark. Please subscribe to a subscription or contact support.',
      })
      return
    }
    watermarkRemovalMutation.mutate()
  }

  const handleBillingPortal = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          "You don't have an active subscription to view billing portal. Please subscribe to a subscription or contact support.",
      })
      return
    }
    clientPortalMutation.mutate()
  }

  return (
    <div className="subscription-management">
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <Spin spinning={overviewLoading}>
            <Card>
              <Space direction="vertical" size="large">
                <Row gutter={[16, 16]} align="middle">
                  <Col xs={24} sm={12}>
                    <Text type="secondary">Subscription Plan</Text>
                    <div>
                      <CheckCircleOutlined className="text-green-500 mr-2" />
                      <Text strong>{overviewData?.data?.basePlan || 'N/A'}</Text>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Text type="secondary">Status</Text>
                    <div>
                      <CheckCircleOutlined
                        className={`${overviewData?.data?.basePlanStatus === 'active' ? 'text-green-500' : overviewData?.data?.basePlanStatus === 'canceled' ? 'text-red-500' : 'text-yellow-500'} mr-2`}
                      />
                      <Text strong>{overviewData?.data?.basePlanStatus || 'N/A'}</Text>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Current Voice Usage"
                      value={overviewData?.data?.currentVoiceUsage}
                      suffix="minutes"
                      prefix={<DollarOutlined />}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Usage Charge"
                      value={overviewData?.data?.voiceUsageCharge || 0}
                      precision={2}
                      prefix="$"
                    />
                  </Col>
                </Row>
                <Divider />
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Statistic
                      title="Remaining Free Minutes"
                      value={overviewData?.data?.remainingFreeMinutes || 0}
                      suffix={`/ ${overviewData?.data?.freeMinutes || 0}`}
                      prefix={<ClockCircleOutlined />}
                    />
                    <Progress
                      percent={
                        ((overviewData?.data?.remainingFreeMinutes || 0) / (overviewData?.data?.freeMinutes || 0)) * 100
                      }
                      showInfo={false}
                      strokeColor="#1890FF"
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Button type="primary" onClick={handleBillingPortal} loading={clientPortalMutation.isPending}>
                      View Billing Portal
                    </Button>
                  </Col>
                </Row>
              </Space>
            </Card>
          </Spin>
        </Col>
        <Col xs={24} lg={8}>
          <Card>
            <Space direction="vertical" size="large">
              <div>
                <Space align="start">
                  <UpgradeOutlined className="upgrade-icon" />
                  <div>
                    <Text strong className="text-lg">
                      Upgrade Subscription
                    </Text>
                    <Text type="secondary" className="block mt-1">
                      Get more features and benefits
                    </Text>
                    <Button
                      onClick={handleUpgrade}
                      loading={upgradeSubMutation.isPending}
                      type="primary"
                      className="mt-2"
                    >
                      Upgrade Now
                    </Button>
                  </div>
                </Space>
              </div>
              <Divider />
              <div>
                <Space align="start">
                  <CreditCardOutlined className="credit-card-icon" />
                  <div>
                    <Text strong className="text-lg">
                      Update Payment Method
                    </Text>
                    <Text type="secondary" className="block mt-1">
                      Manage your billing details
                    </Text>
                    <Button
                      onClick={handleUpdatePaymentMethod}
                      loading={updatePaymentMehtodMutation.isPending}
                      className="mt-2"
                    >
                      Update Now
                    </Button>
                  </div>
                </Space>
              </div>
              <Divider />
              <div>
                <Space align="start">
                  <StopOutlined className="stop-icon" />
                  <div>
                    <Text strong className="text-lg">
                      Cancel Subscription
                    </Text>
                    <Text type="secondary" className="block mt-1">
                      Stop your subscription and billing
                    </Text>
                    <Button
                      onClick={handleCancelSubscription}
                      loading={cancelSubMutation.isPending}
                      danger
                      className="mt-2"
                    >
                      Cancel Now
                    </Button>
                  </div>
                </Space>
              </div>
              {loggedInUser?.showWaterMarkRemoval && !debrand && (
                <>
                  <Divider />
                  <div>
                    <Space align="start">
                      <StopOutlined className="stop-icon" />
                      <div>
                        <Text strong className="text-lg">
                          Remove Watermark
                        </Text>
                        <Text type="secondary" className="block mt-1">
                          Remove the watermark from your texting workflow
                        </Text>
                        {loggedInUser?.removeWatermark ? (
                          <Text type="danger" className="block mt-1">
                            You have already removed the watermark
                          </Text>
                        ) : (
                          <Button
                            onClick={handleWatermarkRemoval}
                            loading={watermarkRemovalMutation.isPending}
                            danger
                            className="mt-2"
                          >
                            Remove Now
                          </Button>
                        )}
                      </div>
                    </Space>
                  </div>
                </>
              )}
            </Space>
          </Card>
        </Col>
      </Row>
      <CancelSubReasonModal mutation={CancelReasonMutation} />
    </div>
  )
}
export default SubscriptionManagementPage
