import React from "react";
import classes from "./Registration.module.css";
import { useNavigate } from "react-router-dom";

const Header = ({ campaign }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={classes.header}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px 0",
          fontSize: "18px", // Increased font size for better readability
          background: "#f8f8f8", // Light background color
          color: "rgb(119, 129, 145)",
        }}
      >
        <button
          className={classes.navItem}
          onClick={() => window.open("https://www.myaifrontdesk.com", "_blank")}
          style={{
            padding: "8px 12px", // Added padding for better button appearance
            border: "none",
            background: "transparent", // Transparent background
            color: "inherit",
          }}
        >
          Product
        </button>
        <button
          className={classes.navItem}
          onClick={() => {
            if (campaign) navigate("/campaign_portal");
            else window.location.href = "/reseller_portal";
          }}
          style={{
            textDecoration: "none",
            cursor: "pointer",
            border: "none",
            padding: "8px 12px",
            background: "transparent",
            color: "inherit",
          }}
        >
          {campaign ? "Campaign" : "Reseller"} Login
        </button>
        <button
          className={classes.navItem}
          onClick={() =>
            window.open(
              "https://calendly.com/myaifrontdesk/partner-consultation",
              "_blank"
            )
          }
          style={{
            textDecoration: "none",
            cursor: "pointer",
            padding: "8px 12px",
            border: "none",
            background: "transparent",
            color: "inherit",
          }}
        >
          Book Consultation
        </button>
        <button
          className={classes.navItem}
          onClick={() =>
            (window.location.href =
              "mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you.")
          }
          style={{
            textDecoration: "none",
            cursor: "pointer",
            padding: "8px 12px",
            border: "none",
            background: "transparent",
            color: "inherit",
          }}
        >
          Contact
        </button>
      </div>
    </>
  );
};

export default Header;
