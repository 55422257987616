import { CustomModal } from '../CustomModal'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { closeModal } from '@/store/modal/slice'
import { MODAL_IDS } from 'src/enums/EModal'
import { Button, Typography, Space, notification } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { createClientPortalSession } from '@/api/user'
import { getLoggedInUser } from '@/store/account/selector'

const { Text, Title } = Typography

export const SubscriptionUpgradeModal = () => {
  const dispatch = useAppDispatch()
  const loggedInUser = useAppSelector(getLoggedInUser)
  const username = loggedInUser.number ?? ''

  const upgradeSubMutation = useMutation({
    mutationKey: ['update-subscription'],
    mutationFn: createClientPortalSession,

    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error upgrading subscription' })
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
  })

  const handleUpgrade = () => {
    upgradeSubMutation.mutate({ username: username, action: 'subscription_update' })
  }

  return (
    <CustomModal
      width={'30%'}
      title={null}
      modalId={MODAL_IDS.SUBSCRIPTION_MODAL}
      onClose={() => dispatch(closeModal())}
      footer={null} // We can customize the footer if needed
    >
      <Space direction="vertical" size="large" style={{ textAlign: 'center', width: '100%' }}>
        <Title level={4}>Premium Subscription</Title>
        <Text type="secondary">
          You are not eligible for this feature right now. Please upgrade your subscription to access this and more
          premium features.
        </Text>
        <Button type="primary" size="large" onClick={() => handleUpgrade()}>
          Upgrade Subscription
        </Button>
      </Space>
    </CustomModal>
  )
}

export default SubscriptionUpgradeModal
