import React, { useState, useEffect } from 'react'
import { Table, Typography, Spin, Select, Button, Modal, Card } from 'antd'
import { PhoneOutlined, EyeOutlined } from '@ant-design/icons'
import { Lead } from '@/interfaces/ILeadsView'
import styles from '../css/main.module.css'
import LeadView from './LeadView'
const { Option } = Select

interface DataTableProps {
  leads: Lead[]
  statuses: string[]
  onUpdateLead: (updatedLead: Lead) => void
  fetchDataByStatus: (value: string) => void
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return isNaN(date.getTime()) ? '-' : new Intl.DateTimeFormat('en-GB').format(date)
}

const DataTable: React.FC<DataTableProps> = ({ leads, statuses, onUpdateLead, fetchDataByStatus }) => {
  const [selectedRow, setSelectedRow] = useState<Lead | null>(null)
  const [isLoading] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string>('all')

  useEffect(() => {
    if (selectedStatus === 'all') {
      setFilteredLeads(leads)
    } else {
      setFilteredLeads(leads.filter((lead) => lead.current_status === selectedStatus))
    }
  }, [leads, selectedStatus])

  const columns = [
    {
      title: <Typography.Text strong>Phone Number</Typography.Text>,
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text: string) => (
        <div style={{ display: 'flex', alignItems: 'center', color: '#1890ff' }}>
          <PhoneOutlined style={{ marginRight: '8px' }} />
          <Typography.Text>{text}</Typography.Text>
        </div>
      ),
    },
    {
      title: <Typography.Text strong>Current Status</Typography.Text>,
      dataIndex: 'current_status',
      key: 'current_status',
    },
    {
      title: <Typography.Text strong>First Contact</Typography.Text>,
      dataIndex: 'first_contact',
      key: 'first_contact',
      render: (text: string) => formatDate(text),
    },
    {
      title: <Typography.Text strong>Last Contact</Typography.Text>,
      dataIndex: 'last_contact',
      key: 'last_contact',
      render: (text: string) => formatDate(text),
    },
    {
      title: <Typography.Text strong>Action</Typography.Text>,
      key: 'action',
      render: (record: Lead) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => handleViewClick(record)}
          type="primary"
          style={{ borderRadius: 4 }}
          size="small"
        >
          View
        </Button>
      ),
    },
  ]

  const handleViewClick = (record: Lead) => {
    setSelectedRow(record)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedRow(null)
  }

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value)
    fetchDataByStatus(value)
  }

  return (
    <Card bordered={false} style={{ borderRadius: 8, boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)' }}>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <Spin size="large" />
        </div>
      )}

      <Select
        className={`${styles.search_input} capitalize`}
        value={selectedStatus}
        onChange={handleStatusChange}
        style={{
          width: 200,
          marginBottom: '20px',
          borderRadius: 8,
          fontSize: '14px',
        }}
        dropdownStyle={{ borderRadius: 8 }}
        placeholder="Select Status"
        showSearch
        allowClear
        optionFilterProp="children"
      >
        <Option value="all">All Leads</Option>
        {statuses?.map((status, index) => (
          <Option key={index} className="capitalize" value={status}>
            {status}
          </Option>
        ))}
      </Select>
      <hr />

      <Table
        dataSource={filteredLeads}
        columns={columns}
        rowKey="phone_number"
        pagination={{ pageSize: 5 }}
        bordered
        // rowClassName="table-row"
        rowClassName={(_record, _index) => styles['table-row']}
        className="capitalize text-md font-semibold p-0"
      />

      {isModalVisible && selectedRow && (
        <Modal open={isModalVisible} onCancel={handleModalClose} footer={null} width={1200}>
          <LeadView
            onClose={handleModalClose}
            rowData={selectedRow as any}
            statuses={statuses}
            onUpdateLead={onUpdateLead as any}
          />
        </Modal>
      )}
    </Card>
  )
}

export default DataTable
