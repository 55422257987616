import { Form, Input, Button, notification } from 'antd'
import './ResellerLoginForm.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { LoginForm } from '../../components/Form/LoginForm/LoginForm'
import PublicLayout from '@/components/Layout/PublicLayout'
import { APP_LANDING_PAGE, AppRoutes, BOOK_CONSULTATION_LANDING_PAGE } from 'src/enums/ERoutes'
import { useMemo } from 'react'
import { setReseller } from '@/store/reseller/slice'
import { resellerLogin } from '@/api/reseller'
import { useMutation } from '@tanstack/react-query'
import LocalStorageUtility from '@/utils/localStorage'
import { LocalStorageKeys } from 'src/enums/ELocalStorage'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { MenuItem } from '@/interfaces/IResellerLoginForm'


const ResellerLoginForm = () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { debrand } = (location.state as {
    from: string
    debrand: boolean
    resellerCode: string
  }) || {
    from: '/',
    debrand: false,
  }

  const mutation = useMutation({
    mutationKey: ['resellerLogin'],
    mutationFn: resellerLogin,
    onSuccess(data: any) {
      dispatch(setReseller(data.reseller))
      LocalStorageUtility.setLocalData(LocalStorageKeys.RESELLER_AUTH_TOKEN, data.reseller.token)
      navigate(AppRoutes.RESELLER_PORTAL)
    },
    onError(data) {
     notification.error({
      message: data.message
     })
    }
  })

  const handleSubmit = async (values: { emailAddress: string; password: string }) => {
    mutation.mutate(values)
  }

  const items: MenuItem[] = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RESELLER_PORTAL, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER_RESELLER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
  ]

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        if (location.pathname === AppRoutes.RESELLER_PORTAL && item.label === 'Login') {
          return false
        }
        if (location.pathname === AppRoutes.REGISTER_RESELLER && item.label === 'Register') {
          return false
        }
        return true
      }),
    [location.pathname]
  )

  const FormItems = () => (
    <>
      <div className={`${baseClass}_info-container`}>
        <h2 className={`${baseClass}_info-container_heading`}>Welcome Back</h2>
        <span className={`${baseClass}_info-container_title`}> Reseller Admin Portal</span>
      </div>
      <Form.Item
        name="emailAddress"
        label="Email"
        className={`${baseClass}__input-item`}
        rules={[
          { required: true, message: 'Email can\'t be blank!' },
          { type: 'email', message: 'Please enter a valid email address!' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Password can\'t be blank!' }]}
        label="Password"
        className={`${baseClass}__password-input-item`}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button loading={mutation.isPending} type="primary" htmlType="submit" className={`${baseClass}__submit-button`}>
          Submit
        </Button>
      </Form.Item>
      <Form.Item className={`${baseClass}__footer`}>
        <span className="register-now">
          Don't have an account? <Link to="/register_reseller">Register Now</Link>
        </span>
      </Form.Item>
    </>
  )

  const baseClass = 'reseller-login'
  return (
    <PublicLayout debrand={debrand} items={filteredItems}>
      <LoginForm
        customClassName="!my-auto"
        form={form}
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
        autoComplete={debrand ? 'off' : 'on'}
      />
    </PublicLayout>
  )
}

export default ResellerLoginForm
