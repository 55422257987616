import { getReseller } from '@/api/reseller'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getResellerAuthenticationStatus } from '@/store/reseller/selector'
import { setReseller } from '@/store/reseller/slice'
import LocalStorageUtility from '@/utils/localStorage'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { LocalStorageKeys } from 'src/enums/ELocalStorage'

const useResellerAuth = () => {
  let isAuthenticated = useAppSelector(getResellerAuthenticationStatus)

  const dispatch = useAppDispatch()
  const authenticationStatus = LocalStorageUtility.getLocalData(LocalStorageKeys.RESELLER_AUTH_TOKEN) ?? isAuthenticated

  const { data, isLoading } = useQuery({
    queryKey: ['reseller'],
    queryFn: getReseller,
    enabled: !!authenticationStatus,
  })

  useEffect(() => {
    if (data) {
      dispatch(
        //@ts-ignore
        setReseller(data.reseller)
      )
    }
  }, [data])

  return {
    isAuthenticated: authenticationStatus,
    isLoading,
  }
}

export default useResellerAuth
