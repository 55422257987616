import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

interface props {
  spinning: boolean
  children: React.ReactNode
}

const Spinner: React.FC<props> = ({ spinning, children }) => {
  return (
    <Spin spinning={spinning} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
      {children}
    </Spin>
  )
}

export default Spinner
