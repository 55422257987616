import { Button, Card, Empty, notification, Switch } from 'antd'
import { OverViewCardInfo } from '../OverviewCardInfo'
import TextingWorkflowForm from './TextingWorkflowForm'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { CustomModal } from '@/components/CustomModal'
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { openModal } from '@/store/modal/slice'
import './TextingWorkflowInfo.scss'
import { deepEqual } from '@/utils/helper'
import { User } from '@/types/TAccounts'
import { TUTORIALS } from 'src/enums/ETutorials'

interface TextingWorkflowInfoProps {
  data: any
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  mutation: UseMutationResult<{}, Error, any, unknown>
  debrand: boolean
}
export const TextingWorkflowInfo: React.FC<TextingWorkflowInfoProps> = ({ data, refetch, mutation, debrand }) => {
  const dispatch = useAppDispatch()

  const textingWorkflowData = data?.workflows ? JSON.parse(data?.workflows) : []
  const [scenarioIndex, setScenarioIndex] = useState<number>()

  const [formInitialValues, setFormInitialValues] = useState<any>(null)

  useEffect(() => {
    const previousWorkflowData = formInitialValues?.workflows[0]?.previousWorkflowData

    if (!deepEqual(previousWorkflowData, textingWorkflowData))
      setFormInitialValues({
        workflows: [
          {
            previousWorkflowData: textingWorkflowData,
          },
        ],
      })
  }, [textingWorkflowData, formInitialValues])

  const handleEditScenario = (scenario: any, index: number) => {
    const initialValues = {
      workflows: [
        {
          description: scenario.description,
          voiceResponse: scenario.voiceResponse,
          textMessage: scenario.textMessage,
          shortURLEnabled: scenario.shortURLEnabled,
          index,
          previousWorkflowData: textingWorkflowData,
          askForPermissionOnText: scenario.askForPermissionOnText,
        },
      ],
    }

    setFormInitialValues(initialValues)
    setScenarioIndex(index)
    dispatch(openModal({ modalId: MODAL_IDS.TEXTING_WORKFLOW_EDIT_MODAL }))
  }

  const handleDeleteScenario = async (index: number) => {
    try {
      // @ts-ignore
      await mutation.mutateAsync({ workflows: textingWorkflowData?.filter((_, i) => i !== index), deleteIndex: index })
      notification.success({ message: `Scenario ${index + 1} deleted successfully.` })
      refetch()
    } catch (err) {
      notification.error({ message: 'Something went wrong, please try again later!' })
    }
  }

  const InfoComponent = () => {
    return (
      <>
        {textingWorkflowData && textingWorkflowData.length > 0 ? (
          // @ts-ignore
          textingWorkflowData.map((textingWorkflow, index) => (
            <Card
              key={index + 1}
              className="mt-4"
              title={
                <div className={`${baseClass}_button-container`}>
                  <span className="font-bold text-base">{`Scenario ${index + 1}`}</span>
                  <div>
                    <Button
                      loading={mutation.isPending && mutation.variables.deleteIndex === index}
                      type="primary"
                      danger
                      icon={<DeleteOutline />}
                      onClick={() => handleDeleteScenario(index)}
                    >
                      <div>Delete</div>
                    </Button>
                    <Button
                      type="primary"
                      className="ml-2"
                      icon={<EditOutlined />}
                      onClick={() => handleEditScenario(textingWorkflow, index)}
                    >
                      <div>Edit</div>
                    </Button>
                  </div>
                </div>
              }
            >
              <>
                {!debrand && (
                  <div className="border-b">
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Enable Short URLs"
                      description="Enable this option to shorten the URLs in the text message. This will allow you to track the analytics of the URL in the Analytics section."
                    />
                    <Switch checked={textingWorkflow?.shortURLEnabled} disabled className=" mt-2 mb-4" />
                  </div>
                )}
                <div className="border-b">
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Scenario Description"
                    description="Describe the scenario in which you would like to send the caller a text message. The AI is intelligent enough to know when the scenario is triggered!"
                  />
                  <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{textingWorkflow.description}</span>
                </div>
                <div className="border-b">
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Text Message"
                    description="Specify the text you want your AI receptionist to send when the scenario is triggered."
                  />
                  <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{textingWorkflow.textMessage}</span>
                </div>
                <div>
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Voice Response"
                    description="Specify what you want your AI receptionist to say after sending the text."
                  />
                  <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">{textingWorkflow.voiceResponse}</span>
                </div>
                <div>
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Ask before sending Text SMS"
                    description="If checked in, above message will be sent when the caller confirms for the text sms"
                  />
                  <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">
                    {textingWorkflow.askForPermissionOnText ? 'Yes' : 'No'}
                  </span>
                </div>
              </>
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </>
    )
  }

  const baseClass = 'texting-workflow'

  return (
    <div className={baseClass}>
      <OverViewCardInfo
        width={'50%'}
        formActionType="Add"
        form={
          <TextingWorkflowForm
            mutation={mutation}
            initialValues={formInitialValues}
            refetch={refetch}
            actionType="ADD"
            debrand={debrand}
          />
        }
        formTitle="Add Texting Workflow"
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.TEXTING_WORKFLOW_MODAL}
        tutorialId={TUTORIALS.TEXTING_WORKFLOW_TUTORIAL}
        displayTutorialActionBtn={true}
      />

      <CustomModal
        width={'50%'}
        title={<span className="font-bold">Edit Scenerio</span>}
        children={
          <TextingWorkflowForm
            actionType="EDIT"
            showAddFieldButton={false}
            initialValues={formInitialValues}
            refetch={refetch}
            mutation={mutation}
            scenarioIndex={scenarioIndex}
            debrand={debrand}
          />
        }
        modalId={MODAL_IDS.TEXTING_WORKFLOW_EDIT_MODAL}
        footer={null}
      />
    </div>
  )
}
