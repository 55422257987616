import { CustomModal } from '@/components/CustomModal'
import { ForwardCallsProps } from '@/types/TTestItOut'
import { Button, Card, FormInstance, notification, Space, Spin } from 'antd'
import { useRef, useState } from 'react'
import { GetStandAloneNumberForm } from './GetStandAloneNumberForm'
import Title from 'antd/es/typography/Title'
import { useMutation, useQuery } from '@tanstack/react-query'
import { provisionNumberRequest, getNonUsNumberRequirement, resetTwilioBundle } from '@/api/reservedNumbers'
import { getLoggedInUser } from '@/store/account/selector'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import GetChooseNumberForm from './GetChooseNumberForm'
import { AvailableNumber } from '@/interfaces/IReservedNumber'
import GetBundleApproveForm from './GetBundleApproveForm'
import { getUser, updatePendingTestingStatus, updateStepsCompleted } from '@/api/user'
import ConfettiExplosion from 'react-confetti-explosion'

import posthog from 'posthog-js'
import { CheckCircleOutlined, LoadingOutlined, PhoneOutlined } from '@ant-design/icons'
import Paragraph from 'antd/es/typography/Paragraph'
import { setInstructionSteps } from '@/store/account/slice'
import { useDispatch } from 'react-redux'
import CustomOnboardingButton from '@/components/Onboarding/CustomButton'
const ForwardCalls = ({
  debrand,
  provisionedNumber,
  setProvisionedNumber,
  getProvisionNumberMutation,
  setOpenedOnboarding,
}: ForwardCallsProps) => {
  const formRef = useRef<FormInstance>(null)
  const [isStandAloneNumberModalVisible, setIsStandAloneNumberModalVisible] = useState<boolean>(false)
  const [isBookNumberModalVisible, setIsBookNumberModalVisible] = useState<boolean>(false)
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [ChooseNumberModalOpen, setChooseNumberModalOpen] = useState<boolean>(false)
  const [isNonUSDetailsModalOpen, setIsNonUSDetailsModalOpen] = useState(false)
  const [celeberate, setCeleberate] = useState(false)
  const dispatch = useDispatch()
  const [twilioDocsRequirements, setTwilioDocsRequirements] = useState<any>([])

  const loggedInUser = useAppSelector(getLoggedInUser)

  const UpdateStepsDoneMutation = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }))
    },
  })

  const UpdatePendingTestingStatusMutation = useMutation({
    mutationKey: ['updatePendingTestingStatus'],
    mutationFn: updatePendingTestingStatus,
  })
  const handleSetPendingTesting = (status: boolean) => {
    UpdatePendingTestingStatusMutation.mutate({ pendingTesting: status })
  }

  const updateStepsDone = (stepNumber: number) => {
    UpdateStepsDoneMutation.mutate({ stepNumber })
  }
  // provision Number Logic

  const { mutate: getNonUsNumberRequirementMutation, isPending: isRequirementDispatchPending } = useMutation({
    mutationKey: ['getNonUsNumberRequirement'],
    mutationFn: getNonUsNumberRequirement,
    onSuccess: (response) => {
      setTwilioDocsRequirements(response)
      setIsNonUSDetailsModalOpen(true)
    },
  })

  const { mutate: provisionNumberRequestMutation, isPending: isProvisionApiPending } = useMutation({
    mutationKey: ['provisionNumberRequest'],
    mutationFn: provisionNumberRequest,
    onSuccess: (response) => {
      const data = response
      if (data.status && data.status === 'success' && data.provisionedNumber) {
        getProvisionNumberMutation.mutate()
        setProvisionedNumber(data.provisionedNumber)
        setIsBookNumberModalVisible(false)
        updateStepsDone(6)
        handleSetPendingTesting(false)
        setCeleberate(true)
      }
    },
    onError: () => {},
  })
  const { refetch } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  })
  const { mutate: resetBundleMutation } = useMutation({
    mutationKey: ['resetBundle'],
    mutationFn: resetTwilioBundle,
    onSuccess: () => {
      refetch()
    },
    onError: () => {
      notification.error({ message: 'Failed to reset bundle' })
    },
  })

  const bookNumberRequest = async (localChoosen: AvailableNumber | null) => {
    if (!selectedCountry) {
      return
    }

    if (!loggedInUser?.twilioBundleId) {
      if (selectedCountry !== '( +1 ) - United States - US' && selectedCountry !== '( +1 ) - Canada - CA') {
        getNonUsNumberRequirementMutation({ countryCodeNumber: selectedCountry.split(' - ')[2] })
        setIsNonUSDetailsModalOpen(true)
        setIsBookNumberModalVisible(false)
        return
      }
    }

    var countryCode = selectedCountry.split(' - ')[2] // Something like "US"

    let payload = {
      username: loggedInUser.number,
      countryCode,
      dryRun: import.meta.env.VITE_APP_DEV_TIER || 'true',
      localChoosen: null as string | null | AvailableNumber, // Update the type to allow both string, null, and AvailableNumber
      bundleId: null as string | null,
      addressId: null as string | null,
    }
    if (localChoosen !== null) {
      payload.localChoosen = localChoosen
    }
    if (loggedInUser?.twilioBundleId && loggedInUser?.twilioAddressSids) {
      payload = {
        ...payload,
        bundleId: loggedInUser.twilioBundleId,
        addressId: loggedInUser.twilioAddressSids[0],
      }
    }

    provisionNumberRequestMutation(payload)
    setIsBookNumberModalVisible(false)
    closeStandAloneNumberModal()
  }
  // End of provision Number Logic

  const handleFormSubmit = (values: { country: string }) => {
    setSelectedCountry(values.country)
    closeStandAloneNumberModal()
    openBookNumberModal()
  }

  const handleConfirmAction = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  const openStandAloneNumberModal = () => {
    posthog.capture('trying_to_get_standalone_number')
    setIsStandAloneNumberModalVisible(true)
  }

  const closeStandAloneNumberModal = () => {
    setIsStandAloneNumberModalVisible(false)
  }

  const openBookNumberModal = () => {
    setIsBookNumberModalVisible(true)
  }

  const closeBookNumberModal = () => {
    setIsBookNumberModalVisible(false)
  }

  if (isProvisionApiPending || isRequirementDispatchPending) {
    return (
      <div className="text-center">
        <Spin />
      </div>
    )
  }
  return (
    <Card>
      {!loggedInUser.demo ? (
        <>
          {celeberate && (
            <ConfettiExplosion
              zIndex={1000}
              force={1}
              height="220vh"
              width={3000}
              particleCount={200}
              duration={5000}
            />
          )}
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Title level={2}>
              <PhoneOutlined /> Reserve your standalone number
            </Title>
            <Spin spinning={getProvisionNumberMutation.isPending}>
              {provisionedNumber ? (
                <>
                  <Paragraph>
                    Forward calls to <strong>{provisionedNumber}</strong> from your existing business phone. Or, use
                    this number as your business phone directly.
                  </Paragraph>
                  <Paragraph>
                    For instructions on how to forward calls,{' '}
                    <a
                      className="text-themeColor font-bold"
                      href="https://www.lifewire.com/how-to-forward-calls-4689010"
                    >
                      click here
                    </a>{' '}
                    .
                  </Paragraph>
                </>
              ) : loggedInUser?.twilioBundleId && loggedInUser?.twilioBundleStatus == 'twilio-approved' ? (
                <>
                  <Paragraph type="success">
                    <CheckCircleOutlined /> Regulatory process successfully completed. You can now get your standalone
                    number.
                  </Paragraph>
                  <Button type="primary" onClick={openStandAloneNumberModal} icon={<PhoneOutlined />}>
                    Get your standalone number
                  </Button>
                </>
              ) : (loggedInUser?.twilioBundleId && loggedInUser?.twilioBundleStatus == 'in-review') ||
                loggedInUser?.twilioBundleStatus == 'pending-review' ? (
                <Paragraph>
                  <LoadingOutlined /> Your information is under review. It can take up to 2 to 3 business days to get it
                  approved.
                </Paragraph>
              ) : loggedInUser?.twilioBundleId && loggedInUser?.twilioBundleStatus == 'twilio-rejected' ? (
                <>
                  <Paragraph>Your regulatory process failed due to the following reasons:</Paragraph>
                  <Paragraph type="danger">{loggedInUser?.twilioBundleFailureReason}</Paragraph>
                  <Button onClick={() => resetBundleMutation(loggedInUser?.number)}>Restart regulatory process</Button>
                </>
              ) : (
                <>
                  <Paragraph>
                    Once you get your standalone number, you can forward calls to this number or use it as your business
                    number directly. It will always be linked to this account.
                  </Paragraph>
                  <Button type="primary" onClick={openStandAloneNumberModal} icon={<PhoneOutlined />}>
                    Get your standalone number
                  </Button>
                </>
              )}
            </Spin>
            <CustomModal
              title="Get your standalone number"
              isModalOpen={isStandAloneNumberModalVisible}
              confirmAction={handleConfirmAction}
              cancelAction={closeStandAloneNumberModal}
              okButtonProps={{ loading: isProvisionApiPending }}
              okText="Get Random Number"
            >
              <GetStandAloneNumberForm
                ref={formRef}
                debrand={debrand}
                setCountry={(country: string) => setSelectedCountry(country)}
                onSubmit={handleFormSubmit}
                setChooseNumberModalOpen={setChooseNumberModalOpen}
              />
            </CustomModal>
            <CustomModal
              title="Get Your Favourite Area Code"
              isModalOpen={ChooseNumberModalOpen}
              confirmAction={() => {
                setChooseNumberModalOpen(false)
                setIsBookNumberModalVisible(false)
              }}
              cancelAction={() => setChooseNumberModalOpen(false)}
              footer={null}
            >
              <GetChooseNumberForm
                isOpen={ChooseNumberModalOpen}
                onClose={() => setChooseNumberModalOpen(false)}
                selectedCountryCode={selectedCountry.split(' - ')[2]}
                bookNumberRequest={(props) => bookNumberRequest(props ?? null)}
              />
            </CustomModal>
            <CustomModal
              title={null}
              isModalOpen={isNonUSDetailsModalOpen}
              confirmAction={() => setIsNonUSDetailsModalOpen(false)}
              cancelAction={() => setIsNonUSDetailsModalOpen(false)}
              width={700}
              footer={null}
            >
              <GetBundleApproveForm
                countryCodeNumber={selectedCountry.split(' - ')[2]}
                twilioDocsRequirements={twilioDocsRequirements.requirements}
                regulationSid={twilioDocsRequirements.regulationSid}
                getClientDetails={() => {}}
                onClose={() => setIsNonUSDetailsModalOpen(false)}
              />
            </CustomModal>
            <CustomModal
              title="Save your changes"
              isModalOpen={isBookNumberModalVisible}
              confirmAction={() => bookNumberRequest(null)}
              cancelAction={closeBookNumberModal}
            >
              <Title level={4}>Click Ok to confirm you want to buy number for {selectedCountry.split('-')[1]} </Title>
            </CustomModal>
          </Space>
        </>
      ) : (
        <>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Title level={2}>
              <PhoneOutlined /> Complete your onboarding 🚀
            </Title>
            <Paragraph>You need to activate your free trial in order to reserve a standalone phone number.</Paragraph>
            <CustomOnboardingButton
              onClick={() => {
                setOpenedOnboarding(true)
              }}
            >
              Complete Onboarding 💪
            </CustomOnboardingButton>
          </Space>
        </>
      )}
    </Card>
  )
}

export default ForwardCalls
