import { Button, Card, Empty, notification } from "antd";
import { OverViewCardInfo } from "../OverviewCardInfo";
import { LabelWithDescription } from "@/components/LabelWithdescription";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import CallTransferingWorkflowForm from "./CallTransferingWorkflowFormAdd";
import { useState } from "react";
import { CustomModal } from "@/components/CustomModal";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { MODAL_IDS } from "src/enums/EModal";
import { TUTORIALS } from "src/enums/ETutorials";
import { useAppDispatch } from "@/store/hooks/useAppDispatch";
import { openModal } from "@/store/modal/slice";
import "./CallTransferingWorkflowInfo.scss";
import { CallWorkflow, User } from "@/types/TAccounts";
import CallTransferingWorkflowFormEdit from "./CallTransferingWorkflowFormEdit";

interface CallTransferringWorkflowProps {
  data?: User;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
}
export const CallTransferingWorkflowInfo: React.FC<
  CallTransferringWorkflowProps
> = ({ data, refetch, mutation }) => {
  const dispatch = useAppDispatch();
  const callTransferingWorkflowData =
    typeof data?.callWorkflows === "string"
      ? JSON.parse(data.callWorkflows)
      : data?.callWorkflows;

  const [scenarioIndex, setScenarioIndex] = useState<number>(0);
  const [callWorkflow, setCallWorkflow] = useState<CallWorkflow | null>(null);

  const handleEditScenario = (index: number) => {
    setScenarioIndex(index);
    const val = callTransferingWorkflowData[index];
    setCallWorkflow(val);
    dispatch(
      openModal({ modalId: MODAL_IDS.CALL_TRANSFERING_WORKFLOW_EDIT_MODAL }),
    );
  };

  const handleDeleteScenario = async (index: number) => {
    try {
      await mutation.mutateAsync({
        callWorkflows: callTransferingWorkflowData?.filter(
          (_data: CallWorkflow, i: number) => i !== index,
        ),
        deleteIndex: index,
      });
      notification.success({
        message: `Scenario ${index + 1} deleted successfully.`,
      });

      refetch();
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Something went wrong, please try again later!",
      });
    }
  };

  const InfoComponent = () => {
    return (
      <>
        {callTransferingWorkflowData &&
        callTransferingWorkflowData.length > 0 ? (
          callTransferingWorkflowData.map(
            (callTransferingWorkflow: CallWorkflow, index: number) => (
              <Card
                key={index + 1}
                className="mt-4"
                title={
                  <div className={`${baseClass}_button-container`}>
                    <span className="font-bold text-base">{`Scenario ${index + 1}`}</span>
                    <div>
                      <Button
                        type="primary"
                        danger
                        icon={<DeleteOutline />}
                        loading={
                          mutation.isPending &&
                          mutation.variables.deleteIndex === index
                        }
                        onClick={() => handleDeleteScenario(index)}
                      >
                        <div>Delete</div>
                      </Button>
                      <Button
                        type="primary"
                        className="ml-2"
                        icon={<EditOutlined />}
                        onClick={() => handleEditScenario(index)}
                      >
                        <div>Edit</div>
                      </Button>
                    </div>
                  </div>
                }
              >
                <>
                  <div className="border-b">
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Scenario Description"
                      description="Describe the scenario in which you would like to transfer the call."
                    />
                    <span className="pl-4 w-8/12 mt-1 pt-2 pb-4">
                      {callTransferingWorkflow.description}
                    </span>
                  </div>
                  <div>
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Phone Number"
                      description="Specify the number you want your AI receptionist to forward the call to when the scenario is triggered."
                    />
                    <span className="pl-4 w-8/12 mt-2 pt-2 pb-4">
                      {callTransferingWorkflow.workflowForwardingNumber}
                      {callTransferingWorkflow.workflowForwardingDID ? (
                        <>
                          <span className="!inline font-extrabold mx-[0.25rem] text-blue-700 text-base">
                            #
                          </span>
                          {callTransferingWorkflow.workflowForwardingDID}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="border-b">
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Voice Response"
                      description="Specify what you want your AI receptionist to say before the call is transferred"
                    />
                    <span className="pl-4 w-8/12 mt-1 pt-2 pb-4">
                      {callTransferingWorkflow.voiceResponse}
                    </span>
                  </div>
                </>
              </Card>
            ),
          )
        ) : (
          <Empty />
        )}
      </>
    );
  };
  const baseClass = "call-transfering-workflow";
  return (
    <div className={baseClass}>
      <OverViewCardInfo
        width={"50%"}
        formActionType="Add"
        form={
          <CallTransferingWorkflowForm
            mutation={mutation}
            callTransferingWorkflowData={callTransferingWorkflowData || []}
            refetch={refetch}
          />
        }
        formTitle="Add Call Transferring Workflow"
        info={<InfoComponent />}
        footer={null}
        modalId={MODAL_IDS.CALL_TRANSFERING_WORKFLOW_MODAL}
        tutorialId={TUTORIALS.CALL_TRANSFERING_WORKFLOW_TUTORIAL}
        displayTutorialActionBtn={true}
      />
      <CustomModal
        width={"50%"}
        title={<span className="font-bold">Edit Scenerio</span>}
        children={
          <CallTransferingWorkflowFormEdit
            mutation={mutation}
            callWorkflow={callWorkflow}
            callTransferingWorkflowData={callTransferingWorkflowData || []}
            refetch={refetch}
            scenarioIndex={scenarioIndex}
          />
        }
        modalId={MODAL_IDS.CALL_TRANSFERING_WORKFLOW_EDIT_MODAL}
        footer={null}
      />
    </div>
  );
};
