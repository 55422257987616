import React, { useState } from 'react'
import { Loading } from '@/components/Loading'
import { IHeaderProps } from '@/interfaces/IHeaderProps'
import TopBar from '../Modals/TopBar'
import DeleteModal from '../Modals/DeleteModal'
import AddEditGroupModal from '../Modals/AddEditGroupModal'
import AddEditTaskModal from '../Modals/AddEditTaskModal'
import { Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

const Header: React.FC<IHeaderProps> = ({
  setIsBoardModalOpen,
  isBoardModalOpen,
  isOpenListView,
  setOpenListView,
  username,
  board,
}) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [boardType, setBoardType] = useState<'add' | 'edit'>('add')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const onDropdownToggle = () => {
    setOpenDropdown((prev) => !prev)
    setBoardType('add')
  }

  const toggleListView = () => {
    setLoading(true)
    setOpenListView((prev) => !prev)
    setTimeout(() => {
      setLoading(false)
    }, 700)
  }

  function onDeleteBtnClick(): void {
    throw new Error('Function not implemented.')
  }

  return (
    <div className="p-4  left-header  w-[100%] flex justify-between ">
      <header className="flex justify-between items-center w-[100%]">
        {/* Left Side */}
        <div className="flex items-center">
          <h3 className="truncate  md:text-2xl text-xl font-bold 0 font-sans">Autopilot CRM</h3>
        </div>

        {/* Right Side */}
        <div className="flex space-x-4 items-center md:space-x-6">
          <button className="flex items-center ml-2 md:hidden" onClick={onDropdownToggle}>
            {openDropdown ? <UpOutlined className="w-3" /> : <DownOutlined className="w-3" />}
          </button>

          <Button type="primary" htmlType="submit" onClick={toggleListView}>
            {isOpenListView ? 'Switch to Board View' : 'Switch to List View'}
          </Button>

          {isLoading && (
            <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 dark:bg-[#2b2c37] dark:bg-opacity-75 z-10">
              <Loading />
            </div>
          )}
        </div>
      </header>

      {/* Modals and Dropdowns */}
      {openDropdown && (
        <div className="absolute top-full left-0 right-0 mt-2 shadow-md rounded-xl">
          <TopBar setOpenDropdown={setOpenDropdown} username={username} />
        </div>
      )}

      {isTaskModalOpen && (
        <AddEditTaskModal
          setIsTaskModalOpen={setIsTaskModalOpen}
          type="add"
          device="mobile"
          taskIndex={0}
          prevColIndex={0}
          setIsAddTaskModalOpen={setIsTaskModalOpen}
        />
      )}

      {isBoardModalOpen && <AddEditGroupModal type={boardType} setIsBoardModalOpen={setIsBoardModalOpen} />}

      {isDeleteModalOpen && (
        <DeleteModal
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          type="board"
          title={board?.name}
          onDeleteBtnClick={onDeleteBtnClick}
        />
      )}
    </div>
  )
}

export default Header
