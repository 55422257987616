import { getReactivateCheckoutURL } from '@/api/user'
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Typography, Card } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import './ReactivateAccount.scss'

const { Text } = Typography

const ReactiveAccount = () => {
  const { username } = useParams()

  const {
    data,
    isLoading,
    error: apiError,
  } = useQuery({
    queryKey: ['getReactivateCheckoutURL', username],
    queryFn: () => getReactivateCheckoutURL(username as string),
  })

  useEffect(() => {
    if (data?.success && data.data?.url) {
      window.location.href = data.data.url
    }
  }, [data])

  const baseClass = 'reactivate-account'

  return (
    <div className={baseClass}>
      {isLoading ? (
        <div className={`${baseClass}__loader`}>
          <LoadingOutlined style={{ fontSize: '64px', color: '#1890ff' }} />
          <Text className={`${baseClass}__text`}>Fetching offer...</Text>
        </div>
      ) : apiError ? (
        <div className={`${baseClass}__error`}>
          <ExclamationCircleOutlined style={{ fontSize: '64px', color: '#ff4d4f' }} />
          <Card className={`${baseClass}__error-card`}>
            <Text className={`${baseClass}__error-text`}>{apiError.message}</Text>
          </Card>
        </div>
      ) : null}
    </div>
  )
}

export default ReactiveAccount
