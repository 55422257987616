import React, { useState, useEffect } from 'react'
import { Button, Input, List, Card, Typography, Space, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons'
import '../css/Sidebar.css'
import { useGetSingleLeadNotes, useUpdateLeadNotes, useDeleteNote } from 'src/Hooks/UseHookForCrmData'
import { LeadNotesResponse } from '@/interfaces/IColumn'

// Define the types for the note object
interface Note {
  id: number
  timestamp: string
  content: string
}

// Define types for props
interface SidebarProps {
  rowData: {
    username: string
    lead_id: number
    notes: string
  }
  onNotesUpdate: (updatedNotes: string) => void | undefined | any
  onUpdateLead: (lead: { id: number;[key: string]: any }) => void | undefined | any
}

const { TextArea } = Input
const { Paragraph } = Typography

const Sidebar: React.FC<SidebarProps> = ({ rowData }) => {
  const { mutate: getSingleLeadNotes, data: leadNotes }: { mutate: Function; data: LeadNotesResponse | undefined } =
    useGetSingleLeadNotes()
  const [newNote, setNewNote] = useState<string>('')
  const [editingNoteId, setEditingNoteId] = useState<number | null>(null)
  const [notes, setNotes] = useState<Note[]>([])


  useEffect(() => {
    const mergeNotes = () => {
      let combinedNotes: Note[] = []

      // Parse the notes from rowData
      if (rowData.notes) {
        try {
          const parsedNotes: Note[] = JSON.parse(rowData.notes)
          combinedNotes = [...parsedNotes]
        } catch (error) {
          console.error('Error parsing notes:', error)
        }
      }

      // Add notes from leadNotes if available
      if (leadNotes?.notes) {
        combinedNotes = [...combinedNotes, ...leadNotes.notes]
      }

      // Remove duplicates based on `id`
      const uniqueNotes = Array.from(new Map(combinedNotes.map((note) => [note.id, note])).values()) as Note[];

      // Sort notes by `timestamp` in descending order (latest first)
      const sortedNotes = uniqueNotes.sort((a, b) =>  new Date(a.timestamp).getTime()-new Date(b.timestamp).getTime() );

      // Update state with sorted notes
      setNotes(sortedNotes);
    };

    mergeNotes();
  }, [rowData.notes, leadNotes]);


  useEffect(() => {
    if (rowData.lead_id) {
      getSingleLeadNotes({ username: rowData.username, lead_id: rowData.lead_id.toString() })
    }
  }, [rowData.lead_id])

  const { mutate: updateLeadaNotesApi } = useUpdateLeadNotes()
  const { mutate: deleteNoteApi } = useDeleteNote()

  const handleAddNote = () => {
    if (newNote.trim()) {
      const newNoteObj: Note = {
        id: Date.now(),
        timestamp: new Date().toISOString(),
        content: newNote.trim(),
      }
      try {
        const updatedNotes = [...notes, newNoteObj]
        const updatingDataNotest = {
          notes: JSON.stringify(updatedNotes),
          username: rowData.username,
          lead_id: rowData.lead_id.toString(), // Convert to string
        }
        updateLeadaNotesApi(updatingDataNotest, {
          onSuccess: () => {
            setNotes(updatedNotes)
            setNewNote('')
          },
          onError: (error) => {
            console.error('Error saving note:', error)
          },
        })
      } catch (error) {
        console.error('Error saving note:', error)
      }
    }
  }

  const handleEditNote = (noteId: number, content: string) => {
    setEditingNoteId(noteId)
    setNewNote(content)
  }
  const handleCancelEdit = () => {
    setEditingNoteId(null)
    setNewNote('')
  }

  const handleUpdateNote = () => {
    if (newNote.trim() && editingNoteId) {
      try {
        const updatedNote: Note = {
          id: editingNoteId,
          timestamp: new Date().toISOString(),
          content: newNote.trim(),
        }
        const updatedNotes = notes.map((note) => (note.id === editingNoteId ? updatedNote : note))
        const updatingDataNotestforupdate = {
          notes: JSON.stringify(updatedNotes),
          username: rowData.username,
          lead_id: rowData.lead_id,
        }

        updateLeadaNotesApi(updatingDataNotestforupdate, {
          onSuccess: () => {
            setNotes(updatedNotes)
            setNewNote('')
            setEditingNoteId(null)
          },
          onError: (error) => {
            console.error('Error updating note:', error)
          },
        })
        setNotes(updatedNotes)
        setNewNote('')
        setEditingNoteId(null)
      } catch (error) {
        console.error('Error updating note:', error)
      }
    }
  }

  const handleDeleteNote = (noteId: number) => {
    try {
      const updatedNotes = notes.filter((note) => note.id !== noteId)
      const updatingDataNotest = {
        notes: JSON.stringify(updatedNotes),
        note_id: noteId,
        username: rowData.username,
        lead_id: rowData.lead_id,
      }
      deleteNoteApi(updatingDataNotest, {
        onSuccess: () => {
          setNotes(updatedNotes)
        },
        onError: (error) => {
          console.error('Error deleting note:', error)
        },
      })
    } catch (error) {
      console.error('Error deleting note:', error)
    }
  }

  return (
    <Card className="sidebar" >
      <div className='border p-4 border-gray-300 rounded-lg shadow-lg'>
        <div className='text-lg font-semibold'>Notes</div>
        {notes.length > 0 && (
          <List
            // header={<div>Notes ({notes.length})</div>}
            // bordered
            dataSource={notes}
            renderItem={(note) => (
              <List.Item
                className="shadow-sm"
                actions={[
                  <Tooltip title="Edit">
                    <Button type="text" icon={<EditOutlined />} onClick={() => handleEditNote(note.id, note.content)} />
                  </Tooltip>,
                  <Tooltip title="Delete">
                    <Button
                      type="text"
                      style={{ color: 'red' }} // Button text color
                      icon={<DeleteOutlined style={{ color: 'red' }} />}
                      onClick={() => handleDeleteNote(note.id)}
                    />
                  </Tooltip>,
                ]}
              >
                <List.Item.Meta
                  title={new Date(note.timestamp).toLocaleString()}
                  description={<Paragraph>{note.content}</Paragraph>}
                />
              </List.Item>
            )}
          />
        )}
      </div>

      <div className='border p-4 mt-5 border-gray-300 rounded-lg shadow-lg'>
        <Typography.Title level={4} style={{ marginBottom: '8px' }}>
          {editingNoteId ? 'Edit Note' : 'Add New Note'}
        </Typography.Title>
        <TextArea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Enter your note here"
          rows={4}
          style={{ marginBottom: '8px' }}
        />
        <Space>
          <Button type="primary" icon={<SaveOutlined />} onClick={editingNoteId ? handleUpdateNote : handleAddNote}>
            {editingNoteId ? 'Update' : 'Add'}
          </Button>
          {editingNoteId && (
            <Button icon={<CloseOutlined />} onClick={handleCancelEdit}>
              Cancel
            </Button>
          )}
        </Space>
      </div>
    </Card>
  )
}

export default Sidebar
