import { LabelWithDescription } from '@/components/LabelWithdescription'
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { DeleteOutline } from '@mui/icons-material'
import { Button, Card, Flex, Form, Input, notification, Select, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { OverViewCardForm } from '../OverviewCardForm'
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query'
import { InitialScenario } from '@/types/TPhoneForm'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { User } from '@/types/TAccounts'

interface PhoneWorkflowFormProps {
  initialValues?: InitialScenario
  actionType: 'EDIT' | 'ADD'
  showAddFieldButton?: boolean
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  mutation: UseMutationResult<{}, Error, any, unknown>
  scenarioIndex?: number
}

const PhoneWorkflowForm: React.FC<PhoneWorkflowFormProps> = ({
  initialValues,
  showAddFieldButton = true,
  refetch,
  actionType,
  mutation,
  scenarioIndex,
}) => {
  const [form] = Form.useForm()
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (initialValues && actionType === 'EDIT') {
      form.setFieldsValue(initialValues)
    }

    if (actionType === 'ADD') {
      form.resetFields()
    }

    return () => {
      form.resetFields()
    }
  }, [initialValues, form, actionType])

  const handleSubmit = async (values: {
    scenarios: {
      customScenario: string
      final_collection_phrase: string
      questions: {
        question: string
      }[]
      description?: string
    }[]
  }) => {
    try {
      let formBuilders: {
        customScenario: string
        final_collection_phrase: string
        questions: {
          question: string
        }
      }[] = []

      if (values.scenarios.filter((secenario) => secenario.description === 'beginning').length > 1) {
        const beginningIndex = values.scenarios
          .map((scenario) => scenario.description)
          .reduceRight((lastIndex, description, index) => {
            return description === 'beginning' && lastIndex === -1 ? index : lastIndex
          }, -1)

        if (beginningIndex > -1)
          form.setFields([
            {
              name: ['scenarios', beginningIndex, 'description'],
              errors: ['There can be only one Beginning of call scenario'],
            },
          ])

        return
      }

      const updatedScenario = values.scenarios.map((scenario) => ({
        customScenario:
          scenario?.description?.toLowerCase() === 'beginning'.toLowerCase() ? '' : scenario.customScenario,
        description: scenario?.description
          ? scenario?.description
          : scenario.customScenario === 'Beginning of call'
            ? 'beginning'
            : 'custom-scenario',
        final_collection_phrase: scenario.final_collection_phrase,
        questions: scenario.questions.map((q) => q.question),
      }))

      if (initialValues?.scenarios && initialValues.scenarios?.[0]?.previousWorkflowData) {
        //@ts-ignore
        formBuilders = [...initialValues.scenarios[0].previousWorkflowData]
      }

      if (actionType === 'EDIT') {
        //@ts-ignore
        formBuilders.splice(scenarioIndex, updatedScenario.length, ...updatedScenario)
      } else if (actionType === 'ADD') {
        // @ts-ignore
        formBuilders = [...formBuilders, ...updatedScenario]
      }

      formBuilders = formBuilders.map((scenario) => ({
        ...scenario,
        description: scenario.customScenario === '' ? 'beginning' : 'custom-scenario',
      }))

      await mutation.mutateAsync({ formBuilders })

      refetch()
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      })

      setIsFieldTouchReset(false)
      dispatch(closeModal())
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Something went wrong, please try again later!',
      })
    }
  }

  const onValuesChange = (changedValues: any) => {
    if (changedValues.scenarios) {
      changedValues.scenarios.forEach((changedScenario: any, index: number) => {
        if (changedScenario.description) {
          form.setFields([
            {
              name: ['scenarios', index, 'description'],
              errors: [],
            },
          ])
        }
      })
    }
  }

  const FormItems = () => (
    <Form.List name="scenarios" initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => {
            const hasBeginningCallScenario = !!(
              initialValues?.scenarios.length &&
              //@ts-ignore
              initialValues?.scenarios[0].previousWorkflowData &&
              //@ts-ignore
              initialValues.scenarios[0].previousWorkflowData.find(
                //@ts-ignore
                (scenario) => scenario.description === 'beginning' && scenario.questions.length > 0
              )
            )

            return (
              <Flex key={key} className="mb-2" gap={8}>
                <Card
                  className="flex-1"
                  title={
                    <div className="flex justify-between">
                      <span className="font-bold text-base">Scenario {(scenarioIndex ?? 0) + index + 1}</span>
                      {fields.length > 1 && showAddFieldButton && (
                        <Button type="primary" danger icon={<DeleteOutline />} onClick={() => remove(name)}>
                          Delete
                        </Button>
                      )}
                    </div>
                  }
                >
                  <div className="border-b">
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Scenario Description"
                      description="Describe the scenario in which you would like to ask the caller some questions."
                    />
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      initialValue={!hasBeginningCallScenario ? 'beginning' : 'custom-scenario'}
                    >
                      <Select
                        disabled={hasBeginningCallScenario}
                        options={[
                          { value: 'beginning', label: 'Beginning of Call' },
                          { value: 'custom-scenario', label: 'Define a custom scenario' },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {(formInstance) => {
                        const workflowScenarios =
                          (formInstance.getFieldsValue() && formInstance.getFieldsValue()?.scenarios) ?? []

                        //@ts-ignore
                        const customScenarioIndex = workflowScenarios?.findIndex(
                          //@ts-ignore
                          (fieldItem) => fieldItem['description'] === 'custom-scenario'
                        )

                        return (
                          customScenarioIndex === index && (
                            <Form.Item
                              {...restField}
                              name={[name, 'customScenario']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please provide a custom scenario description',
                                },
                              ]}
                            >
                              <Input.TextArea
                                className="mt-3"
                                placeholder="Ask the new user some qualifying questions. Execute this anytime the user mentions they are a new customer."
                              />
                            </Form.Item>
                          )
                        )
                      }}
                    </Form.Item>
                  </div>

                  <div className="border-b">
                    <LabelWithDescription
                      customClassName="mt-2 mb-2"
                      label="Questions"
                      description="The questions you would like to ask the user in this scenario."
                    />
                    <Form.List name={[name, 'questions']} initialValue={[{}]}>
                      {(questionFields, { add: addQuestion, remove: removeQuestion }) => (
                        <>
                          {questionFields.map(({ key: qKey, name: qName, ...qRestField }) => (
                            <Flex key={qKey} align="baseline" gap={8}>
                              <Form.Item
                                className="flex-1"
                                {...qRestField}
                                name={[qName, 'question']}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (!value || value.trim() === '') {
                                        return Promise.reject("Question can't be blank")
                                      }
                                      return Promise.resolve()
                                    },
                                  },
                                ]}
                              >
                                <Input placeholder="What is your first and last name?" />
                              </Form.Item>
                              {questionFields.length > 1 && (
                                <MinusCircleOutlined onClick={() => removeQuestion(qName)} />
                              )}
                            </Flex>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => addQuestion()} block icon={<PlusOutlined />}>
                              Add Questions
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>

                  <div>
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Final Phrase"
                      description="The phrase you would like to use to end the conversation."
                    />
                    <Form.Item
                      {...restField}
                      name={[name, 'final_collection_phrase']}
                      initialValue="Thanks, I have collected your information. Is there anything else I can help you with?"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value || value.trim() === '') {
                              return Promise.reject("Final Phrase can't be blank")
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <Input placeholder="Thanks, I have collected your information. Is there anything else I can help you with?" />
                    </Form.Item>
                  </div>
                </Card>
              </Flex>
            )
          })}
          {showAddFieldButton && (
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Intake Form Workflow
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  )

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          onValuesChange={onValuesChange}
          initialValues={actionType === 'EDIT' && initialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  )
}

export default PhoneWorkflowForm
