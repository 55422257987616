import React, { useEffect, useState } from 'react'
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, CircularProgress, Pagination } from '@mui/material'
import PublicLayout from '@/components/Layout/PublicLayout'
import { APP_LANDING_PAGE, AppRoutes, BOOK_CONSULTATION_LANDING_PAGE, PRIVACY_POLICY_LANDING_PAGE, TERMS_OF_USE_LANDING_PAGE } from 'src/enums/ERoutes'
import apiClient from '@/utils/apiClient'
import { useNavigate } from 'react-router-dom'


interface BlogPost {
  id: string;
  name: string;
  'main-image': string;
  slug: string;
  // Add other properties as needed
}

interface BlogData {
  blog: {
    success: boolean;
    data: BlogPost[];
    pageCount: number;
    totalCount: number;
    totalPages: number;
  };
}

const ArticleCards: React.FC<{ blogs: BlogPost[], onCardClick: (id: string, slug: string) => void }> = ({ blogs, onCardClick }) => (
  <Grid container spacing={3}>
    {blogs.map((post) => (
      <Grid item xs={12} sm={6} md={4} key={post.id}>
        <Card onClick={() => onCardClick(post.id, post.slug)} style={{ cursor: 'pointer' }}>
          <CardMedia
            component="img"
            height="140"
            image={post['main-image']}
            alt={post.name}
          />
          <CardContent>
            <Typography variant="h6" component="div">
              {post.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
)

export const getBlogs = async (page: number): Promise<BlogData> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `/blogs`,
      params: { type: "pages", page: page.toString() }
    })
    return response
  } catch (err) {
    throw err
  }
}

const Blogs: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogPost[]>([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const navigate = useNavigate()

  const items = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
    { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
    { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
  ]

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true)
      try {
        const response = await getBlogs(page)
        setBlogs(response.blog.data) 
        setTotalPages(response.blog.totalPages || 1)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }

    fetchBlogs()
  }, [page])

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleCardClick = (_id: string, slug: string) => {
    navigate(`/blog/${slug}`)
  }

  return (
    <PublicLayout debrand={false} items={items}>
      <Container maxWidth="lg" style={{ marginTop: '80px' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <ArticleCards blogs={blogs} onCardClick={handleCardClick} />
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination 
                count={totalPages} 
                page={page} 
                onChange={handlePageChange} 
                color="primary" 
              />
            </Box>
          </>
        )}
      </Container>
    </PublicLayout>
  )
}

export default Blogs