import React from "react";
import { getContrastingColor } from "../../utils/TextColorHelper";

const GuideBanner = ({ setIsBannerActive, color }) => {
  return (
    <>
      <div
        className="shadow-lg"
        style={{
          background: color,
          color: getContrastingColor(color),
          padding: "5px 15px",
          borderRadius: "8px",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        <div></div>
        <div className="">
          Need help setting up? Let us guide you through it.{" "}
          <a
            href="https://calendly.com/myaifrontdesk/partner-consultation"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Book an onboarding call here for free
          </a>
        </div>
        <div
          onClick={() => setIsBannerActive(false)}
          style={{ fontSize: "32px", cursor: "pointer" }}
        >
          &times;
        </div>
      </div>
    </>
  );
};

export default GuideBanner;
