import { Button, Card, Layout, Typography, Space, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import ConfettiExplosion from 'react-confetti-explosion'
import logo from '@/assets/images/My AI Front Desk Dashboard_files/Blue_Logo.png'
import backgroundImage from '@/assets/images/My AI Front Desk Dashboard_files/receptionist.jpg'
import './WatermarkRemovalSuccess.scss'

const { Content } = Layout
const { Title, Text } = Typography

const WatermarkRemovalSuccess = () => {
  const navigate = useNavigate()

  return (
    <Layout className="payment-success-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Content>
        <ConfettiExplosion zIndex={1000} force={1} height="220vh" width={3000} particleCount={200} duration={5000} />
        <Card bordered={false} className="payment-success-card">
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Image src={logo} preview={false} width={100} className="logo" />
            <Title level={2} className="heading">
              Payment Successful!
            </Title>
            <Text className="paragraph">Watermark has been removed from your texting workflow.</Text>
            <Button
              type="primary"
              size="large"
              block
              className="button"
              onClick={() => {
                navigate('/')
              }}
            >
              Enter Dashboard
            </Button>
          </Space>
        </Card>
      </Content>
    </Layout>
  )
}

export default WatermarkRemovalSuccess
