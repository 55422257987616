import React, { useEffect, useState } from 'react'
import { Tabs, Card, Typography, Space, Tag } from 'antd'
import { InfoCircleOutlined, CalendarOutlined } from '@ant-design/icons'
import '../css/ActivityLogs.css'
import { useMutation } from '@tanstack/react-query'
import { getCallLogs, getCallRecording } from '@/api/logs'
import { CallLog } from '@/interfaces/ILogs'
import { Button } from 'antd'
import { CustomModal } from '@/components/CustomModal'
import { CallDetails } from '../../LogsComponent/CallLogs/CallDetails'

interface StatusItem {
  status_type: string
  status: string
  timestamp: string
}

interface ActivityLogProps {
  rowData: {
    phone_number: any
    status_history: string | StatusItem[]
  }
}

export type LastKey = {
  date: string
  startedTimestamp: number
}

const { TabPane } = Tabs
const { Text, Title } = Typography

const PAGE_SIZE = 10

const ActivityLog: React.FC<ActivityLogProps> = ({ rowData }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [script, setScript] = useState<string>('')
  const [callSummary, setCallSummary] = useState<string | undefined>(undefined)
  const [callLogs, setCallLogs] = useState<CallLog[]>([])
  const [lastKey, setLastKey] = useState<LastKey | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const statusHistory: StatusItem[] =
    typeof rowData?.status_history === 'string' ? JSON.parse(rowData?.status_history) : rowData?.status_history

  const handleConfirm = () => {
    setShowModal(false)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const handleClick = (transcript: string, summary: string | undefined) => {
    setShowModal(true)
    setScript(transcript)
    setCallSummary(summary)
  }

  const { mutate: callRecordingMutation, status: getCallRecordingStatus } = useMutation({
    mutationKey: ['getCallRecording'],
    mutationFn: getCallRecording,
    onSuccess(data) {
      const updatedCallLogs = callLogs.map((log: CallLog) => {
        if (log.callSid === data.callSid) {
          return { ...log, audio: data.url }
        }
        return log
      })
      setCallLogs(updatedCallLogs)
    },
  })

  const handleGetCallRecording = (callSid: string) => {
    callRecordingMutation(callSid)
  }

  const fetchCallLogs = async (lastKey?: LastKey) => {
    setIsLoading(true)
    const result = await getCallLogs({
      limit: PAGE_SIZE,
      from: rowData?.phone_number,
      lastKey,
    })

    if (result.callLogs) {
      setCallLogs((prevLogs) => {
        const newLogs = result.callLogs.filter(
          (newLog) => !prevLogs.some((existingLog) => existingLog.callSid === newLog.callSid)
        )
        return [...prevLogs, ...newLogs]
      })
      setLastKey(result.lastKey || null)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchCallLogs()
  }, [])

  const handleLoadMore = () => {
    if (!isLoading && lastKey) {
      fetchCallLogs(lastKey)
    }
  }
  return (
    <div className="activity-log border p-4 border-gray-300 rounded-lg shadow-lg" style={{ padding: '20px' }}>
      <CustomModal
        title={<strong>Call Log Details</strong>}
        children={<CallDetails script={script} callSummary={callSummary} />}
        isModalOpen={showModal}
        confirmAction={handleConfirm}
        cancelAction={handleCancel}
      />
      <Tabs defaultActiveKey="1" tabPosition="top" style={{ marginBottom: '20px' }}>
        <TabPane
          tab={
            <span className={`font-semibold text-black `}>
              <InfoCircleOutlined /> Status History
            </span>
          }
          key="1"
        >
          {statusHistory && Array.isArray(statusHistory) ? (
            statusHistory.map((status, index) => (
              <Card
                title={
                  <Space size="small" className="flex items-center px-0">
                    <Tag color="blue" className="capitalize">
                      {status.status_type}
                    </Tag>
                    <span className="font-semibold text-lg">Lifecycle Change</span>
                  </Space>
                }
                key={index}
                style={{ marginBottom: '16px' }}
                bordered={false}
              >
                <Text>
                  Updated the lifecycle stage for this contact to <strong>{status.status}</strong>.
                </Text>
                <br />
                <Text type="secondary">
                  <CalendarOutlined /> {new Date(status.timestamp).toLocaleString()}
                </Text>
              </Card>
            ))
          ) : (
            <Card style={{ textAlign: 'center' }}>
              <Text>No status history available.</Text>
            </Card>
          )}
        </TabPane>
        <TabPane
          tab={
            <span className="font-semibold text-sm text-black">
              <InfoCircleOutlined /> Call Logs
            </span>
          }
          key="2"
        >
          {callLogs?.length > 0 ? (
            <>
              {callLogs.map((log: CallLog) => (
                <Card
                  title={
                    <Title level={5}>
                      <CalendarOutlined /> {new Date(log?.date).toLocaleString()}
                    </Title>
                  }
                  className="my-6"
                  bordered={false}
                >
                  <Space size="small" className="p-4">
                    <Button type="default" onClick={() => handleClick(log?.transcript, log?.callSummary)}>
                      View Call Transcript
                    </Button>
                    <Button
                      type="default"
                      onClick={() => handleGetCallRecording(log?.callSid)}
                      loading={getCallRecordingStatus === 'pending'}
                    >
                      Listen
                    </Button>
                  </Space>
                </Card>
              ))}
              {lastKey && (
                <div>
                  <Button onClick={handleLoadMore} loading={isLoading}>
                    Load More
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Card style={{ textAlign: 'center' }}>
              <Text style={{ fontWeight: 'semibold', fontSize: '18px' }}>
                It looks like there are no call logs available right now.
              </Text>
              <br />
              <Text>Don’t worry! Please check back soon, or reach out to our support team if you need assistance.</Text>
            </Card>
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ActivityLog
