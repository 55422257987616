import { useState, useEffect } from 'react';
import {
  Form, Input, Select, Button, Typography, Row, Col,
  DatePicker, Card, Divider, InputNumber, Space,
  Spin,
  Tag,
  Modal
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { getCampaignSettings, launchCampaign, useSaveCampaignSettings } from '../../hooks/useCampaignClient';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign, getSelectedCampaignId } from '@/store/novi/user/selector';
import { RocketLaunchOutlined } from '@mui/icons-material';

const { Title, Text } = Typography;
const timeZoneList = momentTimeZone.tz.names()

interface CampaignSettings {
  name: string;
  maxRetries: string;
  timeBetweenRetries: string;
  allowedTimesStart: string;
  allowedTimesEnd: string;
  timezone: string;
  startDate: string;
  endDate: string;
  days: number[];
  allowedStatuses: string[];
}

const initialSettings: CampaignSettings = {
  name: '',
  maxRetries: '',
  timeBetweenRetries: '',
  allowedTimesStart: '',
  allowedTimesEnd: '',
  timezone: '',
  startDate: '',
  endDate: '',
  days: [],
  allowedStatuses: [],
};

const allowedStatusesOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Contacted', label: 'Contacted' },
  { value: 'Interested', label: 'Interested' },
  { value: 'Not Interested', label: 'Not Interested' },
];

const daysOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

const CampaignScheduleForm: React.FC = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [existingSettings, setExistingSettings] = useState<CampaignSettings | null>(null);
  const [launchModalVisible, setLaunchModalVisible] = useState(false);
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const campaginID = useAppSelector(getSelectedCampaignId)

  const { mutate: campaignStatus, isPending: isLaunching } = launchCampaign({
    onSuccess: () => {
      setLaunchModalVisible(false);
      refetch();
    }
  })


  const { mutate: saveCampaignSettings, isPending } = useSaveCampaignSettings({
    onSuccess: () => {
      refetch()
      setIsEditing(false);
    },
  });

  const { data, isLoading, refetch, isRefetching } = getCampaignSettings(campaginID as string);

  const handleLaunchCampaign = async () => {
    campaignStatus({ campaign_id: campaginID as string, email: selectedCampaign.campaign_user_email })
  }

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const formattedSettings = {
        name: data.name,
        maxRetries: data.maxRetries,
        timeBetweenRetries: data.timeBetweenRetries,
        allowedTimesStart: data.allowedTimesStart,
        allowedTimesEnd: data.allowedTimesEnd,
        timezone: data.timezone,
        startDate: data.startDate ? moment(data.startDate) : null,
        endDate: data.endDate ? moment(data.endDate) : null,
        days: data.days,
        allowedStatuses: data.allowedStatuses,
      };
      //@ts-ignore
      setExistingSettings(formattedSettings);
      form.setFieldsValue(formattedSettings);
    }
  }, [data, form]);

  const onFinish = (values: CampaignSettings) => {
    saveCampaignSettings({
      user_id: selectedCampaign.number,
      settings: {
        campaign_id: campaginID,
        ...values,
        start_date: values.startDate,
        end_date: values.endDate,
        call_hours: {
          start: values.allowedTimesStart,
          end: values.allowedTimesEnd,
        },
        days_to_call: values.days,
        allowed_statuses: values.allowedStatuses,
      }
    });
  }

  const timeOptions = Array.from({ length: 24 }, (_, idx) => {
    const hour = idx.toString().padStart(2, '0');
    return { value: `${hour}:00`, label: moment(`${hour}:00`, 'HH:mm').format('hh:mm A') };
  });

  if (existingSettings && !isEditing) {
    return (
      <Spin spinning={isRefetching || isLoading}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Title level={3}>Campaign Schedule Settings</Title>
              <Space >
                {data.status === 'PENDING' ?
                  <>
                    <Button
                      type="primary"
                      danger
                      icon={<RocketLaunchOutlined />}
                      onClick={() => setLaunchModalVisible(true)}
                    >
                      Launch Campaign
                    </Button>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => setIsEditing(true)}
                    >
                      Edit Settings
                    </Button>
                  </>
                  :
                  <Tag color="orange">{data.status}</Tag>
                }
              </Space>
            </div>
            <Row gutter={24}>
              <Col span={12}>
                <Title level={4}>General Settings</Title>
                <Text strong>Schedule Name:</Text> <Text>{existingSettings.name}</Text><br />
                <Text strong>Max Retries:</Text> <Text>{existingSettings.maxRetries}</Text><br />
                <Text strong>Hours Between Retries:</Text> <Text>{existingSettings.timeBetweenRetries}</Text><br />
                <Text strong>Time Zone:</Text> <Text>{existingSettings.timezone}</Text>
              </Col>
              <Col span={12}>
                <Title level={4}>Schedule Details</Title>
                <Text strong>Call Hours:</Text> <Text>{existingSettings.allowedTimesStart} - {existingSettings.allowedTimesEnd}</Text><br />
                {/* @ts-ignore */}
                <Text strong>Active Dates:</Text> <Text>{existingSettings.startDate?.format('YYYY-MM-DD')} to {existingSettings.endDate?.format('YYYY-MM-DD')}</Text><br />
                <Text strong>Active Days:</Text> <Text>{existingSettings.days.map(day =>
                  daysOptions.find(opt => opt.value === day)?.label).join(', ')}</Text><br />
                <Text strong>Allowed Statuses:</Text> <Text>{existingSettings.allowedStatuses.join(', ')}</Text>
              </Col>
            </Row>
          </Space>
        </Card >
        <Modal
          title="Launch Campaign"
          open={launchModalVisible}
          footer={[
            <Button key="back" onClick={() => setLaunchModalVisible(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" danger onClick={handleLaunchCampaign} loading={isLaunching}>
              Launch
            </Button>,
          ]}
        >
          <Text strong>Are you sure you want to launch this campaign?</Text>
        </Modal>
      </Spin>
    );
  }

  return (
    <Spin spinning={isLoading || isRefetching}>
      <Card>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={existingSettings || initialSettings}
        >
          <Title level={3}>Campaign Schedule Settings</Title>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Schedule Name"
                name="name"
                rules={[{ required: true, message: 'Please input the schedule name!' }]}
              >
                <Input />
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Max Retries"
                    name="maxRetries"
                    rules={[{ required: true, message: 'Please input max retries!' }]}
                  >
                    <InputNumber min={1} max={10} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Hours Between Retries"
                    name="timeBetweenRetries"
                    rules={[{ required: true, message: 'Please input hours between retries!' }]}
                  >
                    <InputNumber min={0.5} max={24} step={0.5} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Call Hours Start"
                    name="allowedTimesStart"
                    rules={[{ required: true, message: 'Please select start time!' }]}
                  >
                    <Select options={timeOptions} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Call Hours End"
                    name="allowedTimesEnd"
                    rules={[{ required: true, message: 'Please select end time!' }]}
                  >
                    <Select options={timeOptions} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Time Zone"
                name="timezone"
                rules={[{ required: true, message: 'Please select time zone!' }]}
              >
                <Select
                  options={timeZoneList.map((zone) => ({ label: zone, value: zone }))}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[{ required: true, message: 'Please select start date!' }]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="End Date"
                    name="endDate"
                    rules={[{ required: true, message: 'Please select end date!' }]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Active Days"
                name="days"
                rules={[{ required: true, message: 'Please select at least one day!' }]}
              >
                <Select mode="multiple" options={daysOptions} />
              </Form.Item>

              <Form.Item
                label="Allowed Statuses"
                name="allowedStatuses"
                rules={[{ required: true, message: 'Please select at least one status!' }]}
              >
                <Select mode="multiple" options={allowedStatusesOptions} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Form.Item>
            <Space>
              {isEditing && (
                <Button onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
              )}
              <Button type="primary" htmlType="submit" loading={isPending}>
                {isEditing ? 'Save Changes' : 'Save Settings'}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin >
  );
};

export default CampaignScheduleForm;