import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Layout, Spin, Typography, Card, Row, Col, Divider, Tabs, message } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { fetchCallInformation, getCallRecording } from '@/api/logs'
import { CallLog } from '@/interfaces/ILogs'
import logo from '@/assets/images/mobile-log.png'
import './CallInformation.scss'
import { CallInformationCard } from './CallInformationCard'
import { CallTranscriptCard } from './CallTranscriptCard'
import { CallInakeFormCard } from './CallIntakeFormCard'
import { IntakeForm, TranscriptMessage } from '@/types/TCallLogs'
import { CallSummaryCard } from './CallSummaryCard'

const { Content } = Layout
const { Title } = Typography

export const CallInformation = () => {
  const { key } = useParams()
  const [callInformation, setCallInformation] = useState<CallLog | null>(null)
  const [audioURl, setAudioURL] = useState<string | null>(null)
  const [intakeForm, setIntakeForm] = useState<IntakeForm[]>([])

  const {
    mutate: getCallInformation,
    isPending,
    isSuccess,
  } = useMutation({
    mutationKey: ['CallInformation'],
    mutationFn: fetchCallInformation,
    onSuccess: (data) => {
      setCallInformation(data?.data)
      parseIntakeResponse(data?.data?.intakeResponses as string)
    },
  })

  const { mutate: callRecordingMutation } = useMutation({
    mutationKey: ['getCallRecording'],
    mutationFn: getCallRecording,
    onSuccess: (data) => {
      setAudioURL(data.url)
    },
  })

  useEffect(() => {
    if (key) {
      const shareableLink = window.decodeURIComponent(key)
      getCallInformation(shareableLink)
    }
  }, [key])

  useEffect(() => {
    if (callInformation?.isRecorded && callInformation.callSid) {
      callRecordingMutation(callInformation.callSid)
    }
  }, [callInformation?.callSid])

  const transcriptMessages: TranscriptMessage[] = callInformation?.transcript
    ? (callInformation.transcript
        .split('\n')
        .map((message: string, index: number) => {
          if (message.startsWith('assistant@')) {
            return { key: index, sender: 'Assistant', message: message.replace('assistant@', '') }
          } else if (message.startsWith('user@')) {
            return { key: index, sender: 'User', message: message.replace('user@', '') }
          }
          return null
        })
        .filter(Boolean) as TranscriptMessage[])
    : []

  const parseIntakeResponse = (intakeResponse: string) => {
    try {
      if (!intakeResponse) {
        return []
      }
      const parsedResponse = JSON.parse(intakeResponse)
      if (Array.isArray(parsedResponse)) {
        const transformedResponse = parsedResponse
          .map((obj) => {
            return Object.entries(obj)
              .map(([key, value]) => [
                { key: 'Question', value: key },
                { key: 'Answer', value: value },
              ])
              .flat()
          })
          .flat() as IntakeForm[]
        setIntakeForm(transformedResponse)
      }
    } catch (error) {
      message.error('Error parsing intake response')
      return []
    }
  }

  const tabList = [
    {
      key: '1',
      label: 'Call Summary',
      children: (
        <CallSummaryCard
          callSummary={callInformation?.callSummary}
          style={{ minHeight: '528px', maxHeight: '528px', overflowY: 'auto' }}
        />
      ),
    },
    {
      key: '2',
      label: 'Transcript',
      children: (
        <CallTranscriptCard
          transcriptMessages={transcriptMessages}
          style={{ minHeight: '528px', maxHeight: '528px', overflowY: 'auto' }}
        />
      ),
    },
    {
      key: '3',
      label: 'Intake Form',
      children: (
        <CallInakeFormCard
          intakeForm={intakeForm}
          style={{ minHeight: '528px', maxHeight: '528px', overflowY: 'auto' }}
        />
      ),
    },
  ]

  const baseClass = 'call-information'

  return (
    <Layout className={baseClass}>
      <div className={`${baseClass}__header`}>
        <div className={`${baseClass}__header__logo`}>
          <img src={logo} alt="logo" />
          <Divider type="vertical" orientation="center" />
        </div>
      </div>
      <Content>
        <div className={`${baseClass}__content`}>
          <div className={`${baseClass}__content__header`}>
            <Title level={3}>Call Information</Title>
          </div>
          {isPending ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : isSuccess && callInformation ? (
            <div className={`${baseClass}__content__body`}>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <CallInformationCard callInformation={callInformation as CallLog} audioURl={audioURl} />
                </Col>
                <Col xs={24} lg={12}>
                  {callInformation?.intakeResponses || callInformation?.callSummary ? (
                    <Tabs
                      defaultActiveKey={callInformation?.callSummary ? '1' : '2'}
                      size="small"
                      type="card"
                      centered
                      items={tabList}
                    />
                  ) : (
                    <CallTranscriptCard
                      transcriptMessages={transcriptMessages}
                      style={{ minHeight: '580px', maxHeight: '580px', overflowY: 'auto' }}
                    />
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <Col xs={24} className="p-10">
              <Card className="text-center">
                <Title level={4}>No call information found</Title>
              </Card>
            </Col>
          )}
        </div>
      </Content>
    </Layout>
  )
}
