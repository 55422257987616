import React, { useState, useEffect } from 'react'
import { default as ReactSelect } from 'react-select' // Import react-select
import { tz } from 'moment-timezone' // Import moment-timezone to get timezone names and offsets
import {
  // Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
  // Description,
  Field,
  Input,
  Label,
  Select,
  // ChevronDownIcon,
} from '@headlessui/react'
import { toast } from 'react-hot-toast'
// import fetch from 'node-fetch'
import clsx from 'clsx'
import Papa from 'papaparse'

import { sanitizeString } from '../../utils/sanitizeString'
import '../../App.css'
// import { useNavigate } from "react-router-dom";
import { ReceptionistDashboard } from '../../../pages/ReceptionistDashboard'
import classes from '../Portal.module.css'
// import { Tooltip } from "react-tooltip";
import classes2 from './PortalDirectory.module.css'
import { serverURL } from '../../config'
import './PortalLabelDirectory.css'
import isEmpty from '../../utils/is-empty'
// import cloneDeep from "clone-deep";
import axios from 'axios'
import MinutesControlModal from '../../components/resellerReceptionists/Modals/MinutesControlModal'
import moment from 'moment-timezone'
// import { CampaignListData } from "../data/CampaignListData";
import { MdCopyAll, MdDelete, MdEditSquare, MdInfo } from 'react-icons/md'
import ChangePassModal from '../../components/resellerReceptionists/Modals/ChangePassModal'
import WarningModal from '../../components/resellerReceptionists/Modals/WarningModal'
import CopyReceptionistModal from '../../components/resellerReceptionists/Modals/CopyReceptionistModal'
import Button from '../../components/Button'
import { LoadingOutlined } from '@ant-design/icons'
import { loginApi } from "../../../api/user"
import  LocalStorageUtility from "../../../utils/localStorage"
import { LocalStorageKeys } from "../../../enums/ELocalStorage"
import { useDispatch } from 'react-redux'

import Loader from "../../components/dashboard/Loader";

import { setAccount } from "../../../store/account/slice"
// Allowed extensions for input file
const allowedExtensions = ['csv']

// Define the list of timezones and their offsets
const timeZonesOptions = tz.names().map((zone) => {
  const offset = moment.tz(zone).utcOffset()
  const label = `(GMT${offset >= 0 ? '+' : ''}${offset / 60}) ${zone}`
  return {
    value: -offset,
    label: label,
  }
})

function App({
  color,
  reseller_email,
  user,
  reservedCampaignLists,
  setReservedCampaignLists,
  // isRegistrationModal,
  setIsRegistrationModalActive,
  reloadClients,
  debrand,
  isCampaign,
  setCampaigns,
  updateUser,
  debrand_modal_instructions_setter,
}) {

  const themeColor = color
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleteItemNumber, setDeleteItemNumber] = useState('')
  const [showRegisterResellerInstructionsDialog, setShowRegisterResellerInstructionsDialog] = useState(false)
  const [selected_reseller_username, setSelectedResellerUsername] = useState('')
  const [selected_reseller_password, setSelectedResellerPassword] = useState('')
  const [previewDashboardModalIsOpen, setPreviewDashboardModalIsOpen] = useState(false)
  const [resellerMinutesControlModalOpen, setResellerMinutesControlModalOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [resellerChangePassModalOpen, setResellerChangePassModalOpen] = useState(false)
  const [isScheduleModalActive, setIsScheduleModalActive] = useState(false)
  const [inputState, setInputState] = useState(5)
  const [buyMoreSeatsLoading, setBuyMoreSeatsLoading] = useState(false);
  const [fetchingReceptinistData, setReceptionistFetchStatus] = useState(false);

  const [couponCode, setCouponCode] = useState('')
  const [couponCodeApplied, setCouponCodeApplied] = useState(false)

  const isMobile = window.innerWidth <= 768

  const [purchaseQuotes, setPurchaseQuotes] = useState(0)
  const [reservedReceptionists, setReservedReceptionists] = useState(0)

  const [isCSVSchedule, setIsCSVSchedule] = useState(true)
  const [scheduleStep, setScheduleStep] = useState(0)
  const [consentChecked, setConsentChecked] = useState(false)
  const [isSettingsModalActive, setIsSettingsModalActive] = useState(false)
  const [campaignSettings, setCampaignSettings] = useState({
    max_retries: 3,
    time_between_retries: 24,
    allowed_times_start: '9',
    allowed_times_end: '17',
  })

  const [sortedClients, setSortedClients] = useState([])
  const [cleaningLogsLoading, setCleaningLogsLoading] = useState(false)
  const [deleteReceptionistNoticeModal, setDeleteReceptionistNoticeModal] = useState(false)

  const [deleteReceptionistLoading, setDeleteReceptionistLoading] = useState(false)

  const [clearAllLogsWarningModal, setClearAllLogsWarningModal] = useState(false)

  const [copyReceptionistModal, setCopyReceptionistModal] = useState(false)

  // This state will store the parsed data
  const [data, setData] = useState([{ recepient_number: '', schedule_time: '' }])

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState('')

  // It will store the file uploaded by the user
  const [file, setFile] = useState('')


  const dispatch = useDispatch()

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setError('')

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0]

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split('/')[1]
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file')
        return
      }

      // If input type is correct set the state
      setFile(inputFile)
    }
  }

  const handleClearAllLogs = async () => {
    try {
      setCleaningLogsLoading(true);
      const response = await fetch(
        serverURL + "/api/reseller/clean-data-of-managed-receptionist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            receptionist_username: selectedClient?.number,
            tableNames: ["voicemails", "textLogs", "callLogs"],
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        toast.success("Logs cleared successfully");
        setPreviewDashboardModalIsOpen(false);
        setSelectedClient(null);
      } else {
        toast.error("Error clearing logs");
      }
    } catch (err) {
      toast.error("Error clearing logs");
    } finally {
      setCleaningLogsLoading(false);
      setClearAllLogsWarningModal(false);
    }
  };


  const handleDeleteReceptionist = async () => {
    try {
      setDeleteReceptionistLoading(true);
      const response = await fetch(
        serverURL + "/api/reseller/delete-receptionist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            receptionist_username: selectedClient?.number,
            reseller_email: reseller_email,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        toast.success("Receptionist deleted successfully");
        setDeleteReceptionistNoticeModal(false);

        console.log(user.purchase_quotes);

        //updating the unreserved receptionists with the new data
        const purchase_quotes = user?.purchase_quotes || "[]";
        const purchaseQuotes = JSON.parse(purchase_quotes);
        purchaseQuotes.push(selectedClient?.customer);
    
        // updates the reserved receptionists with the new data
        setReservedReceptionists(
          sortedClients.filter((el) => el.number !== selectedClient?.number)
            .length
        );
        setSortedClients((prev) =>
          prev.filter((el) => el.number !== selectedClient?.number)
        );
      } else {
        toast.error("Error Deleting receptionist");
      }
    } catch (err) {
      console.log(err);
      toast.error("Error Deleting receptionist");
    } finally {
      setDeleteReceptionistNoticeModal(false);
      setDeleteReceptionistLoading(false);
      setSelectedClient(null);
    }
  };

  const updateClientDataInState = (clientNumber, updateObject) => {
    setSortedClients((prev) =>
      prev.map((item) => {
        if (item.number === clientNumber) {
          return {
            ...item,
            ...updateObject,
          }
        }

        return item
      })
    )
  }

  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    // if (!file) return alert("Enter a valid file");

    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const vars = JSON.parse(isScheduleModalActive.variables || '{}')
          const sanitizedData = results.data.map((el) => {
            const obj = {
              recepient_number: sanitizeString(el.recepient_number || ''),
              schedule_time: sanitizeString(el.schedule_time || ''),
            }

            Object.keys(vars).forEach((vari) => {
              obj[vari] = sanitizeString(el[vari] || '')
              // If empty, put default value (if applicable)
              if (isEmpty(obj[vari])) {
                obj[vari] = vars[vari].defaultValue
              }
            })

            return obj
          })
          setData(sanitizedData)
        },
      })
    } catch (err) {
      console.log(err)
      setError('There was an error parsing your file')
    }
  }

  const addRow = () => {
    // if (isSameMsgChecked && data.length > 1)
    //   setData((prev) => [
    //     ...prev,
    //     { phone_number: "+1", message: data[0].message },
    //   ]);
    // else
    setData((prev) => [
      ...prev,
      {
        recepient_number: '',
        schedule_time: '',
        ...allVariables.reduce((acc, el, idx) => {
          acc[el] = allVariablesRaw[el].defaultValue
          return acc
        }, {}),
      },
    ])
  }

  const checkBlastValidity = (data) => {
    let recepients = data.map((el) => el?.recepient_number?.trim())
    let schedule_times = data.map((el) => el.schedule_time)

    let i = 0,
      j = 0

    for (let item of data) {
      for (let variable of allVariables) {
        if (allVariablesRaw[variable].isMandatory && isEmpty(item[variable])) {
          toast.error(`A mandatory variable (${variable}) is missing in the row ${i + 1} of your input data`)
          return false
        }
        j++
      }
      i++
    }

    recepients = recepients.map((el) => sanitizeString(el))
    // if (msgs.length === 0) {
    //   toast.error("Please enter a message");
    //   return false;
    // }
    if (recepients.length === 0) {
      toast.error('Please add recipient/s')
      return false
    }
    // if (msgs.some((el) => typeof el !== "string" || el.trim().length === 0)) {
    //   toast.error("One or more messages are empty");
    //   return false;
    // }
    if (recepients.some((el) => typeof el !== 'string' || el.trim().length === 0)) {
      toast.error('One or more recepients are empty')
      return false
    }
    if (
      recepients.some((el) => {
        console.log(el.length !== 12 ? el : 'good')
        return el.length !== 12
      })
    ) {
      toast.error('One or more recipient does not have a valid length')
      return false
    }
    if (
      recepients.some((el) => el[0].charCodeAt(0) !== '+'.charCodeAt(0) || el[1].charCodeAt(0) !== '1'.charCodeAt(0))
    ) {
      toast.error('One or more recipient does not start with +1')
      return false
    }
    if (recepients.some((el) => isNaN(Number(el.replace('+1', ''))))) {
      toast.error('One or more recipient is not valid')
      return false
    }

    if (schedule_times.some((el) => isNaN(Number(new Date(el).getTime())))) {
      toast.error('One or more schedule_time is not valid number')
      return false
    }

    return true
  }

  // console.log(isScheduleModalActive);

  function sendBulkEvents(data) {
    if (!checkBlastValidity(data)) return
    fetch(`${serverURL}/api/campaigns/calls/schedule-bulk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: isScheduleModalActive?.number,
        campaign_user_email: user?.email_address,
        events: data.map((el) => ({
          ...el,
          schedule_time: new Date(el.schedule_time).toISOString(),
        })),
        // messages: msgs,
        // from: provisionedNumber,
        // theirNumber: theirNumber,
        // recepients: recepients,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success('Successfully scheduled calls')
          // getTextingConvos();
          // setMsgVal("");
          // setRecepients([]);
        } else {
          //throw
          // toast.error("Failed to send one or more messages");
          throw new Error('Couldnt send ')
        }
      })
      .catch((err) => {
        toast.error('Failed to send one or more messages')
        console.error(err)
      })
  }

  useEffect(() => {
    window.gtag('event', 'WhitelabelReceptionistDirectoryView', {
      event_category: 'Pageview',
    })
    console.log('In whitelabel directory')
  }, [])

  useEffect(() => {
    const purchase_quotes = user?.purchase_quotes || '[]'

    setPurchaseQuotes(JSON.parse(purchase_quotes))
  }, [user])

  useEffect(() => {
    if (clients) {
      setSortedClients(
        clients.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            const timestampA = new Date(a.timestamp)
            const timestampB = new Date(b.timestamp)
            return timestampB - timestampA
          } else if (a.timestamp) {
            return -1
          } else if (b.timestamp) {
            return 1
          } else {
            return 0
          }
        })
      )
    }
  }, [clients])

  useEffect(() => {
    if (clients) {
      setSortedClients(
        clients.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            const timestampA = new Date(a.timestamp)
            const timestampB = new Date(b.timestamp)
            return timestampB - timestampA
          } else if (a.timestamp) {
            return -1
          } else if (b.timestamp) {
            return 1
          } else {
            return 0
          }
        })
      )
    }
  }, [clients])

  const fetchResellers = () => {
    fetch(serverURL + (isCampaign ? '/api/campaigns/clients' : '/fetch_reseller_clients'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email_address: reseller_email }),
    })
      .then((response) => response.json())
      .then((data) => {
        setClients(data.items)
        setReservedReceptionists(data.items.filter((el) => el?.isDeleted !== 'true').length)
        const campaignsData = data.items.filter((el) => el?.isDeleted !== 'true')
        setReservedCampaignLists(campaignsData.length)
        setCampaigns(campaignsData)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const csvmaker = function (data) {
    // Empty array for storing the values
    const csvRows = []

    // Headers is basically a keys of an object
    // which is id, name, and profession
    const headers = Object.keys(data)

    // As for making csv format, headers must
    // be separated by comma and pushing it
    // into array
    csvRows.push(headers.join(','))

    // Pushing Object values into array
    // with comma separation
    const values = Object.values(data).join(',')
    csvRows.push(values)

    // Returning the array joining with new line
    return csvRows.join('\n')
  }

  function download_csv_file() {
    //define the heading for each row of the data
    var csv = 'recepient_number,schedule_time,variable1,variable2\n'
    const csvFileData = [
      ['+1234567891', '2024-06-12T00:25', 'Variable 1 value', 'Variable 2 value'],
      ['+1324567891', '2024-06-13T00:25', 'Variable 1 value', 'Variable 2 value'],
      ['+1334567891', '2024-06-14T00:25', 'Variable 1 value', 'Variable 2 value'],
    ]
    //merge the data with CSV
    csvFileData.forEach(function (row) {
      csv += row.join(',')
      csv += '\n'
    })

    //display the created CSV data on the web browser
    // document.write(csv);

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = 'call_blast_csv_template.csv'
    hiddenElement.click()
  }

  useEffect(() => {
    fetchResellers()
  }, [])

  useEffect(() => {
    console.log('reloading resellers')
    fetchResellers()
  }, [reloadClients])

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
    gap: '16px',
    marginTop: '32px',
  }

  const cardStyle = {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '16px',
    position: 'relative', // Add position relative to parent div
    minHeight: '240px',
    paddingBottom: '20px',
  }

  // Start :: Timezone dropdown in campaign settings
  useEffect(() => {
    async function getTimeZone() {
      let username = clients?.number
      if (!username) return
      try {
        fetch(serverURL + '/get-time-zone', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (
              data &&
              data.data &&
              data.data.time_zone &&
              data.data.time_zone !== 'na' &&
              data.data.time_zone !== ''
            ) {
              setSelectedTimeZone(data['data']['time_zone'].S)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.error(error)
      }
    }

    if (clients) {
      getTimeZone()
    }
  }, [clients, serverURL])

  //By default user timezone
  const [selectedTimeZone, setSelectedTimeZone] = useState()
  const handleTimezoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption.value)
  }

  const handleCampaignSettingsSubmit = (e) => {
    e.preventDefault()
    // Convert offset to minutes
    const offsetMinutes = selectedTimeZone

    // Include the offset in the campaign settings
    const updatedCampaignSettings = {
      ...campaignSettings,
      timezone: offsetMinutes,
    }
    console.log(updatedCampaignSettings)
    if (+updatedCampaignSettings.time_between_retries === 0) {
      toast.error('Hours between retries cant be zero')
      return
    }

    if (
      +updatedCampaignSettings.allowed_times_start.split(':')[0] >=
      +updatedCampaignSettings.allowed_times_end.split(':')[0]
    ) {
      toast.error('Start time cant be earlier than End Time')
      return
    }

    axios
      .post(`${serverURL}/api/campaigns/clients/update`, updatedCampaignSettings)
      .then((res) => {
        toast.success('Settings updated successfully')
        setIsSettingsModalActive(false)
      })
      .catch((err) => {
        toast.error('Something went wrong')
      })
  }

  const purchaseNow = async () => {
    setShowRegisterResellerInstructionsDialog(false)
    setBuyMoreSeatsLoading(true);

    window.gtag('event', 'resellerPurchaseClicked', {
      event_category: 'EditDemo.js',
      event_label: 'success',
    })

    try {
      const response = await fetch(`${serverURL}/create-checkout-session-new-resellers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          resellerEmail: user.email_addres,
          quantity: inputState,
        }),
      })

      const data = await response.json()
      window.location.href = data.redirectPaymentURL
    } catch (error) {
      window.gtag('event', 'couldntPurchase', {
        event_category: 'EditDemo.js',
        event_label: 'error',
      })
      setBuyMoreSeatsLoading(false);
      console.error(error)
    }
  }

  const deleteNow = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch(`${serverURL}/delete-receptionist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: deleteItemNumber,
        }),
      })

      const data = await response.json()
      if (data.isReleaseError) toast.success("Delete success but couldn't release the provisioned number")
      else toast.success('Delete success')
      fetchResellers()
    } catch (error) {
      window.gtag('event', 'couldntPurchase', {
        event_category: 'EditDemo.js',
        event_label: 'error',
      })
      toast.error('Delete failed')
      console.error(error)
    } finally {
      setDeleteItemNumber('')
    }
  }

  const fetchReceptionistToken = ({username, password}) => {
    loginApi({ username, password })
      .then((data) => {
        if (data.token) {
          dispatch(setAccount({
            isAuthenticated: true,
            //@ts-ignore
            user: data,
            authToken: data.token,
            currentPage: ''
          }))
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setReceptionistFetchStatus(false)
        setLoading(false)
      })
  }

  const registerResellerInstructionsDialog = (
    <div>
      <div
        style={{
          display: showRegisterResellerInstructionsDialog ? 'flex' : 'none',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          borderRadius: '10px',
          padding: '20px',
          zIndex: '98',
        }}
        className="flex flex-col"
      >
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px',
          }}
          onClick={() => {
            setShowRegisterResellerInstructionsDialog(false)
          }}
        >
          X
        </button>
        <h2> 😁 Let's Purchase an Unbranded Receptionist! </h2>
        <ul>
          <li>In the next step, you will purchase a new unbranded receptionist.</li>
          <li>After purchasing, we'll direct you back to this portal.</li>
          <li>You will be able to create the unbranded receptionist you have just purchased.</li>
        </ul>
        <br />

        <div className={classes2.numberInput}>
          <button
            onClick={() => {
              setInputState((prev) => {
                if (reservedReceptionists + purchaseQuotes.length > 0) {
                  if (prev > 1) return prev - 1
                  else return 1
                } else if (!couponCodeApplied && prev === 5) {
                  return 5
                } else if (prev > 3) return prev - 1
                else return 3
              })
            }}
          >
            -
          </button>
          <input
            type="number"
            value={inputState}
            style={{
              width: '60px',
              textAlign: 'center',
              margin: '0 10px',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.1)',
            }}
          />
          <button
            onClick={() => {
              setInputState((prev) => {
                return prev + 1
              })
            }}
          >
            +
          </button>
        </div>

        <br />
        <div className="w-44 self-end flex items-center">
          <div style={{ flex: 1 }}>
            <label htmlFor="couponCode" style={{ display: 'block' }}>
              Coupon Code:
            </label>
            <div className="flex flex-row">
              <input
                id="couponCode"
                type="text"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.1)',
                }}
              />
              <button
                style={{
                  background: 'green',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  if (couponCode.toLowerCase() === 'yosi') {
                    toast.success('Coupon code applied successfully')
                    setCouponCodeApplied(true)
                  } else {
                    toast.error('Invalid Coupon Code')
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <button
          style={{
            background: 'blue',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginTop: '10px',
          }}
          onClick={purchaseNow}
        >
          Purchase Now 🎉
        </button>
      </div>
    </div>
  )
  const unreserveReceptionistDialog = (
    <div>
      <div
        style={{
          display: deleteItemNumber ? 'block' : 'none',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          borderRadius: '10px',
          padding: '20px',
          zIndex: '98',
        }}
      >
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px',
          }}
          onClick={() => {
            setDeleteItemNumber('')
          }}
        >
          X
        </button>
        <h2> Unreserve Receptionist? </h2>
        <p>
          Unreserving this receptionist will deactivate it and free up space allowing you or your clients to create an
          additional receptionist.
        </p>
        <br />

        <br />

        <button
          style={{
            background: 'red',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginTop: '10px',
            marginLeft: 'auto',
            display: 'block',
          }}
          onClick={deleteNow}
        >
          Delete now
        </button>
      </div>
    </div>
  )

  const allVariables = Object.keys(JSON.parse(isScheduleModalActive?.variables || '{}'))

  const allVariablesRaw = JSON.parse(isScheduleModalActive?.variables || '{}')

  const scheduleModal = (
    <>
      <div
        onClick={() => {
          setIsScheduleModalActive(false)
        }}
        className={classes.modalOverlay}
      ></div>
      <div className={clsx(classes.modal, '!max-w-[90%] !px-5 !pb-5 !pt-12')}>
        <div
          className={classes.close}
          onClick={() => {
            setIsScheduleModalActive(false)
          }}
          style={{ zIndex: 100 }}
        >
          &times;
        </div>
        {scheduleStep === 0 ? (
          <div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid #000',
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              Schedule bulk calls!
            </div>

            <div className="max-w-[500px] mx-auto">
              <label
                style={{
                  marginTop: '16px',
                  display: 'block',
                }}
              >
                <input
                  style={{
                    marginRight: '16px',
                  }}
                  type="checkbox"
                  value={consentChecked}
                  onChange={(e) => setConsentChecked(e.target.checked)}
                />
                By sending bulk calls, I agree that all my recepients have opted in to receiving the call from me. If my
                texts are marked as spam or cold outreach, I understand my account will immediately be banned and I may
                be charged a fee for abuse.
              </label>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '24px',
              }}
            >
              <button
                style={{
                  backgroundColor: themeColor,
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!consentChecked) {
                    toast.error('Please check the consent to continue')
                    return
                  }
                  setScheduleStep(1)
                  setIsCSVSchedule(true)
                }}
              >
                Upload CSV
              </button>
              <button
                style={{
                  backgroundColor: themeColor,
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!consentChecked) {
                    toast.error('Please check the consent to continue')
                    return
                  }
                  setScheduleStep(1)
                  setIsCSVSchedule(false)
                }}
              >
                Enter manually
              </button>
            </div>
          </div>
        ) : (
          <>
            {scheduleStep < 4 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px',
                  marginTop: '16px',
                  maxWidth: '300px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '1px',
                    background: '#aeaeae',
                    top: '25px',
                  }}
                ></div>
                {[
                  { label: isCSVSchedule ? 'Upload CSV' : 'Enter Blast' },
                  { label: 'Verify Blast' },
                  { label: 'Schedule' },
                ].map((el, idx) => {
                  return (
                    <div
                      key={'bulk-step-' + idx}
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (idx + 1 < scheduleStep) setScheduleStep(idx + 1)
                      }}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          border: '1px solid #eee',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: idx + 1 === scheduleStep ? '#484848' : '#fff',
                          color: idx + 1 === scheduleStep ? '#fff' : '#484848',
                          fontWeight: 'bold',
                          position: 'relative',
                        }}
                      >
                        {idx + 1}
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '100px',
                          textAlign: 'center',
                          top: '100%',
                          fontWeight: idx + 1 === scheduleStep ? 'bold' : '400',
                        }}
                      >
                        {el.label}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            <div
              style={{
                marginTop: '40px',
              }}
            >
              {scheduleStep === 1 && isCSVSchedule && (
                <div>
                  <div>
                    Make sure your file has columns number (recepient_number) and the call schedule time (schedule_time)
                    in Unix epoch format. If you have variables set in your campaign, it should also have all those
                    variables columns.
                  </div>
                  <div className="cursor-pointer" onClick={download_csv_file}>
                    <strong>Example file format, (Click to download):</strong>
                    <div
                      style={{
                        padding: '2px 12px',
                        border: '1px solid #a8a8a8',
                        background: 'rgb(233 233 233)',
                        marginTop: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: 'monospace',
                        }}
                      >
                        recepient_number,schedule_time,name
                        <br />
                        +1xxxxxxxxxx,2024-06-12T00:25,John
                        <br />
                        +1xxxxxxxxxx,2024-07-12T00:25,Jane
                        <br />
                        +1xxxxxxxxxx,2027-09-12T00:25,Mirko
                      </pre>
                    </div>
                  </div>
                  <input id="upload" type="file" accept="text/csv" onChange={handleFileChange} />
                  <label
                    htmlFor="upload"
                    style={{
                      border: '1px dashed #ccc',
                      borderRadius: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '26px',
                      textAlign: 'center',
                      paddingBottom: '30px',
                      cursor: 'pointer',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '90px',
                      }}
                    >
                      ☁️
                    </span>
                    <span style={{ fontSize: '20px' }}>{file?.name || 'Upload CSV'}</span>
                  </label>{' '}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setScheduleStep(0)
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleParse()
                        if (!file) toast.error('Please select a file to continue')
                        else setScheduleStep(2)
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {scheduleStep === 1 && !isCSVSchedule && (
                <div>
                  <table className="text-center pt-2">
                    <tr
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <td
                        className="border-none"
                        style={{
                          fontSize: '10px',
                          textAlign: 'left',
                        }}
                      >
                        Phone number (recepient_number)
                      </td>
                      <td
                        className="border-none"
                        style={{
                          fontSize: '10px',
                        }}
                      >
                        Schedule time (schedule_time)
                      </td>
                      {allVariables.map((el, idx) => {
                        return <td className="border-none text-[10px]">({el})</td>
                      })}
                    </tr>
                    {data.map((el, idx) => {
                      return (
                        <tr
                          key={'bulk-message-custom-' + idx}
                          className="border-none"
                          style={{
                            margin: '6px 0',
                            paddingTop: '4px',
                          }}
                        >
                          <td className="border-none">
                            <input
                              className="w-full"
                              style={{
                                fontSize: '13px',
                              }}
                              value={el.recepient_number}
                              onChange={(e) =>
                                setData((prev) => {
                                  const newData = [...prev]

                                  newData[idx].recepient_number = e.target.value
                                  return newData
                                })
                              }
                            />
                          </td>
                          <td className="border-none">
                            <input
                              className="w-full"
                              style={{
                                fontSize: '13px',
                              }}
                              value={el.schedule_time}
                              type="datetime-local"
                              onChange={(e) =>
                                setData((prev) => {
                                  const newData = [...prev]

                                  newData[idx].schedule_time = e.target.value
                                  return newData
                                })
                              }
                            />
                          </td>
                          {allVariables.map((el, idx2) => {
                            return (
                              <td className="border-none">
                                <input
                                  className="w-full"
                                  style={{
                                    fontSize: '13px',
                                  }}
                                  value={data[idx][el]}
                                  placeholder="Hi there!"
                                  onChange={(e) =>
                                    setData((prev) => {
                                      const newData = [...prev]

                                      newData[idx][el] = e.target.value
                                      return newData
                                    })
                                  }
                                />
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </table>{' '}
                  <div className="flex justify-center">
                    <button
                      style={{
                        backgroundColor: 'grey',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                        marginTop: '10px',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setData([
                          {
                            recepient_number: '',
                            schedule_time: '',
                            ...allVariables.reduce((acc, el, idx) => {
                              acc[el] = allVariablesRaw[el].defaultValue
                              return acc
                            }, {}),
                          },
                        ])
                      }}
                    >
                      Reset
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                      onClick={() => {
                        addRow()
                      }}
                    >
                      + Add
                    </button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setScheduleStep(0)
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // const users = data.map((el) => el.recepient_number);
                        // const msgs = data.map((el) => el.message);
                        if (checkBlastValidity(data)) setScheduleStep(2)
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {scheduleStep === 2 && (
                <div>
                  <div
                    style={{
                      maxHeight: '200px',
                      overflow: 'auto',
                    }}
                  >
                    <table
                      style={{
                        width: '100%',
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            background: 'rgb(58, 58, 58)',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          Phone number (recepient_number)
                        </td>
                        <td
                          style={{
                            background: 'rgb(58, 58, 58)',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          Schedule time (schedule_time)
                        </td>
                        {allVariables.map((el, idx) => {
                          return (
                            <td
                              style={{
                                background: 'rgb(58, 58, 58)',
                                color: '#fff',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              ({el})
                            </td>
                          )
                        })}
                      </tr>
                      <tbody>
                        {error
                          ? error
                          : data.map((e, i) => (
                            <tr key={'data-row-' + i} className="item">
                              <td className="item">{e.recepient_number}</td>
                              <td className="item">{e.schedule_time}</td>
                              {allVariables.map((el, idx) => {
                                return <td>{e[el]}</td>
                              })}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setScheduleStep(1)
                      }}
                    >
                      Back
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // const users = data.map((el) => el.recepient_number);
                        // const msgs = data.map((el) => el.message);
                        if (checkBlastValidity(data)) setScheduleStep(3)
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
              {scheduleStep === 3 && (
                <div>
                  <div
                    style={{
                      fontSize: '30px',
                      textAlign: 'center',
                      padding: '1.5rem 0',
                    }}
                  >
                    Schedule{' '}
                    <span
                      style={{
                        color: themeColor,
                        fontWeight: 'bold',
                      }}
                    >
                      {data.length}
                    </span>{' '}
                    calls?
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '0',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '36px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setScheduleStep(4)
                        // const users = data.map((el) => el.phone_number);
                        // const msgs = data.map((el) => el.message);
                        // updateContacts(data);
                        sendBulkEvents(data)
                      }}
                    >
                      🚀 Schedule
                    </button>
                  </div>
                </div>
              )}
              {scheduleStep === 4 && (
                <div>
                  <div
                    style={{
                      fontSize: '30px',
                      textAlign: 'center',
                    }}
                  >
                    🎉{' '}
                    <span
                      style={{
                        color: themeColor,
                        fontWeight: 'bold',
                      }}
                    >
                      {data.length}
                    </span>{' '}
                    Call Scheduled!
                  </div>
                  <div
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '2rem',
                    }}
                  >
                    Woohooo! You've just set scheduled outbound calls
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )

  const settingsModal = (
    <>
      <Transition appear show={isSettingsModalActive}>
        <Dialog as="div" className="relative z-10 focus:outline-none" onClose={() => setIsSettingsModalActive(false)}>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md rounded-xl bg-white/5 shadow-xl p-6 backdrop-blur-2xl">
                  <form onSubmit={handleCampaignSettingsSubmit}>
                    <DialogTitle as="h3" className="text-base/7 font-medium ">
                      Campaign Settings
                    </DialogTitle>
                    <p className="text-sm/6">Time control and retries settings</p>

                    <div className="mt-5">
                      <Field className="mb-2">
                        <Label className="text-sm/6 font-medium">Max retries</Label>
                        <p className="mt-2 text-xs/6">
                          The number of retries a campaign would do on a FAILED outbound "event" to be able to connect
                          to the recipient. The status is FAILED_TERMINAL if number of retries have reached and it
                          couldn't connect to the recepient. The "event" would not be retried after that limit has
                          reached
                        </p>
                        <Input
                          className={clsx(
                            'mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6',
                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                          )}
                          value={campaignSettings.max_retries}
                          onChange={(e) =>
                            setCampaignSettings((prev) => ({
                              ...prev,
                              max_retries: e.target.value,
                            }))
                          }
                        />
                      </Field>
                      <Field className="mb-2">
                        <Label className="text-sm/6 font-medium">Hours between retries</Label>
                        <p className="mt-2 text-xs/6">
                          Cooldown hours after each retry. The campaign will wait for these many hours before it retries
                          a FAILED outbound "event" again. Automatically shifts to the next "Allowed times start" if the
                          next retry time doesn't lie between the business hours
                        </p>
                        <Input
                          className={clsx(
                            'mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6',
                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                          )}
                          value={campaignSettings.time_between_retries}
                          type="number"
                          onChange={(e) =>
                            setCampaignSettings((prev) => ({
                              ...prev,
                              time_between_retries: e.target.value,
                            }))
                          }
                        />
                      </Field>
                      <div className="grid grid-cols-2 gap-4">
                        <Field className="mb-2">
                          <Label className="text-sm/6 font-medium">Allowed Times Start</Label>
                          <div className="relative">
                            <Select
                              className={clsx(
                                'mt-3 block w-full appearance-none rounded-lg border-none py-1.5 px-3 text-sm/6',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25',
                                // Make the text of each option black on Windows
                                '*:text-black'
                              )}
                              value={campaignSettings.allowed_times_start}
                              onChange={(e) =>
                                setCampaignSettings((prev) => ({
                                  ...prev,
                                  allowed_times_start: e.target.value,
                                }))
                              }
                            >
                              {new Array(24).fill(0).map((el, idx) => {
                                let timeNum = idx
                                if (timeNum === 0) {
                                  timeNum = 12
                                }
                                if (idx > 12) {
                                  timeNum = timeNum - 12
                                }

                                let time1 = `${timeNum.toLocaleString('en-US', {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                })}:00 ${idx + 1 > 12 ? 'PM' : 'AM'}`
                                let time2 = `${timeNum.toLocaleString('en-US', {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                })}:30 ${idx + 1 > 12 ? 'PM' : 'AM'}`
                                // timeValuesRef.current[2 * idx] = time1;
                                // timeValuesRef.current[2 * idx + 1] = time2;
                                return (
                                  <option
                                    key={el}
                                    value={`${idx.toLocaleString('en-US', {
                                      minimumIntegerDigits: 2,
                                    })}`}
                                  >
                                    {time1}
                                  </option>
                                )
                              })}
                            </Select>
                            {/* <ChevronDownIcon
                          className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
                          aria-hidden="true"
                        /> */}
                          </div>
                        </Field>
                        <Field className="mb-2">
                          <Label className="text-sm/6 font-medium">Allowed Times End</Label>
                          <div className="relative">
                            <Select
                              className={clsx(
                                'mt-3 block w-full appearance-none rounded-lg border-none py-1.5 px-3 text-sm/6',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25',
                                // Make the text of each option black on Windows
                                '*:text-black'
                              )}
                              value={campaignSettings.allowed_times_end}
                              onChange={(e) =>
                                setCampaignSettings((prev) => ({
                                  ...prev,
                                  allowed_times_end: e.target.value,
                                }))
                              }
                            >
                              {new Array(24).fill(0).map((el, idx) => {
                                let timeNum = idx
                                if (timeNum === 0) {
                                  timeNum = 12
                                }
                                if (idx > 12) {
                                  timeNum = timeNum - 12
                                }

                                let time1 = `${timeNum.toLocaleString('en-US', {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                })}:00 ${idx + 1 > 12 ? 'PM' : 'AM'}`
                                let time2 = `${timeNum.toLocaleString('en-US', {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false,
                                })}:30 ${idx + 1 > 12 ? 'PM' : 'AM'}`
                                // timeValuesRef.current[2 * idx] = time1;
                                // timeValuesRef.current[2 * idx + 1] = time2;
                                return (
                                  <option
                                    key={el}
                                    value={`${idx.toLocaleString('en-US', {
                                      minimumIntegerDigits: 2,
                                    })}`}
                                  >
                                    {time1}
                                  </option>
                                )
                              })}
                            </Select>
                            {/* <ChevronDownIcon
                          className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-white/60"
                          aria-hidden="true"
                        /> */}
                          </div>
                        </Field>
                      </div>
                      <p className="mt-2 text-xs/6">
                        Just your business hours. No outbound calls outside this time. Make sure to check your timezone
                        before submission. This will be applied to calculate the business hours
                      </p>

                      <div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'quicksand, sans-serif',
                            color: 'rgb(0 123 255)',
                          }}
                        >
                          <label
                            className="text-sm/6"
                            htmlFor="timeZoneSelect"
                            style={{
                              marginRight: '8px',
                              fontFamily: 'quicksand, sans-serif',
                              fontWeight: 600,
                              color: 'rgb(0 123 255)',
                            }}
                          >
                            Time Zone:
                          </label>
                          <div className="flex-grow text-sm">
                            <ReactSelect
                              id="timeZoneSelect"
                              options={timeZonesOptions}
                              onChange={handleTimezoneChange}
                              isSearchable
                              placeholder="Select timezone..."
                              value={timeZonesOptions.find((option) => option.value === selectedTimeZone)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button
                        className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                        type="submit"
                      >
                        Save settings
                      </button>
                    </div>
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )

  const ClientGrid = sortedClients && (
    <div className="client-grid" style={{} || gridStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        {!debrand &&
          // begun_reselling &&
          (Number(user?.totalReceptionists) || 0) > 0 && (
            <Button
              text={" ➕ Buy More Seats"}
              className="register-client border-2 border-[#b1aaaadf]"
              themeColor={"#ededed"}
              loading={buyMoreSeatsLoading}
              onClick={() => {
                // 1: Check if user has a purchased receptionist lying around (i.e. check the Purchase_Quotes field)
                // var hasPurchasedReceptionist = user.totalReceptionists?.N > 0;
                // 2: If user has a purchased receptionist, then allow them to register a new client
                // 3: If user does not have a purchased receptionist, then force them to purchase a receptionist before accessing registration page
                // if (!hasPurchasedReceptionist) {
                setShowRegisterResellerInstructionsDialog(true);
                //   return;
                // }
                // console.log("Reserve a puchased receptionist...");
                // setIsRegistrationModalActive(true);
              }}
            />
          )}
      </div>

      {debrand &&
        reservedReceptionists === 0 &&
        (!user.purchase_quotes || JSON.parse(user.purchase_quotes).length === 0) && (
          <div
            style={{
              backgroundColor: '#f0f0f0',
              padding: '20px',
              borderRadius: '10px',
              margin: '20px 0',
            }}
          >
            <div
              style={{
                backgroundColor: '#ffffff',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h2
                style={{
                  color: '#333333',
                  fontSize: '24px',
                  marginBottom: '10px',
                }}
              >
                Contact Administrator
              </h2>
              <p
                style={{
                  color: '#666666',
                  fontSize: '16px',
                  lineHeight: '1.5',
                }}
              >
                Please contact your administrator to load in new receptionists.
              </p>
            </div>
          </div>
        )}

      <div>
        <strong>Reserved Receptionists: </strong>

        <span style={{ color: color }}>{reservedReceptionists}</span>
      </div>

      {sortedClients && (
        <div>
          <div
            className={classes2.cards}
            style={{
              marginTop: '2rem',
              // display: "flex",
              // flexWrap: "wrap",
              gap: '16px',
            }}
          >
            {sortedClients.map((client, index) => {
              if (client.number === 'resellerTest1') {
                console.log('client', client)
              }
              return (
                <div className="flex flex-col">
                  <div
                    className="p-2  rounded-t-md flex flex-col-reverse md:flex-row flex-start md:items-center justify-between gap-2"
                    style={{
                      backgroundColor: '#f0f0f0',
                    }}
                  >
                    {client?.minutesLimitEnabled ? (
                      <p className="text-[#373737]">
                        <span className="font-bold ">
                          Monthly Usage <span className="text-xs font-normal ">(minutes)</span>:{' '}
                        </span>
                        {
                          // get the usage form logs for this month
                          Math.floor(
                            client?.max_usage_limit_logs?.find(
                              (log) => log.month === `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
                            )?.usage || 0
                          )
                        }
                      </p>
                    ) : (
                      <p className="font-bold text-[#373737]">Minutes Limitation Disabled</p>
                    )}
                  </div>
                  <div key={'client.clientKey-' + index}>
                    <div
                      className="rounded-b-md"
                      style={{
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        padding: '16px 24px',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className="flex flex-row items-start justify-between">
                        <h3
                          style={{
                            fontSize: '24px',
                            margin: '0',
                          }}
                        >
                          {client.business_name}
                        </h3>
                        <div className="flex flex-row items-center gap-3 mt-2">
                          <MdDelete
                            onClick={() => {
                              setSelectedClient(client)
                              setDeleteReceptionistNoticeModal(true)
                            }}
                            className="text-xl text-[red]"
                          />

                          <MdCopyAll
                            onClick={() => {
                              setSelectedClient(client)
                              setCopyReceptionistModal(true)
                            }}
                          />
                        </div>
                      </div>

                      <div style={{ fontSize: '16px', margin: '4px 0' }}>
                        <p>
                          <b>Username:</b> {client.number}
                        </p>
                        <div className="flex flex-row items-center gap-3">
                          <p>
                            <b>Password:</b> {client.password}
                          </p>
                          <div
                            onClick={() => {
                              setResellerChangePassModalOpen(true)
                              setSelectedClient(client)
                            }}
                            className=" flex flex-row items-center justify-normal "
                          >
                            <MdEditSquare color={themeColor || '#000'} />
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-3">
                        <Button
                          text={'Open'}
                          onClick={() => {
                            setSelectedResellerUsername(client.number)
                            setSelectedResellerPassword(client.password)
                            setSelectedClient(client)
                            setReceptionistFetchStatus(true)
                            setPreviewDashboardModalIsOpen(true)
                            fetchReceptionistToken({username: client.number, password: client.password})
                          }}
                          className={'border border-[#ccc] text-[grey] font-normal text-sm '}
                        />

                        <Button
                          text={'Minutes Limitation'}
                          onClick={() => {
                            setSelectedClient(client)
                            setResellerMinutesControlModalOpen(true)
                          }}
                          className={'border border-[#ccc] text-[grey] font-normal text-sm '}
                        />
                        <Button
                          text={'Clear All Logs'}
                          onClick={() => {
                            setSelectedClient(client)
                            setClearAllLogsWarningModal(true)
                          }}
                          themeColor={themeColor}
                          className={'col-span-2'}
                        />
                      </div>

                      {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    > */}
                      {/* <button
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          background: "transparent",
                          border: "1px solid #ccc",
                          borderRadius: "6px",
                          height: "40px",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => setDeleteItemNumber(client.number)}
                      >
                        <span style={{ fontSize: "12px", marginRight: "5px" }}>
                          ❌
                        </span>
                        Delete
                      </button> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <br />

      <div>
        <strong>Purchased Receptionists: </strong>

        <span style={{ color: color }}> {user?.unreservedReceptionists || 0}</span>
      </div>
      <br />

      {user && user.unreservedReceptionists && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(330px, 1fr))",
            gap: "20px",
          }}
        >
          {Array.from({ length: user?.unreservedReceptionists }).map(
            (purchase_quote, index) => {
              return (
                <div
                  style={{
                    borderRadius: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px',
                    padding: '16px 24px',
                  }}
                  key={'purchase_quote-' + index}
                >
                  <h3
                    style={{
                      fontSize: '20px',
                      margin: '0',
                    }}
                  >
                    Unreserved Receptionist #{index + 1}
                  </h3>

                  <div
                    style={{
                      marginTop: '1rem',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <button
                      style={{
                        fontSize: '16px',
                        color: 'grey',
                        margin: '0',
                        background: 'transparent',
                        border: '1px solid #ccc',
                        borderRadius: '6px',
                        height: '40px',
                        flexGrow: 1,
                      }}
                      onClick={() => {
                        setIsRegistrationModalActive(true)
                      }}
                    >
                      ⚙️ Configure
                    </button>
                  </div>
                </div>
              )
            })}
        </div>
      )}

      {!debrand &&
        user &&
        (!user?.totalReceptionists || user?.totalReceptionists == "0") && (
          <>
            <div className={classes2.info}>
              <h2
                style={{
                  fontSize: '30px',
                  color: 'rgb(0, 123, 255)',
                  marginBottom: '.25rem',
                }}
              >
                Get Started
              </h2>

              <p
                style={{
                  fontSize: '16px',
                  color: 'grey',
                  margin: '0',
                }}
              >
                Start by reserving five white-labeled receptionists at $54.99 / Month / Receptionist. These
                receptionists will use your branding everywhere. You can manage them here yourself.
              </p>
            </div>

            <div className={classes2.card}>
              <div className={classes2.numberInput}>
                <button
                  onClick={() => {
                    setInputState((prev) => {
                      if (
                        user &&
                        !couponCodeApplied &&
                        reservedReceptionists === 0 &&
                        (!user.purchase_quotes || JSON.parse(user.purchase_quotes).length === 0)
                      ) {
                        toast.error('You must start with a package of five receptionists.')
                        return 5
                      } else if (couponCodeApplied) {
                        if (prev > 3) {
                          return prev - 1
                        } else {
                          toast.error('You must start with a package of three receptionists.')
                          return 3
                        }
                      }
                      return prev - 1
                    })
                  }}
                >
                  -
                </button>
                <input type="number" value={inputState} onChange={(e) => setInputState(e.target.value)} />
                <button
                  onClick={() => {
                    setInputState((prev) => {
                      return prev + 1
                    })
                  }}
                >
                  +
                </button>
              </div>
              <button
                onClick={purchaseNow}
                style={{
                  background: 'rgb(0, 123, 255)',
                  border: 'none',
                  height: '50px',
                  padding: '0 20px',
                  color: '#fff',
                  fontSize: '16px',
                  borderRadius: '10px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  marginTop: '30px',
                }}
              >
                Purchase Now
              </button>
              <br />
              <div className="flex flex-col w-full">
                <label htmlFor="couponCode" style={{ display: 'block' }}>
                  Coupon Code:
                </label>
                <div className="flex flex-row items-center mt-2">
                  <input
                    id="couponCode"
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    style={{
                      flex: 1,
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                  <button
                    style={{
                      background: 'green',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      border: 'none',
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={() => {
                      if (couponCode.toLowerCase() === 'yosi') {
                        toast.success('Coupon code applied successfully')
                        setCouponCodeApplied(true)
                      } else {
                        toast.error('Invalid Coupon Code')
                      }
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  )

  const MyCampaigns = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1rem',
        }}
      >
        <button
          className="register-client border border-solid border-black rounded-full py-4 px-6 text-sm hover:-translate-y-1 transition-all"
          style={{
            background: '#fff',
            color: '#000',
            fontWeight: 'bold',
          }}
          onClick={() => {
            setIsRegistrationModalActive(true)
          }}
        >
          <span
            style={{
              marginRight: '.5rem',
              fontSize: '24px',
              lineHeight: '10px',
              transform: 'translateY(4px)',
              display: 'inline-block',
              fontWeight: 400,
            }}
          >
            {user && user.purchase_quotes && JSON.parse(user.purchase_quotes).length > 0 ? '⚙️' : '+'}
          </span>{' '}
          {user && user.purchase_quotes && JSON.parse(user.purchase_quotes).length > 0 ? 'Configure ' : 'Create '}{' '}
          Campaign
        </button>
      </div>

      <div className="w-full flex flex-col">
        <div className="text-lg">
          <span>My Campaigns </span>
          <span className="font-bold" style={{ color: color }}>
            ({reservedCampaignLists})
          </span>{' '}
          :
        </div>
        <div className="w-full flex flex-wrap mt-3 gap-5">
          {!isEmpty(sortedClients) ? (
            <div>
              <div
                className={classes2.cards}
                style={{
                  marginTop: '2rem',
                  // display: "flex",
                  // flexWrap: "wrap",
                  gap: '16px',
                }}
              >
                {sortedClients.map((client, index) => {
                  return (
                    <div
                      style={
                        {
                          // width: "330px",
                        }
                      }
                      key={'client.clientKey-' + index}
                    >
                      <div
                        style={{
                          borderRadius: '10px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                          padding: '16px 24px',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="w-full flex justify-between items-center">
                          <h3
                            style={{
                              fontSize: '24px',
                              margin: '0',
                            }}
                          >
                            {client.business_name}
                          </h3>
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => {
                                setIsScheduleModalActive(client)
                                const allVariables = Object.keys(JSON.parse(client?.variables || '{}'))

                                const allVariablesRaw = JSON.parse(client?.variables || '{}')
                                setData([
                                  {
                                    recepient_number: '',
                                    schedule_time: '',
                                    ...allVariables.reduce((acc, el, idx) => {
                                      acc[el] = allVariablesRaw[el].defaultValue
                                      return acc
                                    }, {}),
                                  },
                                ])
                                setScheduleStep(0)
                              }}
                            >
                              <img className="w-6" src="./calendar.svg" alt="settings" />
                            </button>
                            <button
                              onClick={() => {
                                setIsSettingsModalActive(true)
                                setSelectedTimeZone(client?.user_timezone || new Date().getTimezoneOffset())
                                setCampaignSettings({
                                  timezone: client?.user_timezone || new Date().getTimezoneOffset(),
                                  number: client?.number,
                                  max_retries: client?.max_retries || 3,
                                  time_between_retries: client?.time_between_retries || 24,
                                  allowed_times_start: client?.allowed_times_start || '9',
                                  allowed_times_end: client?.allowed_times_end || '17',
                                })
                              }}
                            >
                              <img className="w-6" src="./settingsIcon.svg" alt="settings" />
                            </button>
                          </div>
                        </div>
                        <div style={{ fontSize: '16px', margin: '4px 0' }}>
                          <p>
                            <b>Username:</b> {client.number}
                          </p>
                          <p>
                            <b>Password:</b> {client.password}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '1rem',
                          }}
                        >
                          <button
                            style={{
                              fontSize: '16px',
                              color: 'grey',
                              background: 'transparent',
                              border: '1px solid #ccc',
                              borderRadius: '6px',
                              height: '40px',
                              flex: 1,
                              marginRight: '10px',
                            }}
                            onClick={() => {
                              setSelectedResellerUsername(client.number)
                              setSelectedResellerPassword(client.password)
                              fetchReceptionistToken({username: client.number, password: client.password})
                              setSelectedClient(client)
                              setPreviewDashboardModalIsOpen(true)
                              setReceptionistFetchStatus(true)
                            }}
                          >
                            Open
                          </button>
                          {/* <button
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          background: "transparent",
                          border: "1px solid #ccc",
                          borderRadius: "6px",
                          height: "40px",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => setDeleteItemNumber(client.number)}
                      >
                        <span style={{ fontSize: "12px", marginRight: "5px" }}>
                          ❌
                        </span>
                        Delete
                      </button> */}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className="font-semibold text-lg text-center w-full">No campaigns found!</div>
          )}
        </div>
      </div>
    </>
  )
  return (
    <div>
      {registerResellerInstructionsDialog}
      {unreserveReceptionistDialog}
      {isScheduleModalActive && scheduleModal}
      {settingsModal}
      <br />
      {previewDashboardModalIsOpen && (
        <>
          <div
            onClick={() => {
              setSelectedClient(null)
              setPreviewDashboardModalIsOpen(false)
            }}
            className={classes.modalOverlay}
          ></div>
          <div className={classes.modal + ' ' + classes.wide}>
          {fetchingReceptinistData ? <Loader /> :   <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center justify-end p-2">
                <div
                  className={'text-3xl cursor-pointer'}
                  onClick={() => {
                    setSelectedClient(null)
                    setPreviewDashboardModalIsOpen(false)
                  }}
                  style={{ zIndex: 100 }}
                >
                  &times;
                </div>
              </div>

             <div className="flex-1 relative">
                <ReceptionistDashboard
                  debrand={true}
                />
              </div>
            </div>}
          </div>
        </>
      )}
      {resellerMinutesControlModalOpen && (
        <MinutesControlModal
          themeColor={themeColor}
          selectedClient={selectedClient}
          setResellerMinutesControlModalOpen={setResellerMinutesControlModalOpen}
          setSelectedClient={setSelectedClient}
          updateClientDataInState={updateClientDataInState}
        />
      )}
      {resellerChangePassModalOpen && (
        <ChangePassModal
          themeColor={themeColor}
          selectedClient={selectedClient}
          setResellerChangePassModalOpen={setResellerChangePassModalOpen}
          setSelectedClient={setSelectedClient}
          updateClientDataInState={updateClientDataInState}
        />
      )}
      {deleteReceptionistNoticeModal && (
        <WarningModal
          heading={'Remove Receptionist'}
          text={
            'This will remove the receptionist. It will become available to be reserved again. This action cannot be undone.'
          }
          onApply={handleDeleteReceptionist}
          close={() => {
            setSelectedClient(null)
            setDeleteReceptionistNoticeModal(false)
          }}
          themeColor={themeColor}
          loading={deleteReceptionistLoading}
        />
      )}
      {clearAllLogsWarningModal && (
        <WarningModal
          heading={'Clear all logs'}
          text={'This will clear all logs for this receptionist. This action cannot be undone.'}
          onApply={handleClearAllLogs}
          close={() => {
            setSelectedClient(null)
            setClearAllLogsWarningModal(false)
          }}
          themeColor={themeColor}
          loading={cleaningLogsLoading}
        />
      )}
      {copyReceptionistModal && (
        <CopyReceptionistModal
          user={user}
          selectedClient={selectedClient}
          setCopyReceptionistModalOpen={setCopyReceptionistModal}
          setSelectedClient={setSelectedClient}
          themeColor={themeColor}
          debrand={debrand}
          isCampaign={isCampaign}
          debrand_modal_instructions_setter
        />
      )}
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader2 ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
        </div>
      ) : isCampaign ? (
        MyCampaigns
      ) : (
        ClientGrid
      )}
      {/* {ClientGrid} */}
    </div>
  )
}

export default App