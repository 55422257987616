import axios from "axios";
import React, { useEffect, useState } from "react";
import { serverURL } from "../../config";
import toast from "react-hot-toast";
import isEmpty from "../../utils/is-empty";
import { Tab } from "@headlessui/react";
import { Tooltip } from "react-tooltip";

const Outbond = ({ campaigns = [], email_address }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [transcript, setTranscript] = useState("");
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
  const [isVoiceLoading, setIsVoiceLoading] = useState(false);
  const [sound, setSound] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editedVariables, setEditedVariables] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [camps, setCamps] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("All");
  const [campaignFilter, setCampaignFilter] = useState("All");

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    const vars = JSON.parse(event?.variables?.S || "{}");
    const varsChanges = Object.keys(vars).map((el) => ({
      name: el,
      value: vars[el],
    }));
    setEditedVariables(varsChanges);
    setEditMode(false);
    console.log(event);

    setIsTranscriptLoading(true);
    axios
      .post(`${serverURL}/api/calls/transcript/${event?.callSid?.S}`, {
        forwardedFrom: event?.username?.S,
      })
      .then((response) => {
        console.log(response);
        setTranscript(response?.data?.transcript);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsTranscriptLoading(false);
      });

    setIsVoiceLoading(true);
    axios
      .get(`${serverURL}/api/calls/recordings/${event?.callSid?.S}`, {
        responseType: "blob",
      })
      .then(async (response) => {
        const blob = response.data;
        const url = URL.createObjectURL(blob);
        setSound(url);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong while fetching your call recording");
      })
      .finally(() => setIsVoiceLoading(false));
  };

  const handleCloseSidebar = () => {
    setSelectedEvent(null);
    setEditMode(false);
  };

  const handleSaveChanges = () => {
    setSelectedEvent((prevEvent) => ({
      ...prevEvent,
      variables: editedVariables,
    }));
    console.log("Updated event:", selectedEvent);
    handleCloseSidebar();
  };

  useEffect(() => {
    setCamps(() => {
      return campaigns.reduce((acc, el) => {
        return { ...acc, [el.number]: el };
      }, {});
    });
  }, [campaigns]);

  useEffect(() => {
    async function getCallEvents() {
      try {
        const response = await axios.post(
          `${serverURL}/api/campaigns/calls/find`,
          {
            campaign_user_email: email_address,
          }
        );

        setData(response?.data?.events || []);
        setFilteredData(response?.data?.events || []);
        console.log(response?.data?.events);
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    }
    getCallEvents();
  }, []);

  useEffect(() => {
    let filtered = data;
    if (filter !== "All") {
      filtered = filtered.filter((event) =>
        event.event_state?.S.includes(filter)
      );
    }
    if (campaignFilter !== "All") {
      filtered = filtered.filter(
        (event) => camps[event?.username?.S]?.business_name === campaignFilter
      );
    }
    setFilteredData(filtered);
  }, [filter, campaignFilter, data, camps]);

  const renderTranscript = (text) => {
    if (!text) return "";
    const endingString =
      "user@ [Silence]\nassistant@ Sorry, I didn't hear anything. Can you say that again?";
    if (text?.endsWith(endingString)) {
      text =
        text.slice(0, text.length - endingString.length) +
        "user@ [User Hung Up]";
    }
    const formattedText = text
      ?.replace(
        /user@/g,
        '<br /><span style="font-weight: bold;">Caller:</span><br />'
      )
      ?.replace(
        /assistant@/g,
        '<br /><span style="font-weight: bold;">Assistant:</span><br />'
      )
      ?.replace(/@/g, ":<br />");

    if (
      formattedText?.endsWith(
        '<span style="font-weight: bold;">Assistant:</span> <br />'
      )
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: formattedText + "[User Hung up]" }}
        ></div>
      );
    }
    if (
      formattedText?.endsWith(
        '<span style="font-weight: bold;">Caller:</span> <br />'
      )
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: formattedText + "[User Hung up]" }}
        ></div>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: formattedText }}></div>;
  };

  return (
    <div className="flex">
      <div className="flex-1 p-6">
        <h1 className="text-3xl font-semibold mb-6 text-gray-800">
          Event List
        </h1>
        <div className="flex mb-4 space-x-4">
          {/* Filters */}
          <div>
            <label className="mr-2">Filter by state:</label>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-md"
            >
              <option value="All">All</option>
              <option value="SUCCESS">SUCCESSFUL</option>
              <option value="FAILED">FAILED</option>
              <option value="PENDING">PENDING</option>
            </select>
          </div>
          <div>
            <label className="mr-2">Filter by campaign:</label>
            <select
              value={campaignFilter}
              onChange={(e) => setCampaignFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-md"
            >
              <option value="All">All</option>
              {Object.values(camps).map((camp, index) => (
                <option key={index} value={camp.business_name}>
                  {camp.business_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {isEmpty(camps) ? (
          <div className="text-center font-bold">No campaigns found!</div>
        ) : loading ? (
          <div className="flex justify-center items-center">
            <div className="loader2 ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {filteredData.map((event, index) => {
              const stateHistories = JSON.parse(
                event?.state_histories?.S || "[]"
              );

              return (
                <div
                  key={index}
                  className="bg-white flex flex-row items-center justify-between rounded-lg shadow p-3 w-full cursor-pointer hover:shadow-md transition-shadow duration-300"
                  onClick={() => handleEventClick(event)}
                >
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="text-lg font-semibold text-gray-800">
                      {camps[event?.username?.S]?.business_name}
                    </h2>
                    <div className="flex items-center space-x-2 ms-3">
                      {stateHistories.map((state, stateIndex) => (
                        <span
                          key={stateIndex}
                          className={`${
                            state === "SUCCESS"
                              ? "bg-green-500"
                              : state === "FAILED"
                              ? "bg-red-500"
                              : "bg-gray-500"
                          } w-3 h-3 rounded-full`}
                        ></span>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className={`mr-2 w-3 h-3 rounded-full ${
                        event.event_state?.S.includes("SUCCESS")
                          ? "bg-green-500"
                          : event.event_state?.S.includes("FAILED")
                          ? "bg-red-500"
                          : "bg-yellow-500"
                      }`}
                    ></div>
                    <p
                      className={`${
                        event.event_state?.S.includes("SUCCESS")
                          ? "text-green-600"
                          : event.event_state?.S.includes("FAILED")
                          ? "text-red-600"
                          : "text-yellow-600"
                      }`}
                    >
                      {event.event_state?.S.includes("FAILED")
                        ? "Failed"
                        : event.event_state?.S}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {selectedEvent && (
        <div
          className={`fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[1000] transform transition-transform duration-500 ${
            selectedEvent ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="bg-white rounded-lg shadow-md p-6 w-4/5 h-[90%] overflow-y-auto">
            <Tab.Group>
              <Tab.List className="flex justify-around bg-white">
                <Tab
                  className={({ selected }) =>
                    selected
                      ? " text-blue-900 border-b pb-1"
                      : "text-grey hover:bg-white/[0.12] hover:text-blue-900  border-b pb-1"
                  }
                >
                  Basic Information
                </Tab>
                <Tab
                  className={({ selected }) =>
                    selected
                      ? " text-blue-900 border-b pb-1"
                      : "text-grey hover:bg-white/[0.12] hover:text-blue-900  border-b pb-1"
                  }
                >
                  Logs
                </Tab>
                {selectedEvent?.event_state?.S.includes("SUCCESS") && (
                  <Tab
                    className={({ selected }) =>
                      selected
                        ? " text-blue-900 border-b pb-1"
                        : "text-grey hover:bg-white/[0.12] hover:text-blue-900  border-b pb-1"
                    }
                  >
                    Transcript
                  </Tab>
                )}
                <Tab
                  className={({ selected }) =>
                    selected
                      ? " text-blue-900 border-b pb-1"
                      : "text-grey hover:bg-white/[0.12] hover:text-blue-900  border-b pb-1"
                  }
                >
                  Variables
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel>
                  <div className="mb-4">
                    <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
                      Basic Information
                    </h2>
                    <p className="text-gray-700">
                      <strong
                        data-tooltip-id="Campaign-Name"
                        className="font-medium cursor-pointer border-b-2 border-dashed border-black"
                      >
                        Campaign Name:
                      </strong>{" "}
                      <Tooltip
                        id="Campaign-Name"
                        content="Name of the campaign"
                      />
                      {camps[selectedEvent?.username?.S]?.business_name}
                    </p>
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="recipient-number"
                        className="font-medium cursor-pointer border-b-2 border-dashed border-black"
                      >
                        Recipient Number:
                      </strong>{" "}
                      <Tooltip
                        id="recipient-number"
                        content="The phone number of the receiver"
                      />
                      {selectedEvent?.recipient_number?.S}
                    </p>
                    {/* <p className="mt-4 text-gray-700">
                      <strong className="font-medium">Execution Times:</strong>{" "}
                      {JSON.parse(
                        selectedEvent?.execution_times?.S || "[]"
                      )?.join(", ")}
                    </p> */}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="mb-4">
                    <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
                      Logs
                    </h2>
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="state-history"
                        className="font-bold border-b-2 border-dashed border-black mt-6 mb-4 text-gray-800"
                      >
                        State History:
                      </strong>{" "}
                      <Tooltip
                        id="state-history"
                        content="The full history of the state in order"
                      />
                      {JSON.parse(
                        selectedEvent?.state_histories?.S || "[]"
                      )?.join(" ➡️ ")}
                    </p>
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="execution-times"
                        className="font-bold border-b-2 border-dashed border-black mt-6 mb-4 text-gray-800"
                      >
                        Execution times:
                      </strong>{" "}
                      <Tooltip
                        id="execution-times"
                        content="The times on which the event got triggered (try to a call was made)"
                      />
                      {JSON.parse(selectedEvent?.execution_times?.S || "[]")
                        ?.map((el) => new Date(el).toLocaleString())
                        ?.join(" ➡️ ")}
                    </p>
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="execution-time"
                        className="font-bold border-b-2 border-dashed border-black mt-6 mb-4 text-gray-800"
                      >
                        Execution Time:
                      </strong>{" "}
                      <Tooltip
                        id="execution-time"
                        content="Upcoming execution time of next trigger"
                      />
                      {new Date(
                        +selectedEvent?.event_execution_time?.N
                      ).toLocaleString()}
                    </p>
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="error-detail"
                        className="font-bold border-b-2 border-dashed border-black mt-6 mb-4 text-gray-800"
                      >
                        Error Detail:
                      </strong>{" "}
                      {selectedEvent?.errors_blob?.S}
                    </p>{" "}
                    <Tooltip
                      id="error-detail"
                      content="Too technical sometimes. No?"
                    />
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="state-desc"
                        className="font-bold border-b-2 border-dashed border-black mt-6 mb-4 text-gray-800"
                      >
                        State:
                      </strong>{" "}
                      {selectedEvent?.event_state?.S}
                    </p>
                    <Tooltip
                      id="state-desc"
                      content={
                        <>
                          The state of the last trigger.
                          <br /> 1. PENDING: Event is scheduled, has not been
                          processed processed
                          <br /> 2. FAILED: Event failed last time, still has
                          retries left
                          <br /> 3. SUCCESS: Event has been processed
                          successfully
                          <br /> 4. FAILED_TERMINAL: Event has failed N times,
                          will not be retried
                          <br /> 5. PROCESSING: Event is currently being
                          processed by event processor
                        </>
                      }
                    />
                    <p className="mt-4 text-gray-700">
                      <strong
                        data-tooltip-id="num-tries"
                        className="font-bold border-b-2 border-dashed border-black mt-6 mb-4 text-gray-800"
                      >
                        Number of tries so far:
                      </strong>{" "}
                      {selectedEvent?.num_tries_so_far?.N}
                    </p>{" "}
                    <Tooltip
                      id="num-tries"
                      content="Number of times the platform tried sending a call after seeing a FAILED or PENDING status"
                    />
                  </div>
                </Tab.Panel>
                {selectedEvent?.event_state?.S.includes("SUCCESS") && (
                  <Tab.Panel>
                    <div className="mb-4">
                      <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
                        Recording
                      </h2>

                      {isVoiceLoading ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Please wait while we are carrying the bulky data from
                          our servers on your device{" "}
                          <span style={{ fontSize: "32px" }}>🏋️</span>...
                        </div>
                      ) : (
                        <div className="flex justify-center mb-4">
                          {<audio controls src={sound} />}
                        </div>
                      )}

                      <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
                        Transcript
                      </h2>
                      {isTranscriptLoading ? (
                        <>Loading...</>
                      ) : (
                        <p className="mt-4 text-gray-700">
                          {renderTranscript(transcript)}
                        </p>
                      )}
                    </div>
                  </Tab.Panel>
                )}
                <Tab.Panel>
                  <div className="mb-4">
                    <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">
                      Variables
                    </h2>
                    <ul className="space-y-3">
                      {editedVariables.map((variable, index) => (
                        <li key={index} className="flex items-center space-x-4">
                          {editMode ? (
                            <input
                              type="text"
                              value={variable.value}
                              onChange={(e) => {
                                const updatedVariables = [...editedVariables];
                                updatedVariables[index] = {
                                  ...updatedVariables[index],
                                  value: e.target.value,
                                };
                                setEditedVariables(updatedVariables);
                              }}
                              className="flex-1 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          ) : (
                            <div className="flex-1 text-gray-700 border-b border-gray-200 py-2">
                              <strong className="font-medium">
                                {variable.name}:
                              </strong>{" "}
                              {variable.value}
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="flex justify-end mt-6">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors duration-300 ml-2"
                onClick={handleCloseSidebar}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Outbond;
