import { useEffect, useState } from "react";
import { Select, Card, Typography, Tooltip, Tabs, Modal, Tag, Layout, TabsProps, Spin } from "antd";
import { useAppSelector } from "@/store/hooks/useAppSelector";
import { getLoggedInNoviUser } from "@/store/novi/user/selector";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, PhoneOutlined } from "@ant-design/icons";
import { useCampaignClient } from "../Campagins/hooks/useCampaignClient";
import { useCampaignEvents, useRecording, useTranscript } from "./hooks/outBondAnalyticsHook";
import { BasicInformation } from "./Components/BasicInformation";
import { Logs } from "./Components/Logs";
import { RecordingAndTranscript } from "./Components/RecordingAndTranscript";
import { Variables } from "./Components/Variables";


const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

interface Campaign {
    number: string;
    business_name: string;
}

interface Event {
    execution_times: any;
    callSid?: string
    username?: string
    variables?: string
    recipient_number?: string
    state_histories?: string
    event_execution_time?: string
    event_state?: string
    errors_blob?: string
    num_tries_so_far?: number
}

const OutbondAnalytics: React.FC = () => {
    const noviUser = useAppSelector(getLoggedInNoviUser);
    const email_address = noviUser?.email_address;
    const { data: client } = useCampaignClient(email_address);
    const campaigns = client?.items || [];
    const { data: eventsData, isPending } = useCampaignEvents(email_address);
    const { mutate: fetchTranscript, data: transcriptData, isPending: isTranscriptLoading } = useTranscript();
    const { mutate: fetchRecording, data: voiceData, isPending: isVoiceLoading } = useRecording();
    const [sound, setSound] = useState("");
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [transcript, setTranscript] = useState<string>("");
    const [editedVariables, setEditedVariables] = useState<{ name: string; value: string }[]>([]);
    const [filteredData, setFilteredData] = useState<Event[]>([]);
    const [camps, setCamps] = useState<{ [key: string]: Campaign }>({});
    const [filter, setFilter] = useState<string>("All");
    // @ts-ignore
    const [campaignFilter, setCampaignFilter] = useState<string>("All");
    const [visible, setVisible] = useState<boolean>(false);
    const [activeTabKey, setActiveTabKey] = useState("1");

    const getBadgeColor = (state: string | undefined) => {
        switch (state) {
            case "SUCCESS":
                return "green";
            case "FAILED":
                return "red";
            case "PENDING":
                return "orange";
            default:
                return "gray";
        }
    };

    const handleEventClick = async (event: Event) => {
        setSelectedEvent(event);
        const vars = event?.variables?.length ? JSON.parse(event?.variables) : {};
        const varsChanges = Object.keys(vars).map((el) => ({ name: el, value: vars[el] }));
        setEditedVariables(varsChanges);
        if (event.callSid && event.username) {
            await Promise.all([
                fetchTranscript({ callSid: event.callSid, forwardedFrom: event.username }),
                fetchRecording(event.callSid),
            ]);
        }
        setVisible(true);
    };

    useEffect(() => {
        if (campaigns && Array.isArray(campaigns)) {
            setCamps(campaigns.reduce((acc, el) => {
                return { ...acc, [el.number]: el };
            }, {} as { [key: string]: Campaign }));
        }
        if (voiceData && !isVoiceLoading) {
            setSound(voiceData.url);
        }
        if (transcriptData && !isTranscriptLoading) {
            setTranscript(transcriptData);
        }
    }, [campaigns, voiceData, isVoiceLoading, transcriptData, isTranscriptLoading]);

    useEffect(() => {
        console.log("UseEffect 2 Events:", eventsData);
        setFilteredData(eventsData || []);
    }, [eventsData]);

    useEffect(() => {
        let filtered = eventsData || [];
        if (filter !== "All") {
            filtered = filtered.filter((event: { event_state: string | string[]; }) => event.event_state.includes(filter));
        }
        if (campaignFilter !== "All") {
            filtered = filtered.filter((event: { username: string | number; }) => camps[event?.username]?.business_name === campaignFilter);
        }
        setFilteredData(filtered);
    }, [filter, campaignFilter, eventsData, camps]);

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'SUCCESS': return 'green';
            case 'FAILED': return 'red';
            case 'PROCESSING': return 'blue';
            case 'FAILED_TERMINAL': return 'red';
            default: return 'default';
        }
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'SUCCESS': return <CheckCircleOutlined className="text-green-500" />;
            case 'FAILED': return <CloseCircleOutlined className="text-red-500" />;
            case 'PROCESSING': return <LoadingOutlined className="text-blue-500" />;
            case 'FAILED_TERMINAL': return <CloseCircleOutlined className="text-red-500" />;
            default: return null;
        }
    };

    const stateHistories = selectedEvent?.state_histories?.length ? JSON.parse(selectedEvent.state_histories) : [];
    const executionTimes = selectedEvent?.execution_times?.length ? JSON.parse(selectedEvent.execution_times) : [];

    const handleCloseModal = () => {
        setVisible(false);
        setActiveTabKey("1");
        setSelectedEvent(null);
        setSound("");
        setTranscript("");
        setEditedVariables([]);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Basic Information',
            children: <BasicInformation
                selectedEvent={selectedEvent}
                getBadgeColor={getBadgeColor}
            />,
        },
        {
            key: '2',
            label: 'Logs',
            children: <Logs
                selectedEvent={selectedEvent}
                stateHistories={stateHistories}
                executionTimes={executionTimes}
                getStatusColor={getStatusColor}
                getStatusIcon={getStatusIcon}
            />,
        },
        {
            key: '3',
            label: 'Recording & Transcript',
            children: <RecordingAndTranscript
                sound={sound}
                transcript={transcript}
                isVoiceLoading={isVoiceLoading}
                isTranscriptLoading={isTranscriptLoading}
            />,
        },
        {
            key: '4',
            label: 'Variables',
            children: <Variables editedVariables={editedVariables} />
        },
    ]

    return (
        <Spin spinning={isPending}>
            <Layout>
                <Title level={2}>Event List</Title>
                <Content className="p-6">
                    <div className="mb-6 flex justify-between">
                        <Select defaultValue="All" className="w-48" onChange={(value) => setFilter(value)}>
                            <Option value="All">All States</Option>
                            <Option value="SUCCESS">Success</Option>
                            <Option value="FAILED">Failed</Option>
                            <Option value="PROCESSING">Processing</Option>
                        </Select>
                        <Select defaultValue="All" className="w-48">
                            <Option value="All">All Campaigns</Option>
                        </Select>
                    </div>
                    {filteredData.length ? filteredData?.map((event, index) => (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            <Card
                                key={index}
                                hoverable
                                className="shadow-lg transition-all duration-300 hover:shadow-xl"
                                onClick={() => handleEventClick(event)}
                            >
                                <div className="flex justify-between items-center mb-4">
                                    <Title level={4} className="m-0">{camps[event?.username || '']?.business_name || "Unknown"}</Title>
                                    <Tag color={getStatusColor(event?.event_state as string)} icon={getStatusIcon(event?.event_state as string)}>
                                        {event.event_state}
                                    </Tag>
                                </div>
                                <div className="flex items-center mb-2">
                                    <PhoneOutlined className="mr-2 text-gray-500" />
                                    <Text strong> Recipient: </Text>
                                    <Text>{event?.recipient_number || "N/A"}</Text>
                                </div>
                                <div className="mt-4 flex">
                                    {event?.state_histories?.length ? JSON.parse(event.state_histories).map((state: any, i: number) => (
                                        <Tooltip key={i} title={state}>
                                            <div className={`w-3 h-3 rounded-full mr-1 bg-${getStatusColor(state)}-500`} />
                                        </Tooltip>
                                    )) : null}
                                </div>
                            </Card>
                        </div>
                    )) : (
                        <div className="text-center text-gray-500">No events found</div>
                    )}
                </Content>
                <Modal
                    open={visible}
                    onCancel={handleCloseModal}
                    footer={null}
                    width={800}
                >
                    {selectedEvent && (
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                            activeKey={activeTabKey}
                            onChange={(key) => setActiveTabKey(key)}
                        />
                    )}
                </Modal>
            </Layout >
        </Spin>
    );
};

export default OutbondAnalytics;
