import { LabelWithDescription } from '@/components/LabelWithdescription'
import {
  AccessAlarmsOutlined,
  DialpadOutlined,
  HourglassBottomOutlined,
  LockClockOutlined,
  RadioButtonCheckedOutlined,
  RecordVoiceOverOutlined,
  VoicemailOutlined,
  WebhookOutlined,
  PinOutlined,
  InfoOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material'
import { Button, Form, Input, notification, Slider, Spin, Switch, Table } from 'antd'
import './TweakAdvancedSettingsForm.scss'
import { useState } from 'react'
import { CustomModal } from '@/components/CustomModal'
import ExtensionDigitBuilderForm from './ExtensionDigitBuilderForm/ExtensionDigitBuilderForm'
import ZapierIntegrationForm from './ZapierIntegration/ZapierIntegrationForm/ZapierIntegrationForm'
import TimeControlForm from './TimeControlForm/TimeControlForm'
import AddPronunciationForm from './AddPronunciationForm/AddPronunciationForm'
import MaxUsageLimitForm from './MaxUsageLimit/MaxUsageLimitForm'
import { OverViewCardForm } from '../OverviewCardForm'
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query'
import { updateUser } from '@/api/user'
import { TweakFormInitialValues } from '@/types/TTweakForm'
import { LoadingOutlined } from '@ant-design/icons'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal, openModal } from '@/store/modal/slice'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getUserFeatures } from '@/store/account/selector'
import { MODAL_IDS } from 'src/enums/EModal'
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput'
import { User } from '@/types/TAccounts'
import { handlePrefixPlusOnPhone } from '@/utils/helper'

const TweakAdvancedSettingsForm: React.FC<{
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  formInitialValues: TweakFormInitialValues
  activeField: string | null
  debrand: boolean
}> = ({ refetch, formInitialValues, activeField, debrand }) => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm<{
    chattiness: number
    endpointing: number
    interruption_words: number
    maxCallDuration: number
    extension_digits: {
      extension: number
      phoneNumber: number
    }
    voicemails_enabled: boolean
    call_recording_enabled: boolean | string
    word_pronunciation: {
      pronunciation: string
      word: string
    }[]
    time_zone: any
    advancedTimeControls: {
      start_time: string
      end_time: string
      phone_number: number
    }[]
    autoHangupAfterInteraction: boolean
    autoHangupAfterInteractionText?: string
    disableReceptionistSettings?: {
      disabled?: boolean
      disableType: string // enum  --> ["message", "forward"]
      forwardingNumber?: string
      hangupMessage?: string
    }
  }>()

  const features = useAppSelector(getUserFeatures)

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)
  const [isZapierIntegrationEnabled, setIsZapierIntegrationEnabled] = useState(false)

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch()
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      })
      setIsFieldTouchReset(false)
      dispatch(closeModal())
    },
  })

  const handleSubmit = async (value: TweakFormInitialValues) => {
    const mainDisableReceptionistSettings = form.getFieldValue('disableReceptionistSettings')

    await mutation.mutateAsync({
      maxCallDuration: value.maxCallDuration,
      voicemails_enabled: value.voicemails_enabled,
      call_recording_enabled: value.call_recording_enabled,
      autoHangupAfterInteraction: value.autoHangupAfterInteraction,
      autoHangupAfterInteractionText: value.autoHangupAfterInteractionText,
      disableReceptionistSettings: value.disableReceptionistSettings?.disabled
        ? {
            disabled: value.disableReceptionistSettings?.disabled,
            disableType: value.disableReceptionistSettings?.disableType || mainDisableReceptionistSettings?.disableType,
            forwardingNumber: handlePrefixPlusOnPhone(value.disableReceptionistSettings?.forwardingNumber || ''),
            hangupMessage: value.disableReceptionistSettings?.hangupMessage,
          }
        : {
            disabled: false,
          },
    })
    dispatch(closeModal())
  }

  const handleTimeControlClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.TIME_CONTROL_MODAL }))
  }

  const handlePronunciationGuideClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.PRONUNCIATION_GUIDE_MODAL }))
  }

  const handleExtensionDigitsClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.EXTENSION_DIGIT_MODAL }))
  }

  const handleZapierIntegrationClick = (checked: boolean) => {
    if (!features?.advancedCapabilities?.zapierIntegration?.value) {
      dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }))
      return
    }

    setIsZapierIntegrationEnabled(checked)
    if (checked) {
      dispatch(openModal({ modalId: MODAL_IDS.ZAPIER_INTEGRATION_MODAL }))
    }
  }

  const handleMaxUsageLimitClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.MAX_USAGE_LIMIT_MODAL }))
  }

  const handleConfirm = () => {
    dispatch(closeModal())
  }
  const handleClose = () => {
    dispatch(closeModal())
    window.dispatchEvent(new Event('modalClosed'))
  }

  const handleMaxCallDurationChange = (value: number) => {
    if (!features?.advancedCapabilities?.setMaxRecipientMinutes?.value) {
      return
    }
    form.setFieldsValue({ maxCallDuration: value })
  }

  const pronunciationColumns = [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
    },
    {
      title: 'Pronunciation',
      dataIndex: 'pronunciation',
      key: 'pronunciation',
    },
  ]

  const timeControlColumns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_: any, record: any) => {
        return <div>{record.start_time || 'NA'}</div>
      },
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      render: (_: any, record: any) => {
        return <div>{record.end_time || 'NA'}</div>
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_: any, record: any) => {
        return <div>{record.phone_number || 'NA'}</div>
      },
    },
  ]

  const FormItems = () => (
    <>
      {activeField === 'extensionDigits' || activeField === null ? (
        <Form.Item
          className={activeField === 'extensionDigits' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          valuePropName="checked"
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                label="Extension Digits"
                description='You can set up extension codes that your AI Receptionist can handle to reroute the caller. i.e. "press 3 for billing department".'
                icon={<DialpadOutlined className="mr-1 mb-1" />}
              />
              <CustomModal
                title={
                  <>
                    <PinOutlined className="!text-4xl" /> <strong>Extension Digits Builder</strong>
                  </>
                }
                children={
                  <ExtensionDigitBuilderForm
                    extension_digits={formInitialValues.extension_digits || []}
                    refetch={refetch}
                  />
                }
                confirmAction={handleConfirm}
                cancelAction={handleClose}
                footer={null}
                modalId={MODAL_IDS.EXTENSION_DIGIT_MODAL}
              />
            </div>
          }
        >
          <div className="flex justify-end mr-2">
            <Button type="primary" onClick={handleExtensionDigitsClick}>
              View Details
            </Button>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'zapierIntegration' || activeField === null ? (
        <Form.Item
          className={activeField === 'zapierIntegration' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          valuePropName="checked"
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName="w-full"
                label="Zapier Integration"
                description="Your AI Receptionist can connect to other apps."
                icon={<WebhookOutlined className="mr-1 mb-1" />}
              />
              <CustomModal
                title={<strong>Connect With Zapier</strong>}
                children={<ZapierIntegrationForm debrand={debrand} />}
                confirmAction={handleConfirm}
                footer={null}
                modalId={MODAL_IDS.ZAPIER_INTEGRATION_MODAL}
              />
            </div>
          }
        >
          <Switch
            className={`${baseClass}__item-container_switch`}
            size="small"
            checked={isZapierIntegrationEnabled}
            onClick={handleZapierIntegrationClick}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'allowVoicemails' || activeField === null ? (
        <Form.Item
          className={activeField === 'allowVoicemails' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name="voicemails_enabled"
          valuePropName="checked"
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName="w-full"
                label="Allow Voicemails"
                description='If someone asks to leave a message or voicemail, the AI Receptionis will collect a voicemail. This message will be separately shown in the "Voicemails" section of the dashboard.'
                icon={<VoicemailOutlined className="mr-1 mb-1" />}
              />
            </div>
          }
        >
          <Switch className={`${baseClass}__item-container_switch`} size="small" />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'callRecording' || activeField === null ? (
        <Form.Item
          className={activeField === 'callRecording' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name="call_recording_enabled"
          valuePropName="checked"
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName="w-8/12"
                label="Enable Call Recording"
                description="Record all calls. Recorded calls will be stored in the dashboard for review."
                icon={<RadioButtonCheckedOutlined className="mr-1 mb-1" />}
              />
            </div>
          }
        >
          <Switch className={`${baseClass}__item-container_switch`} size="small" />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'temperoryDisabled' || activeField === null ? (
        <Form.Item
          className={activeField === 'temperoryDisabled' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
        >
          <div className={`${baseClass}__item`}>
            <div className={`${baseClass}__details-container flex flex-col gap-6`}>
              <div className="flex items-center justify-between">
                <LabelWithDescription
                  customClassName="w-8/12"
                  label="Temporary Disable"
                  description="Temporarily disable your Receptionist by either setting a default hangup message or specifying a number to automatically forward calls to."
                  icon={<WarningAmberOutlined fontSize="large" className="mr-1 mb-1" />}
                />
                <Form.Item name={['disableReceptionistSettings', 'disabled']}>
                  <Switch size="small" />
                </Form.Item>
              </div>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.disableReceptionistSettings?.disabled !==
                    currentValues.disableReceptionistSettings?.disabled ||
                  prevValues.disableReceptionistSettings?.disableType !==
                    currentValues.disableReceptionistSettings?.disableType
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const isDisabled = getFieldValue(['disableReceptionistSettings', 'disabled'])
                  const disableType = getFieldValue(['disableReceptionistSettings', 'disableType'])

                  const handleSwitchChange = (type: string) => {
                    setFieldsValue({
                      disableReceptionistSettings: {
                        ...getFieldValue('disableReceptionistSettings'),
                        disableType: type,
                      },
                    })
                  }

                  return (
                    isDisabled && (
                      <>
                        <div className="flex items-center justify-center gap-2 py-1 px-2 text-sm bg-yellow-100 my-2 border rounded-md">
                          <InfoOutlined fontSize="small" className="text-blue-600" />
                          <span>
                            Your receptionist will be disabled. Please select from below, what will happen if your
                            customer calls this receptionist number
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className={`${baseClass}__details`}>
                            <span>Forward all incoming calls to another number</span>
                            <Switch
                              size="small"
                              checked={disableType === 'forward'}
                              onChange={(checked) => handleSwitchChange(checked ? 'forward' : '')}
                            />
                          </div>
                          {disableType === 'forward' && (
                            <div className="ml-[20px] sm:ml-[45px]">
                              <PhoneNumberInput name={['disableReceptionistSettings', 'forwardingNumber']} />
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className={`${baseClass}__details`}>
                            <span>Say a custom message and automatically hang up</span>
                            <Switch
                              size="small"
                              checked={disableType === 'message'}
                              onChange={(checked) => handleSwitchChange(checked ? 'message' : '')}
                            />
                          </div>
                          {disableType === 'message' && (
                            <div className="ml-[20px] sm:ml-[45px]">
                              <Form.Item name={['disableReceptionistSettings', 'hangupMessage']}>
                                <Input />
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  )
                }}
              </Form.Item>
            </div>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'advancedTimeControls' || activeField === null ? (
        <Form.Item
          className={
            activeField === 'advancedTimeControls' ? `${baseClass}_active-class pt-4` : `${baseClass}__item__with-tbl`
          }
        >
          <div className="flex-1 mb-3">
            <LabelWithDescription
              customClassName="w-full"
              label="Time Control"
              description="Define specific times to automatically transfer calls to another number"
              icon={<LockClockOutlined className="mr-1 mb-1" />}
            />
            <Table
              pagination={false}
              className={`${baseClass}__item__with-tbl_table mt-4 w-full`}
              dataSource={formInitialValues.advancedTimeControls.map((item, index) => ({
                ...item,
                key: index,
              }))}
              columns={timeControlColumns}
            />
          </div>
          <div className="flex justify-end">
            <Button type="primary" onClick={handleTimeControlClick}>
              View Details
            </Button>
          </div>
          <CustomModal
            title={
              <>
                <div className="flex flex-col">
                  <strong>Time Controls</strong>
                  <span className="text-xs">Choose your timezone</span>
                </div>
              </>
            }
            children={
              <TimeControlForm
                advancedTimeControls={
                  formInitialValues.advancedTimeControls || [{ start_time: '', end_time: '', phone_number: '' }]
                }
                refetch={refetch}
                time_zone={formInitialValues.time_zone}
              />
            }
            modalId={MODAL_IDS.TIME_CONTROL_MODAL}
            confirmAction={handleConfirm}
            footer={null}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'wordPronunciation' || activeField === null ? (
        <Form.Item
          className={
            activeField === 'wordPronunciation' ? `${baseClass}_active-class pt-4` : `${baseClass}__item__with-tbl`
          }
        >
          <div className=" mb-3">
            <LabelWithDescription
              customClassName="w-full"
              label="Pronunciation Guides"
              description="Your corresponding pronounciation"
              icon={<RecordVoiceOverOutlined className="mr-1 mb-1" />}
            />
            <Table
              pagination={false}
              className={`${baseClass}__item__with-tbl_table mt-4 w-full`}
              dataSource={(formInitialValues?.word_pronunciation ?? []).map((item, index) => ({
                ...item,
                key: index,
              }))}
              columns={pronunciationColumns}
            />
          </div>
          <div className="flex justify-end">
            <Button type="primary" onClick={handlePronunciationGuideClick}>
              View Details
            </Button>
          </div>

          <CustomModal
            title={<strong>Add Word And Pronunciation</strong>}
            children={
              <AddPronunciationForm
                word_pronunciation={formInitialValues.word_pronunciation || [{ pronunciation: '', word: '' }]}
                refetch={refetch}
              />
            }
            modalId={MODAL_IDS.PRONUNCIATION_GUIDE_MODAL}
            confirmAction={handleConfirm}
            footer={null}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'maxUsageLimit' || activeField === null ? (
        <Form.Item
          className={activeField === 'maxUsageLimit' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName="w-8/12"
                label="Max Usage Limit"
                description="Maximum usage limit (in minutes) for your AI receptionist. Once your usage reaches 50%, 75% or 100% of the limit you will recieve an email notification."
                icon={<HourglassBottomOutlined className="mr-1 mb-1" />}
              />

              <CustomModal
                title={<strong>Set Maximum Usage Limit</strong>}
                children={
                  <MaxUsageLimitForm
                    refetch={refetch}
                    usage_notification_emails={formInitialValues.usage_notification_emails}
                    max_minutes={formInitialValues.max_minutes}
                    minutesLimitEnabled={formInitialValues.minutesLimitEnabled}
                  />
                }
                modalId={MODAL_IDS.MAX_USAGE_LIMIT_MODAL}
                confirmAction={handleConfirm}
                footer={null}
              />
            </div>
          }
        >
          <div className="flex justify-end">
            <Button type="primary" onClick={handleMaxUsageLimitClick}>
              View Details
            </Button>
          </div>
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'maxCallDuration' || activeField === null ? (
        <Form.Item
          className={activeField === 'maxCallDuration' ? `${baseClass}_active-class` : `${baseClass}__item-container`}
          name="maxCallDuration"
          label={
            <div className={`${baseClass}__item`}>
              <LabelWithDescription
                customClassName="w-8/12"
                label="Maximum Call Duration"
                description="Maximum call duration (in minutes) of the AI receptionist before it automatically hang up"
                icon={<AccessAlarmsOutlined className="mr-1 mb-1" />}
              />
            </div>
          }
        >
          <Slider
            min={2}
            max={30}
            className="mx-4"
            value={form.getFieldValue('maxCallDuration')}
            onChange={handleMaxCallDurationChange}
          />
        </Form.Item>
      ) : (
        ''
      )}

      {activeField === 'hangupWorkflow' || activeField === null ? (
        <div>
          <Form.Item
            label={
              <LabelWithDescription
                customClassName="mt-2"
                label="Auto Hangup the call after interaction?"
                description="Enable this option to automatically end the call when the interaction is clearly over. This helps avoid unnecessary call duration and reduces additional costs."
              />
            }
            name="autoHangupAfterInteraction"
            valuePropName="checked"
            className={`${baseClass}__input-item`}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.autoHangupAfterInteraction !== currentValues.autoHangupAfterInteraction
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('autoHangupAfterInteraction') && (
                <Form.Item
                  label={
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Text Message"
                      description="This will be spoken while auto hanging up the call after interaction is over"
                    />
                  }
                  name="autoHangupAfterInteractionText"
                  className={`${baseClass}__input-item`}
                >
                  <Input.TextArea rows={2} placeholder="Please enter text message" />
                </Form.Item>
              )
            }
          </Form.Item>
        </div>
      ) : (
        ''
      )}
    </>
  )

  const baseClass = 'tweak-advanced-settings-form'

  return (
    <div className={baseClass}>
      <Spin
        spinning={mutation.isPending}
        indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
        percent="auto"
      >
        <OverViewCardForm
          form={form}
          initialValues={formInitialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  )
}

export default TweakAdvancedSettingsForm
