import axios from "axios";
import { serverURL } from "../config";
import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

export const ResellerContext = createContext();

export const useResellerGlobal = () => {
  return useContext(ResellerContext);
};

export const ResellerGlobalProvider = ({ children }) => {
  const [branded, setBranded] = useState(true);
  const [brandedLoading, setBrandedLoading] = useState(false);

  const toggleBranded = async ({
    value = null,
    resellerEmail,
    action = null,
  }) => {
    if (action === "api") {
      setBrandedLoading(true);
      // save to DB here
      const resp = await axios.put(
        `${serverURL}/api/reseller/branded/setting/update`,
        {
          resellerEmail,
          branded: value !== null ? value : !branded,
        }
      );

      if (resp.data.success) {
        setBrandedLoading(false);
        setBranded(value !== null ? value : !branded);
      } else {
        toast.error("Could not update branded setting");
      }
    } else {
      setBranded(value !== null ? value : !branded);
    }
  };

  return (
    <ResellerContext.Provider
      value={{
        branded,
        brandedLoading,
        toggleBranded,
      }}
    >
      {children}
    </ResellerContext.Provider>
  );
};
