import { Modal, Button } from 'antd'
import React from 'react'

interface DeleteModalProps {
  type: string | undefined
  title: string | undefined
  onDeleteBtnClick: () => void
  setIsDeleteModalOpen: (isOpen: boolean) => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({ type, title, onDeleteBtnClick, setIsDeleteModalOpen }) => {
  return (
    <Modal
      visible={true} // The modal is always visible when this component is rendered.
      onCancel={() => setIsDeleteModalOpen(false)} // Close the modal on cancel
      footer={null} // Disable the default footer
      centered // Center the modal
      width={600} // Set a custom width
      title={`Delete this ${type}?`} // Set the title
      className="delete-modal"
    >
      <p className="text-gray-500 font-[600] tracking-wide text-xs pt-6">
        {type === 'task'
          ? `Are you sure you want to delete the "${title}" task and its subtasks? This action cannot be reversed.`
          : `Are you sure you want to delete the "${title}" board? This action will remove all columns and tasks and cannot be reversed.`}
      </p>

      <div className="flex w-full mt-4 items-center justify-center space-x-4">
        <Button onClick={() => onDeleteBtnClick()} type="primary" danger className="w-full">
          Delete
        </Button>
        <Button onClick={() => setIsDeleteModalOpen(false)} className="w-full">
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteModal
