import { useState } from "react";
import classes from "./DashboardV2.module.css";
import { prod } from "./config";

if (prod) {
  console.log = function () {};
  console.error = function () {};
}

var serverURL = prod
  ? "https://myaifrontdeskadmin.com"
  : "http://localhost:8080";

function BulkSettings({ isOpen, onClose, onSave }) {
  const [isOn, setIsOn] = useState(false);

  return (
    <div>
      {isOpen && (
        <div
          style={{
            zIndex: "1000",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              padding: "16px",
              width: "600px",
              position: "relative",
              overflow: "auto",
            }}
          >
            <button
              onClick={() => {
                onClose();
              }}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                top: "8px",
                left: "8px",
              }}
            >
              X
            </button>{" "}
            <div
              style={{
                marginTop: "2rem",
              }}
            >
              <h2
                style={{
                  fontSize: "1.25rem",
                  textAlign: "center",
                }}
              >
                Bulk settings
              </h2>

              <p>Flip the AI autoplay settings in bulk</p>
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1.5rem",
                }}
              >
                <div>AI Autoreply</div>
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsOn(e.target.checked)}
                    checked={isOn}
                  />
                  <span className={classes.slider}></span>
                </label>
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="submit"
                onClick={() => {
                  onSave(isOn);
                  onClose();
                }}
                style={{
                  background: "#02c433",
                  cursor: "pointer",
                  color: "white",
                  padding: "1rem 2rem",
                  borderRadius: "5px",
                  border: "none",
                  margin: "1rem auto",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
                  transition: "background-color 0.3s, transform 0.2s",
                }}
              >
                <span style={{ marginRight: "8px" }}>Save Settings</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BulkSettings;
