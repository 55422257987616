import { TAnalytics, TTextLogs } from '@/types/TAnalytics'
import { Line } from '@ant-design/plots'
import moment from 'moment'

export const AllTextLogs = ({ analyticsData }: TAnalytics) => {
  const config = {
    data: analyticsData,
    xField: (row: TTextLogs) => moment(row.date).format('ddd D/M/YYYY'),
    yField: 'value',
    colorField: 'value',
    point: {
      shapeField: 'square',
      sizeField: 4,
    },
    legend: false,
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 3,
      gradient: 'y',
    },
    scale: {
      color: { range: ['#6e79d6'] },
    },
    tooltip: {
      title: 'date',
      items: [{ name: 'Total Texts', channel: 'y' }],
    },
  }
  return <Line {...config} />
}
