import { useState } from 'react';
import { Row, Col, Card, Typography, Space } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import UploadCSV from '../UploadCsv';
// import GoogleSheets from './GoogleSheets';
// import BulkInsertPhoneNumbers from './BulkInsertPhoneNumbers';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';

const { Title, Text } = Typography;

const OptionCard = ({ icon, title, description, color, onClick }: {
  icon: JSX.Element;
  title: string;
  description: string;
  color: string;
  onClick: () => void;
}) => (
  <Card
    hoverable
    onClick={onClick}
    className="h-full transition-all hover:shadow-lg"
    style={{ padding: '24px' }}
  >
    <Space direction="horizontal" size="middle" align="start">
      <div
        className="flex items-center justify-center w-12 h-12 rounded-lg"
        style={{ backgroundColor: color }}
      >
        {icon}
      </div>
      <Space direction="vertical" size={0}>
        <Title level={4} style={{ marginBottom: 4, marginTop: 0 }}>
          {title}
        </Title>
        <Text type="secondary">{description}</Text>
      </Space>
    </Space>
  </Card>
);

const AddNewLead = ({ onOptionSelect }: { onOptionSelect: (option: string) => void }) => {
  const options = [
    {
      icon: <FileExcelOutlined style={{ fontSize: 24, color: 'white' }} />,
      title: 'Upload CSV',
      description: 'Import leads from a CSV file',
      color: '#52c41a',
      key: 'uploadCSV'
    },
    // {
    //   icon: <UserAddOutlined style={{ fontSize: 24, color: 'white' }} />,
    //   title: 'Enter Manually',
    //   description: 'Add lead information manually',
    //   color: '#1890ff',
    //   key: 'manualPhones'
    // },
    // {
    //   icon: <GoogleOutlined style={{ fontSize: 24, color: 'white' }} />,
    //   title: 'Google Sheets',
    //   description: 'Import from Google Sheets',
    //   color: '#f5222d',
    //   key: 'googleSheets'
    // }
  ];

  return (
    <div>
      <Row gutter={[16, 16]}>
        {options.map(option => (
          <Col xs={24} key={option.key}>
            <OptionCard {...option} onClick={() => onOptionSelect(option.key)} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const LeadManagement = ({ refetchLeads }: { refetchLeads: () => void }) => {
  const [activeComponent, setActiveComponent] = useState('options');
  const campaign_id = useAppSelector(getSelectedCampaignId) as string;

  const handleOptionSelect = (option: string) => {
    setActiveComponent(option);
  };

  const handleOnClose = () => {
    setActiveComponent('options');
    refetchLeads();
  }

  return (
    <div>
      {activeComponent === 'options' && (
        <AddNewLead onOptionSelect={handleOptionSelect} />
      )}
      {activeComponent === 'uploadCSV' && (
        <UploadCSV onClose={handleOnClose} campaignId={campaign_id} />
      )}
      {/* {activeComponent === 'manualPhones' && (
        <BulkInsertPhoneNumbers onClose={handleOnClose} campaignId={campaign_id} />
      )}
      {activeComponent === 'googleSheets' && (
        <GoogleSheets onClose={handleOnClose} campaignId={campaign_id} />
      )} */}
    </div>
  );
};
