import { CustomModal } from '@/components/CustomModal'
import { LogoutOutlined } from '@ant-design/icons'
import { SettingsOutlined } from '@mui/icons-material'
import { Avatar, Button, List, Popover } from 'antd'
import { FC, useMemo, useState } from 'react'
import { SettingMenu } from './SettingMenu'
import './Setting.scss'
import { logout } from '@/api/user'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import { generateRandomColour } from '@/utils/helper'
import classNames from 'classnames'

export const Setting: FC<{ isPromoVisibile: boolean, debrand: boolean }> = ({ isPromoVisibile,debrand}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const currentUser = useAppSelector(getLoggedInUser)

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const handleModalOpenChange = () => {
    setOpen(false)
    setShowModal((prevStatus) => !prevStatus)
  }

  const avatarContent = currentUser.business_name?.charAt(0).toUpperCase()
  const avatarColor = useMemo(() => generateRandomColour(), [])

  return (
    <>
      <Popover
        className={classNames({ 'mt-12': isPromoVisibile, "mt-6": !isPromoVisibile })}
        content={
          <List style={{ width: '200px' }}>
            <List.Item
              onClick={handleModalOpenChange}
              className="font-medium hover:bg-gray-100 cursor-pointer !pl-2 hover:border-none hover:rounded-lg h-12"
            >
              <SettingsOutlined className="mr-2" />
              Prefrences
            </List.Item>
            <List.Item>
              <Button className="w-full h-10 " danger onClick={logout} icon={<LogoutOutlined />}>
                Logout
              </Button>
            </List.Item>
          </List>
        }
        trigger="click"
        placement="rightTop"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Avatar
          className="z-50 absolute right-2 top-1 cursor-pointer"
          size={50}
          children={avatarContent}
          style={{ backgroundColor: currentUser.avatarBg ?? avatarColor }}
        />
      </Popover>
      <CustomModal
        className="setting-modal"
        width={'40%'}
        styles={{
          content: {
            minHeight: '430px',
          },
        }}
        title="Preferences"
        confirmAction={handleModalOpenChange}
        children={<SettingMenu debrand={debrand}/>}
        // @ts-ignore
        onCancel={handleModalOpenChange}
        isModalOpen={showModal}
        footer={null}
        onClose={() => setShowModal(false)}
        cancelAction={() => setShowModal(false)}
      />
    </>
  )
}
