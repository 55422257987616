import React from 'react'
import { Form, Input } from 'antd'

const NumberInput: React.FC<{
  name: string | string[]
  restField?: any
  placeholder?: string
  min?: number
  max?: number
}> = ({ name, restField, placeholder, min, max }) => {
  return (
    <Form.Item
      {...restField}
      name={name}
      className="flex-auto !mb-2"
      rules={[
        { required: true, message: 'Required' },
        {
          pattern: /^[0-9]+$/,
          message: 'Please enter numbers only',
        },
        {
          validator: (_, value) => {
            const val = Number(value)
            if (max && val > max) {
              return Promise.reject(`Should be less than or equal to ${max}`)
            } else if (min && val < min) {
              return Promise.reject(`Should be greater than or equal to ${min}`)
            } else {
              return Promise.resolve()
            }
          },
        },
      ]}
      //label="Extension:"
    >
      <Input
        placeholder={placeholder}
        className="w-full"
        onKeyPress={(event) => {
          const keyCode = event.keyCode || event.which
          const keyValue = String.fromCharCode(keyCode)
          if (!/^\d+$/.test(keyValue)) {
            event.preventDefault()
          }
        }}
      />
    </Form.Item>
  )
}

export default NumberInput
