//@ts-ignore
import ResellerRegistration from "../../v1/Whitelabel/ResellerRegistration";

const UnbrandedResellerRegistration = () => {
  return (
    <ResellerRegistration debrand={true} /> 
  )
}

export default UnbrandedResellerRegistration
