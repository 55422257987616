import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import clsx from 'clsx'
import { generateRandomColour } from '../utils/helper'
import { countries } from './countriesData'
import { prod } from './config'
import posthog from 'posthog-js'
import {
  businessInformationsTemplates,
  businessNameTemplates,
  greetingsPromptTemplates,
  textingWorkflowsTemplates,
  callTransferTemplates,
} from './constants/prompt-templates'
import classes from './RegisterPageV3.module.css'
import TextEditor from './components/TextEditor/TextEditor'
import AIModels, { soundMapping } from './constants/models'
import play from './assets/images/play.png'
import { Button, notification } from 'antd'
import { AppRoutes } from '../enums/ERoutes'

const RegisterPage = ({
  // The next four props are for registering a receptionist belonging to a reseller
  debrand,
  reseller_email = '',
  campaign_user_email = '',
  purchase_quotes = '',
  reseller_name = '',
  campaign_user_name = '',
  campaign_user_password = '',
  debranded_registration_modal_active_setter,
  debrand_modal_instructions_setter,
  logo_s3,
  reroute_to_unbranded_login,
  isCampaign,
  signOut,
  user,
  highlightColor = '#007BFF',
  backgroundColor = 'white',
  auto_scale = false,
}) => {
  const [testingNumber, setTestingNumber] = useState('+1')
  const [businessName, setBusinessName] = useState('')
  const [businessType, setBusinessType] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [findOutAboutUs, setFindOutAboutUs] = useState('')
  const [systemPhrase, setSystemPhrase] = useState('')
  const [campaignVariables, setCampaignVariables] = useState({})
  const [inputVariables, setInputVariables] = useState('')
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [prompt, setPrompt] = useState(
    "From now on, you are a phone receptionist at that customers can call to ask questions. You always answer in one or two short, concise sentences, and you never make up information that you don't know."
  )
  const [showBusinessInformationModal, setShowBusinessInformationModal] = useState(false)
  const [showCallTransferringModal, setShowCallTransferringModal] = useState(false)
  const [showLinkTextingModal, setShowLinkTextingModal] = useState(false)
  const [companyInfo, setCompanyInfo] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMatchError, setPasswordMatchError] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const captchaRef = useRef(null)
  const [stepState, setStepState] = useState(0)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [greetingTemplateModalActive, setGreetingTemplateModalActive] = useState(false)
  const [businessInformationTemplateModalActive, setBusinessInformationTemplateModalActive] = useState(false)
  const [linksTextingTemplateModalActive, setLinksTextingTemplateModalActive] = useState(false)
  const [textingTemplateModalActive, setTextingTemplateModalActive] = useState(false)
  const [callTransferTemplateModalActive, setCallTransferTemplateModalActive] = useState(false)
  const [selectedGreetingTemplate, setSelectedGreetingTemplate] = useState(false)
  const [selectedBusinessInfoTemplate, setSelectedBusinessInfoTemplate] = useState(false)
  const [selectedTextingTemplate, setSelectedTextingTemplate] = useState(false)
  const [selectedCallTemplate, setSelectedCallTemplate] = useState(false)
  const [sampleQA, setSampleQA] = useState('')
  const [isReferralUser, setIsReferralUser] = useState(false)
  const [isUsingATemplate, setIsUsingATemplate] = useState(false)
  const [voiceModel, setVoiceModel] = useState('')
  const [voiceProvider, setVoiceProvider] = useState('')
  const soundPlayerRef = useRef()
  const [errors, setErrors] = useState()
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)

  /* -------------------- TEXTING WORKFLOW LOGIC ----------------- */
  const [workflows, setWorkflows] = useState([
    {
      description:
        'Send the caller a link to schedule an appointment. Execute whenever caller asks to schedule an appointment or consultation, or if they ask to speak to a human. ',
      voiceResponse:
        'Great! I’ve just texted you a link to schedule your appointment. Is there anything else I can help you with?',
      textMessage: 'Here is a link to schedule your appointment: [Put your link here]',
    },
  ])

  // Add workflow function
  const addWorkflow = (e) => {
    e.preventDefault()
    setWorkflows([...workflows, { description: '', voiceResponse: '', textMessage: '' }])
  }
  const handleDescriptionChange = (e, index) => {
    e.preventDefault()
    const updatedWorkflows = [...workflows]
    updatedWorkflows[index].description = e.target.value
    setWorkflows(updatedWorkflows)
  }

  const handleVoiceResponseChange = (e, index) => {
    e.preventDefault()
    const updatedWorkflows = [...workflows]
    updatedWorkflows[index].voiceResponse = e.target.value
    setWorkflows(updatedWorkflows)
  }

  const handleTextMessageChange = (e, index) => {
    e.preventDefault()
    const updatedWorkflows = [...workflows]
    updatedWorkflows[index].textMessage = e.target.value
    setWorkflows(updatedWorkflows)
  }

  const removeWorkflow = (e, index) => {
    e.preventDefault()
    const updatedWorkflows = workflows.filter((_, i) => i !== index)
    setWorkflows(updatedWorkflows)
  }
  /* -------------------- END TEXTING WORKFLOW LOGIC ----------------- */

  /* -------------------- CALL WORKFLOW LOGIC ----------------- */
  const [callWorkflows, setCallWorkflows] = useState([])

  // Add workflow function
  const addCallWorkflow = (e) => {
    e.preventDefault()
    setCallWorkflows([
      ...callWorkflows,
      {
        description: '',
        voiceResponse: '',
        workflowForwardingNumber: countries[0] ? countries[0].code : 0,
        country: countries[0] ? countries[0] : {},
      },
    ])
  }
  const handleCallWorkflowDescriptionChange = (e, index) => {
    e.preventDefault()
    const updatedCallWorkflows = [...callWorkflows]
    updatedCallWorkflows[index].description = e.target.value
    setCallWorkflows(updatedCallWorkflows)
  }

  const handleCallWorkflowVoiceResponseChange = (e, index) => {
    e.preventDefault()
    const updatedCallWorkflows = [...callWorkflows]
    updatedCallWorkflows[index].voiceResponse = e.target.value
    setCallWorkflows(updatedCallWorkflows)
  }

  const handleCallWorkflowCountryChange = (e, index) => {
    e.preventDefault()
    const selectedCountry = countries.find((c) => c.name === e.target.value)
    const updatedCallWorkflows = [...callWorkflows]
    updatedCallWorkflows[index].workflowForwardingNumber = selectedCountry.code
    updatedCallWorkflows[index].country = selectedCountry
    setCallWorkflows(updatedCallWorkflows)
  }

  const handleCallWorkflowForwardingNumber = (e, index) => {
    e.preventDefault()
    const updatedCallWorkflows = [...callWorkflows]

    let value = e.target.value.replace(/[^0-9+]/g, '') // Remove non-digit characters except '+'

    if (!value.startsWith(updatedCallWorkflows[index].country.code)) {
      value = updatedCallWorkflows[index].country.code
    }

    updatedCallWorkflows[index].workflowForwardingNumber = value
    setCallWorkflows(updatedCallWorkflows)
  }

  const removeCallWorkflow = (e, index) => {
    e.preventDefault()
    const updatedCallWorkflows = callWorkflows.filter((_, i) => i !== index)
    setCallWorkflows(updatedCallWorkflows)
  }
  /* -------------------- END CALL WORKFLOW LOGIC ----------------- */

  const navigate = useNavigate()

  useEffect(() => {
    if (!isUsingATemplate) {
      setSelectedGreetingTemplate('')
      setSystemPhrase('')
    } else {
      const businessNameTemplate = businessNameTemplates.find((el) => el.label === businessType)?.template

      setBusinessName(businessNameTemplate)

      const greetingTemplate = greetingsPromptTemplates.find((el) => el.label === businessType)?.template

      setSystemPhrase(greetingTemplate?.replace('[BUSINESS NAME HERE]', businessNameTemplate))

      const businessInfoTemplate = businessInformationsTemplates.find((el) => el.label === businessType)?.template

      setCompanyInfo(businessInfoTemplate?.replace('[BUSINESS NAME HERE]', businessNameTemplate))
    }
  }, [businessType, isUsingATemplate])

  useEffect(() => {
    window.gtag('event', 'RegisterPageView', {
      event_category: 'Pageview',
    })

    if (reseller_email) console.log('reseller email is ' + reseller_email)
    if (campaign_user_email) console.log('campaign email is ' + campaign_user_email)
  }, [])

  useEffect(() => {
    if (reseller_email) console.log('reseller email is ' + reseller_email)
    if (campaign_user_email) console.log('campaign email is ' + campaign_user_email)
  }, [])

  useEffect(() => {
    if (!reseller_email && !campaign_user_email) {
      console.log('Creating')
      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=a1895b38-5ada-4a38-b4b1-580c900fcc1e'
      script.async = true

      document.body.appendChild(script)

      return () => {
        // Cleanup when component unmounts
        document.body.removeChild(script)
      }
    }
  }, [])

  // use effect for step state changing to fire GTag analytics
  useEffect(() => {
    // just record what step number we are on
    var eventName = 'RegisterPageStep' + stepState
    // console.log(eventName);
    window.gtag('event', eventName, {
      event_category: 'RegisterPage.js',
      event_label: 'Step # ' + stepState,
    })
    // console.log("Step State: " + stepState);
  }, [stepState])

  const prod = import.meta.env.VITE_APP_PROD === 'true'
  const serverURL = import.meta.env.VITE_APP_API_BASE_URL

  if (prod) {
    console.log = function () {}
    console.error = function () {}
  }
  useEffect(() => {
    ;(function (w, r) {
      w._rwq = r
      w[r] =
        w[r] ||
        function () {
          ;(w[r].q = w[r].q || []).push(arguments)
        }
    })(window, 'rewardful')
    let s = document.createElement('script')
    s.async = true
    s.src = 'https://r.wdfl.co/rw.js'
    s.setAttribute('data-rewardful', '30c5c6')
    s.setAttribute('data-domains', 'myaifrontdesk.com, myaifrontdeskdashboard.com')
    document.head.appendChild(s)

    window.rewardful('ready', function () {
      // console.log(window.Rewardful);
      if (window.Rewardful.referral) {
        // console.log("Current referral ID: ", window.Rewardful.referral);
      } else {
        // console.log("No referral present.");
      }
    })
  }, [])

  useEffect(() => {
    // Create a URLSearchParams object to access the query parameters
    const queryParams = new URLSearchParams(window.location.search)

    // Get the 'referralCode' parameter
    const referralCode = queryParams.get('referralCode')

    if (referralCode) {
      // Do something with the referralCode
      console.log('Referral Code:', referralCode)

      if (referralCode) {
        // The user has a referral code, so set the state to true
        setIsReferralUser(true)
        setReferralCode(referralCode)
      }
    } else {
      // Handle the case when referralCode is not present in the URL
      // console.log("Referral Code is not present in the URL");
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    posthog.capture('create_account_button_clicked')

    setIsSubmittingForm(true)

    if (password !== confirmPassword) {
      setPasswordMatchError(true)
      return
    }

    const d = Date.now()
    const campaignUsername = (campaign_user_name + '-' + businessName + '-' + d).replace(/ /g, '-')

    // Before registering user, make sure client does not exist

    try {
      let usernameTaken = false

      const response = await fetch(serverURL + '/clientExistsNoPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: isCampaign ? campaignUsername : username,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const status = data.status
          console.log('Status: ' + status)
          if (status == 'true') {
            console.log('Client already exists')
            alert('This username is already registered. Please login or register with a different phone number.')
            usernameTaken = true // Set the variable to true if the phone number is taken
            window.gtag('event', 'phone_number_exists', {
              event_category: 'RegisterPage.js',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          window.gtag('event', 'couldnt_create_client', {
            event_category: 'RegisterPage.js',
            event_label: 'error',
          })
          alert('We are having trouble registering you. Please try again or email contact@myaifrontdesk.com.')
        })

      if (usernameTaken) {
        console.log('Phone num taken, returning')
        return // Return from the main function if the phone number is taken
      }
    } catch (error) {
      console.error(error)
    }
    var email = emailAddress
    if (reseller_email) {
      email = reseller_email
    }
    if (campaign_user_email) {
      email = campaign_user_email
    }
    console.log('Creating client with testingNumber ' + testingNumber)
    // Make server call using fetch to /createClient
    try {
      const bodyObject = {
        email,
        businessName,
        testingNumber,
        systemPhrase,
        // variables: campaignVariables,
        companyInfo,
        password: isCampaign ? campaign_user_password || Date.now() : password,
        referralCode,
        findOutAboutUs,
        sampleQA,
        selectedLanguages, // List of selected languages
        languageGreetings, // Dict of language to greeting phrase
        workflows,
        callWorkflows,
        businessType,
        reseller_email,
        campaign_user_email,
        purchase_quotes,
        username: isCampaign ? campaignUsername : username,
        english_voice_selected: JSON.stringify({
          voiceId: voiceModel,
          provider: voiceProvider,
        }),
        auto_scale: auto_scale,
        avatarBg: generateRandomColour(),
        voicemails_enabled: true,
        call_recording_enabled: true,
      }
      let body = JSON.stringify(bodyObject)

      if (isCampaign) {
        const filteredCampaignVariables = {}
        Object.keys(campaignVariables || {}).forEach((el) => {
          if (body.includes(`{{${el}}}`)) {
            filteredCampaignVariables[el] = campaignVariables[el]
          }
        })
        bodyObject.variables = filteredCampaignVariables
        body = JSON.stringify(bodyObject)
      }

      const response = await fetch(`${serverURL}/createClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      })
      if (!response.ok) {
        window.gtag('event', 'couldnt_create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'error',
        })
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      const status = data.success
      if (status) {
        setIsSubmittingForm(false)
        window.gtag('event', 'create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'success',
        })

        if (data.token) {
          /** ToDo:: Token localstorage logic will be changed in future. For now I have just ignore saving token
           for reseller embedded receptionist **/

          if (isCampaign) {
            navigate('/novi-portal/login')
          } else if (!window.location.href.includes(AppRoutes.RESELLER_PORTAL)) {
            localStorage.setItem('AUTH_TOKEN', JSON.stringify(data.token))
          }

          if (!debrand) {
            navigate('/')
          }
        }
        // Debranded post-creation flow is different, keep them on the same page
        // No need to navigate to login page

        if (debrand && reroute_to_unbranded_login) {
          navigate('/unbranded_receptionist', {
            state: { username: username, password: password },
          })
        }

        if (debrand) {
          debrand_modal_instructions_setter(true) // Open debrand modal telling reseller client is created
          debranded_registration_modal_active_setter(false) // Close existing registration modal
          return
        }
      }
    } catch (error) {
      window.gtag('event', 'couldnt_create_client', {
        event_category: 'RegisterPage.js',
        event_label: 'error',
      })
      console.error(error)
    }
  }

  const [country, setCountry] = useState(countries[0])

  const handleCountryChange = (event) => {
    const selectedCountry = countries.find((c) => c.name === event.target.value)
    setCountry(selectedCountry)
    setTestingNumber(selectedCountry.code)
  }

  const handletestingNumberChange = (event) => {
    let value = event.target.value.replace(/[^0-9+]/g, '') // Remove non-digit characters except '+'
    if (!value.startsWith(country.code)) {
      value = country.code + value
    }
    setTestingNumber(value)
  }

  const handleUsernameChange = (event) => {
    const newValue = event.target.value?.toLowerCase()
    const allowedReg = /^(?!.*\s)[a-z0-9._#@!$%]*$/

    setUsername(newValue)

    if (allowedReg.test(newValue)) {
      setErrors((prev) => ({ ...prev, username: '' }))
    } else {
      setErrors((prev) => ({ ...prev, username: 'Only these characters are allowed: a-z, 0-9, _ # @ ! $ %' }))
    }
  }

  const languages = [
    'English',
    'Spanish',
    'French',
    'German',
    'Portuguese',
    'Japanese',
    'Mandarin',
    'Arabic',
    'Russian',
    'Hindi',
    'Dutch',
  ]
  const [selectedLanguages, setSelectedLanguages] = useState(['English'])
  const handleLanguageChange = (language) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language))
      const updatedGreetings = { ...languageGreetings }
      delete updatedGreetings[language]
      setLanguageGreetings(updatedGreetings)
    } else {
      setSelectedLanguages([...selectedLanguages, language])
      setLanguageGreetings({
        ...languageGreetings,
        [language]: languageExamples[language],
      })
    }
  }
  useEffect(() => {}, [])
  const handleGreetingChange = (language, value) => {
    setLanguageGreetings({ ...languageGreetings, [language]: value })
  }

  const [languageGreetings, setLanguageGreetings] = useState({
    English: '',
  })

  const languageExamples = {
    English: 'Hello, how may I help you?',
    Spanish: 'Hola, ¿en qué puedo ayudarte?',
    French: 'Bonjour, comment puis-je vous aider ?',
    German: 'Hallo, wie kann ich Ihnen helfen?',
    Portuguese: 'Olá, como posso ajudá-lo?',
    Japanese: 'こんにちは、どのようなお手伝いができますか？',
    Mandarin: '你好，我可以帮助你吗？',
    Arabic: 'مرحبًا، كيف يمكنني مساعدتك؟',
    Russian: 'Привет, чем я могу помочь вам?',
    Hindi: 'नमस्ते, मैं आपकी कैसे मदद कर सकता हूँ?',
  }

  const isFormValid = testingNumber && systemPhrase && companyInfo

  const header = (
    <div
      className={classes.header}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0',
        fontSize: '18px', // Increased font size for better readability
        background: '#f8f8f8', // Light background color
        color: 'rgb(119, 129, 145)',
      }}
    >
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com', '_blank')}
        style={{
          padding: '8px 12px', // Added padding for better button appearance
          border: 'none',
          background: 'transparent', // Transparent background
          color: 'inherit',
        }}
      >
        Product
      </button>
      <button
        className={classes.navItem}
        onClick={() => (window.location.href = '/')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          border: 'none',
          padding: '8px 12px',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Login
      </button>
      <button
        className={classes.navItem}
        onClick={() => window.open('https://www.myaifrontdesk.com/book-a-consultation', '_blank')}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Book Consultation
      </button>
      <button
        className={classes.navItem}
        onClick={() =>
          (window.location.href =
            'mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you.')
        }
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          padding: '8px 12px',
          border: 'none',
          background: 'transparent',
          color: 'inherit',
        }}
      >
        Contact
      </button>
    </div>
  )

  const welcomeScreen = <></>

  const [variables, setVariables] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [variableName, setVariableName] = useState('')
  const [isMandatory, setIsMandatory] = useState(true)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const insertVariable = (name) => {
    const variable = `{{${name}}}`
    const textArea = document.getElementById('systemPhraseTextArea')
    const startPos = textArea.selectionStart
    const endPos = textArea.selectionEnd
    const newText = systemPhrase.slice(0, startPos) + variable + systemPhrase.slice(endPos)
    setSystemPhrase(newText)
    setVariables([...variables, { name, isMandatory }])
    closeModal()
  }

  const handleEditVariable = (variableName) => {
    // Implement edit variable functionality
    console.log('Edit variable:', variableName)
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  return (
    <>
      <div
        className={(isDarkMode ? classes.darkMode : '') + ' ' + classes.rootPage}
        style={{
          height: '100%',
          overflow: 'auto',
          // fontFamily: "Roboto, sans-serif",
          overflowX: 'hidden',
          transition: '.4s ease',
        }}
      >
        {!debrand && header}

        <script src="https://www.google.com/recaptcha/api.js" async defer></script>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxwidth: '100%',
            padding: '1rem 1rem 3rem',
            borderRadius: '10px',
            // fontFamily: "Quicksand, sans-serif",
            margin: '1rem auto', // Centering the card on the page
            minHeight: 'calc(100vh - 163px)',
            position: 'relative',
          }}
        >
          <div
            style={{
              color: 'rgb(40, 42, 48)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
              // overflow: "auto",
              '@media screen and (max-width: 480px)': {
                padding: '0.5rem',
              },
              '@media screen and (max-width: 375px)': {
                padding: '0.25rem',
              },
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              {debrand && (
                <>
                  {logo_s3 && (
                    <img
                      className={classes.centered}
                      style={{
                        position: 'absolute', // Add position absolute
                        top: 10, // Align to the top
                        left: 10, // Align to the left
                        margin: '1rem', // Add some margin for spacing
                      }}
                      width="50rem"
                      height="50rem"
                      src={logo_s3}
                      alt="AI Receptionist"
                    />
                  )}
                </>
              )}
              {stepState === 0 && (
                <div
                  style={{
                    color: 'rgb(40, 42, 48)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontFamily: 'Roboto, sans-serif', // Modern font
                  }}
                >
                  <Fade duration={1000} bottom>
                    <h3
                      style={{
                        fontSize: 42,
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        textAlign: 'center', // Center the heading
                        fontFamily: 'Roboto, sans-serif', // Modern font
                      }}
                    >
                      {/* / NEW CHANGES TO REVIEW */}
                      Build your {isCampaign ? 'Campaign' : 'AI Receptionist'} in Minutes!
                    </h3>

                    <div className={classes.box}>
                      <Fade duration={1000} bottom>
                        <div
                          className={classes.subtitle}
                          style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                          }}
                        >
                          Start by choosing a voice.
                        </div>

                        <Fade duration={1000} bottom>
                          <div
                            className={classes.themeChooser + ' ' + classes.languagesChooser}
                            style={{
                              display: 'flex', // Display the theme chooser as a flex container
                              justifyContent: 'center', // Center the items horizontally
                            }}
                          >
                            {AIModels.slice(0, 10).map((model) => (
                              <div
                                onClick={() => {
                                  setVoiceModel(model.value)
                                  setVoiceProvider(model.provider)
                                  posthog.capture('voice_selected')
                                }}
                                className={classes.themeBox + ' ' + (voiceModel === model.value ? classes.active : '')}
                                style={{
                                  flexDirection: 'column',
                                  padding: '1rem 0',
                                  display: 'flex', // Display each theme box as a flex container
                                  alignItems: 'center', // Center the items vertically
                                }}
                              >
                                <img style={{ width: '2rem' }} src={model.icon} />

                                <div>{model.name}</div>

                                <img
                                  onClick={(e) => {
                                    e.stopPropagation()

                                    soundPlayerRef?.current?.pause()
                                    soundPlayerRef.current = new Audio(soundMapping[model.value])
                                    soundPlayerRef?.current.load()
                                    // soundPlayerRef.current.play();
                                    const promise = soundPlayerRef?.current?.play()
                                    if (promise !== undefined) {
                                      // On older browsers play() does not return anything, so the value would be undefined.
                                      promise
                                        .then(() => {
                                          // Audio is playing.
                                        })
                                        .catch((error) => {
                                          console.log(error)
                                        })
                                    }
                                  }}
                                  width={24}
                                  src={play}
                                  alt="play"
                                />
                              </div>
                            ))}
                          </div>
                        </Fade>
                      </Fade>

                      <button
                        type="button"
                        onClick={() => {
                          soundPlayerRef?.current?.pause()
                          if (voiceModel.length > 0) setStepState(stepState + 1)
                          else notification.error({ message: 'Please select a voice to continue', duration: 1 })
                        }}
                        className={classes.button + ' ' + classes.centered}
                        style={{
                          marginTop: '2rem',
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </Fade>
                </div>
              )}

              {stepState === 1 && (
                <div
                  className={classes.box}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Business Type
                    </h3>
                    <select
                      className={classes.input}
                      value={businessType}
                      onChange={(e) => {
                        setIsUsingATemplate(false)
                        setIsUsingATemplate(true)
                        setBusinessType(e.target.value)
                        posthog.capture('business_type_selected', { business_type: e.target.value })
                      }}
                    >
                      <option value="">Select Business Type</option>
                      <option value="HVAC">HVAC</option>
                      <option value="Beauty">Beauty</option>
                      <option value="Construction">Construction</option>
                      <option value="Automotive">Automotive</option>
                      <option value="Retail">Retail</option>
                      <option value="Food">Food</option>
                      <option value="Legal">Legal</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Technology">Technology</option>
                      <option value="Entertainment">Entertainment</option>
                      <option value="Real Estate">Real Estate</option>
                      <option value="Financial Services">Financial Services</option>
                      <option value="Nonprofit">Nonprofit</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Education">Education</option>
                      <option value="Consulting">Consulting</option>
                      <option value="Other">Other</option>
                    </select>

                    {isUsingATemplate ? (
                      <p
                        style={{
                          maxWidth: '336px',
                          margin: '1rem auto 0',
                          fontSize: '13px',
                        }}
                      >
                        ✨ Great! We’ve applied our {businessType.replace(' Services', '')} services template to your
                        receptionist. Now, let’s tweak it.
                      </p>
                    ) : (
                      <></>
                    )}

                    {/* for skip language selection section */}
                    <button
                      type="button"
                      onClick={() => {
                        if (businessType) {
                          // skipping language for campaign
                          if (isCampaign) setStepState(3)
                          else setStepState(stepState + 2)
                        } else notification.error({ message: 'Please select a business type', duration: 1 })
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 2 && (
                <div className={classes.box} style={{}}>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Select Languages (Optional)
                    </h3>

                    <div
                      className={classes.subtitle}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Select the languages you want your receptionist to speak.
                    </div>

                    <Fade duration={1000} bottom>
                      <div className={classes.themeChooser + ' ' + classes.languagesChooser}>
                        {languages.map((language) => (
                          <div
                            onClick={() => handleLanguageChange(language)}
                            className={
                              classes.themeBox + ' ' + (selectedLanguages.includes(language) ? classes.active : '')
                            }
                          >
                            {language}
                          </div>
                        ))}
                      </div>
                    </Fade>
                  </Fade>

                  <button
                    type="button"
                    onClick={() => {
                      if (selectedLanguages.length > 0) setStepState(stepState + 1)
                      else notification.error({ message: 'Please select at least one language', duration: 1 })
                    }}
                    className={classes.button + ' ' + classes.centered}
                    style={{
                      marginTop: '2rem',
                    }}
                  >
                    Continue
                  </button>
                </div>
              )}

              {stepState === 3 && (
                <div className={classes.box}>
                  <Fade duration={1000} bottom>
                    {React.createElement(() => {
                      posthog.capture('business_name_entered')
                      return null
                    })}
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {isCampaign ? 'Campaign Name' : 'Business Name'}
                    </h3>
                    <div
                      className={classes.subtitle}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      The name of{' '}
                      {reseller_email
                        ? " your client's business"
                        : campaign_user_email
                          ? ' your campaign'
                          : ' your business'}
                    </div>

                    <input
                      value={businessName}
                      className={classes.input + ' ' + classes.centered}
                      required
                      onChange={(event) => {
                        let value = event.target.value
                        setBusinessName(value)
                      }}
                      placeholder={businessName}
                    />

                    <button
                      type="button"
                      onClick={() => {
                        console.log(systemPhrase)
                        if (!businessName)
                          notification.error({ message: 'Please enter the name of your business', duration: 1 })
                        else setStepState(stepState + 1)
                      }}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {isCampaign
                ? stepState === 4 && (
                    <div className={clsx(classes.box, '')}>
                      <div>
                        <h3
                          className={classes.heading}
                          style={{
                            textAlign: 'center',
                            marginTop: '1rem',
                          }}
                        >
                          Greeting Phrase
                        </h3>
                        <div className={classes.subtitle} style={{ textAlign: 'center' }}>
                          This is the opening line your receptionist will use when answering the phone. Shorter greeting
                          phrases do better.
                        </div>
                        {React.createElement(() => {
                          posthog.capture('greeting_phrase_entered')
                          return null
                        })}

                        <TextEditor
                          html={systemPhrase}
                          vvariables={campaignVariables}
                          onChange={(value, variables) => {
                            setSystemPhrase(value)
                            setCampaignVariables(variables)
                          }}
                          placeholder={`e.g. Hi, welcome to ${businessName}. We're currently doing a 10% off summer sale. I can help you book an appointment or answer any questions you may have. How may I help you?`}
                        />
                        <button
                          type="button"
                          onClick={() => setStepState(5)}
                          className={classes.button + ' ' + classes.centered}
                          style={{
                            marginTop: '4rem',
                          }}
                        >
                          Continue
                        </button>
                      </div>
                      {React.createElement(() => {
                        posthog.capture('greeting_phrase_entered')
                        return null
                      })}
                    </div>
                  )
                : stepState === 4 && (
                    <div className={classes.box}>
                      <Fade duration={1000} bottom>
                        <h3
                          className={classes.heading}
                          style={{
                            textAlign: 'center',
                            marginTop: '1rem',
                          }}
                        >
                          Greeting Phrase
                        </h3>
                        <div className={classes.subtitle} style={{ textAlign: 'center' }}>
                          This is the opening line your receptionist will use when answering the phone. Shorter greeting
                          phrases do better.
                        </div>
                        {React.createElement(() => {
                          posthog.capture('greeting_phrase_entered')
                          return null
                        })}

                        <textarea
                          className={classes.input + ' ' + classes.textarea}
                          style={{ fontFamily: 'Quicksand, sans-serif' }}
                          required
                          rows={7}
                          value={systemPhrase}
                          onChange={(event) => setSystemPhrase(event.target.value)}
                          placeholder={`e.g. Hi, welcome to ${businessName}. We're currently doing a 10% off summer sale. I can help you book an appointment or answer any questions you may have. How may I help you?`}
                        />

                        <button
                          className={classes.whiteButton + ' ' + classes.flexCentered}
                          style={{
                            marginTop: '1rem',
                            width: 'max-content',
                            maxWidth: 'max-content',
                          }}
                          onClick={(event) => {
                            event.preventDefault() // Prevent the button from scrolling up
                            setGreetingTemplateModalActive(true)
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              style={{
                                strokeWidth: '1.5px',
                                height: '16px',
                                width: '16px',
                                marginRight: '8px',
                              }}
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                              ></path>
                            </svg>
                            {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
                          </div>
                        </button>

                        {selectedLanguages.map((language) =>
                          language !== 'English' ? (
                            <>
                              <label
                                key={language}
                                className={classes.heading}
                                style={{
                                  marginTop: '1rem',
                                  display: 'block',
                                }}
                              >
                                Greeting Phrase for {language}
                              </label>
                              <p className={classes.subtitle}>
                                {' '}
                                Please type this in {language}. This is the opening line your receptionist will use when
                                answering the phone if the caller selects {language}{' '}
                              </p>
                              <textarea
                                type="text"
                                value={languageGreetings[language]}
                                onChange={(event) => {
                                  handleGreetingChange(language, event.target.value)
                                }}
                                style={{ fontFamily: 'Quicksand, sans-serif' }}
                                required
                                className={classes.input + ' ' + classes.textarea}
                                placeholder={languageExamples[language]}
                                rows={5}
                              />
                            </>
                          ) : (
                            <></>
                          )
                        )}

                        <button
                          type="button"
                          onClick={() => {
                            if (!systemPhrase) {
                              notification.error({ message: 'Please enter the greeting phrase', duration: 1 })
                              return
                            }

                            const languagesWithoutGreeting = Object.keys(languageGreetings).filter(
                              (language) => language !== 'English' && !languageGreetings[language]
                            )

                            if (languagesWithoutGreeting.length > 0) {
                              notification.error({
                                message: `Please enter the greeting phrase for the following languages: ${languagesWithoutGreeting.join(
                                  ', '
                                )}`,
                                duration: 1,
                              })
                            } else setStepState(stepState + 1)
                          }}
                          className={classes.button + ' ' + classes.centered}
                          style={{
                            marginTop: '2rem',
                          }}
                        >
                          Continue
                        </button>
                      </Fade>
                    </div>
                  )}

              {stepState === 4 && greetingTemplateModalActive && <div className={classes.modalOverlay}></div>}
              {stepState === 4 && (
                <div
                  className={classes.box + ' ' + classes.modal + ' ' + (greetingTemplateModalActive && classes.active)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.close} onClick={() => setGreetingTemplateModalActive(false)}>
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Prompt Templates
                    </h3>

                    <div className={classes.subtitle + ' '} style={{ marginBottom: '.5rem' }}>
                      Select a template
                    </div>

                    <select
                      style={{ marginBottom: '1rem' }}
                      className={classes.input}
                      // value={selectedGreetingTemplate}
                      onChange={(e) =>
                        setSelectedGreetingTemplate(
                          greetingsPromptTemplates
                            .find((el) => el.label === e.target.value)
                            .template.replace('[BUSINESS NAME HERE]', businessName)
                        )
                      }
                    >
                      <option value="">Choose an option</option>
                      {greetingsPromptTemplates.map((el, idx) => {
                        return <option key={el.label}>{el.label}</option>
                      })}
                    </select>

                    <p>{selectedGreetingTemplate}</p>

                    <button
                      type="button"
                      onClick={() => {
                        setSystemPhrase(selectedGreetingTemplate)
                        setGreetingTemplateModalActive(false)
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Use Template
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 5 && (
                <div className={classes.box}>
                  {React.createElement(() => {
                    posthog.capture('business_information_entered')
                    return null
                  })}
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                        marginBottom: '0rem',
                      }}
                    >
                      (Optional) Business Information
                    </h3>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ul className={classes.list}>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          Your receptionist will be customized to your business
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          You can paste 20 pages of information into your receptionist
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          Your receptionist will form a knowledge base around it
                        </li>
                      </ul>
                    </div>

                    <button
                      type="button"
                      onClick={() => setShowBusinessInformationModal(true)}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Enter Business Information
                    </button>

                    <div onClick={() => setStepState(stepState + 1)} className={classes.later}>
                      I'll do this later
                    </div>
                  </Fade>
                </div>
              )}

              {stepState === 5 && showBusinessInformationModal && <div className={classes.modalOverlay} />}

              {stepState === 5 && (
                <div
                  className={classes.box + ' ' + classes.modal + ' ' + (showBusinessInformationModal && classes.active)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.close} onClick={() => setShowBusinessInformationModal(false)}>
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Enter Business Information
                    </h3>

                    <div className={classes.subtitle + ' '}>
                      Paste any information about your business. Your receptionist will use this to answer questions.
                      For example, your business services, prices, and mission. Don't worry about formatting. The AI is
                      smart enough to effectively interpret. Maximum 20 pages of text.
                    </div>

                    {isCampaign ? (
                      <TextEditor
                        html={companyInfo}
                        vvariables={campaignVariables}
                        onChange={(value, variables) => {
                          setCompanyInfo(value)
                          setCampaignVariables(variables)
                        }}
                        placeholder={`e.g. ${businessName} is a popular spot for men looking for a classic haircut experience. Here is some more information about our business and services...`}
                      />
                    ) : (
                      <textarea
                        className={classes.input + ' ' + classes.textarea}
                        required
                        rows={7}
                        style={{ fontFamily: 'Quicksand, sans-serif' }}
                        value={companyInfo}
                        onChange={(event) => {
                          const value = event.target.value
                          const words = value.trim().split(/\s+/)
                          if (words.length <= 7000) {
                            setCompanyInfo(value)
                          }
                        }}
                        placeholder={`e.g. ${businessName} is a popular spot for men looking for a classic haircut experience. Here is some more information about our business and services...`}
                      ></textarea>
                    )}

                    <button
                      className={classes.whiteButton + ' ' + classes.flexCentered}
                      style={{
                        marginTop: '1rem',
                        width: 'max-content',
                        maxWidth: 'max-content',
                      }}
                      onClick={(event) => {
                        event.preventDefault() // Prevent the button from scrolling up

                        setBusinessInformationTemplateModalActive(true)
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          style={{
                            strokeWidth: '1.5px',
                            height: '16px',
                            width: '16px',
                            marginRight: '8px',
                          }}
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                          ></path>
                        </svg>
                        {isUsingATemplate && companyInfo ? `Using ${businessType} Template` : 'Use a Template'}
                      </div>
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setStepState(stepState + 1)
                        setShowBusinessInformationModal(false)
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 5 && businessInformationTemplateModalActive && (
                <div className={classes.modalOverlay}></div>
              )}

              {stepState === 5 && (
                <div
                  className={
                    classes.box + ' ' + classes.modal + ' ' + (businessInformationTemplateModalActive && classes.active)
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.close} onClick={() => setBusinessInformationTemplateModalActive(false)}>
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Prompt Templates
                    </h3>

                    <div className={classes.subtitle + ' '} style={{ marginBottom: '.5rem' }}>
                      Select a template
                    </div>

                    <select
                      style={{ marginBottom: '1rem' }}
                      className={classes.input}
                      // value={selectedGreetingTemplate}
                      onChange={(e) =>
                        setSelectedBusinessInfoTemplate(
                          businessInformationsTemplates
                            .find((el) => el.label === e.target.value)
                            .template.replace('[BUSINESS NAME HERE]', businessName)
                        )
                      }
                    >
                      <option value="">Choose an option</option>
                      {greetingsPromptTemplates.map((el, idx) => {
                        return <option key={el.label}>{el.label}</option>
                      })}
                    </select>

                    <p>{selectedBusinessInfoTemplate}</p>

                    <button
                      type="button"
                      onClick={() => {
                        setCompanyInfo(selectedBusinessInfoTemplate)
                        setBusinessInformationTemplateModalActive(false)
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Use Template
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 6 && (
                <div className={classes.box}>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                        marginBottom: '0rem',
                      }}
                    >
                      (Optional) Link Texting
                    </h3>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {React.createElement(() => {
                        posthog.capture('link_texting_setup')
                        return null
                      })}
                      <ul className={classes.list}>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          Your receptionist can send custom texts to callers in real-time.
                        </li>

                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          Use this feature to send booking links, menus, and more
                        </li>
                      </ul>
                    </div>
                    <button
                      type="button"
                      onClick={() => setShowLinkTextingModal(true)}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Setup Link Texting
                    </button>

                    <div onClick={() => setStepState(stepState + 1)} className={classes.later}>
                      I'll do this later
                    </div>
                  </Fade>
                </div>
              )}

              {stepState === 6 && showLinkTextingModal && <div className={classes.modalOverlay} />}
              {stepState === 6 && (
                <div className={classes.box + ' ' + classes.modal + ' ' + (showLinkTextingModal && classes.active)}>
                  <div
                    className={classes.close}
                    onClick={() => {
                      if (
                        workflows.some(
                          (workflow) => !workflow.description || !workflow.textMessage || !workflow.voiceResponse
                        )
                      ) {
                        notification.error({
                          message: 'Please fill out all workflows, or remove empty workflows',
                          duration: 1,
                        })
                        return
                      }
                      setShowLinkTextingModal(false)
                    }}
                  >
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Texting Workflows
                    </h3>

                    <div className={classes.subtitle}>
                      Send custom texts to callers in real-time. For example, if the caller asks to schedule an
                      appointment, the receptionist can text them a link to schedule their appointment.
                    </div>

                    {workflows.map((workflow, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '1rem',
                          border: '2px dashed #ccc',
                          borderRadius: '10px',
                          padding: '1rem',
                        }}
                      >
                        <label className={classes.label}> Trigger</label>
                        <div
                          className={classes.subtitle}
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Describe the scenario in which you would like to send this text message.
                        </div>
                        {isCampaign ? (
                          <TextEditor
                            html={workflow.description}
                            vvariables={campaignVariables}
                            onChange={(value, variables) => {
                              handleDescriptionChange(
                                {
                                  preventDefault: () => {},
                                  target: { value },
                                },
                                index
                              )
                              setCampaignVariables(variables)
                            }}
                            placeholder="e.g. Send the caller a copy of the menu. Execute whenever caller asks for menu or prices."
                          />
                        ) : (
                          <textarea
                            style={{
                              marginBottom: '16px',
                              fontFamily: 'Quicksand, sans-serif',
                            }}
                            rows={4}
                            className={classes.input + ' ' + classes.textarea}
                            value={workflow.description}
                            required
                            onChange={(e) => handleDescriptionChange(e, index)}
                            placeholder="e.g. Send the caller a copy of the menu. Execute whenever caller asks for menu or prices."
                          />
                        )}
                        <label className={classes.label}>Text Message</label>
                        <div
                          className={classes.subtitle}
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Enter the text message to be sent to the user when this scenario is triggered.
                        </div>
                        {isCampaign ? (
                          <TextEditor
                            html={workflow.textMessage}
                            vvariables={campaignVariables}
                            onChange={(value, variables) => {
                              handleTextMessageChange(
                                {
                                  preventDefault: () => {},
                                  target: { value },
                                },
                                index
                              )
                              setCampaignVariables(variables)
                            }}
                            placeholder="e.g. Here is our menu: www.restaurant.com/menu"
                          />
                        ) : (
                          <textarea
                            style={{
                              marginBottom: '16px',
                              fontFamily: 'Quicksand, sans-serif',
                            }}
                            rows={4}
                            className={classes.input + ' ' + classes.textarea}
                            value={workflow.textMessage}
                            required
                            onChange={(e) => handleTextMessageChange(e, index)}
                            placeholder="e.g. Here is our menu: www.restaurant.com/menu"
                          />
                        )}
                        <label className={classes.label}>Voice Response</label>
                        <div
                          className={classes.subtitle}
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Enter the response to be spoken by the phone receptionist after the text is sent.
                        </div>
                        {isCampaign ? (
                          <TextEditor
                            html={workflow.voiceResponse}
                            vvariables={campaignVariables}
                            onChange={(value, variables) => {
                              handleVoiceResponseChange(
                                {
                                  preventDefault: () => {},
                                  target: { value },
                                },
                                index
                              )
                              setCampaignVariables(variables)
                            }}
                            placeholder="e.g. Great! I’ve just texted you a copy of the menu"
                          />
                        ) : (
                          <textarea
                            style={{
                              marginBottom: '16px',
                              fontFamily: 'Quicksand, sans-serif',
                            }}
                            rows={4}
                            className={classes.input + ' ' + classes.textarea}
                            value={workflow.voiceResponse}
                            required
                            onChange={(e) => handleVoiceResponseChange(e, index)}
                            placeholder="e.g. Great! I’ve just texted you a copy of the menu"
                          />
                        )}
                        <button
                          className={clsx(isCampaign && 'mt-6')}
                          type="button"
                          style={{
                            background: 'rgb(237 35 35)',
                            color: '#fff',
                            border: 'none',
                            height: '36px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => removeWorkflow(e, index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}

                    <button onClick={addWorkflow} style={{ maxWidth: '300px' }} className={classes.whiteButton}>
                      + Add Texting Workflow?
                    </button>

                    <>
                      {workflows.length !== 0 && (
                        <button
                          className={classes.whiteButton + ' ' + classes.flexCentered}
                          style={{
                            marginTop: '1rem',
                            width: 'max-content',
                            maxWidth: 'max-content',
                          }}
                          onClick={(event) => {
                            event.preventDefault() // Prevent the button from scrolling up

                            setTextingTemplateModalActive(true)
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              style={{
                                strokeWidth: '1.5px',
                                height: '16px',
                                width: '16px',
                                marginRight: '8px',
                              }}
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                              ></path>
                            </svg>
                            {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
                          </div>
                        </button>
                      )}
                    </>

                    <button
                      type="button"
                      onClick={() => {
                        // If any of the workflows are empty, error and don't continue
                        if (
                          workflows.some(
                            (workflow) => !workflow.description || !workflow.textMessage || !workflow.voiceResponse
                          )
                        ) {
                          notification.error({
                            message: 'Please fill out all workflows, or remove empty workflows',
                            duration: 1,
                          })
                          return
                        }

                        setStepState(stepState + 1)
                        setShowLinkTextingModal(false)
                      }}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 6 && textingTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {stepState === 6 && (
                <div
                  className={classes.box + ' ' + classes.modal + ' ' + (textingTemplateModalActive && classes.active)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.close} onClick={() => setTextingTemplateModalActive(false)}>
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Prompt Templates
                    </h3>

                    <div className={classes.subtitle + ' '} style={{ marginBottom: '.5rem' }}>
                      Select a template
                    </div>

                    <select
                      style={{ marginBottom: '1rem' }}
                      className={classes.input}
                      // value={selectedGreetingTemplate}
                      onChange={(e) =>
                        setSelectedTextingTemplate(
                          textingWorkflowsTemplates.find((el) => el.scenarioDescription === e.target.value)
                        )
                      }
                    >
                      <option value="">Choose an option</option>
                      {textingWorkflowsTemplates.map((el, idx) => {
                        return <option key={el.scenarioDescription}>{el.scenarioDescription}</option>
                      })}
                    </select>

                    <div style={{ width: '100%' }}>
                      <h5 className={classes.label}>Trigger</h5>
                      <p>{selectedTextingTemplate?.scenarioDescription}</p>
                      <h5 className={classes.label}>Text Message</h5>
                      <p>{selectedTextingTemplate?.textMessage}</p>
                      <h5 className={classes.label}>Voice Response</h5>
                      <p>{selectedTextingTemplate?.voiceResponse}</p>
                    </div>

                    <button
                      type="button"
                      onClick={() => {
                        const updateDescription = (description, words, currentIndex, setFunction, intervalName) => {
                          const modifiedWorkflows = [...workflows]
                          const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1]
                          lastWorkflow[setFunction] = ''

                          const interval = setInterval(() => {
                            const modifiedWorkflows = [...workflows]
                            const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1]

                            if (currentIndex < words.length) {
                              lastWorkflow[setFunction] += words[currentIndex] + ' '
                              currentIndex++
                              setWorkflows(modifiedWorkflows)
                            } else {
                              clearInterval(interval)
                            }
                          }, 50)
                        }

                        const scenarioDescription = selectedTextingTemplate?.scenarioDescription
                        const scenarioWords = scenarioDescription.split(' ')
                        let currentIndex1 = 0

                        const textDescription = selectedTextingTemplate?.textMessage
                        const textWords = textDescription.split(' ')
                        let currentIndex2 = 0

                        const voiceResponse = selectedTextingTemplate?.voiceResponse
                        const voiceWords = voiceResponse.split(' ')
                        let currentIndex3 = 0

                        updateDescription(scenarioDescription, scenarioWords, currentIndex1, 'description', 'interval1')
                        updateDescription(textDescription, textWords, currentIndex2, 'textMessage', 'interval2')
                        updateDescription(voiceResponse, voiceWords, currentIndex3, 'voiceResponse', 'interval3')
                        setTextingTemplateModalActive(false)
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Use Template
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 7 && (
                <div className={classes.box}>
                  {React.createElement(() => {
                    posthog.capture('call_transferring_step')
                    return null
                  })}
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                        marginBottom: '0rem',
                      }}
                    >
                      (Optional) Call Transferring
                    </h3>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ul className={classes.list}>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          Transfer calls based on what the caller says
                        </li>
                        <li>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 17.837 17.837"
                            style={{
                              marginRight: '.5rem',
                              transform: 'translateY(3px)',
                            }}
                          >
                            <g>
                              <path
                                style={{ fill: 'rgb(110, 121, 214)' }}
                                d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27   c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0   L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                              />
                            </g>
                          </svg>
                          Use this feature to intelligently reroute the caller
                        </li>
                      </ul>
                    </div>

                    <button
                      type="button"
                      onClick={() => setShowCallTransferringModal(true)}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Setup Call Transferring
                    </button>

                    <div onClick={() => setStepState(stepState + 1)} className={classes.later}>
                      I'll do this later
                    </div>
                  </Fade>
                </div>
              )}

              {stepState === 7 && (
                <div
                  className={classes.box + ' ' + classes.modal + ' ' + (showCallTransferringModal && classes.active)}
                >
                  <div
                    className={classes.close}
                    onClick={() => {
                      if (
                        callWorkflows.some(
                          (workflow) =>
                            !workflow.description ||
                            !workflow.voiceResponse ||
                            !workflow.workflowForwardingNumber ||
                            !workflow.country.name ||
                            workflow.workflowForwardingNumber.length < 6
                        )
                      ) {
                        notification.error({
                          message: 'Please fill out all workflows, or remove empty workflows',
                          duration: 1,
                        })
                        return
                      }
                      setShowCallTransferringModal(false)
                    }}
                  >
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Call Transferring
                    </h3>

                    <div className={classes.subtitle}>
                      Describe when you want your receptionist to transfer calls during a conversation. For example, you
                      can program your receptionist to transfer the call to the billing department anytime the caller
                      asks for a refund or receipt.
                    </div>

                    {callWorkflows.map((phoneWorkflow, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '1rem',
                          border: '2px dashed #ccc',
                          borderRadius: '10px',
                          padding: '1rem',
                        }}
                      >
                        <label className={classes.label}>Trigger</label>
                        <div
                          className={classes.subtitle}
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Describe the scenario in which you would like to transfer the call.
                        </div>

                        {isCampaign ? (
                          <TextEditor
                            html={phoneWorkflow.description}
                            vvariables={campaignVariables}
                            onChange={(value, variables) => {
                              handleCallWorkflowDescriptionChange(
                                {
                                  preventDefault: () => {},
                                  target: { value },
                                },
                                index
                              )
                              setCampaignVariables(variables)
                            }}
                            placeholder="e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt."
                          />
                        ) : (
                          <textarea
                            style={{
                              marginBottom: '16px',
                              fontFamily: 'Quicksand, sans-serif',
                            }}
                            rows={4}
                            className={classes.input + ' ' + classes.textarea}
                            value={phoneWorkflow.description}
                            required
                            onChange={(e) => handleCallWorkflowDescriptionChange(e, index)}
                            placeholder="e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt."
                          />
                        )}

                        <label className={classes.label}>Voice Response</label>
                        <div
                          className={classes.subtitle}
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Enter the response to be spoken by the phone receptionist right before the call is
                          transferred.
                        </div>

                        {isCampaign ? (
                          <TextEditor
                            html={phoneWorkflow.voiceResponse}
                            vvariables={campaignVariables}
                            onChange={(value, variables) => {
                              handleCallWorkflowVoiceResponseChange(
                                {
                                  preventDefault: () => {},
                                  target: { value },
                                },
                                index
                              )
                              setCampaignVariables(variables)
                            }}
                            placeholder="e.g. Sounds good, I am transferring you to the billing department right now. Please hold."
                          />
                        ) : (
                          <textarea
                            style={{
                              marginBottom: '16px',
                              fontFamily: 'Quicksand, sans-serif',
                            }}
                            rows={4}
                            className={classes.input + ' ' + classes.textarea}
                            value={phoneWorkflow.voiceResponse}
                            required
                            onChange={(e) => handleCallWorkflowVoiceResponseChange(e, index)}
                            placeholder="e.g. Sounds good, I am transferring you to the billing department right now. Please hold."
                          />
                        )}
                        <label className={classes.label}>Phone Number</label>
                        <div
                          className={classes.subtitle}
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          Enter the phone number the receptionist should call when this scenario is triggered.
                        </div>
                        <select
                          value={phoneWorkflow.country.name}
                          onChange={(e) => handleCallWorkflowCountryChange(e, index)}
                          className={classes.input}
                          style={{
                            marginBottom: '16px',
                            width: '100%',
                            maxWidth: 'unset',
                          }}
                        >
                          <option value="">Select</option>
                          {countries.map((c) => (
                            <option key={c.name} value={c.name}>
                              {c.name}
                            </option>
                          ))}
                        </select>

                        <input
                          className={classes.input}
                          style={{
                            marginBottom: '16px',
                            width: '100%',
                            maxWidth: 'unset',
                          }}
                          type="tel"
                          value={phoneWorkflow.workflowForwardingNumber}
                          onChange={(e) => handleCallWorkflowForwardingNumber(e, index)}
                          required
                          placeholder={phoneWorkflow.country.code}
                          autoComplete="username"
                        />

                        <button
                          type="button"
                          style={{
                            background: 'rgb(237 35 35)',
                            color: '#fff',
                            border: 'none',
                            height: '36px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => removeCallWorkflow(e, index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}

                    <button onClick={addCallWorkflow} className={classes.whiteButton} style={{ maxWidth: '300px' }}>
                      + Add Call Transferring Workflow?
                    </button>
                    <>
                      {callWorkflows.length !== 0 && (
                        <button
                          className={classes.whiteButton + ' ' + classes.flexCentered}
                          style={{
                            marginTop: '1rem',
                            width: 'max-content',
                            maxWidth: 'max-content',
                          }}
                          onClick={(event) => {
                            event.preventDefault() // Prevent the button from scrolling up

                            setCallTransferTemplateModalActive(true)
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              style={{
                                strokeWidth: '1.5px',
                                height: '16px',
                                width: '16px',
                                marginRight: '8px',
                              }}
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                              ></path>
                            </svg>
                            {isUsingATemplate && systemPhrase ? `Using ${businessType} Template` : 'Use a Template'}
                          </div>
                        </button>
                      )}
                    </>

                    <button
                      type="button"
                      onClick={() => {
                        // If any of the workflows are empty, error and don't continue
                        if (
                          callWorkflows.some(
                            (workflow) =>
                              !workflow.description ||
                              !workflow.voiceResponse ||
                              !workflow.workflowForwardingNumber ||
                              !workflow.country.name ||
                              workflow.workflowForwardingNumber.length < 6
                          )
                        ) {
                          notification.error({
                            message: 'Please fill out all workflows, or remove empty workflows',
                            duration: 1,
                          })
                          return
                        }

                        setStepState(stepState + 1)
                        setShowCallTransferringModal(false)
                      }}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 7 && callTransferTemplateModalActive && <div className={classes.modalOverlay}></div>}

              {stepState === 7 && (
                <div
                  className={
                    classes.box + ' ' + classes.modal + ' ' + (callTransferTemplateModalActive && classes.active)
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.close} onClick={() => setCallTransferTemplateModalActive(false)}>
                    &times;
                  </div>
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Prompt Templates
                    </h3>

                    <div className={classes.subtitle + ' '} style={{ marginBottom: '.5rem' }}>
                      Select a template
                    </div>

                    <select
                      style={{ marginBottom: '1rem' }}
                      className={classes.input}
                      // value={selectedGreetingTemplate}
                      onChange={(e) =>
                        setSelectedCallTemplate(
                          callTransferTemplates.find((el) => el.scenarioDescription === e.target.value)
                        )
                      }
                    >
                      <option value="">Choose an option</option>
                      {callTransferTemplates.map((el, idx) => {
                        return <option key={el.scenarioDescription}>{el.scenarioDescription}</option>
                      })}
                    </select>

                    <div style={{ width: '100%' }}>
                      <h5 className={classes.label}>Trigger</h5>
                      <p>{selectedCallTemplate?.scenarioDescription}</p>
                      <h5 className={classes.label}>Voice Response</h5>
                      <p>{selectedCallTemplate?.voiceResponse}</p>
                      <h5 className={classes.label}>Phone Number</h5>
                      <p>{selectedCallTemplate?.phoneNumber}</p>
                    </div>

                    <button
                      type="button"
                      onClick={() => {
                        const updateDescription = (description, words, currentIndex, setFunction, intervalName) => {
                          const modifiedWorkflows = [...callWorkflows]
                          const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1]
                          lastWorkflow[setFunction] = ''

                          const interval = setInterval(() => {
                            const modifiedWorkflows = [...callWorkflows]
                            const lastWorkflow = modifiedWorkflows[modifiedWorkflows.length - 1]

                            if (currentIndex < words.length) {
                              if (setFunction === 'workflowForwardingNumber') {
                                // No spacing for digits
                                lastWorkflow[setFunction] += words[currentIndex]
                                currentIndex++
                                setCallWorkflows(modifiedWorkflows)
                              } else {
                                lastWorkflow[setFunction] += words[currentIndex] + ' '
                                currentIndex++
                                setCallWorkflows(modifiedWorkflows)
                              }
                            } else {
                              clearInterval(interval)
                            }
                          }, 50)
                        }

                        const scenarioDescription = selectedCallTemplate?.scenarioDescription
                        const scenarioWords = scenarioDescription.split(' ')
                        let currentIndex1 = 0
                        const voiceResponse = selectedCallTemplate?.voiceResponse
                        const voiceWords = voiceResponse.split(' ')
                        let currentIndex2 = 0

                        const phoneNum = selectedCallTemplate?.phoneNumber
                        const phoneDigits = phoneNum.split('')
                        let currentIndex3 = 0

                        updateDescription(scenarioDescription, scenarioWords, currentIndex1, 'description', 'interval1')
                        updateDescription(voiceResponse, voiceWords, currentIndex2, 'voiceResponse', 'interval2')
                        updateDescription(phoneNum, phoneDigits, currentIndex3, 'workflowForwardingNumber', 'interval3')
                        setCallTransferTemplateModalActive(false)
                      }}
                      className={classes.button}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Use Template
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 8 && !reseller_email && !campaign_user_email && (
                <div className={classes.box}>
                  {React.createElement(() => {
                    posthog.capture('email_address_entered')
                    return null
                  })}
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Email Address
                    </h3>
                    <div
                      className={classes.subtitle}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {' '}
                      Your email address. We will never share this with anyone, or send you spam.
                    </div>

                    <input
                      type="email"
                      value={emailAddress}
                      className={classes.input + ' ' + classes.centered}
                      required
                      onChange={(event) => {
                        let value = event.target.value
                        setEmailAddress(value)
                      }}
                      placeholder={`e.g. john@${businessName.toLocaleLowerCase().replace(' ', '')}.com`}
                    />

                    <button
                      type="button"
                      onClick={() => {
                        if (!emailAddress) {
                          notification.error({ message: 'Please enter your email address', duration: 1 })
                        } else if (!validateEmail(emailAddress)) {
                          notification.error({ message: 'Please enter a valid email address', duration: 1 })
                        } else {
                          setStepState(stepState + 1)
                        }
                      }}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {stepState === 9 && !reseller_email && !campaign_user_email && (
                <div className={classes.box}>
                  <Fade duration={1000} bottom>
                    {React.createElement(() => {
                      posthog.capture('testing_number_entered')
                      return null
                    })}

                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Testing Call: Your Phone Number
                    </h3>
                    <div
                      className={classes.subtitle}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {reseller_email || campaign_user_email
                        ? 'Use your client’s phone number here. This is what they will use to login to their unbranded portal. This number has nothing to do with your receptionist’s number, it is just used to login'
                        : "You will test your receptionist by calling our test number from your phone. Please enter the number you'll use to make the test call."}
                    </div>
                    <select
                      value={country?.name}
                      className={classes.input + ' ' + classes.centered}
                      onChange={handleCountryChange}
                      style={{
                        padding: '0.5rem',
                        borderRadius: '10px',
                        marginBottom: '1rem',
                      }}
                    >
                      {countries.map((c) => (
                        <option key={c.name} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      value={testingNumber}
                      className={classes.input + ' ' + classes.centered}
                      onChange={handletestingNumberChange}
                      required
                      style={{
                        padding: '0.5rem',
                        borderRadius: '10px',
                        marginBottom: '1rem',
                      }}
                      placeholder={country?.code}
                      autoComplete="username"
                    />

                    <button
                      type="button"
                      onClick={() => {
                        if (!country?.name) {
                          notification.error({ message: 'Please select a country', duration: 1 })
                        } else if (!testingNumber || testingNumber.length < 6) {
                          notification.error({ message: 'Please enter your phone number', duration: 1 })
                        } else {
                          setStepState(stepState + 1)
                        }
                      }}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Continue
                    </button>
                  </Fade>
                </div>
              )}

              {(stepState === 10 || ((reseller_email || campaign_user_email) && stepState === 8)) && (
                <div className={classes.box}>
                  {React.createElement(() => {
                    posthog.capture('create_credentials_entered')
                    return null
                  })}
                  <Fade duration={1000} bottom>
                    <h3
                      className={classes.heading}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Final Step: Create Account
                    </h3>
                    {!isCampaign ? (
                      <div
                        className={classes.subtitle}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {reseller_email || campaign_user_email
                          ? 'Your client will use this'
                          : 'Before you can test your receptionist, create an account. You will use this'}{' '}
                        username and password to log in to the dashboard
                      </div>
                    ) : (
                      <></>
                    )}

                    {!isCampaign ? (
                      <>
                        <label
                          style={{
                            textAlign: 'center',
                            marginTop: '1.5rem',
                            display: 'block',
                          }}
                        >
                          <p className={classes.heading} style={{ textAlign: 'center' }}>
                            Username
                          </p>
                          <input
                            className={classes.input + ' ' + classes.centered}
                            type="text"
                            value={username}
                            required
                            onChange={handleUsernameChange}
                          />
                          {errors && <span className="text-red-500 text-center">{errors?.username}</span>}
                        </label>

                        <label
                          style={{
                            textAlign: 'center',
                            marginTop: '1.5rem',
                            display: 'block',
                          }}
                        >
                          <p className={classes.heading} style={{ textAlign: 'center' }}>
                            Password
                          </p>
                          <p className={classes.subtitle}>You will use this password to log in to your dashboard</p>
                          <input
                            type="password"
                            value={password}
                            className={classes.input + ' ' + classes.centered}
                            required
                            onChange={(event) => {
                              setPassword(event.target.value)

                              if (confirmPassword && confirmPassword !== event.target.value) {
                                setErrors((prev) => ({
                                  ...prev,
                                  password: 'Password & Confirm Password does not match.',
                                }))
                              } else {
                                setErrors((prev) => ({ ...prev, password: '' }))
                              }
                            }}
                          />
                        </label>

                        {password.length > 0 ? (
                          <label
                            style={{
                              textAlign: 'center',
                              marginTop: '1.5rem',
                              display: 'block',
                            }}
                          >
                            <p className={classes.heading} style={{ textAlign: 'center' }}>
                              Confirm Password
                            </p>
                            <p className={classes.subtitle}>Please enter your password once again </p>
                            <input
                              className={classes.input + ' ' + classes.centered}
                              type="password"
                              value={confirmPassword}
                              required
                              onChange={(event) => {
                                setConfirmPassword(event.target.value)
                                if (password !== event.target.value) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    password: 'Password & Confirm Password does not match.',
                                  }))
                                } else {
                                  setErrors((prev) => ({ ...prev, password: '' }))
                                }
                              }}
                            />
                          </label>
                        ) : (
                          <></>
                        )}
                        <div
                          style={{
                            height: '26px',
                            width: '100%',
                            marginTop: '.5rem',
                          }}
                        >
                          {errors?.password && (
                            <p
                              className={classes.label}
                              style={{
                                color: 'red',
                                textAlign: 'center',
                                margin: 0,
                              }}
                            >
                              {errors.password}
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div style={{ textAlign: 'center' }}>
                      {!debrand && password && confirmPassword && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <input
                            type="checkbox"
                            name="termsAndConditions"
                            value={termsAndConditions}
                            onChange={(e) => setTermsAndConditions(e.target.checked)}
                          />
                          <label htmlFor="termsAndConditions">
                            I agree to the{' '}
                            <a
                              href="https://assets-global.website-files.com/646676436cb9dc8974098e06/65a60030580c5ed45b310736_Terms%20and%20Conditions.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              terms and conditions
                            </a>
                          </label>
                        </div>
                      )}
                    </div>

                    <Button
                      disabled={(!isCampaign && password !== confirmPassword) || (!debrand && !termsAndConditions)}
                      className={classes.button + ' ' + classes.centered}
                      style={{
                        marginTop: '2rem',
                      }}
                      loading={isSubmittingForm}
                      onClick={(e) => {
                        if (!isCampaign && (!password || !confirmPassword)) {
                          e.preventDefault()
                          notification.error({ message: 'Please enter a new password', duration: 1 })
                        }
                        if (!username && !isCampaign) {
                          e.preventDefault()
                          notification.error({ message: 'Please enter a username', duration: 1 })
                        }

                        handleSubmit(e)
                      }}
                    >
                      Create My AI!
                    </Button>
                  </Fade>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2rem',
                  justifyContent: 'center',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  bottom: '1rem',
                }}
              >
                <div
                  style={{
                    marginRight: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    opacity: stepState === 0 ? 0.5 : 1,
                    pointerEvents: stepState === 0 ? 'none' : 'all',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setStepState((prev) => {
                      let newState = prev - 1
                      // skipping language for campaign
                      if (newState === 2) newState = 1
                      if (newState <= 0) return 0
                      return newState
                    })
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    height="20px"
                    width="20px"
                    version="1.1"
                    id="Capa_1"
                    viewBox="0 0 219.151 219.151"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575   C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575   c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z" />
                      <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008   c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825   c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628   c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z" />
                    </g>
                  </svg>
                </div>
                {new Array(reseller_email || campaign_user_email ? 10 : 12).fill(0).map((el, idx) => {
                  return (
                    <div
                      key={'dot-indicator-' + idx}
                      style={{
                        padding: '6px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // skipping language for campaign
                        if (idx === 2) {
                          setStepState(1)
                          return
                        }
                        if (idx < stepState) setStepState(idx)
                      }}
                    >
                      <div className={classes.dot + ' ' + (idx === stepState ? classes.active : '')}></div>
                    </div>
                  )
                })}
                <div style={{ visibility: 'hidden' }}>➡️</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RegisterPage
