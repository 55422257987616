import { Button, List, Steps } from 'antd'
import './TextLogDetails.scss'
import { TextLog } from '@/interfaces/ILogs'
import { useState } from 'react'
import moment from 'moment'
import { Check } from '@mui/icons-material'

type TextLogDetailsProps = {
  textLog: TextLog
}

export const TextLogDetails = ({ textLog }: TextLogDetailsProps) => {
  const [showDetails, setShowDetails] = useState(false)

  const gerDeliveryStatus = (deliveryStatus: string) => {
    const st = JSON.parse(deliveryStatus) || []

    if (st.length > 0) {
      const lastObj = st[st.length - 1]
      return lastObj?.status
    }
    return 'queued'
  }

  const data = [
    { label: 'Date', value: textLog.date },
    { label: 'To', value: textLog.to },
    {
      label: 'Text',
      value: textLog?.text,
    },
    ...(textLog?.textType
      ? [
          {
            label: 'Text Type',
            value: textLog.textType,
          },
        ]
      : []),
    ...(textLog?.workflow
      ? [
          {
            label: 'Workflow Triggered',
            value: textLog.workflow,
          },
        ]
      : []),
    ...(textLog?.deliveryStatus
      ? [
          {
            label: 'Delivery Status',
            value: gerDeliveryStatus(textLog.deliveryStatus),
          },
        ]
      : []),
    ...(textLog?.errorText
      ? [
          {
            label: 'Error',
            value: textLog.errorText,
          },
        ]
      : []),
    ...(textLog?.errorResolve
      ? [
          {
            label: 'How to Resolve',
            value: textLog.errorResolve,
          },
        ]
      : []),
  ]

  const isURL = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return urlRegex.test(text)
  }

  const wrapInAnchorTag = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a href={part} key={index} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        )
      }
      return part
    })
  }

  const renderDeliveryStatusDetails = () => {
    const statusArray = textLog?.deliveryStatus ? JSON.parse(textLog.deliveryStatus) : []

    return (
      <Steps direction="horizontal" size="small" current={statusArray.length - 1}>
        {statusArray.map((statusObj: any, index: number) => (
          <Steps.Step
            key={index}
            title={statusObj?.status}
            description={moment(statusObj?.timeUtc).format('ddd D MMM YY, h:mm:ss A')}
          />
        ))}
      </Steps>
    )
  }

  return (
    <List
      key={textLog.date}
      size="small"
      dataSource={data}
      className="text-logs-details"
      renderItem={(item) => (
        <List.Item>
          <strong>{item.label}:</strong>
          <br />
          <span className="text-container">{isURL(item.value) ? wrapInAnchorTag(item.value) : item.value}</span>
          {item.label === 'Delivery Status' && textLog?.deliveryStatus && (
            <>
              <Button type="link" className="text-xs" onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? 'Hide Details' : 'Show More'}
              </Button>
              {textLog?.fallbackResentText && (
                <Button type="link" className="text-xs text-green-500">
                  <Check fontSize="inherit" />
                  Fulfilled by our number
                </Button>
              )}
              {showDetails && renderDeliveryStatusDetails()}
            </>
          )}
        </List.Item>
      )}
    />
  )
}
// changes from main