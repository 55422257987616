import { Alert, Button, Form, FormInstance, Select, Space } from 'antd'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import './GetStandAloneNumberForm.scss'

import { forwardRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getTwilioSupportedCountries } from '@/api/mock'
import { CONTACT_SUPPORT_EMAIL } from 'src/constants'
import { getUserFeatures } from '@/store/account/selector'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { openModal } from '@/store/modal/slice'
import { MODAL_IDS } from 'src/enums/EModal'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'

type GetStandAloneNumberFormProps = {
  onSubmit: (values: { country: string }) => void
  debrand: boolean
  setChooseNumberModalOpen?: (value: boolean) => void
  setCountry?: (value: string) => void
}

export const GetStandAloneNumberForm = forwardRef<FormInstance, GetStandAloneNumberFormProps>(
  ({ onSubmit, debrand, setChooseNumberModalOpen, setCountry }, ref) => {
    const [form] = Form.useForm<{
      country: string
    }>()

    const [isTouched, setIsTouched] = useState<boolean>(form.isFieldsTouched())
    const features = useAppSelector(getUserFeatures)
    const dispatch = useAppDispatch()

    const { data } = useQuery({
      queryKey: ['twilioSupportedCountries'],
      queryFn: getTwilioSupportedCountries,
    })

    const twilioSupportedCountryOptions = data?.map((country) => ({
      label: country.countryCode,
      value: `( ${country.phoneNumberPrefix} ) - ${country.countryName} - ${country.countryCode}`,
    }))

    const handleFinish = (values: { country: string }) => {
      onSubmit(values)
    }

    const SaveButton = () => {
      return <span>You have some unsaved changes </span>
    }

    const baseClass = 'get-stand-alone-number-form'
    return (
      <div className={`${baseClass}`}>
        {debrand && (
          <p className="text-sm mt-1 mb-2">
            <span> You will be charged a one-time $1 fee to receive your number.</span>
          </p>
        )}
        <Form
          form={form}
          layout="vertical"
          name="get-stand-alone-number-form"
          className={baseClass}
          onFinish={handleFinish}
          ref={ref}
        >
          <Form.Item
            label={
              <LabelWithDescription
                label="Country"
                description="The country you want to get a number from"
                required={true}
              />
            }
            name="country"
            className={`${baseClass}__input-item`}
            rules={[
              {
                required: true,
                message: 'Country is required!',
              },
            ]}
          >
            <Select
              options={twilioSupportedCountryOptions}
              className={`${baseClass}__country-selection`}
              onChange={(value) => setCountry?.(value)}
              placeholder="Select a country"
              optionRender={(option) => (
                <Space>
                  <span>{option.value}</span>
                </Space>
              )}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate={() => {
              const isFormChanged = form.isFieldsTouched() !== isTouched
              if (isFormChanged) {
                setIsTouched(form.isFieldsTouched())
              }

              return isFormChanged
            }}
            className={`${baseClass}__footer`}
          >
            {() => form.isFieldsTouched() && isTouched && <Alert type="warning" message={<SaveButton />} />}
          </Form.Item>
        </Form>
        {['CA', 'US'].includes(form.getFieldValue('country')?.split(' - ')[2]) && (
          <div>
            <p className="text-xs mt-1 mb-2">Want Your Favourite Area Code ?</p>

            <Button
              type="primary"
              className={`${baseClass}__submit-button`}
              onClick={() => {
                if (!features?.phoneNumbersAndForwarding.pickYourAreaCode.value) {
                  dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }))
                  return
                }
                setChooseNumberModalOpen?.(true)
              }}
            >
              Get Your Favourite Number
            </Button>
          </div>
        )}
        {!debrand && (
          <p className="text-xs mt-1 mb-2">
            Want another country code?
            <br />
            Contact support at{' '}
            <a href={`mailto:${CONTACT_SUPPORT_EMAIL}`} className="text-themeColor font-bold italic">
              {CONTACT_SUPPORT_EMAIL}
            </a>
          </p>
        )}
      </div>
    )
  }
)
