import React from 'react'
import { Button, Typography, Layout, Space } from 'antd'
import { GiftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import logo from '@/assets/images/logo.png'

const { Content } = Layout
const { Title, Paragraph } = Typography

const AffiliateProgramPage: React.FC = () => {
  return (
    <Layout className="min-h-screen bg-gradient-to-br from-blue-50 via-blue-100 to-blue-200">
      <Content className="flex flex-col justify-center items-center p-4 text-center">
        <img src={logo} alt="logo" className="fixed top-8 left-4 w-16 h-16" />

        <Space direction="vertical" size="large" className="max-w-2xl">
          <Title level={1} className="text-4xl md:text-6xl font-bold text-blue-800 mb-6">
            Earn 40% Commissions
          </Title>

          <Paragraph className="text-xl md:text-2xl text-blue-700 mb-8">
            You can earn 40% commissions by sharing your affiliate link. This program is a better fit for you.
          </Paragraph>

          <Button
            type="primary"
            size="large"
            icon={<GiftOutlined className="text-2xl" />}
            className="h-14 px-10 text-xl font-semibold !bg-blue-600 text-white border-2 border-blue-600 hover:!bg-white hover:text-blue-600 hover:border-blue-600 transition-all duration-300 shadow-lg"
            href="https://www.myaifrontdesk.com/affiliate"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
            <ArrowRightOutlined className="ml-2 text-2xl" />
          </Button>
        </Space>
      </Content>
    </Layout>
  )
}

export default AffiliateProgramPage
