import React, { useEffect, useState } from 'react';
import { Button, Table, Input, Spin, Tag, Dropdown, Modal } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import LeadDetailsSheet from './LeadDetailsSheet';
import { LeadManagement } from './AddLead';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';

interface Contact {
  leadId: string;
  phone_number: string;
  status: 'Contacted' | 'Not Yet Contacted' | 'Bounced' | 'Interested' | 'Not Interested' | 'NEW';
}

interface LeadsTableProps {
  campaignId: string | undefined;
  initialContacts: Contact[];
  refetch: () => void;
  isLoading: boolean;
  isRefetching: boolean;
}

const StatusBadge: React.FC<{ status: Contact['status'] }> = ({ status }) => {
  let color;
  switch (status) {
    case 'Contacted':
      color = 'green';
      break;
    case 'Not Yet Contacted':
      color = 'yellow';
      break;
    case 'Bounced':
      color = 'red';
      break;
    default:
      color = 'grey';
  }
  return <Tag color={color}>{status}</Tag>;
};

const menuItems = [
  {
    label: 'All',
    key: 'All',
  },
  {
    label: 'Pending',
    key: 'Pending',
  },
  {
    label: 'Contacted',
    key: 'Contacted',
  },
  {
    label: 'Interested',
    key: 'Interested',
  },
  {
    label: 'Not Interested',
    key: 'Not Interested',
  },
];

const LeadsTable: React.FC<LeadsTableProps> = ({ initialContacts, refetch, isLoading, isRefetching }) => {
  const [loading, setLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState<Contact | null>(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [addLeadModal, setAddLeadModal] = useState(false);

  const selectedCampaign = useAppSelector(getSelectedCampaign)
  const variables = selectedCampaign?.variables ? JSON.parse(selectedCampaign.variables) : []

  const closeAddLeadModal = () => {
    setAddLeadModal(false);
  };

  useEffect(() => {
    setContacts(initialContacts);
  }, [initialContacts]);

  const filteredContacts = contacts.filter(contact => {
    const matchesSearch =
      contact?.phone_number?.includes(searchText);

    const matchesFilter =
      filterStatus === 'All' && searchText === ''
        ? true
        : filterStatus
          ? contact.status === filterStatus
          : true;

    return matchesSearch && matchesFilter;
  });

  const displayedContacts = filteredContacts.slice(0, visibleCount);

  const handleRowClick = (contact: Contact) => {
    setSelectedLead(contact);
  };

  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      if (displayedContacts.length < filteredContacts.length) {
        setVisibleCount(prevCount => prevCount + 5);
      }
      setLoading(false);
    }, 1000);
  };

  const closeSheet = () => {
    setSelectedLead(null);
  };

  const columns = [
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    ...Object.keys(variables).map((variable: string) => ({
      title: variable.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
      dataIndex: ['variables', variable],
      key: `variables.${variable}`,
    })),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: Contact['status']) => <StatusBadge status={status} />,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: Contact) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => handleRowClick(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="tab-controls flex justify-between items-center p-4">
        <div className="tab-controls-left">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search leads..."
            className="search-input"
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
        <div className="tab-controls-right flex items-center gap-2">
          <Dropdown.Button
            menu={{
              items: menuItems,
              onClick: e => {
                setFilterStatus(e.key);
              },
            }}
          >
            Filter
          </Dropdown.Button>
          <Button
            type="primary"
            onClick={() => setAddLeadModal(true)}
          >
            Add New Lead
          </Button>
        </div>
      </div>
      <div className="max-h-[600px] overflow-y-auto">
        <Spin spinning={loading || isLoading || isRefetching}>
          <Table
            columns={columns}
            dataSource={displayedContacts}
            pagination={false}
            rowKey="leadId"
            locale={{ emptyText: <div style={{ color: 'black' }}>🌟 No Leads Available! Click Add New Lead To Add Leads 🌟</div> }}
          />
        </Spin>
      </div>
      <div className="flex items-center justify-center mt-4">
        <div className="m-2 text-sm text-gray-500">
          Showing {displayedContacts.length} of {filteredContacts.length} Leads
        </div>
        {filteredContacts.length > 5 && (
          <Button
            onClick={handleLoadMore}
            disabled={loading || displayedContacts.length >= filteredContacts.length}
            type="primary"
          >
            {loading ? <Spin size="small" /> : 'Load more'}
          </Button>
        )}
      </div>
      <Modal
        open={!!selectedLead}
        title="Lead Details"
        onCancel={closeSheet}
        footer={null}
        width={800}
      >
        <LeadDetailsSheet lead={selectedLead} refetchLeads={refetch} onClose={closeSheet} />
      </Modal>
      <Modal
        title="Add New Lead"
        open={addLeadModal}
        onCancel={closeAddLeadModal}
        footer={null}
        width={800}
      >
        <LeadManagement refetchLeads={refetch} />
      </Modal>
    </div >
  );
};

export default LeadsTable;
