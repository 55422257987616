export enum OverViewCards {
  GREETING_PHRASE = 'Greeting Phrase',
  AI_PROMPT = 'AI Prompt',
  CUSTOMIZE_VOICE_LANGUAGES = 'Customize Voice & Languages',
  SAMPLE_QUESTIONS_ANSWERS = 'Sample Questions & Answers',
  KNOWLEDGE_BASE = 'Knowledge Base',
  CALL_TRANSFERRING_WORKFLOW = 'Call Transferring Workflow',
  CALENDAR_SCHEDULING_WORKFLOW = 'Calendar Scheduling Workflow',
  TEXTING_WORKFLOW = 'Texting Workflow',
  HANGUP_WORKFLOW = 'Hangup Workflow',
  PHONE_FORM_WORKFLOW = 'Phone Form Workflow',
  POST_CALL_WEBHOOK = 'Post Call Webhook',
  TWEAK_ADVANCED_SETTINGS = 'Tweak Advanced Settings',
  API_WORKFLOW = 'API Workflow',
}
