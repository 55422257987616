import { RESELLER_BOOK_CALL_CALENDLY_URL } from 'src/constants'

const ResellerPromo = () => {
  return (
    <div>
      <span className="mr-1">Need help setting up? Let us guide you through it.</span>
      <a href={RESELLER_BOOK_CALL_CALENDLY_URL} target="_blank" rel="noreferrer">
        <u>Book an onboarding call here for free</u>
      </a>
    </div>
  )
}

export default ResellerPromo
