import React from 'react'
import {
  cleanDataOfManagedReceptionist,
  createCheckoutSessionNewResellers,
  deleteReceptionist,
  fetchResellerClients,
  updateManagedReceptionist,
} from '@/api/reseller'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getResellerData } from '@/store/reseller/selector'
import { useMutation } from '@tanstack/react-query'
import { Button, Input, InputNumber, notification, Switch, Card, Row, Col, Typography } from 'antd'

// @ts-ignore
import RegisterPageV3 from '../../../v1/RegisterPageV3.jsx'
import classes from '../../../v1/Whitelabel/ResellerPortal.module.css'
import { CustomModal } from '@/components/CustomModal'
import { ReceptionistDashboard } from '@/pages/ReceptionistDashboard/ReceptionistDashboard.tsx'
import { loginApi } from '@/api/user'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { setAccount } from '@/store/account/slice'
import { Loading } from '@/components/Loading'
import ReceptionistCard from './ReceptionistCard.tsx'
import { updateReseller } from '@/store/reseller/slice.js'
import { ShoppingCartCheckout } from '@mui/icons-material'
import ResellerDashboard from '../ResellerDashboard.tsx'
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems.ts'
import LocalStorageUtility from '@/utils/localStorage.ts'
import { LocalStorageKeys } from 'src/enums/ELocalStorage.ts'

const { Title } = Typography

export default function MyReceptionist() {
  const resellerData = useAppSelector(getResellerData)

  const dispatch = useAppDispatch()

  const [clients, setClients] = React.useState<any[]>([])
  const [isRegistrationModal, setIsRegistrationModalActive] = React.useState<boolean>(false)
  const [successConfigureReceptionistModal, setSuccessConfigureReceptionistModal] = React.useState<boolean>(false)
  const [deleteReceptionistNoticeModal, setDeleteReceptionistNoticeModal] = React.useState<boolean>(false)
  const [clearAllLogsWarningModal, setClearAllLogsWarningModal] = React.useState<boolean>(false)
  const [receptionistCurrentlySelected, setCurrentReceptionist] = React.useState<{ number: string } | null>(null)
  const [changeMangedReceptionistPasswordModal, setChangeMangedReceptionistPasswordModal] =
    React.useState<boolean>(false)
  const [resellerMinutesLimitationModal, setResellerMinutesLimitationModal] = React.useState<boolean>(false)
  const [purchaseSeatsModal, setPurchaseSeatsModal] = React.useState<boolean>(false)
  const [newPassword, setNewPassword] = React.useState<string>('')
  const [maxMinutes, setMaxMinutes] = React.useState<number | null>(60) // Default max_minutes value
  const [minutesLimitEnabled, setMinutesLimitEnabled] = React.useState<boolean>(false) // Default enabled value
  const [seatsBuyed, setSeatsBuyed] = React.useState<number | null>(1)

  const [previewDashboardModalIsOpen, setPreviewDashboardModalIsOpen] = React.useState<boolean>(false)

  const getClientsMutation = useMutation({
    mutationKey: ['fetchResellerClients'],
    mutationFn: fetchResellerClients,
    onSuccess(data: any) {
      setClients(data.items)
      if (!resellerData.totalReceptionists) {
        setSeatsBuyed(5)
      }
    },
    onError() {
      notification.error({
        message: 'Error fetching clients',
      })
    },
  })

  const deleteReceptionistMutation = useMutation({
    mutationKey: ['deleteReceptionist'],
    mutationFn: deleteReceptionist,

    onSuccess(_, payload) {
      setClients((prev) => prev.filter((client) => client.number !== payload.receptionist_username))
      dispatch(
        updateReseller({
          ...resellerData,
          unreservedReceptionists: resellerData.unreservedReceptionists + 1,
        })
      )
      notification.success({
        message: 'Receptionist deleted',
      })
    },
    onError() {
      notification.error({
        message: 'Error deleting receptionist',
      })
    },
  })

  const previewCurrentClientMutation = useMutation({
    mutationKey: ['previewCurrentClient'],
    mutationFn: loginApi,
    onSuccess(data) {
      if (data.token) {
        dispatch(
          setAccount({
            isAuthenticated: true,
            user: data,
            authToken: data.token,
            currentPage: '',
          })
        )

        LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, data.token)
      }
      setPreviewDashboardModalIsOpen(true)
    },
    onError() {
      notification.error({
        message: 'Error previewing client',
      })
    },
  })

  const updateMangedReceptionistPassword = useMutation({
    mutationKey: ['updateMangedReceptionistPassword'],
    mutationFn: updateManagedReceptionist,
    onSuccess(_, payload) {
      notification.success({
        message: 'Receptionist password updated',
      })

      setClients((prev) =>
        prev.map((client) => {
          if (client.number === payload.receptionist_username) {
            return {
              ...client,
              password: newPassword,
            }
          }
          return client
        })
      )
      setChangeMangedReceptionistPasswordModal(false)
    },
    onError() {
      notification.error({
        message: 'Error updating receptionist password',
      })
    },
  })

  const updateMangedReceptionistMinutes = useMutation({
    mutationKey: ['updateMangedReceptionistMinutes'],
    mutationFn: updateManagedReceptionist,
    onSuccess(_, payload) {
      notification.success({
        message: 'Receptionist Minutes updated',
      })

      setClients((prev) =>
        prev.map((client) => {
          if (client.number === payload.receptionist_username) {
            return {
              ...client,
              max_minutes: maxMinutes,
              minutesLimitEnabled: minutesLimitEnabled,
            }
          }
          return client
        })
      )

      setResellerMinutesLimitationModal(false)
    },
    onError() {
      notification.error({
        message: 'Error updating receptionist minutes',
      })
    },
  })

  const clearAllLogsMutation = useMutation({
    mutationKey: ['clearAllLogs'],
    mutationFn: cleanDataOfManagedReceptionist,
    onSuccess() {
      notification.success({
        message: `Logs cleared for ${receptionistCurrentlySelected?.number}`,
      })
    },
    onError() {
      notification.error({
        message: 'Error clearing logs',
      })
    },
  })

  const createCheckoutSessionNewResellersMutation = useMutation({
    mutationKey: ['createCheckoutSessionNewResellers'],
    mutationFn: createCheckoutSessionNewResellers,
    onSuccess(data: any) {
      setPurchaseSeatsModal(false)
      if (data?.redirectPaymentURL) {
        // open this on same page
        window.location.href = data?.redirectPaymentURL

        notification.success({
          message: 'Payment page opened',
        })

        // @ts-ignore
        window.gtag('event', 'resellerPurchaseClicked', {
          event_category: 'EditDemo.js',
          event_label: 'success',
        })
      } else if (data.quantity) {
        dispatch(
          updateReseller({
            ...resellerData,
            totalReceptionists: resellerData.totalReceptionists + data.quantity,
            unreservedReceptionists: resellerData.unreservedReceptionists + data.quantity,
          })
        )
        notification.success({
          message: `Seats Added to your subscription ${data.quantity}`,
        })
      }
    },
    onError() {
      notification.error({
        message: 'Error creating checkout session',
      })
      // @ts-ignore
      window.gtag('event', 'couldntPurchase', {
        event_category: 'EditDemo.js',
        event_label: 'error',
      })
    },
  })

  React.useEffect(() => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address)
    }
    // @ts-ignore

    window.gtag('event', 'WhitelabelReceptionistDirectoryView', {
      event_category: 'Pageview',
    })
  }, [resellerData.email_address])

  if (previewCurrentClientMutation.isPending || !resellerData || getClientsMutation.isPending) {
    return <Loading />
  }

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.MY_RECEPTIONISTS}>
      <div className="!h-full p-6">
        {/* Reserved Receptionists */}
        <div className="flex flex-row justify-between">
          <h1 className="text-2xl font-semibold mb-4">Reserved Receptionists ({clients.length})</h1>
          <Button type="default" onClick={() => setPurchaseSeatsModal(true)}>
            {/* Add some shopping cart */}
            <ShoppingCartCheckout />
            Purchase More Seats
          </Button>
        </div>
        <Row gutter={[16, 16]}>
          {clients.map((client) => (
            <Col key={client.id} xs={24} sm={12} md={8}>
              <ReceptionistCard
                business_name={client.business_name}
                number={client.number}
                password={client.password}
                minutesLimitEnabled={client.minutesLimitEnabled}
                max_usage_limit_logs={client.max_usage_limit_logs}
                onDelete={(number: any) => {
                  setCurrentReceptionist({ number })
                  setDeleteReceptionistNoticeModal(true)
                }}
                onPreview={(credentials: any) => {
                  previewCurrentClientMutation.mutate(credentials)
                }}
                onChangePassword={(number: any) => {
                  setCurrentReceptionist({ number })
                  setChangeMangedReceptionistPasswordModal(true)
                }}
                onClearLogs={(number: any) => {
                  setCurrentReceptionist({ number })
                  setClearAllLogsWarningModal(true)
                }}
                onSetMinutesLimit={(number: any) => {
                  setCurrentReceptionist({ number })
                  setResellerMinutesLimitationModal(true)
                }}
              />
            </Col>
          ))}
        </Row>

        {/* Unreserved Receptionists */}
        <h1 className="text-2xl font-semibold my-4">
          Unreserved Receptionists ({resellerData?.unreservedReceptionists || 0})
        </h1>
        <Row gutter={[16, 16]}>
          {Array.from({ length: resellerData?.unreservedReceptionists }).map((_, index) => (
            <Col key={index} xs={24} sm={12} md={8}>
              <Card title="Unreserved Receptionist" className="hover:shadow-lg transition-shadow duration-300">
                <Button
                  onClick={() => {
                    setIsRegistrationModalActive(true)
                  }}
                  type="primary"
                  block
                >
                  Reserve Receptionist
                </Button>
              </Card>
            </Col>
          ))}
        </Row>

        {/* All Modals below this point */}
        <CustomModal
          title="Success"
          isModalOpen={successConfigureReceptionistModal}
          confirmAction={() => {
            getClientsMutation.mutate(resellerData.email_address)

            setSuccessConfigureReceptionistModal(false)
          }}
          cancelAction={() => {
            getClientsMutation.mutate(resellerData.email_address)
            setSuccessConfigureReceptionistModal(false)
          }}
        >
          <Title level={4}>Congrats, your new receptionist has been onboarded successfully!</Title>
        </CustomModal>

        <CustomModal
          title="Are you sure?"
          isModalOpen={deleteReceptionistNoticeModal}
          confirmAction={() => {
            if (receptionistCurrentlySelected) {
              deleteReceptionistMutation.mutate({
                receptionist_username: receptionistCurrentlySelected.number,
                reseller_email: resellerData.email_address,
              })
            }
            setDeleteReceptionistNoticeModal(false)
          }}
          cancelAction={() => {
            setDeleteReceptionistNoticeModal(false)
          }}
          okText="Yes, Proceed"
        >
          <Title level={4}>This action cannot be undone. Are you sure you want to delete this receptionist?</Title>
        </CustomModal>

        <CustomModal
          title={null}
          footer={null}
          isModalOpen={previewDashboardModalIsOpen}
          confirmAction={() => {
            setPreviewDashboardModalIsOpen(false)
          }}
          width={'90%'}
          cancelAction={() => {
            setPreviewDashboardModalIsOpen(false)
          }}
        >
          <ReceptionistDashboard debrand={true} />
        </CustomModal>

        <CustomModal
          title={null}
          isModalOpen={changeMangedReceptionistPasswordModal}
          confirmAction={() => {
            if (receptionistCurrentlySelected && newPassword) {
              updateMangedReceptionistPassword.mutate({
                receptionist_username: receptionistCurrentlySelected.number,
                fields: { password: newPassword },
              })
            }
          }}
          cancelAction={() => {
            setChangeMangedReceptionistPasswordModal(false)
          }}
          okText="Change Password"
        >
          <Title level={4}>Change Password for username {receptionistCurrentlySelected?.number}</Title>
          <Input
            placeholder="New Password"
            onChange={(e) => {
              setNewPassword(e.target.value)
            }}
          />
        </CustomModal>

        <CustomModal
          title="Are you sure?"
          isModalOpen={clearAllLogsWarningModal}
          confirmAction={() => {
            if (receptionistCurrentlySelected) {
              clearAllLogsMutation.mutate({
                receptionist_username: receptionistCurrentlySelected.number,
                tableNames: ['voicemails', 'textLogs', 'callLogs'],
              })
            }
            setClearAllLogsWarningModal(false)
          }}
          cancelAction={() => {
            setClearAllLogsWarningModal(false)
          }}
          okText="Yes, Proceed"
        >
          <Title level={4}>
            This action cannot be undone. Are you sure you want to clear all logs for{' '}
            {receptionistCurrentlySelected?.number} receptionist?
          </Title>
        </CustomModal>

        <CustomModal
          title="Minutes Limitation"
          isModalOpen={resellerMinutesLimitationModal}
          confirmAction={() => {
            if (receptionistCurrentlySelected) {
              updateMangedReceptionistMinutes.mutate({
                receptionist_username: receptionistCurrentlySelected.number,
                fields: {
                  max_minutes: maxMinutes,
                  usage_notification_emails: [resellerData.email_address],
                  enabled: minutesLimitEnabled,
                },
              })
            }
          }}
          cancelAction={() => {
            setResellerMinutesLimitationModal(false)
          }}
          okText="Save"
        >
          <Title level={4}>Set Minutes Limitation for {receptionistCurrentlySelected?.number}</Title>

          <div className="mt-4 space-y-4">
            <div className="flex flex-col">
              <label className="font-medium">Maximum Minutes</label>
              <InputNumber
                min={1}
                value={maxMinutes}
                onChange={(value) => setMaxMinutes(value)}
                className="w-full mt-2"
              />
            </div>

            <div className="flex flex-row justify-between items-center">
              <label className="font-medium">Enable Limitation</label>
              <Switch checked={minutesLimitEnabled} onChange={(checked) => setMinutesLimitEnabled(checked)} />
            </div>
          </div>
        </CustomModal>

        <CustomModal
          title="Purchase More Seats"
          isModalOpen={purchaseSeatsModal}
          confirmAction={() => {
            // Purchase more seats
            if (seatsBuyed) {
              createCheckoutSessionNewResellersMutation.mutate({
                resellerEmail: resellerData.email_address,
                quantity: seatsBuyed,
              })
            }
          }}
          cancelAction={() => {
            setPurchaseSeatsModal(false)
          }}
          okText="Purchase"
        >
          <Title level={4}>Buy More seats for your reseller dashboard</Title>

          <Typography.Text>
            You currently have {resellerData.totalReceptionists + 1 || 'no'} seats. How many more would you like to
            purchase?{' '}
            {resellerData.totalReceptionists == undefined
              ? 'Note: for newcomers if they are new they must buy  5 seats '
              : ''}
          </Typography.Text>

          <div className="mt-4 space-y-4">
            <div className="flex flex-col">
              <label className="font-medium">Number of Seats</label>
              <InputNumber
                min={resellerData?.totalReceptionists == undefined ? 5 : 1}
                value={seatsBuyed}
                className="w-full mt-2"
                onChange={(value) => setSeatsBuyed(value)}
              />
            </div>
          </div>
        </CustomModal>

        {isRegistrationModal && (
          <>
            <div
              onClick={() => {
                setIsRegistrationModalActive(false)
              }}
              className={classes.modalOverlay}
            ></div>
            <div className={`${classes.modal} ${classes.wide}`}>
              <div
                className={classes.close}
                onClick={() => {
                  setIsRegistrationModalActive(false)
                }}
              >
                &times;
              </div>

              <RegisterPageV3
                debrand={true}
                reseller_email={resellerData.email_address}
                purchase_quotes={resellerData.purchase_quotes}
                reseller_name={resellerData.reseller_name}
                debranded_registration_modal_active_setter={setIsRegistrationModalActive}
                debrand_modal_instructions_setter={setSuccessConfigureReceptionistModal}
              />
            </div>
          </>
        )}
      </div>
    </ResellerDashboard>
  )
}
