import { Alert, Button, Form, FormInstance } from 'antd'
import './OverViewCardForm.scss'
import { ReactNode, useState } from 'react'

export const OverViewCardForm: React.FC<{
  form: FormInstance
  initialValues: any
  formItems: ReactNode
  handleSubmit?: ((values: any) => void) | undefined
  isFieldTouchReset?: boolean
  isFormLoading?: boolean
  onValuesChange?: ((changedValues: any, values: any) => void) | undefined
}> = ({ form, initialValues, formItems, handleSubmit, isFormLoading, onValuesChange }) => {
  const [isTouched, setIsTouched] = useState<boolean>(form.isFieldsTouched())

  const SaveButton = () => {
    return (
      <>
        <span>You have some unsaved changes</span>
        <Button loading={isFormLoading} htmlType="submit" className={`${baseClass}__submit-button ml-2`}>
          Submit
        </Button>
        <Button
          danger
          htmlType="submit"
          onClick={() => setIsTouched(false)}
          className={`${baseClass}__submit-button ml-2`}
        >
          Cancel
        </Button>
      </>
    )
  }

  const handleOnChnage = (changedValues: any, values: any) => {
    onValuesChange && onValuesChange(changedValues, values)
    setIsTouched(true)
  }

  const baseClass = 'overview-card-form'
  return (
    <div className={`${baseClass}`}>
      <Form
        form={form}
        onFinish={handleSubmit}
        onSubmitCapture={() => setIsTouched(false)}
        layout="vertical"
        name="overview-form"
        className={baseClass}
        initialValues={initialValues}
        onValuesChange={handleOnChnage}
      >
        {formItems}
        <Form.Item className={`${baseClass}__footer`}>
          {isTouched && <Alert type="warning" message={<SaveButton />} />}
        </Form.Item>
      </Form>
    </div>
  )
}
