import { Col, Divider, Row, Space, Tag, Typography } from "antd";
import { InfoCircleOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

type BasicInformationProps = {
  selectedEvent: any;
  getBadgeColor: (state: string) => string;
};

export const BasicInformation: React.FC<BasicInformationProps> = ({ selectedEvent, getBadgeColor }) => (
  <Space direction="vertical" size="large" style={{ width: '100%' }}>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={4}>Event Details</Title>
      </Col>
      <Col xs={24} sm={12}>
        <Space>
          <UserOutlined />
          <Text strong>Username :&nbsp;</Text>
        </Space>
        <Text>{selectedEvent.username}</Text>
      </Col>
      <Col xs={24} sm={12}>
        <Space>
          <PhoneOutlined />
          <Text strong>Recipient Number :&nbsp;</Text>
        </Space>
        <Text>{selectedEvent.recipient_number}</Text>
      </Col>
      <Col xs={24} sm={12}>
        <Space>
          <InfoCircleOutlined />
          <Text strong>Event State :&nbsp;</Text>
        </Space>
        <Tag color={getBadgeColor(selectedEvent.event_state)}>{selectedEvent.event_state}</Tag>
      </Col>
    </Row>

    <Divider style={{ margin: '0' }} />

    {selectedEvent?.state_histories?.length && (
      <>
        <Title level={4}>State History</Title>
        <Space wrap>
          {JSON.parse(selectedEvent.state_histories).map((state: string, index: number) => (
            <Tag key={index} color={getBadgeColor(state)}>
              {state}
            </Tag>
          ))}
        </Space>
      </>
    )}
  </Space>
)