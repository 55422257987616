import { Form, notification, Spin } from 'antd'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { OverViewCardForm } from '../OverviewCardForm'
import React, { useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { RefetchOptions, QueryObserverResult, useMutation } from '@tanstack/react-query'
import { updateUser } from '@/api/user'
import { LoadingOutlined } from '@ant-design/icons'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { User } from '@/types/TAccounts'

export const KnowledgeBaseForm: React.FC<{
  business_information?: string
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
}> = ({ business_information, refetch }) => {
  const [form] = Form.useForm<{
    business_information: string
  }>()
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch()
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      })
      setIsFieldTouchReset(false)
    },
  })

  const handleSubmit = async (value: any) => {
    await mutation.mutateAsync({
      business_information: value.business_information,
    })
    dispatch(closeModal())
  }

  const FormItems = () => (
    <Form.Item
      label={
        <LabelWithDescription
          customClassName="mt-2 "
          label="Business Information"
          description="Tell the AI about your business. Please input detailed information about your business such as its history, mission, and services. Don't worry about formatting. The AI can effectively interpret it and build a knowledge base around it. Maximum 20 pages of text!"
        />
      }
      name="business_information"
      className={`${baseClass}__input-item`}
    >
      <TextArea rows={8} placeholder="Please enter your business information" />
    </Form.Item>
  )

  const baseClass = 'question-and-answer-form'
  return (
    <div className={`${baseClass}`}>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            business_information,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  )
}
