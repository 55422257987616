import { TAnalytics, TCallDuration } from '@/types/TAnalytics'
import { Area } from '@ant-design/plots'

export const WeeklyCallDuration = ({ analyticsData }: TAnalytics) => {
  const config = {
    data: analyticsData,
    xField: 'duration',
    yField: 'value',
    style: {
      fill: 'linear-gradient(90deg, rgba(110, 121, 214, 1) 0%, rgba(51, 51, 153, 1) 100%)',
    },
    axis: {
      y: { labelFormatter: (v: number) => `${v} calls` },
      x: { labelFormatter: (v: number) => `${v} seconds` },
    },
    tooltip: {
      title: (data: TCallDuration) => `Duration: ${data.duration} seconds`,
      items: [{ name: 'Total Calls', channel: 'y' }],
    },
  }
  return <Area {...config} />
}
