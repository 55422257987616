import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import classes from '../../../DashboardV2.module.css'
import { TextareaAutosize } from '@material-ui/core'
import CreatableSelect from 'react-select/creatable'
import { countries } from '../../../countriesData'
import validateEmail from '../../../utils/is-valid-email'
import isEmpty from '../../../utils/is-empty'
import cloneDeep from 'clone-deep'
import { Button, notification } from 'antd'

const Notifications = ({
  username,
  callNotification,
  textNotification,
  voicemailNotification,
  serverURL,
  setVoicemailNotification,
  themeColor,
  setTextNotification,
  setCallNotification,
  callNotifEmailRecepients,
  callNotifTextRecepients,
  setCallNotifEmailRecepients,
  setCallNotifTextRecepients,
  textNotifEmailRecepients,
  textNotifTextRecepients,
  setTextNotifEmailRecepients,
  setTextNotifTextRecepients,
  voicemailTextNotification,
  setVoicemailTextNotification,
  notificationScenarios,
  setNotificationScenarios,
}) => {
  const [isCallNotifsModalActive, setIsCallNotifsModalActive] = useState(false)
  const [isTextNotifsModalActive, setIsTextNotifsModalActive] = useState(false)
  const [isScenarioNotifsModalActive, setIsScenarioNotifsModalActive] = useState(false)

  const [notifScenarioDirty, setNotifScenarioDirty] = useState(false)
  const handleScenarioDescriptionChange = (e, idx) => {
    setNotificationScenarios((prev) => {
      const newState = cloneDeep(prev)

      newState[idx].scenario = e.target.value

      return newState
    })
  }
  const handleScenarioEmailRecepientsChange = (e, idx) => {
    setNotificationScenarios((prev) => {
      const newState = cloneDeep(prev)

      newState[idx].email_recipients = e

      return newState
    })
  }

  const handleScenarioTextRecepientsChange = (e, idx) => {
    setNotificationScenarios((prev) => {
      const newState = cloneDeep(prev)

      newState[idx].text_recipients = e

      return newState
    })
  }
  const addNotifScenario = () => {
    setNotificationScenarios((prev) => [...prev, { scenario: '', email_recipients: [], text_recipients: [] }])
  }
  const removeNotifScenario = (e, idx) => {
    setNotificationScenarios((prev) => {
      const newState = cloneDeep(prev)
      newState.splice(idx, 1)
      return newState
    })
  }

  useEffect(() => {}, [])

  return (
    <>
      {/* <form
                style={{ display: "block", width: "100%" }}
                onSubmit={async (e) => {
                  e.preventDefault();
                  // Handle form submission here, e.g., send data to the server

                  const requestData = {
                    username: username,
                    callNotifications: callNotification,
                    textNotifications: textNotification,
                    voicemailNotifications: voicemailNotification,
                    notificationRecipientEmail: notificationRecipientEmail,
                    textingNotificationEnabled: notificationEnabled,
                    textingNotificationEmail: notificationEmail,
                  };

                  try {
                    const response = await fetch(
                      `${serverURL}/update-notification-settings`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestData),
                      }
                    );

                    if (!response.ok) {
                      throw new Error(
                        "Update Notification Settings response was not ok"
                      );
                    }

                    const data = await response.json();
                    console.log("Settings updated successfully", data);
                    toast("Successfully updated notification settings!");
                  } catch (error) {
                    toast("Error updating notification settings");
                    console.error("Error updating settings", error);
                  }
                }}
              > */}

      <>
        {isCallNotifsModalActive && (
          <div
            onClick={() => setIsCallNotifsModalActive(false)}
            className={classes.modalOverlay}
            style={{
              opacity: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            }}
          ></div>
        )}
        <div
          className={classes.box + ' ' + classes.modal + ' ' + (isCallNotifsModalActive && classes.active)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className={classes.close} onClick={() => setIsCallNotifsModalActive(false)}>
            &times;
          </div>

          <h1 className="font-semibold">Call Notification Settings</h1>

          <form
            onSubmit={async (e) => {
              e.preventDefault()
              // Handle form submission here, e.g., send data to the server

              // const requestData = {
              //   username: username,
              //   callNotifications: callNotification,
              //   textNotifications: textNotification,
              //   voicemailNotifications: voicemailNotification,
              //   notificationRecipientEmail: notificationRecipientEmail,
              // };
              const requestData = {
                username: username,
                emailRecipients: callNotifEmailRecepients.map((el) => el.value),
                textRecipients: callNotifTextRecepients.map((el) => el.value),
                textNotification,
                callNotification,
                voicemailNotification,
              }

              if (requestData.emailRecipients.some((el) => !validateEmail(el))) {
                toast.error('One or more email recipients is invalid')
                return false
              }
              if (requestData.textRecipients.some((el) => typeof el !== 'string' || el.trim().length === 0)) {
                toast.error('One or more text recepients are empty')
                return false
              }
              if (requestData.textRecipients.some((el) => el.length !== 12)) {
                toast.error('One or more text recipients does not have a valid length')
                return false
              }
              if (
                requestData.textRecipients.some(
                  (el) => el[0].charCodeAt(0) !== '+'.charCodeAt(0) || el[1].charCodeAt(0) !== '1'.charCodeAt(0)
                )
              ) {
                toast.error('One or more text recipients does not start with +1')
                return false
              }
              if (requestData.textRecipients.some((el) => isNaN(Number(el.replace('+1', ''))))) {
                toast.error('One or more text recipients are not valid')
                return false
              }

              try {
                const response = await fetch(`${serverURL}/update-call-notification-settings`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })

                if (!response.ok) {
                  throw new Error('Update Notification Settings response was not ok')
                }

                const data = await response.json()
                console.log('Settings updated successfully', data)
                notification.success({
                  key: 'form-success',
                  message: 'Successfully updated notification settings!',
                  duration: 3,
                })
                setIsCallNotifsModalActive(false)
              } catch (error) {
                notification.success({
                  key: 'form-error',
                  message: 'Error updating notification settings!',
                  duration: 3,
                })
                console.error('Error updating settings', error)
              }
            }}
          >
            <div style={{ margin: '1rem 0' }}>
              {/* <input
                        type="checkbox"
                        Text
                        checked={voicemailNotification}
                        onChange={() =>
                          setVoicemailNotification(!voicemailNotification)
                        }
                      />
                      <label htmlFor="voicemailSwitch">
                        Receive Voicemail Notifications
                      </label> */}
              <label
                className="checkbox-item"
                style={{
                  marginTop: '1rem',
                  display: 'block',
                }}
              >
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    Text
                    checked={voicemailNotification}
                    onChange={() => setVoicemailNotification(!voicemailNotification)}
                  />
                  <span className={classes.slider}></span>
                </label>
                <div
                  style={{
                    marginLeft: '.5rem',
                    display: 'inline-block',
                  }}
                >
                  Receive Voicemail Notifications
                </div>
              </label>
            </div>

            <div style={{ margin: '1rem 0' }}>
              {/* <input
                        type="checkbox"
                        id="textSwitch"
                        checked={textNotification}
                        onChange={() => setTextNotification(!textNotification)}
                      />
                      <label htmlFor="textSwitch">
                        Receive Text Notifications
                      </label> */}

              <label
                className="checkbox-item"
                style={{
                  marginTop: '1rem',
                  display: 'block',
                }}
              >
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    id="textSwitch"
                    checked={textNotification}
                    onChange={() => setTextNotification(!textNotification)}
                  />
                  <span className={classes.slider}></span>
                </label>
                <div
                  style={{
                    marginLeft: '.5rem',
                    display: 'inline-block',
                  }}
                >
                  Receive Text Notifications
                </div>
              </label>
            </div>

            <div style={{ margin: '1rem 0' }}>
              {/* <input
                        type="checkbox"
                        id="callSwitch"
                        checked={callNotification}
                        onChange={() => setCallNotification(!callNotification)}
                      />
                      <label htmlFor="callSwitch">
                        Receive New Call Notifications
                      </label> */}

              <label
                className="checkbox-item"
                style={{
                  marginTop: '1rem',
                  display: 'block',
                }}
              >
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    id="callSwitch"
                    checked={callNotification}
                    onChange={() => setCallNotification(!callNotification)}
                  />
                  <span className={classes.slider}></span>
                </label>
                <div
                  style={{
                    marginLeft: '.5rem',
                    display: 'inline-block',
                  }}
                >
                  Receive New Call Notifications
                </div>
              </label>
            </div>
            <div
              style={{
                margin: '1rem 0',
              }}
            >
              <label htmlFor="notificationRecipientEmail">Email recepient/s</label>

              <p
                style={{
                  margin: '.25rem 0',
                  fontSize: '12px',
                }}
              >
                You can add multiple recepients by pressing enter each time you enter a recepient
              </p>
              {/* <input
                        type="email"
                        id="notificationRecipientEmail"
                        value={notificationRecipientEmail}
                        style={{
                          borderRadius: "5px",
                          marginLeft: "1rem",
                          width: "20%",
                          padding: "0.5rem",
                          flexGrow: 1,
                        }}
                        onChange={(e) =>
                          setNotificationRecipientEmail(e.target.value)
                        }
                        required
                      /> */}

              <div
                style={{
                  marginTop: '.5rem',
                }}
              >
                <CreatableSelect
                  isClearable
                  isMulti
                  value={callNotifEmailRecepients}
                  onChange={(val) => setCallNotifEmailRecepients(val)}
                  placeholder="a@b.com, c@d.com"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: '100%',
                      boxShadow: 'none',
                      borderWidth: '1px',
                      borderRadius: '0',
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      display: 'none',
                    }),
                    // menu: (baseStyles) => ({
                    //   ...baseStyles,
                    //   display: "none",
                    // }),
                  }}
                ></CreatableSelect>
              </div>
            </div>

            {/* <div
              style={{
                marginTop: ".5rem",
              }}
            >
              <label htmlFor="notificationRecipientEmail">
                Text Recepient/s
              </label>

              <p
                style={{
                  margin: ".25rem 0",
                  fontSize: "12px",
                }}
              >
                You can add multiple recepients by pressing enter each time you
                enter a recepient
              </p>

              <CreatableSelect
                isClearable
                isMulti
                value={callNotifTextRecepients}
                onChange={(val) => setCallNotifTextRecepients(val)}
                placeholder="+1XXXXXXXXXX, +1XXXXXXXXXX"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    boxShadow: "none",
                    borderWidth: "1px",
                    borderRadius: "0",
                  }),
                  indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    display: "none",
                  }),
                  // menu: (baseStyles) => ({
                  //   ...baseStyles,
                  //   display: "none",
                  // }),
                }}
              ></CreatableSelect>
            </div> */}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                type="submit"
                style={{
                  background: themeColor,
                  color: 'white',
                  padding: '0.5rem 1rem',
                  borderRadius: '10px',
                  border: 'none',
                  marginTop: '1.5rem',
                  fontSize: '1rem',
                  fontWeight: 'semibold',
                  cursor: 'pointer',
                  transition: 'background 0.3s',
                }}
              >
                Save Settings
              </button>
            </div>
          </form>
        </div>
      </>
      <>
        {isTextNotifsModalActive && (
          <div
            onClick={() => setIsTextNotifsModalActive(false)}
            className={classes.modalOverlay}
            style={{
              opacity: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            }}
          ></div>
        )}
        <div
          className={classes.box + ' ' + classes.modal + ' ' + (isTextNotifsModalActive && classes.active)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className={classes.close} onClick={() => setIsTextNotifsModalActive(false)}>
            &times;
          </div>

          <h1 className="font-semibold">Texting Platform Notification Settings</h1>

          <form
            onSubmit={async (e) => {
              e.preventDefault()
              // Handle form submission here, e.g., send data to the server

              // const requestData = {
              //   username: username,
              //   callNotifications: callNotification,
              //   textNotifications: textNotification,
              //   voicemailNotifications: voicemailNotification,
              //   notificationRecipientEmail: notificationRecipientEmail,
              // };
              const requestData = {
                username: username,
                enabled: voicemailTextNotification,
                textNotifEmailRecepients: textNotifEmailRecepients.map((el) => el.value),
                textNotifTextRecepients: textNotifTextRecepients.map((el) => el.value),
              }

              for (const iterator of requestData.textNotifEmailRecepients) {
                if (!validateEmail(iterator)) {
                  toast.error('One of the emails you entered is not valid')
                  return
                }

                if (iterator?.text_recipients?.some((el) => typeof el !== 'string' || el.trim().length === 0)) {
                  toast.error('One or more text recepients are empty')
                  return false
                }
                if (iterator?.text_recipients?.some((el) => el.length !== 12)) {
                  toast.error('One or more text recipients does not have a valid length')
                  return false
                }
                if (
                  iterator?.text_recipients?.some(
                    (el) => el[0].charCodeAt(0) !== '+'.charCodeAt(0) || el[1].charCodeAt(0) !== '1'.charCodeAt(0)
                  )
                ) {
                  toast.error('One or more text recipients does not start with +1')
                  return false
                }
                if (iterator?.text_recipients?.some((el) => isNaN(Number(el.replace('+1', ''))))) {
                  toast.error('One or more text recipients are not valid')
                  return false
                }
              }

              try {
                const response = await fetch(`${serverURL}/update-text-notification-settings`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })

                if (!response.ok) {
                  throw new Error('Update Notification Settings response was not ok')
                }

                const data = await response.json()
                console.log('Settings updated successfully', data)
                notification.success({
                  key: 'form-success',
                  message: 'Successfully updated notification settings!',
                  duration: 3,
                })

                setIsTextNotifsModalActive(false)
              } catch (error) {
                notification.success({
                  key: 'form-error',
                  message: 'Error updating notification settings!',
                  duration: 3,
                })
                console.error('Error updating settings', error)
              }
            }}
          >
            <div style={{ margin: '1rem 0' }}>
              {/* <input
                        type="checkbox"
                        Text
                        checked={voicemailNotification}
                        onChange={() =>
                          setVoicemailNotification(!voicemailNotification)
                        }
                      />
                      <label htmlFor="voicemailSwitch">
                        Receive Voicemail Notifications
                      </label> */}
              <label
                className="checkbox-item"
                style={{
                  marginTop: '1rem',
                  display: 'block',
                }}
              >
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    id="voicemailTextSwitch"
                    checked={voicemailTextNotification}
                    onChange={() => setVoicemailTextNotification(!voicemailTextNotification)}
                  />
                  <span className={classes.slider}></span>
                </label>
                <div
                  style={{
                    marginLeft: '.5rem',
                    display: 'inline-block',
                  }}
                >
                  {voicemailTextNotification ? 'On' : 'Off'}
                </div>
              </label>
            </div>

            <div
              style={{
                margin: '1rem 0',
              }}
            >
              <label htmlFor="notificationRecipientEmail">Email recepient/s</label>

              <p
                style={{
                  margin: '.25rem 0',
                  fontSize: '12px',
                }}
              >
                You can add multiple recepients by pressing enter each time you enter a recepient
              </p>
              {/* <input
                        type="email"
                        id="notificationRecipientEmail"
                        value={notificationRecipientEmail}
                        style={{
                          borderRadius: "5px",
                          marginLeft: "1rem",
                          width: "20%",
                          padding: "0.5rem",
                          flexGrow: 1,
                        }}
                        onChange={(e) =>
                          setNotificationRecipientEmail(e.target.value)
                        }
                        required
                      /> */}

              <div
                style={{
                  marginTop: '.5rem',
                }}
              >
                <CreatableSelect
                  isClearable
                  isMulti
                  value={textNotifEmailRecepients}
                  onChange={(val) => setTextNotifEmailRecepients(val)}
                  placeholder="a@b.com, c@d.com"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: '100%',
                      boxShadow: 'none',
                      borderWidth: '1px',
                      borderRadius: '0',
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                      display: 'none',
                    }),
                    // menu: (baseStyles) => ({
                    //   ...baseStyles,
                    //   display: "none",
                    // }),
                  }}
                ></CreatableSelect>
              </div>
            </div>

            {/* <div
              style={{
                marginTop: ".5rem",
              }}
            >
              <label htmlFor="notificationRecipientEmail">
                Text Recepient/s
              </label>

              <p
                style={{
                  margin: ".25rem 0",
                  fontSize: "12px",
                }}
              >
                You can add multiple recepients by pressing enter each time you
                enter a recepient
              </p>

              <CreatableSelect
                isClearable
                isMulti
                value={textNotifTextRecepients}
                onChange={(val) => setTextNotifTextRecepients(val)}
                placeholder="+1XXXXXXXXXX, +1XXXXXXXXXX"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    boxShadow: "none",
                    borderWidth: "1px",
                    borderRadius: "0",
                  }),
                  indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    display: "none",
                  }),
                  // menu: (baseStyles) => ({
                  //   ...baseStyles,
                  //   display: "none",
                  // }),
                }}
              ></CreatableSelect>
            </div> */}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                type="submit"
                style={{
                  background: themeColor,
                  color: 'white',
                  padding: '0.5rem 1rem',
                  borderRadius: '10px',
                  border: 'none',
                  marginTop: '1.5rem',
                  fontSize: '1rem',
                  fontWeight: 'semibold',
                  cursor: 'pointer',
                  transition: 'background 0.3s',
                }}
              >
                Save Settings
              </button>
            </div>
          </form>

          {/* <p>
                    Please enter the email address you'd like your password
                    reset information sent to
                  </p>

                  <form onSubmit={requestResetLinkHandler}>
                    <div style={{ maxWidth: "300px", margin: "auto" }}>
                      <label
                        style={{
                          textAlign: "left",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        Enter email address
                      </label>

                      <input
                        style={{
                          borderRadius: "8px",
                          padding: "8px",
                          fontFamily: "quicksand, sans-serif",
                          width: "100%",
                          border: "1px solid black",
                        }}
                        value={forgottenPasswordEmail}
                        onChange={(e) =>
                          setForgottenPasswordEmail(e.target.value)
                        }
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      {
                        <ReCaptcha
                          sitekey={"6LfFf64lAAAAADNRXnw6Vq0RdkARDgrfHsZc6-Ir"}
                          ref={captcha2Ref}
                        />
                      }
                    </div>

                    <button
                      type="submit"
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#85bb65",
                        padding: "8px",
                        borderRadius: "8px",
                        marginRight: "5px",
                        border: "none",
                        cursor: "pointer",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      Request reset link
                    </button>
                  </form> */}
        </div>
      </>
      <>
        {isScenarioNotifsModalActive && (
          <div
            onClick={() => setIsScenarioNotifsModalActive(false)}
            className={classes.modalOverlay}
            style={{
              opacity: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            }}
          ></div>
        )}
        <div
          className={classes.box + ' ' + classes.modal + ' ' + (isScenarioNotifsModalActive && classes.active)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className={classes.close} onClick={() => setIsScenarioNotifsModalActive(false)}>
            &times;
          </div>

          <h1 className="font-semibold">Scenario based AI Notifications</h1>

          <form
            onSubmit={async (e) => {
              e.preventDefault()
              const scenarios = notificationScenarios.map((el) => {
                return {
                  ...el,
                  email_recipients: el.email_recipients.map((el) => el.value),
                  text_recipients: el.text_recipients.map((el) => el.value),
                }
              })
              const requestData = {
                username: username,
                scenarios,
              }

              for (const iterator of scenarios) {
                if (isEmpty(iterator.scenario)) {
                  toast.error('Please enter the scenario')
                  return
                }
                console.log(iterator)

                if (isEmpty(iterator.email_recipients) && isEmpty(iterator.text_recipients)) {
                  toast.error('Please enter at-least one email or text recepient')
                  return
                }

                if (iterator?.email_recipients?.some((el) => !validateEmail(el))) {
                  toast.error('One of the emails you entered is not valid')
                  return
                }

                if (iterator?.text_recipients?.some((el) => typeof el !== 'string' || el.trim().length === 0)) {
                  toast.error('One or more text recepients are empty')
                  return false
                }
                if (iterator?.text_recipients?.some((el) => el.length !== 12)) {
                  toast.error('One or more text recipients does not have a valid length')
                  return false
                }
                if (
                  iterator?.text_recipients?.some(
                    (el) => el[0].charCodeAt(0) !== '+'.charCodeAt(0) || el[1].charCodeAt(0) !== '1'.charCodeAt(0)
                  )
                ) {
                  toast.error('One or more text recipients does not start with +1')
                  return false
                }
                if (iterator?.text_recipients?.some((el) => isNaN(Number(el.replace('+1', ''))))) {
                  toast.error('One or more text recipients are not valid')
                  return false
                }
              }

              try {
                const response = await fetch(`${serverURL}/update-scenario-notification-settings`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestData),
                })

                if (!response.ok) {
                  throw new Error('Update Notification Settings response was not ok')
                }

                const data = await response.json()
                console.log('Settings updated successfully', data)
                notification.success({
                  key: 'form-success',
                  message: 'Successfully updated notification settings!',
                  duration: 3,
                })

                setIsScenarioNotifsModalActive(false)
              } catch (error) {
                notification.error({
                  key: 'form-success',
                  message: 'Error updating notification settings!',
                  duration: 3,
                })
                console.error('Error updating settings', error)
              }
            }}
          >
            {notificationScenarios.map((el, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '1rem',
                    border: '2px dashed #ccc',
                    borderRadius: '10px',
                    padding: '1rem',
                  }}
                >
                  <div>
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: '100%',
                        fontSize: '1.2em',
                        marginBottom: '0.5rem',
                      }}
                    >
                      Scenario Description
                      <p
                        style={{
                          textAlign: 'left',
                          fontSize: '12px',
                          margin: '0',
                        }}
                      >
                        Using plain English, define when you would like this notification to be triggered. After a call,
                        our AI will analyze the call transcript. If the call transcript contains the scenario you define
                        here, the notification will be triggered.
                      </p>
                    </label>

                    <TextareaAutosize
                      type="text"
                      value={el.scenario}
                      onChange={(event) => {
                        handleScenarioDescriptionChange(event, index)
                      }}
                      style={{
                        padding: '0.5rem',
                        borderRadius: '10px',
                        marginBottom: '0.4rem',
                        width: '100%',
                        border: '1px solid #b3b3b3',
                      }}
                      minRows={2}
                      placeholder="e.g. If the caller mentions anything related to billing, refunds, or payments"
                    />
                  </div>

                  <div>
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: '100%',
                        fontSize: '1.2em',
                        marginBottom: '0.5rem',
                      }}
                    >
                      Email Recipients
                    </label>

                    <CreatableSelect
                      isClearable
                      isMulti
                      value={el.email_recipients}
                      onChange={(val) => handleScenarioEmailRecepientsChange(val, index)}
                      placeholder="a@b.com, c@d.com"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: '100%',
                          boxShadow: 'none',
                          borderWidth: '1px',
                          fontSize: '14px',
                          borderRadius: '10px',
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                        // menu: (baseStyles) => ({
                        //   ...baseStyles,
                        //   display: "none",
                        // }),
                      }}
                    ></CreatableSelect>
                  </div>

                  {/* <div style={{ margin: ".5rem 0 1rem" }}>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        maxWidth: "100%",
                        fontSize: "1.2em",
                        margin: "0.5rem 0",
                      }}
                    >
                      Text Recepients
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "12px",
                          margin: "0",
                        }}
                      >
                        Enter the number in the following format: +1xxxxxxxxxx
                      </p>
                    </label>

                    <CreatableSelect
                      isClearable
                      isMulti
                      value={el.text_recipients}
                      onChange={(val) =>
                        handleScenarioTextRecepientsChange(val, index)
                      }
                      placeholder="a@b.com, c@d.com"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          boxShadow: "none",
                          borderWidth: "1px",
                          fontSize: "14px",
                          borderRadius: "10px",
                        }),
                        indicatorsContainer: (baseStyles) => ({
                          ...baseStyles,
                          display: "none",
                        }),
                        // menu: (baseStyles) => ({
                        //   ...baseStyles,
                        //   display: "none",
                        // }),
                      }}
                    ></CreatableSelect>
                  </div> */}

                  <button onClick={(e) => removeNotifScenario(e, index)}>Remove</button>
                </div>
              )
            })}

            <button
              type="button"
              style={{
                color: '#ffffff',
                backgroundColor: '#85bb65',
                padding: '8px',
                borderRadius: '8px',
                marginRight: '5px',
                border: 'none',
                cursor: 'pointer',
                marginTop: '10px',
                width: '100%',
              }}
              onClick={addNotifScenario}
            >
              Add scenario
            </button>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                type="submit"
                style={{
                  background: themeColor,
                  color: 'white',
                  padding: '0.5rem 1rem',
                  borderRadius: '10px',
                  border: 'none',
                  marginTop: '1.5rem',
                  fontSize: '1rem',
                  fontWeight: 'semibold',
                  cursor: 'pointer',
                  transition: 'background 0.3s',
                }}
              >
                Save Settings
              </button>
            </div>
          </form>
        </div>
      </>

      <div
        style={{
          display: window.innerWidth >= 1150 ? 'grid' : 'block',
          width: '100%',
          gridTemplateColumns: 'repeat(2, minmax(450px, 1fr))',
          gap: '2rem',
        }}
      >
        <div
          style={{
            padding: '1.5rem',
            fontFamily: 'Quicksand, sans-serif',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h2 className="font-semibold">Call Notification Settings</h2>

            <p>
              Automatically send detailed call transcripts via text or email after your receptionist answers a new call
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="primary"
              // style={{
              //   background: themeColor,
              //   color: "white",
              //   padding: ".5rem 1.25rem",
              //   borderRadius: "5px",
              //   border: "none",
              //   marginTop: "1.5rem",
              //   fontSize: "1rem",
              //   cursor: "pointer",
              //   transition: "background 0.3s",
              // }}
              onClick={() => setIsCallNotifsModalActive(true)}
            >
              Setup
            </Button>
          </div>
          {/* <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      // Handle form submission here, e.g., send data to the server

                      const requestData = {
                        username: username,
                        callNotifications: callNotification,
                        textNotifications: textNotification,
                        voicemailNotifications: voicemailNotification,
                        notificationRecipientEmail: notificationRecipientEmail,
                      };

                      try {
                        const response = await fetch(
                          `${serverURL}/update-notification-settings`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify(requestData),
                          }
                        );

                        if (!response.ok) {
                          throw new Error(
                            "Update Notification Settings response was not ok"
                          );
                        }

                        const data = await response.json();
                        console.log("Settings updated successfully", data);
                        toast("Successfully updated notification settings!");
                      } catch (error) {
                        toast("Error updating notification settings");
                        console.error("Error updating settings", error);
                      }
                    }}
                  >
                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        Text
                        checked={voicemailNotification}
                        onChange={() =>
                          setVoicemailNotification(!voicemailNotification)
                        }
                      />
                      <label htmlFor="voicemailSwitch">
                        Receive Voicemail Notifications
                      </label>
                    </div>

                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        id="textSwitch"
                        checked={textNotification}
                        onChange={() => setTextNotification(!textNotification)}
                      />
                      <label htmlFor="textSwitch">
                        Receive Text Notifications
                      </label>
                    </div>
                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        id="callSwitch"
                        checked={callNotification}
                        onChange={() => setCallNotification(!callNotification)}
                      />
                      <label htmlFor="callSwitch">
                        Receive New Call Notifications
                      </label>
                    </div>
                    <div
                      style={{
                        margin: "1rem 0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label htmlFor="notificationRecipientEmail">
                        Notification Recipient Email
                      </label>
                      <input
                        type="email"
                        id="notificationRecipientEmail"
                        value={notificationRecipientEmail}
                        style={{
                          borderRadius: "5px",
                          marginLeft: "1rem",
                          width: "20%",
                          padding: "0.5rem",
                          flexGrow: 1,
                        }}
                        onChange={(e) =>
                          setNotificationRecipientEmail(e.target.value)
                        }
                        required
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        style={{
                          background: themeColor,
                          color: "white",
                          padding: "1rem 2rem",
                          borderRadius: "10px",
                          border: "none",
                          marginTop: "1.5rem",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          cursor: "pointer",
                          transition: "background 0.3s",
                        }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </form> */}
        </div>
        <div
          style={{
            padding: '1.5rem',
            fontFamily: 'Quicksand, sans-serif',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h2 className="font-semibold">Text Notification Settings</h2>

            <p>Receive email notifications when someone texts your AI receptionist number</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="primary"
              // style={{
              //   background: themeColor,
              //   color: 'white',
              //   padding: '.5rem 1.25rem',
              //   borderRadius: '5px',
              //   border: 'none',
              //   marginTop: '1.5rem',
              //   fontSize: '1rem',
              //   cursor: 'pointer',
              //   transition: 'background 0.3s',
              // }}
              onClick={() => setIsTextNotifsModalActive(true)}
            >
              Setup
            </Button>
          </div>
          {/* <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      // Handle form submission here, e.g., send data to the server

                      const requestData = {
                        username: username,
                        textingNotificationEnabled: notificationEnabled,
                        textingNotificationEmail: notificationEmail,
                      };

                      try {
                        const response = await fetch(
                          `${serverURL}/update-texting-notification-settings`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify(requestData),
                          }
                        );

                        if (!response.ok) {
                          throw new Error(
                            "Update Notification Settings response was not ok"
                          );
                        }

                        const data = await response.json();
                        console.log("Settings updated successfully", data);
                        toast("Successfully updated notification settings!");
                      } catch (error) {
                        toast("Error updating notification settings");
                        console.error("Error updating settings", error);
                      }
                    }}
                  >
                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        id="notificationEnabled"
                        checked={notificationEnabled}
                        onChange={(e) =>
                          setNotificationEnabled(e.target.checked)
                        }
                      />
                      <label htmlFor="notificationEnabled">Enabled</label>
                    </div>

                    {notificationEnabled && (
                      <div
                        style={{
                          margin: "1rem 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="notificationRecipientEmail">
                          Email address
                        </label>
                        <input
                          type="email"
                          id="notificationEmail"
                          value={notificationEmail}
                          style={{
                            borderRadius: "5px",
                            marginLeft: "1rem",
                            width: "20%",
                            padding: "0.5rem",
                            flexGrow: 1,
                          }}
                          onChange={(e) => setNotificationEmail(e.target.value)}
                          required
                        />
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        style={{
                          background: themeColor,
                          color: "white",
                          padding: "1rem 2rem",
                          borderRadius: "10px",
                          border: "none",
                          marginTop: "1.5rem",
                          marginLeft: "1.5rem",
                          marginRight: "1.5rem",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          cursor: "pointer",
                          transition: "background 0.3s",
                        }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </form> */}
        </div>
      </div>
      <div
        style={{
          marginTop: '2rem',
          width: '100%',
          padding: '1.5rem',
          fontFamily: 'Quicksand, sans-serif',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gridColumn: 'span 1 / span 1',
        }}
      >
        <div>
          <h2 className="font-semibold">Scenario based AI notifications</h2>

          <p>
            Define scenarios using plain english to configure customized, post-call notifications. After a call, our AI
            will analyze the call transcript. If any of your scenarios are met, the relevant party will receive a
            notification via email.
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="primary"
            // style={{
            //   background: themeColor,
            //   color: 'white',
            //   padding: '.5rem 1.25rem',
            //   borderRadius: '5px',
            //   border: 'none',
            //   marginTop: '1.5rem',
            //   fontSize: '1rem',
            //   cursor: 'pointer',
            //   transition: 'background 0.3s',
            // }}
            onClick={() => setIsScenarioNotifsModalActive(true)}
          >
            Setup
          </Button>
        </div>
        {/* <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      // Handle form submission here, e.g., send data to the server

                      const requestData = {
                        username: username,
                        textingNotificationEnabled: notificationEnabled,
                        textingNotificationEmail: notificationEmail,
                      };

                      try {
                        const response = await fetch(
                          `${serverURL}/update-texting-notification-settings`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify(requestData),
                          }
                        );

                        if (!response.ok) {
                          throw new Error(
                            "Update Notification Settings response was not ok"
                          );
                        }

                        const data = await response.json();
                        console.log("Settings updated successfully", data);
                        toast("Successfully updated notification settings!");
                      } catch (error) {
                        toast("Error updating notification settings");
                        console.error("Error updating settings", error);
                      }
                    }}
                  >
                    <div style={{ margin: "1rem 0" }}>
                      <input
                        type="checkbox"
                        id="notificationEnabled"
                        checked={notificationEnabled}
                        onChange={(e) =>
                          setNotificationEnabled(e.target.checked)
                        }
                      />
                      <label htmlFor="notificationEnabled">Enabled</label>
                    </div>

                    {notificationEnabled && (
                      <div
                        style={{
                          margin: "1rem 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="notificationRecipientEmail">
                          Email address
                        </label>
                        <input
                          type="email"
                          id="notificationEmail"
                          value={notificationEmail}
                          style={{
                            borderRadius: "5px",
                            marginLeft: "1rem",
                            width: "20%",
                            padding: "0.5rem",
                            flexGrow: 1,
                          }}
                          onChange={(e) => setNotificationEmail(e.target.value)}
                          required
                        />
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        style={{
                          background: themeColor,
                          color: "white",
                          padding: "1rem 2rem",
                          borderRadius: "10px",
                          border: "none",
                          marginTop: "1.5rem",
                          marginLeft: "1.5rem",
                          marginRight: "1.5rem",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          cursor: "pointer",
                          transition: "background 0.3s",
                        }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </form> */}
      </div>
      {/* <button
                type="submit"
                style={{
                  background: themeColor,
                  color: "white",
                  padding: "1rem 2rem",
                  borderRadius: "10px",
                  border: "none",
                  marginTop: "1.5rem",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  transition: "background 0.3s",
                  margin: "0 auto",
                  display: "block",
                }}
              >
                Save Settings
              </button> */}
      {/* </form> */}
    </>
  )
}

export default Notifications
