import { Button, message, Card } from 'antd'
import { uploadCsvFn } from '@/api/novi/noviLeads'
import { IoChevronBack } from 'react-icons/io5'
import UploadCSVValidator from './UploadCSVValidator'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getSelectedCampaign } from '@/store/novi/user/selector'
import { isValidPhone } from '@/utils/helper'

interface UploadCSVProps {
  onClose: () => void
  campaignId: string | undefined
}

const UploadCSV: React.FC<UploadCSVProps> = ({ onClose, campaignId }) => {
  const selectedCampaign = useAppSelector(getSelectedCampaign)
  const requiredColumns = ['phone_number']

  const variables = selectedCampaign?.variables ? JSON.parse(selectedCampaign.variables) : []
  const requiredColumnsWithVariables = [...requiredColumns, ...Object.keys(variables)]

  const handleUpload = async (file: File) => {
    if (file.type === 'text/csv') {
      try {
        if (campaignId) {
          await uploadCsvFn(file, campaignId)
          onClose()
        } else {
          throw new Error('Campaign ID is undefined')
        }
      } catch (error) {
        message.error('Upload failed!')
      }
    } else {
      message.error('Please upload a valid CSV file.')
    }
  }

  const validateRow = (row: any) => {
    const errors = []

    if (!isValidPhone(row.phone_number)) {
      errors.push('Invalid phone number format')
    }

    return {
      isValid: errors.length === 0,
      errors,
    }
  }

  return (
    <Card>
      <Button
        type="link"
        icon={<IoChevronBack />}
        onClick={onClose}
        style={{ marginBottom: '24px', color: '#1890ff', fontWeight: 500 }}
      >
        Choose another method
      </Button>
      <UploadCSVValidator
        onClose={onClose}
        onSubmit={handleUpload}
        requiredColumns={requiredColumnsWithVariables}
        validateRow={validateRow}
      />
    </Card>
  )
}

export default UploadCSV
