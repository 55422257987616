import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { UseMutationResult } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { CustomModal } from '../CustomModal'
import { Button, Form, Input } from 'antd'
import { MIN_10_WORDS_REGEX } from 'src/constants'

const CancelSubReasonModal = ({ mutation }: { mutation: UseMutationResult<{}, Error, any, unknown> }) => {
  const [form] = Form.useForm<{
    reason: string
  }>()

  const dispatch = useAppDispatch()

  const handleSubmit = async (values: { reason: string }) => {
    try {
      await mutation.mutateAsync({ reason: values.reason })
      dispatch(closeModal())
    } catch (e) {
      console.error(e)
    }
  }

  const baseClass = 'cancel-sub-reason-modal'

  return (
    <CustomModal
      width={'50%'}
      title={'What was our product missing?'}
      modalId={MODAL_IDS.CANCEL_REASON_MODAL}
      onClose={() => {
        dispatch(closeModal())
      }}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        name="cancel-sub-reason-form"
        className={`${baseClass}`}
      >
        <Form.Item
          label="Reason"
          name="reason"
          className={`${baseClass}__input_item`}
          rules={[
            { required: true, message: 'Please input your reason!' },
            {
              // regex to check if the input contains minimum 10 words
              pattern: MIN_10_WORDS_REGEX,
              message: 'Please enter at least 10 words',
            },
          ]}
        >
          <Input placeholder="Reason" />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={mutation.isPending}
            type="primary"
            className={`${baseClass}__submit-button mt-2`}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default CancelSubReasonModal
