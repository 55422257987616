import { Button, Card, Flex, Form, Input, notification, Spin } from 'antd'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { OverViewCardForm } from '../OverviewCardForm'
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { closeModal } from '@/store/modal/slice'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { QuestionAndAnswerFormInitialValues } from '@/types/TQuestionAndAnswer'
import { DeleteOutline } from '@mui/icons-material'
import { formatQAString } from '@/utils/helper'
import { User } from '@/types/TAccounts'

interface QuestionAndAnswerFormProps {
  initialValues?: QuestionAndAnswerFormInitialValues[]
  showAddFieldButton?: boolean
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  actionType: 'ADD' | 'EDIT'
  mutation: UseMutationResult<{}, Error, any, unknown>
  qnaIndex?: number
}
export const QuestionAndAnswerForm: React.FC<QuestionAndAnswerFormProps> = ({
  initialValues,
  showAddFieldButton = true,
  refetch,
  actionType,
  mutation,
  qnaIndex,
}) => {
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)
  const [form] = Form.useForm()

  useEffect(() => {
    if (initialValues && actionType === 'EDIT') {
      form.setFieldsValue(initialValues)
    }

    if (actionType === 'ADD') {
      form.resetFields()
    }

    return () => {
      form.resetFields()
    }
  }, [initialValues, form, actionType])
  const dispatch = useAppDispatch()

  const handleSubmit = async (values: {
    questions_and_answers: {
      question: string
      answer: string
    }[]
  }) => {
    try {
      let formData: { question: string; answer: string }[] = []

      const updatedQna = values.questions_and_answers.map((qna) => ({
        question: qna.question,
        answer: qna.answer,
      }))

      const qna = values.questions_and_answers[0]

      //@ts-ignore
      if (initialValues?.questions_and_answers[0].previousQnaData) {
        //@ts-ignore
        qna.previousQnaData = initialValues.questions_and_answers[0].previousQnaData
      }
      //@ts-ignore
      const qnaIndex = qna?.index ?? 0

      //@ts-ignore
      if (qna?.previousQnaData) {
        //@ts-ignore
        formData = [...qna.previousQnaData]
      } else {
        console.log('No previous Q&A data found')
      }

      if (actionType === 'EDIT') {
        formData.splice(qnaIndex, updatedQna.length, ...updatedQna)
      } else if (actionType === 'ADD') {
        formData = [...formData, ...updatedQna]
      }

      await mutation.mutateAsync({ sample_qa: formatQAString(formData) })
      refetch()
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      })
      form.resetFields()
      setIsFieldTouchReset(false)
      dispatch(closeModal())
    } catch (err) {
      console.log('Error: ', err)
      notification.error({
        message: 'Something went wrong, please try again later!',
      })
    }
  }

  const FormItems = () => (
    <Form.Item
      label={
        <LabelWithDescription
          label="(Optional) Sample Q&A for the Bot"
          description="Provide a few example questions and answers for common questions. No need to write out every possible question and answer, this is just to guide the AI."
        />
      }
      name="questions_and_answers"
      className={`${baseClass}__input-item`}
    >
      <Form.List name="questions_and_answers" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Flex key={key} className="mb-2" gap={8}>
                <Card
                  className="flex-1"
                  title={
                    <div className="flex justify-between">
                      <span className="font-bold text-base">{`Q&A # ${(qnaIndex ?? 0) + index + 1}`}</span>
                      {fields.length > 1 && showAddFieldButton && (
                        <Button type="primary" danger icon={<DeleteOutline />} onClick={() => remove(name)}>
                          Delete
                        </Button>
                      )}
                    </div>
                  }
                >
                  <div className="border-b">
                    <LabelWithDescription customClassName="mt-2" label="Question" description="" />
                    <Form.Item
                      className=" mt-2 pt-2 "
                      {...restField}
                      name={[name, 'question']}
                      rules={[{ required: true, message: 'Missing question.' }]}
                    >
                      <Input.TextArea
                        placeholder="e.g. What services do you offer?"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    </Form.Item>
                  </div>
                  <div className="border-b">
                    <LabelWithDescription customClassName="mt-2" label="Answer" description="" />
                    <Form.Item
                      className=" mt-2 pt-2 "
                      {...restField}
                      name={[name, 'answer']}
                      rules={[{ required: true, message: 'Missing answer.' }]}
                    >
                      <Input.TextArea
                        placeholder="e.g. We offer haircuts, beard trims, hot towel shaves, and neck cleanups."
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    </Form.Item>
                  </div>
                </Card>
              </Flex>
            ))}
            {showAddFieldButton && (
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add another Q&A
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Form.Item>
  )

  const baseClass = 'question-and-answer-form'
  return (
    <div className={`${baseClass}`}>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={actionType === 'EDIT' && initialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  )
}
