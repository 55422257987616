import { Table, Typography } from "antd";

const { Text } = Typography;

type VariablesProps = {
  editedVariables: { name: string; value: string }[];
};

const columns = [
  {
    title: 'Variable Name',
    dataIndex: 'name',
    key: 'name',
    render: (text: string) => <Text strong>{text}</Text>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (text: string) => <Text>{text}</Text>,
  },
];

export const Variables: React.FC<VariablesProps> = ({ editedVariables }) => {
  return (
    <Table
      columns={columns}
      dataSource={editedVariables.map((variable, index) => ({ ...variable, key: index }))}
      pagination={false}
      bordered
    />
  )
}
