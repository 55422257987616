import './ContactSupport.scss'

export const ContactSupport = () => {
  const baseClass = 'contact-support'
  return (
    <div className={baseClass}>
      <iframe
        src="https://calendly.com/d/cph3-yqw-sfb/invite-only-ai-front-desk-product-consultation"
        title="Calendly"
        className={`${baseClass}__content`}
      />
    </div>
  )
}
