import { List } from 'antd'
import './CallDetails.scss'

export const CallDetails = ({ script, callSummary }: { script: string; callSummary: string | undefined }) => {
  const scriptMessages = script
    ?.split('\n')
    ?.map((message: string, index: number) => {
      if (message?.startsWith('assistant@')) {
        return { key: index, sender: 'Assistant', message: message.replace('assistant@', '') }
      } else if (message?.startsWith('user@')) {
        return { key: index, sender: 'User', message: message.replace('user@', '') }
      }
    })
    ?.filter(Boolean)

  return (
    <div className="max-h-[400px] overflow-y-auto pt-2">
      {callSummary && (
        <div className="flex flex-col mb-6 gap-2">
          <span className="font-medium italic">SUMMARY</span>
          <span className="text-gray-500">{callSummary}</span>
        </div>
      )}
      <div>
        <span className="font-medium italic">TRANSCRIPT</span>
        <List
          key={script}
          className="call-log-details"
          itemLayout="horizontal"
          dataSource={scriptMessages}
          renderItem={(item) =>
            item && (
              <List.Item
                key={item.key}
                className="call-log-details__item"
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  flexDirection: item.sender == 'Assistant' ? 'row' : 'row-reverse',
                  textAlign: item.sender == 'Assistant' ? 'left' : 'right',
                  padding: '10px',
                }}
              >
                <List.Item.Meta title={<strong>{item.sender}</strong>} description={<span>{item.message}</span>} />
              </List.Item>
            )
          }
        />
      </div>
    </div>
  )
}
