import { TranscriptMessage } from '@/types/TCallLogs'
import { Card, List } from 'antd'

export const CallTranscriptCard = ({
  transcriptMessages,
  style,
}: {
  transcriptMessages: TranscriptMessage[] | null[] | undefined
  style?: React.CSSProperties
}) => {
  return (
    <Card title="Call Transcript" style={style}>
      <List
        dataSource={transcriptMessages as TranscriptMessage[]}
        renderItem={(item) =>
          item && (
            <List.Item
              style={{
                display: 'flex',
                justifyContent: item.sender === 'Assistant' ? 'flex-start' : 'flex-end',
                textAlign: item.sender === 'Assistant' ? 'left' : 'right',
              }}
            >
              <List.Item.Meta title={<strong>{item.sender}</strong>} description={item.message} />
            </List.Item>
          )
        }
      />
    </Card>
  )
}
