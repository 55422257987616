import React from "react";
import { prod } from "./config";

if (prod) {
  console.log = function () {};
  console.error = function () {};
}

var serverURL = prod
  ? "https://myaifrontdeskadmin.com"
  : "http://localhost:8080";

function PurchaseDialog({ isOpen, onClose, onBuy, phoneNumber }) {
  return (
    <div>
      {isOpen && (
        <div
          style={{
            zIndex: "1000",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              padding: "16px",
              width: "600px",
              position: "relative",
              overflow: "auto",
            }}
          >
            <button
              onClick={() => {
                onClose();
              }}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                top: "8px",
                left: "8px",
              }}
            >
              X
            </button>{" "}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                border: "1px solid #000",
                marginTop: "2rem",
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  padding: "16px 24px",
                  borderRight: "1px solid #000",
                  width: "calc(50% - 1px)",
                  boxSizing: "border-box",
                  minWidth: "250px",
                }}
              >
                <ul>
                  <li>This number will be linked</li>
                  <li>Can use standalone</li>
                  <li>Texts will be sent from this</li>
                  <li>You can view/arrange</li>
                </ul>
              </div>
              <div
                style={{
                  flexGrow: 1,
                  padding: "16px 24px",
                  width: "calc(50% - 1px)",
                  textAlign: "center",
                  boxSizing: "border-box",
                  minWidth: "250px",
                }}
              >
                <h2
                  style={{
                    fontSize: "1.25rem",
                  }}
                >
                  Standalone Phone Number
                </h2>
                <div>$10</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <button
                    style={{
                      marginTop: "3rem",
                      backgroundColor: "lightgreen",
                      color: "black",
                      borderRadius: "8px",
                      padding: "12px 30px",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onClose();
                      onBuy();
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PurchaseDialog;
