import { AppState } from '@/types/TApp'
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit'

const initialState: AppState = {
  isLoading: false,
}

export const slice: Slice<
  AppState,
  {
    setAppLoading: (state: AppState, action: PayloadAction<boolean>) => void
  },
  'app'
> = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const { setAppLoading } = slice.actions
export default slice.reducer
