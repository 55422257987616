import { getCampaginReceptionist } from '@/api/novi/overViewApi';
import { useQuery } from '@tanstack/react-query';
import { fetchLeads } from './useLeadsHook';


export const useLeadsHooks = (campaignId: string | undefined) => {
  return useQuery<any, Error>({
    queryKey: ['leads', campaignId],
    queryFn: () => {
      if (!campaignId) {
        throw new Error('Email address is required');
      }
      return fetchLeads(campaignId);
    },
  });
};

export const getReceptionistConfig = (number: string) => {
  return useQuery<any, Error>({
    queryKey: ['receptionist', number],
    queryFn: () => {
      return getCampaginReceptionist(number);
    },
  });
}