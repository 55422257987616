import React from 'react'
import { Menu, MenuProps } from 'antd'
import Sider from 'antd/es/layout/Sider'
import './Sider.scss'
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems'
import OnboardingStepsList from '@/pages/ReceptionistDashboard/TestItOut/v2/OnboardingStepsList'

interface CommonSiderProps {
  items: MenuProps['items']
  logo: React.ReactNode
  activeMenuItem: ResellerSiderItem | string
  className?: string
  menuRef?: React.RefObject<HTMLDivElement>
  handleClick: MenuProps['onClick']
  setOpenedOnboarding?: (key: boolean) => void
  openedOnboarding?: boolean
  tourOpen?: boolean
}

const CommonSider: React.FC<CommonSiderProps> = ({
  items,
  logo,
  activeMenuItem,
  className = '',
  menuRef,
  handleClick,
  setOpenedOnboarding,
  openedOnboarding,
  tourOpen,
}) => {
  const baseClass = 'common-sider'
  return (
    <Sider className={`${baseClass} ${className}`} collapsible={false} theme="light" breakpoint="md">
      <div>{logo}</div>
      <div ref={menuRef}>
        <Menu items={items} selectedKeys={[activeMenuItem]} onClick={handleClick} mode="inline" />
      </div>
      {!openedOnboarding && !tourOpen && (
        <div className="mt-8">
          <OnboardingStepsList setOpenedOnboarding={setOpenedOnboarding} />
        </div>
      )}
    </Sider>
  )
}

export default CommonSider
