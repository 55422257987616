import { useEffect, useMemo, useState } from 'react'
import { Badge, MenuProps } from 'antd'
import './ReceptionistSider.scss'
import MobileLogo from '@/assets/images/mobile-log.png'
import DesktopLogo from '@/assets/images/desktop-logo.png'
import { SiderMenuProps } from '@/interfaces/IDashboardSider'
import {
  AnalyticsOutlined,
  ArticleOutlined,
  ContactSupportOutlined,
  CallOutlined,
  FeedOutlined,
  VoicemailOutlined,
  TextsmsOutlined,
  TextSnippetOutlined,
  StackedBarChartOutlined,
  PhoneIphoneOutlined,
} from '@mui/icons-material'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getUserInstructionSteps } from '@/store/account/selector'
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile'
import { getResellerData } from '@/store/reseller/selector'
import { useQuery } from '@tanstack/react-query'
import { getResellerLogo } from '@/api/unbrandedReceptionist'
import { DollarOutlined, SettingOutlined, SoundOutlined } from '@ant-design/icons'
import CommonSider from '@/components/Sider/Sider'
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem'

const items: MenuProps['items'] = [
  {
    key: ReceptionistDashboardSiderItem.CONFIGURE,
    icon: <SettingOutlined />,
    label: ReceptionistDashboardSiderItem.CONFIGURE,
  },
  {
    key: ReceptionistDashboardSiderItem.ANALYTICS,
    icon: <AnalyticsOutlined />,
    label: ReceptionistDashboardSiderItem.ANALYTICS,
  },
  {
    key: ReceptionistDashboardSiderItem.VOICE_LIBRARY,
    icon: <SoundOutlined />,
    label: ReceptionistDashboardSiderItem.VOICE_LIBRARY,
  },
  {
    key: ReceptionistDashboardSiderItem.INTAKE_FORMS,
    icon: <TextSnippetOutlined />,
    label: ReceptionistDashboardSiderItem.INTAKE_FORMS,
  },
  {
    key: ReceptionistDashboardSiderItem.TEXTING_PLATFORM,
    icon: <TextsmsOutlined />,
    label: ReceptionistDashboardSiderItem.TEXTING_PLATFORM,
  },
  {
    key: ReceptionistDashboardSiderItem.LOGS,
    icon: <ArticleOutlined />,
    label: ReceptionistDashboardSiderItem.LOGS,
    children: [
      {
        key: ReceptionistDashboardSiderItem.CALL_LOGS,
        icon: <CallOutlined />,
        label: ReceptionistDashboardSiderItem.CALL_LOGS,
      },
      {
        key: ReceptionistDashboardSiderItem.TEXT_LOGS,
        icon: <FeedOutlined />,
        label: ReceptionistDashboardSiderItem.TEXT_LOGS,
      },
      {
        key: ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS,
        icon: <VoicemailOutlined />,
        label: ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS,
      },
    ],
  },
  {
    key: ReceptionistDashboardSiderItem.CRM,
    icon: <StackedBarChartOutlined />,
    label: (
      <Badge count="New" offset={[30, 0]}>
        <span>{ReceptionistDashboardSiderItem.CRM}</span>
      </Badge>
    ),
  },
  {
    key: ReceptionistDashboardSiderItem.SUBSCRIPTION_MANAGEMENT,
    icon: <DollarOutlined />,
    label: ReceptionistDashboardSiderItem.SUBSCRIPTION_MANAGEMENT,
  },
  {
    key: ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS,
    icon: <PhoneIphoneOutlined />,
    label: ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS,
  },
  {
    key: ReceptionistDashboardSiderItem.CONTACT_SUPPORT,
    icon: <ContactSupportOutlined />,
    label: ReceptionistDashboardSiderItem.CONTACT_SUPPORT,
  },
]

const disallowedDebrandedItems = [
  ReceptionistDashboardSiderItem.CONTACT_SUPPORT,
  ReceptionistDashboardSiderItem.SUBSCRIPTION_MANAGEMENT,
]

const ReceptionistSiderSider: React.FC<SiderMenuProps> = ({
  activeMenuItem,
  onMenuSelect,
  menuRef,
  overviewRef,
  logsRef,
  className = '',
  debrand,
  setOpenedOnboarding,
  crmRef,
  openedOnboarding,
  tourOpen,
}) => {
  const instructionSteps = useAppSelector(getUserInstructionSteps)
  const [menuItems, setMenuItems] = useState<MenuProps['items']>(items)
  const { isMobile } = useMobileDeviceCheck()
  const currentReseller = useAppSelector(getResellerData)

  const { data } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: currentReseller.logo_s3 }),
    enabled: !!(debrand && currentReseller?.logo_s3),
  })

  const resellerLogoUrl = useMemo(() => {
    if (!data) return

    return URL.createObjectURL(data)
  }, [data])

  const handleClick: MenuProps['onClick'] = (e) => {
    onMenuSelect(e.key as ReceptionistDashboardSiderItem)
  }

  useEffect(() => {
    const menuItemList = items
    setMenuItems(menuItemList)
  }, [instructionSteps, debrand])

  useEffect(() => {
    const setRefs = () => {
      if (menuRef.current) {
        const menuItems = menuRef.current.querySelectorAll('.ant-menu-item')
        const subMenuItems = menuRef.current.querySelectorAll('.ant-menu-submenu')

        let firstItem
        let fourthItem
        let crmItem

        if (instructionSteps && instructionSteps.length > 2) {
          firstItem = menuItems[0]
          fourthItem = subMenuItems[0]
          crmItem = menuItems[5]
        } else {
          firstItem = menuItems[1]
          crmItem = menuItems[6]
          fourthItem = subMenuItems[0]
        }

        if (firstItem) {
          overviewRef.current = firstItem as HTMLElement
        }
        if (fourthItem) {
          logsRef.current = fourthItem as HTMLElement
        }
        if (crmItem) {
          crmRef.current = crmItem as HTMLElement
        }
      }
    }

    setRefs()

    const observer = new MutationObserver(setRefs)
    if (menuRef.current) {
      observer.observe(menuRef.current, { childList: true, subtree: true })
    }

    return () => observer.disconnect()
  }, [instructionSteps])

  const baseClass = 'sider-container'
  const logo = (
    <div className={`${baseClass}_logo`}>
      {debrand ? (
        resellerLogoUrl ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={resellerLogoUrl} alt="logo" />
          </div>
        ) : (
          <h1> {currentReseller && currentReseller.reseller_name} </h1>
        )
      ) : isMobile ? (
        <img src={MobileLogo} alt="logo" />
      ) : (
        <img src={DesktopLogo} alt="logo" />
      )}
    </div>
  )

  return (
    <CommonSider
      items={menuItems?.filter((items) => !disallowedDebrandedItems.some((item) => items?.key === item && !!debrand))}
      logo={logo}
      activeMenuItem={activeMenuItem}
      handleClick={handleClick}
      className={baseClass + className}
      menuRef={menuRef}
      setOpenedOnboarding={setOpenedOnboarding}
      openedOnboarding={openedOnboarding}
      tourOpen={tourOpen}
    />
  )
}

export default ReceptionistSiderSider
