import { LocalStorageKeys } from 'src/enums/ELocalStorage'

class LocalStorageUtility {
  static setLocalData<T>(key: LocalStorageKeys, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (err) {
      console.log('failed to set localstorage item')
      throw err
    }
  }

  static getLocalData<T>(key: LocalStorageKeys): T | null {
    try {
      const data = localStorage.getItem(key)
      return data ? JSON.parse(data) : null
    } catch (err) {
      console.log('failed to retrive localstorage item')
      throw err
    }
  }

  static clearLocalData(): void {
    localStorage.clear()
  }

  static removeLocalData(key: LocalStorageKeys): void {
    localStorage.removeItem(key)
  }
}

export default LocalStorageUtility
