import { List } from 'antd'
import './VoiceMailLogsDetails.scss'
import { VoiceMailLog } from '@/interfaces/ILogs'

export const VoiceMailLogDetails = ({ voicemailLog }: { voicemailLog: VoiceMailLog }) => {
  const data = [
    { label: 'Username', value: voicemailLog.forwardedFrom },
    { label: 'Date', value: voicemailLog.date },
    { label: 'from', value: voicemailLog.from },
    { label: 'Transcript', value: voicemailLog.voicemail_transcript },
  ]

  return (
    <List
      size="small"
      dataSource={data}
      className="voicemail-logs-details "
      renderItem={(item) => (
        <List.Item>
          <strong>{item.label}:</strong>
          <span> {item.value}</span>
        </List.Item>
      )}
    />
  )
}
