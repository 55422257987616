import apiClient from '@/utils/apiClient'

const CHECK_RESELLER_EMAIL = '/api/reseller/hubspot-get-contact'

export const checkResellerEmail = async (email: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${CHECK_RESELLER_EMAIL}/${email}`,
    })

    return response.data
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}
