import { Form, FormInstance } from 'antd'
import './LoginForm.scss'
import { ReactNode } from 'react'
import classNames from 'classnames'

export const LoginForm: React.FC<{
  form: FormInstance
  formItems: ReactNode
  handleSubmit?: ((values: any) => void) | undefined
  autoComplete?: string | undefined
  customClassName?: string
}> = ({ form, formItems, handleSubmit, autoComplete, customClassName }) => {
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      name="login-form"
      className={classNames(`login-form`,{
        [`${customClassName}`]: !!customClassName,
      })}
      autoComplete={autoComplete}
    >
      {formItems}
    </Form>
  )
}
