import useResellerAuth from '@/routes/hooks/useResellerAuth'
import { Loading } from '@/components/Loading'
import { Navigate, Outlet } from 'react-router-dom'
import { AppRoutes } from 'src/enums/ERoutes'

export const PublicResellerRoute = () => {
  const { isAuthenticated, isLoading } = useResellerAuth()
  if (isLoading) {
    return <Loading />
  }

 return isAuthenticated ? <Navigate to={AppRoutes.RESELLER_PORTAL} /> : <Outlet />

}

export default PublicResellerRoute
