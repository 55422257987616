import { BlogPost } from '@/interfaces/IBlogs'
import apiClient from '@/utils/apiClient'

const GET_BLOGS = '/blogs'

export const getBlogs = async (payload: any): Promise<BlogPost> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: GET_BLOGS,
      params: payload,
    })

    return response.blog.data
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}
