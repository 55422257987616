import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import useAuth from '@/routes/hooks/useAuth'
import { Loading } from '@/components/Loading'
import { AppRoutes } from 'src/enums/ERoutes'

const PrivateReceptionistRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const location = useLocation();

  const isUnbrandedPath = location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST

  const [searchParams] = useSearchParams();
  const resellerCode = searchParams.get('reseller_code');

  if (isLoading) {
    return <Loading />
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname, debrand:isUnbrandedPath, resellerCode }} replace />
  }

  return <Outlet context={{ debrand:isUnbrandedPath }} />
}

export default PrivateReceptionistRoute
