import './LabelWithDescription.scss'
import classnames from 'classnames'

export const LabelWithDescription: React.FC<{
  icon?: React.ReactNode
  label: string | React.ReactNode
  description?: string | React.ReactNode
  required?: boolean
  badges?: React.ReactNode
  customClassName?: string
}> = ({ icon, label, description, badges, required, customClassName }) => {
  const baseClass = 'label-with-description'

  return (
    <div
      className={classnames(baseClass, {
        [`${customClassName}`]: !!customClassName,
      })}
    >
      <h2
        className={classnames(`${baseClass}__label`, {
          [`${baseClass}__label-with-required`]: !!required,
        })}
      >
        {icon && icon} {label} {badges && badges}
      </h2>
      <div
        className={classnames(`${baseClass}__description`, {
          [`${baseClass}__description-with-required`]: !!required,
          [`${baseClass}__description-with-icon`]: !!icon,
        })}
      >
        {description}
      </div>
    </div>
  )
}
