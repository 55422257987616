import {
  Button,
  Input,
  Select,
  Form,
  Space,
  notification,
} from 'antd';
import { useUpdateLeadData } from '../../hooks/useCampaignClient';
const { Option } = Select;

interface LeadDetailsSheetProps {
  lead: any;
  refetchLeads: () => void;
  onClose: () => void;
}

const LeadDetailsSheet: React.FC<LeadDetailsSheetProps> = ({ lead, refetchLeads, onClose }) => {
  const [form] = Form.useForm();
  const { variables, campaign_id, lead_id, ...rest } = lead;

  const options = [
    {
      label: 'CONTACTED',
      value: 'CONTACTED',
    },
    {
      label: 'NOT YET CONTACTED',
      value: 'NOT YET CONTACTED',
    },
    {
      label: 'BOUNCED',
      value: 'BOUNCED',
    },
    {
      label: 'INTERESTED',
      value: 'INTERESTED',
    },
    {
      label: 'NOT INTERESTED',
      value: 'NOT INTERESTED',
    },
    {
      label: 'NEW',
      value: 'NEW',
    },
  ];

  const { mutate: updateLeadData, isPending } = useUpdateLeadData({
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Lead data updated successfully',
      });
      refetchLeads();
      onClose();
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: error?.message || 'Something went wrong',
      });
    },
  });

  const handleSave = (values: any) => {
    const { phone_number, status, ...rest } = values;
    const variables = { ...rest }
    updateLeadData({
      phone_number,
      status,
      lead_id,
      campaign_id,
      variables,
    });
  };

  return (
    <Space direction='vertical' className='w-full'>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSave}
      >
        {Object.keys(rest).map((key) => {
          return (
            <Form.Item
              key={key}
              label={key}
              name={key}
              initialValue={rest[key]}
            >
              {key === 'status' ? (
                <Select>
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input />
              )}
            </Form.Item>
          );
        })}
        {Object.keys(variables).map((key) => {
          return (
            <Form.Item
              key={key}
              label={key}
              name={key}
              initialValue={variables[key]}
            >
              <Input />
            </Form.Item>
          );
        })}
        <Form.Item>
          <Space className="flex justify-end">
            <Button type="primary" htmlType="submit" loading={isPending}>
              Save Changes
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Space>
  );
}

export default LeadDetailsSheet;