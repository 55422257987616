import { ClockCircleOutlined, InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tag, Timeline, Tooltip, Typography } from "antd";

const { Text } = Typography;

type StatItemProps = {
  title: string;
  value: string | number;
  icon?: JSX.Element | null;
  tooltip?: string | JSX.Element;
};

type LogsProps = {
  selectedEvent: any;
  stateHistories: string[];
  executionTimes: number[];
  getStatusColor: (state: string) => string;
  getStatusIcon: (state: string) => JSX.Element | null;
};

export const Logs: React.FC<LogsProps> = ({ selectedEvent, stateHistories, executionTimes, getStatusColor, getStatusIcon }) => {

  const StatItem = ({ title, value, icon, tooltip }: StatItemProps) => {
    const content = (
      <div className="flex flex-col">
        <Text type="secondary" className="text-sm mb-1">{title}</Text>
        <div className="flex items-center">
          {icon && <span className="mr-2">{icon}</span>}
          <Text strong className="text-base">
            {value}
          </Text>
        </div>
      </div>
    );

    return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card size="small" className="mb-4">
          <Row gutter={16} align="middle">
            <Col span={8}>
              <StatItem
                title="Current State"
                value={selectedEvent?.event_state || 'N/A'}
                icon={getStatusIcon(selectedEvent?.event_state as string)}
                tooltip={
                  <>
                    1. PENDING: Scheduled, not processed
                    <br />2. FAILED: Failed, has retries left
                    <br />3. SUCCESS: Processed successfully
                    <br />4. FAILED_TERMINAL: Failed, no more retries
                    <br />5. PROCESSING: Currently being processed
                  </>
                }
              />
            </Col>
            <Col span={8}>
              <StatItem
                title="Number of Tries"
                value={selectedEvent?.num_tries_so_far || '0'}
                icon={<SyncOutlined className="text-blue-500" />}
                tooltip="Number of times the event has been tried"
              />
            </Col>
            <Col span={8}>
              <StatItem
                title="Next Execution"
                value={selectedEvent?.event_execution_time?.length
                  ? new Date(+selectedEvent.event_execution_time).toLocaleString()
                  : 'N/A'}
                icon={<ClockCircleOutlined className="text-gray-500" />}
                tooltip="Upcoming execution time of next trigger"
              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={12}>
        <Card
          title={<Text strong>State History</Text>}
          extra={
            <Tooltip title={
              <>
                1. PENDING: Scheduled, not processed
                <br />2. FAILED: Failed, has retries left
                <br />3. SUCCESS: Processed successfully
                <br />4. FAILED_TERMINAL: Failed, no more retries
                <br />5. PROCESSING: Currently being processed
              </>
            }>
              <InfoCircleOutlined />
            </Tooltip>
          }
        >
          <Timeline>
            {stateHistories.map((state: any, index: number) => (
              <Timeline.Item
                key={index}
                color={getStatusColor(state)}
                dot={getStatusIcon(state)}
              >
                <Tag color={getStatusColor(state)}>{state}</Tag>
                {executionTimes[index] && (
                  <div className="text-xs text-gray-500">
                    {new Date(executionTimes[index]).toLocaleString()}
                  </div>
                )}
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      </Col>

      <Col span={12}>
        <Card title={<Text strong>Error Details</Text>}>
          {selectedEvent?.errors_blob ? (
            <div className="bg-red-50 p-4 rounded-md">
              <Text type="danger">{selectedEvent?.errors_blob}</Text>
            </div>
          ) : (
            <Text type="secondary">No errors reported</Text>
          )}
        </Card>
      </Col>
    </Row>
  )
}
