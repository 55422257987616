import { useEffect, useState } from 'react'
import { Button, Card, Layout, Typography, Space, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import ConfettiExplosion from 'react-confetti-explosion'
import logo from '@/assets/images/My AI Front Desk Dashboard_files/Blue_Logo.png'
import backgroundImage from '@/assets/images/My AI Front Desk Dashboard_files/receptionist.jpg'
import './PaymentSuccess.scss'

const { Content } = Layout
const { Title, Text } = Typography

const successValues: { [key: string]: number } = {
  '306a0b70a98dd315b87b64e5a71f0b99': 150.0, //monhtly-basic
  ae217d0900e13123043c315d412bcd2f: 585.0, //yealry-basic
  '3792eaa6b3407e2a70e34351d13a5e50': 300.0, //monhtly-pro
  '4e367dd868927e8f9c5a3d0e573eb620': 873.0, //yealry-pro
}

const PaymentSuccess = ({ reseller = false }) => {
  const [timer, setTimer] = useState(10)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false) // Button enable/disable state

  // fetch query params
  const search = window.location.search
  const params = new URLSearchParams(search)
  const successId = params.get('id')

  useEffect(() => {
    //@ts-ignore
    window.gtag('event', 'SuccessPageView', {
      event_category: 'Pageview',
    })
  }, [reseller])

  useEffect(() => {
    if (successId && successValues[successId]) {
      //@ts-ignore
      window.gtag('event', 'conversion', {
        send_to: 'AW-11361005625/djBKCOmY_9IZELngrKkq',
        value: successValues[successId],
        currency: 'USD',
        transaction_id: '',
      })
    }
  }, [successId])

  const navigate = useNavigate()

  // Countdown timer logic
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 1) {
          return prevTimer - 1
        } else {
          clearInterval(interval)
          setIsButtonEnabled(true) // Enable the button after 10 seconds
          return 0
        }
      })
    }, 1000)

    return () => clearInterval(interval) // Cleanup interval on component unmount
  }, [])

  return (
    <Layout className="payment-success-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Content>
        <ConfettiExplosion zIndex={1000} force={1} height="220vh" width={3000} particleCount={200} duration={5000} />
        <Card bordered={false} className="payment-success-card">
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Image src={logo} preview={false} width={100} className="logo" />
            <Title level={2} className="heading">
              {reseller ? '1 New Unbranded Receptionist Reserved' : 'Payment Successful!'}
            </Title>
            <Text className="paragraph">
              {reseller
                ? "You've reserved a new unbranded receptionist. You can begin reselling this receptionist to your clients immediately. To configure your new receptionist, click the button below."
                : 'Thank you for your payment. Your AI receptionist is now ready to receive calls. You can now start forwarding your calls to your AI receptionist immediately.'}
            </Text>
            <Button
              type="primary"
              size="large"
              block
              className="button"
              onClick={() => {
                reseller ? navigate('/reseller_portal') : navigate('/?refresh=true')
              }}
              disabled={!isButtonEnabled}
            >
              {isButtonEnabled
                ? reseller
                  ? 'Configure Unbranded Receptionist'
                  : 'Enter Dashboard'
                : `Configuring your dashboard: ${timer}s...`}
            </Button>
          </Space>
        </Card>
      </Content>
    </Layout>
  )
}

export default PaymentSuccess
