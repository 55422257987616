//@ts-ignore
import CampaignPortalV1 from "../../v1/Campaign/CampaignPortal";

const CampaignPortal = () => {
  return (
    <CampaignPortalV1 />
  )
}

export default CampaignPortal
