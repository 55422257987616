export const countries = [
  { name: 'United States (+1)', code: '+1' },
  { name: 'United Kingdom (+44)', code: '+44' },
  { name: 'Andorra (+376)', code: '+376' },
  { name: 'Argentina (+54)', code: '+54' },
  { name: 'Australia (+61)', code: '+61' },
  { name: 'Austria (+43)', code: '+43' },
  { name: 'Bahrain (+973)', code: '+973' },
  { name: 'Bangladesh (+880)', code: '+880' },
  { name: 'Belgium (+32)', code: '+32' },
  { name: 'Brazil (+55)', code: '+55' },
  { name: 'Brunei Darussalam (+673)', code: '+673' },
  { name: 'Canada (+1)', code: '+1' },
  { name: 'Chile (+56)', code: '+56' },
  { name: 'China (+86)', code: '+86' },
  { name: 'Colombia (+57)', code: '+57' },
  { name: 'Denmark (+45)', code: '+45' },
  { name: 'Egypt (+20)', code: '+20' },
  { name: 'Finland (+358)', code: '+358' },
  { name: 'France (+33)', code: '+33' },
  { name: 'Germany (+49)', code: '+49' },
  { name: 'Hong Kong (+852)', code: '+852' },
  { name: 'Iceland (+354)', code: '+354' },
  { name: 'India (+91)', code: '+91' },
  { name: 'Indonesia (+62)', code: '+62' },
  { name: 'Iran (+98)', code: '+98' },
  { name: 'Iraq (+964)', code: '+964' },
  { name: 'Ireland (+353)', code: '+353' },
  { name: 'Israel (+972)', code: '+972' },
  { name: 'Italy (+39)', code: '+39' },
  { name: 'Japan (+81)', code: '+81' },
  { name: 'Kenya (+254)', code: '+254' },
  { name: 'Kuwait (+965)', code: '+965' },
  { name: 'Luxembourg (+352)', code: '+352' },
  { name: 'Macau (+853)', code: '+853' },
  { name: 'Malaysia (+60)', code: '+60' },
  { name: 'Mexico (+52)', code: '+52' },
  { name: 'Morocco (+212)', code: '+212' },
  { name: 'Netherlands (+31)', code: '+31' },
  { name: 'New Zealand (+64)', code: '+64' },
  { name: 'Nigeria (+234)', code: '+234' },
  { name: 'Norway (+47)', code: '+47' },
  { name: 'Oman (+968)', code: '+968' },
  { name: 'Pakistan (+92)', code: '+92' },
  { name: 'Peru (+51)', code: '+51' },
  { name: 'Philippines (+63)', code: '+63' },
  { name: 'Russia (+7)', code: '+7' },
  { name: 'Saudi Arabia (+966)', code: '+966' },
  { name: 'Singapore (+65)', code: '+65' },
  { name: 'South Africa (+27)', code: '+27' },
  { name: 'South Korea (+82)', code: '+82' },
  { name: 'Spain (+34)', code: '+34' },
  { name: 'Sweden (+46)', code: '+46' },
  { name: 'Switzerland (+41)', code: '+41' },
  { name: 'Suriname (+597)', code: '+597' },
  { name: 'Thailand (+66)', code: '+66' },
  { name: 'Tunisia (+216)', code: '+216' },
  { name: 'Turkey (+90)', code: '+90' },
  { name: 'United Arab Emirates (+971)', code: '+971' },
  { name: 'United Kingdom (+44)', code: '+44' },
  { name: 'United States (+1)', code: '+1' },
  { name: 'Vietnam (+84)', code: '+84' },
  { name: 'n/a', code: '' },
]

export const companyInfoPlaceholder = `John's Barbershop is a popular spot for men looking for a classic haircut experience. Located in the heart of downtown at 123 Alden Avenue in Berkeley, California, the shop boasts a team of highly skilled barbers who specialize in traditional cuts and shaves.

Summer Sale:
10% off all haircuts from June through August

Services:
- Haircuts: Starting at $30
- Beard Trims: Starting at $20
- Hot Towel Shaves: Starting at $50
- Neck Cleanups: Starting at $15 

Hours:
- Tuesday - Saturday: 9am to 6pm
- Sunday: 11am to 4pm
- Monday: Closed

Experience:
The barbers at John's Barbershop are known for their attention to detail and personalized service. Each barber has their own unique style and approach to cutting hair, but all share a passion for classic barbering techniques.

Address: 
123 Alden Avenue, Berkeley, California`

export const qAndAPlaceholder = `Q: What services do you offer?
A: We offer haircuts, beard trims, hot towel shaves, and neck cleanups.

Q: I have a complaint
A: If you have a complaint, you can leave a message by saying "leave a message" and we will get back to you as soon as possible.

Q: Do you have any availability right now?
A: You can check for availability on our website.

Q: I want to cancel my appointment.
A: Please note our cancellation rescheduling policy: 24 hours for haircut services and 48 hours for hair coloring services. To cancel your appointment, use the link in your confirmation email or text message.`
