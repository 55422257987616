import { Layout } from 'antd'
import SiderMenu from './Sidebar/Sidebar'
import NoviContent from './Content/Content'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { setNoviCurrentPage } from '@/store/novi/user/slice'
import { ENoviDashboardSider } from '../../enums/ENoviDashboardSider'
import { getNoviCurrentPage, getSelectedCampaignId } from '@/store/novi/user/selector'
import { useEffect } from 'react'


export const NoviDashboard: React.FC = () => {
  const dispatch = useDispatch()
  const currentPage = useAppSelector(getNoviCurrentPage)
  const selectedCampaignId = useAppSelector(getSelectedCampaignId)

  const handleMenuSelect = (key: ENoviDashboardSider) => {
    dispatch(setNoviCurrentPage(key))
  }

  useEffect(() => {
    if (selectedCampaignId) {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.LEADS))
    } else {
      dispatch(setNoviCurrentPage(ENoviDashboardSider.MY_CAMPAIGNS))
    }
  }, [selectedCampaignId])

  const baseClass = 'app-dashboard'
  return (
    <div className={baseClass}>
      <Layout>
        <SiderMenu
          currentPage={currentPage}
          handleMenuSelect={handleMenuSelect}
        />
        <NoviContent currentPage={currentPage} />
      </Layout>
    </div>
  )
}
