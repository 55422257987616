import { IntakeForm } from '@/types/TCallLogs'
import { List } from 'antd'
import './CallDetails.scss'

export const CallIntakeResponse = ({ intakeResponse }: { intakeResponse: IntakeForm[] }) => {
  return (
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <List
        dataSource={intakeResponse}
        renderItem={(item) =>
          item && (
            <List.Item
              style={{
                display: 'flex',
                justifyContent: item.key === 'Question' ? 'flex-start' : 'flex-end',
                textAlign: item.key === 'Question' ? 'left' : 'right',
              }}
            >
              <List.Item.Meta title={<strong>{item.key}</strong>} description={item.value} />
            </List.Item>
          )
        }
      />
    </div>
  )
}
