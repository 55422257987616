import { useEffect, useState } from 'react'
import { MOBILE_WIDTH_THREASHOLD } from 'src/constants'

export const useMobileDeviceCheck = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const checkIfMobile = () => {
    const mobileWidthThreshold = MOBILE_WIDTH_THREASHOLD
    setIsMobile(window.innerWidth <= mobileWidthThreshold)
  }

  useEffect(() => {
    checkIfMobile()

    window.addEventListener('resize', checkIfMobile)

    return () => {
      window.removeEventListener('resize', checkIfMobile)
    }
  }, [])

  return { isMobile }
}
