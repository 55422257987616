import React, { useState, useEffect } from 'react'
import { IGroupData, IWorkflow, IAddEditGroupModalProps } from 'src/interfaces/IGroupInterfaces'
import DeleteModal from './DeleteModal'
import {
  useCreateGroup,
  useDeleteGroup,
  useFetchGroups,
  useFetchWorkflows,
  useUpdateGroup,
} from 'src/Hooks/UseHookForCrmData'
import { Modal, Button, Form, Input, Radio, Space, Tooltip } from 'antd'
import '../css/EditModalGroup.css'
import { CreateGroupData, UpdateGroupData } from '@/interfaces/ICrmApisInterface'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'

const AddEditGroupModal: React.FC<IAddEditGroupModalProps> = ({ setIsBoardModalOpen, type, boardToEdit }) => {
  const loggedInUser = useAppSelector(getLoggedInUser)
  const username = loggedInUser?.number
  const groupId = boardToEdit?.id || ''
  const [form] = Form.useForm()
  const [selectedWorkflows, setSelectedWorkflows] = useState<string[]>([])
  const [existingWorkflows, setExistingWorkflows] = useState<IWorkflow[]>([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [showWorkflowSelection, setShowWorkflowSelection] = useState<boolean>(false)

  const { mutate: createGroupData } = useCreateGroup()
  const { mutate: updateGroupData } = useUpdateGroup()
  const { mutate: deleteGroups, isPending: isDeleteGroupQueryLoading } = useDeleteGroup()
  const { refetch: refetchGroups } = useFetchGroups(username || '')

  useEffect(() => {
    if (type === 'edit' && boardToEdit) {
      form.setFieldsValue({
        groupName: boardToEdit?.name || '',
        groupId: boardToEdit?.id || '',
        definitionType: boardToEdit?.definitionType || 'scenario',
        description: boardToEdit?.definition?.description || '',
        selectedWorkflows: boardToEdit?.definition?.keywords || [],
        workflowName: boardToEdit?.workflow_name || '',
        triggerAction: boardToEdit?.trigger_action || '',
      })
      setSelectedWorkflows(boardToEdit?.definition?.keywords || [])
      setShowWorkflowSelection(boardToEdit?.definitionType === 'workflow')
    } else {
      form.setFieldsValue({
        definitionType: 'scenario',
      })
    }
  }, [type, boardToEdit, form])

  const { data } = useFetchWorkflows(username || '')

  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        if (!data) {
          console.warn('No data available to fetch client info.')
          return
        }
        const workflowNames: IWorkflow[] = []
        Object?.keys(data)?.forEach((key: any) => {
          const camelCaseKey = key?.replace(/_([a-z])/g, (g: any) => g[1]?.toUpperCase())
          let workflows = JSON.parse(data[key])
          if (workflows?.call_webhook_workflows) {
            workflows = workflows?.call_webhook_workflows
          }
          workflows?.forEach((workflow: any, index: number) => {
            workflowNames?.push({
              id: `${Math.floor(1000 + Math.random() * 9000)}${index}`,
              name: `${camelCaseKey}${index}`,
              scenario: workflow?.description || workflow?.scenario,
            })
          })
        })

        const boardItems = Array.isArray(boardToEdit) ? boardToEdit : []
        const boardWorkflows = boardItems
          ?.filter((item: any) => item?.definitionType === 'workflow')
          ?.flatMap((item: any) => item?.definition?.keywords || [])

        const filteredWorkflows = workflowNames?.filter((workflow) => !boardWorkflows?.includes(workflow.name))

        setExistingWorkflows(filteredWorkflows)
      } catch (error) {
        console.error('Error fetching client info:', error)
      }
    }

    if (username) {
      fetchClientInfo()
    }
  }, [username, boardToEdit, data])

  const handleWorkflowSelection = (workflowName: string) => {
    const newSelectedWorkflows = selectedWorkflows.includes(workflowName)
      ? selectedWorkflows.filter((name) => name !== workflowName)
      : [...selectedWorkflows, workflowName]

    setSelectedWorkflows(newSelectedWorkflows)
    form.setFieldsValue({ selectedWorkflows: newSelectedWorkflows })
  }

  const handleDefinitionTypeChange = (e: any) => {
    const value = e.target.value
    form.setFieldsValue({ definitionType: value })
    setShowWorkflowSelection(value === 'workflow')
  }

  const handleSubmit = (values: any) => {
    const { groupName, definitionType, description, workflowName, triggerAction } = values
    const groupData: IGroupData = {
      username,
      groupName,
      groupId: groupId || groupName.toLowerCase().replace(/ /g, '_'),
      definitionType,
      definition: {
        description,
        keywords: selectedWorkflows,
        phrases: [],
      },
      workflowName: workflowName,
      triggerAction: triggerAction,
    }

    try {
      if (type === 'add') {
        createGroupData(groupData as CreateGroupData, {
          onSuccess: () => {
            refetchGroups()
          },
          onError: (error) => {
            console.error('Error creating group:', error)
          },
        })
      } else if (type === 'edit') {
        updateGroupData({ ...groupData } as UpdateGroupData, {
          onSuccess: () => {
            refetchGroups()
          },
          onError: (error) => {
            console.error('Error updating group:', error)
          },
        })
      } else {
        throw new Error('Invalid operation type')
      }

      refetchGroups()
      setIsBoardModalOpen(false)
    } catch (error) {
      console.error('Failed to save group. Please try again.')
    }
  }

  const setOpenDeleteModal = () => setIsDeleteModalOpen(true)

  const onDeleteBtnClick = async () => {
    if (isDeleteGroupQueryLoading) return
    try {
      deleteGroups(
        { groupId, username: username || '' },
        {
          onSuccess: () => {
            refetchGroups()
            setIsDeleteModalOpen(false)
            setIsBoardModalOpen(false)
          },
          onError: (error) => {
            console.error('Error deleting group:', error)
          },
        }
      )
    } catch (err) {
      console.error('Failed to delete group. Please try again.')
    }
  }

  return (
    <Modal
      open
      title={null}
      footer={null}
      onCancel={() => setIsBoardModalOpen(false)}
      className="modal-custom"
      width={800}
      style={{ top: 20 }}
    >
      <div className="modal-content p-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          {type === 'edit' ? 'Edit Group' : 'Create a New Lead Group'}
        </h2>
        <p className="text-gray-600 mb-6">
          Groups help you organize leads based on specific criteria. Your AI Receptionist will automatically assign
          leads to these groups during calls.
        </p>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="groupName"
            label={<span className="text-gray-700 font-semibold">Group Name</span>}
            rules={[{ required: true, message: 'Please enter a name for this group' }]}
          >
            <Input placeholder="e.g., Pizza Enjoyers" autoComplete="off" className="rounded-md" />
          </Form.Item>

          <div className="mb-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">How should leads be added to this group?</h3>
            <Form.Item
              name="definitionType"
              rules={[{ required: true, message: 'Please select how leads should be added to this group' }]}
            >
              <Radio.Group onChange={handleDefinitionTypeChange}>
                <Space direction="vertical" className="w-full">
                  <Radio value="scenario" className="w-full p-4 border border-gray-200 rounded-md mb-4">
                    <span className="font-medium text-gray-800">Based on a specific scenario</span>
                    <p className="text-sm text-gray-600 mt-2">
                      Define a situation that would qualify a lead for this group
                    </p>
                  </Radio>
                  <Radio value="workflow" className="w-full p-4 border border-gray-200 rounded-md">
                    <span className="font-medium text-gray-800">When specific workflows are triggered</span>
                    <p className="text-sm text-gray-600 mt-2">
                      Select workflows that, when triggered, will add a lead to this group
                    </p>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>

          {form.getFieldValue('definitionType') === 'scenario' && (
            <Form.Item
              name="description"
              label={<span className="text-gray-700 font-semibold">Scenario Description</span>}
              rules={[{ required: true, message: 'Please describe the scenario for this group' }]}
            >
              <Input.TextArea
                placeholder="Describe the scenario in plain English, e.g., 'If the caller mentions they enjoy pizza'"
                rows={4}
                className="rounded-md"
              />
            </Form.Item>
          )}

          {form.getFieldValue('definitionType') === 'workflow' && showWorkflowSelection && (
            <>
              <Form.Item
                label={<span className="text-gray-700 font-semibold">Select Workflows</span>}
                name="selectedWorkflows"
                rules={[{ required: true, message: 'Please select at least one workflow' }]}
              >
                <div className="text-sm text-gray-600 mb-2">
                  Choose workflows that, when triggered during a call, will add the lead to this group:
                </div>
                <div className="workflow-selection grid grid-cols-2 gap-4">
                  {existingWorkflows.map((workflow) => (
                    <Tooltip
                      key={workflow.id}
                      title={
                        <>
                          <b>Scenario:</b> {workflow.scenario}
                        </>
                      }
                    >
                      <div
                        className={`workflow-item p-3 border rounded-md cursor-pointer transition-all duration-200 ${
                          selectedWorkflows.includes(workflow.name)
                            ? 'bg-gray-100 border-gray-400'
                            : 'border-gray-200 hover:border-gray-400'
                        }`}
                        onClick={() => handleWorkflowSelection(workflow.name)}
                      >
                        <span className="text-gray-800">{workflow.name}</span>
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </Form.Item>

              {selectedWorkflows.length > 0 && (
                <Form.Item
                  name="workflowName"
                  label={<span className="text-gray-700 font-semibold">Group Identifier</span>}
                  rules={[{ required: true, message: 'Please provide a name for this workflow combination' }]}
                >
                  <Input placeholder="e.g., Pizza Enjoyers" className="rounded-md" />
                </Form.Item>
              )}
            </>
          )}

          <Form.Item className="mt-8">
            <Space>
              <Button type="primary" htmlType="submit" size="large">
                {type === 'edit' ? 'Save Changes' : 'Create Group'}
              </Button>
              {type === 'edit' && (
                <Button danger onClick={setOpenDeleteModal} disabled={isDeleteGroupQueryLoading} size="large">
                  Delete Group
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>

        {isDeleteModalOpen && (
          <DeleteModal
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            type="Group"
            title={form.getFieldValue('groupName')}
            onDeleteBtnClick={onDeleteBtnClick}
          />
        )}
      </div>
    </Modal>
  )
}

export default AddEditGroupModal
