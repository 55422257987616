import LocalStorageUtility from '@/utils/localStorage';
import { Navigate, Outlet } from 'react-router-dom';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import { AppRoutes } from 'src/enums/ERoutes';
import { setNoviUser } from '@/store/novi/user/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { NoviUser } from '../types/NoviUser';

const NoviPrivateRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const authenticationStatus = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_AUTH_TOKEN)
  const isAuthenticated = !!authenticationStatus;

  if (isAuthenticated) {
    const noviUser = LocalStorageUtility.getLocalData(LocalStorageKeys.NOVI_USER);
    dispatch(setNoviUser(noviUser as NoviUser));
  }


  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={AppRoutes.NOVI_PORTAL_LOGIN} />
  );
};

export default NoviPrivateRoute;