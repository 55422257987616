//@ts-ignore
import RegisterCampaignV1 from '../../v1/Campaign/RegisterCampaign'

const RegisterCampaign = () => {
  return (
    <RegisterCampaignV1 />
  )
}

export default RegisterCampaign
