import { Button, notification, Spin } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { cancelSubReason, createClientPortalSession } from '@/api/user'
import { LoadingOutlined } from '@ant-design/icons'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { SyncAlt, Payment, DoDisturb } from '@mui/icons-material'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import CancelSubReasonModal from '@/components/CancelSubReasonModal/CancelSubReasonModal'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { openModal } from '@/store/modal/slice'
import { MODAL_IDS } from 'src/enums/EModal'

const ManageSubscription = () => {
  const loggedInUser = useAppSelector(getLoggedInUser)
  const username = loggedInUser.number as string
  const dispatch = useAppDispatch()

  const updatePaymentMehtodMutation = useMutation({
    mutationKey: ['update-payment-method'],
    mutationFn: createClientPortalSession,
    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error updating payment method' })
      } else {
        notification.success({
          message: 'Redirecting to payment portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
  })

  const CancelReasonMutation = useMutation({
    mutationKey: ['cancel-subscription-reason'],
    mutationFn: cancelSubReason,
    onSuccess: (data) => {
      if (!data?.success) {
        notification.error({ message: 'Error canceling subscription' })
      } else {
        cancelSubMutation.mutate({ username: username, action: 'subscription_cancel' })
      }
    },
    onError: () => {
      notification.error({ message: 'Error canceling subscription' })
    },
  })

  const cancelSubMutation = useMutation({
    mutationKey: ['cancel-subscription'],
    mutationFn: createClientPortalSession,

    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error canceling subscription' })
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
  })

  const upgradeSubMutation = useMutation({
    mutationKey: ['update-subscription'],
    mutationFn: createClientPortalSession,

    onSuccess: (data) => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error upgrading subscription' })
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        })
        window.location.href = data.redirectPaymentURL as string
      }
    },
  })

  const handleUpgrade = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          'You don’t have an active subscription to upgrade. Please subscribe to a subscription or contact support.',
      })
      return
    }
    upgradeSubMutation.mutate({ username: username, action: 'subscription_update' })
  }

  const handleUpdatePaymentMethod = () => {
    updatePaymentMehtodMutation.mutate({ username: username, action: 'payment_method_update' })
  }

  const handleCancelSubscription = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          'You don’t have an active subscription to cancel. Please subscribe to a subscription or contact support.',
      })
      return
    }
    dispatch(openModal({ modalId: MODAL_IDS.CANCEL_REASON_MODAL }))
  }

  const baseClass = 'manage-subscription'

  return (
    <>
      <div className={`${baseClass}__item py-2`}>
        <Spin spinning={upgradeSubMutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
          <LabelWithDescription
            // customClassName="w-8/12"
            label="Upgrade Subscription"
            description={
              <div className="flex flex-col gap-3">
                <p>Upgrade your subscription to get more features and benefits.</p>
                <Button onClick={handleUpgrade} className="w-8/12">
                  Upgrade Now
                </Button>
              </div>
            }
            icon={<SyncAlt className="mr-1 mb-1" />}
          />
        </Spin>
      </div>
      <div className={`${baseClass}__item py-2`}>
        <Spin
          spinning={updatePaymentMehtodMutation.isPending}
          indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
        >
          <LabelWithDescription
            // customClassName="w-8/12"
            label="Update Payment Method"
            description={
              <div className="flex flex-col gap-3">
                <p>Update your payment method for the billing.</p>
                <Button onClick={handleUpdatePaymentMethod} className="w-8/12">
                  Update Now
                </Button>
              </div>
            }
            icon={<Payment className="mr-1 mb-1" />}
          />
        </Spin>
      </div>
      <div className={`${baseClass}__item py-2`}>
        <Spin spinning={cancelSubMutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
          <LabelWithDescription
            // customClassName="w-8/12"
            label="Cancel Subscription"
            description={
              <div className="flex flex-col gap-3">
                <p>Cancel your subscription to stop the billing.</p>
                <Button onClick={handleCancelSubscription} className="w-8/12">
                  Cancel Now
                </Button>
              </div>
            }
            icon={<DoDisturb className="mr-1 mb-1" />}
          />
        </Spin>
      </div>
      <CancelSubReasonModal mutation={CancelReasonMutation} />
    </>
  )
}

export default ManageSubscription
