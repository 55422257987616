import React, { useEffect, useState } from 'react'
import './PhoneNumberSettings.scss'
import ForwardCalls from '../Steps/ForwardCalls'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getProvisionedNumber, getTwilioBundleStatus } from 'src/api/reservedNumbers'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import { getUser } from '@/api/user'

interface TestItOutProps {
  setOpened?: (open: boolean) => void
  setOpenedOnboarding: (open: boolean) => void
  debrand: boolean
}

export const PhoneNumberSettings: React.FC<TestItOutProps> = ({ debrand, setOpenedOnboarding }) => {
  const [provisionedNumber, setProvisionedNumber] = useState<string | null>(null)
  const loggedInUser = useAppSelector(getLoggedInUser)

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      setProvisionedNumber(data.provisioned_number)
    },
  })
  const { refetch } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  })

  const getBundleIdMutation = useMutation({
    mutationKey: ['getBundleId'],
    mutationFn: getTwilioBundleStatus,
    onSuccess() {
      refetch()
    },
  })

  useEffect(() => {
    getProvisionNumberMutation.mutate()
    if (loggedInUser.twilioBundleId) {
      getBundleIdMutation.mutate(loggedInUser.twilioBundleId)
    }
  }, [])

  const baseClass = 'test-it-out-container'

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__title`}>
        <h2 className="text-2xl font-bold">Your AI Phone Number</h2>
        <span>Manage your AI Phone Number here.</span>
      </div>
      <ForwardCalls
        debrand={debrand}
        provisionedNumber={provisionedNumber}
        setProvisionedNumber={setProvisionedNumber}
        getProvisionNumberMutation={getProvisionNumberMutation}
        setOpenedOnboarding={setOpenedOnboarding}
      />
    </div>
  )
}
