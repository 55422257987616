import { useAppSelector } from '@/store/hooks/useAppSelector'
//@ts-ignore
import Notifications from '../../../../v1/components/dashboard/Notifications/Notifications'
import { getLoggedInUser } from '@/store/account/selector'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
//@ts-ignore
import { prod } from '../../../../v1/config'
import { notification } from 'antd'

export const ManageNotifications = () => {
  const loggedInUser = useAppSelector(getLoggedInUser)
  const [callNotification, setCallNotification] = useState(false)
  const [callNotifEmailRecepients, setCallNotifEmailRecepients] = useState([])
  const [callNotifTextRecepients, setCallNotifTextRecepients] = useState([])
  const [textNotifEmailRecepients, setTextNotifEmailRecepients] = useState([])
  const [textNotifTextRecepients, setTextNotifTextRecepients] = useState([])
  const [notificationRecipientEmail, setNotificationRecipientEmail] = useState('')
  const [textNotification, setTextNotification] = useState(false)
  const [voicemailNotification, setVoicemailNotification] = useState(false)
  const [voicemailTextNotification, setVoicemailTextNotification] = useState(false)
  const [notificationScenarios, setNotificationScenarios] = useState([])
  const captcha2Ref = useRef(null)

  useEffect(() => {
    getNotificationSettings()
  }, [])

  const serverURL = import.meta.env.VITE_APP_API_BASE_URL

  const getNotificationSettings = async () => {
    axios
      .post(serverURL + '/get-notification-settings', { username: loggedInUser.number })
      .then((res) => {
        const callSettings = res.data?.data?.call_notif_settings?.S
        const textSettings = res.data?.data?.texting_platform_notif_settings?.S
        const workflowScenarioSettings = res.data?.data?.workflow_notif_settings?.S
        if (callSettings) {
          const callSettingsParsed = JSON.parse(callSettings)
          setCallNotifEmailRecepients(
            callSettingsParsed.email_recipients?.map((el: string) => ({
              label: el,
              value: el,
            }))
          )
          setCallNotifTextRecepients(
            callSettingsParsed.text_recipients?.map((el: string) => ({
              label: el,
              value: el,
            }))
          )
          setNotificationRecipientEmail(callSettingsParsed.email_recipients)
          setTextNotification(callSettingsParsed.text_enabled)
          setCallNotification(callSettingsParsed.call_enabled)
          setVoicemailNotification(callSettingsParsed.voicemail_enabled)
        }
        if (textSettings) {
          const textSettingsParsed = JSON.parse(textSettings)
          setTextNotifEmailRecepients(
            textSettingsParsed.email_recipients?.map((el: string) => ({
              label: el,
              value: el,
            }))
          )
          setTextNotifTextRecepients(
            textSettingsParsed.text_recipients?.map((el: string) => ({
              label: el,
              value: el,
            }))
          )
          setVoicemailTextNotification(textSettingsParsed.enabled)
        }
        if (workflowScenarioSettings) {
          let workflowScenarioSettingsParsed = JSON.parse(workflowScenarioSettings)
          workflowScenarioSettingsParsed = workflowScenarioSettingsParsed?.map((el: any) => {
            const convertedEmailRecipients =
              el.email_recipients?.map((el: string) => ({
                label: el,
                value: el,
              })) || []
            const convertedPhoneRecipients =
              el.text_recipients?.map((el: string) => ({
                label: el,
                value: el,
              })) || []

            return {
              ...el,
              email_recipients: convertedEmailRecipients,
              text_recipients: convertedPhoneRecipients,
            }
          })
          setNotificationScenarios(workflowScenarioSettingsParsed)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const requestResetLinkHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    //@ts-ignore
    const token = captcha2Ref.current.getValue()
    //@ts-ignore
    captcha2Ref.current.reset()

    if (prod) {
      try {
        const captchaResponse = await fetch(`${serverURL}/captcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
          }),
        })
        if (!captchaResponse.ok) {
          notification.error({
            key: 'form-error',
            message: 'Captcha failed!',
            duration: 3,
          })
          return
        }
        const captchaResult = await captchaResponse.json()
        if (captchaResult.status !== 'human') {
          notification.error({
            key: 'form-error',
            message: 'Please complete the captcha!',
            duration: 3,
          })
          return
        }
      } catch (err) {
        notification.error({
          key: 'form-error',
          message: 'Something went wrong!',
          duration: 3,
        })
      }
    }
  }

  return (
    <Notifications
      username={loggedInUser.number}
      themeColor={'#007bff'}
      callNotification={callNotification}
      textNotification={textNotification}
      voicemailNotification={voicemailNotification}
      notificationRecipientEmail={notificationRecipientEmail}
      serverURL={serverURL}
      setVoicemailNotification={setVoicemailNotification}
      setTextNotification={setTextNotification}
      setCallNotification={setCallNotification}
      setNotificationRecipientEmail={setNotificationRecipientEmail}
      requestResetLinkHandler={requestResetLinkHandler}
      callNotifEmailRecepients={callNotifEmailRecepients}
      setCallNotifEmailRecepients={setCallNotifEmailRecepients}
      callNotifTextRecepients={callNotifTextRecepients}
      setCallNotifTextRecepients={setCallNotifTextRecepients}
      textNotifEmailRecepients={textNotifEmailRecepients}
      setTextNotifEmailRecepients={setTextNotifEmailRecepients}
      textNotifTextRecepients={textNotifTextRecepients}
      setTextNotifTextRecepients={setTextNotifTextRecepients}
      voicemailTextNotification={voicemailTextNotification}
      setVoicemailTextNotification={setVoicemailTextNotification}
      notificationScenarios={notificationScenarios}
      setNotificationScenarios={setNotificationScenarios}
    />
  )
}
