import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '@/routes/hooks/useAuth'
import { Loading } from '@/components/Loading'

const PublicReceptionistRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  return isAuthenticated ? <Navigate to="/" /> : <Outlet />
}

export default PublicReceptionistRoute
