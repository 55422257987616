import { ResellerSiderItem } from 'src/enums/IResellerSiderItems'
import ResellerDashboard from '../ResellerDashboard'
import { Button } from 'antd'
import { useRef } from 'react'
import { setTour } from '@/store/resellerTour/slice'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'

const ResellerTutorials = () => {
  const ref = useRef(null)
  const dispatch = useAppDispatch()

  const handleOpen = () => {
    dispatch(setTour({
      currentStep: 0,
      open: true
    }))
  }
  return (
    <ResellerDashboard activeKey={ResellerSiderItem.TUTORIALS}>
      <div className="flex justify-end mr-4">
        <Button type="primary" onClick={handleOpen} ref={ref} className="text-base">
          Watch Tutorial
        </Button>
      </div>
      <div className="h-full pt-4 relative ">
        <iframe src="https://www.myaifrontdesk.com/tutorials" className="w-full h-full" />
      </div>
    </ResellerDashboard>
  )
}

export default ResellerTutorials
