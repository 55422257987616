import apiClient from "@/utils/apiClient";

const UPLOAD_RESELLER_LOGO_API_PATH = '/upload_reseller_logo'
const UPDATE_CAMPAIGN_USER_API_PATH = '/api/campaigns/users/update'

// api.ts
export const fetchUserBranding = async (emailAddress: string): Promise<any> => {
    return apiClient.request({
        method: 'GET',
        endPoint: `/api/campaigns/getBranding/${emailAddress}`,
    });
}

export const uploadResellerLogo = async (logo: File): Promise<string> => {
    const formData = new FormData();
    formData.append('logo', logo);

    const response = await apiClient.request({
        method: 'POST',
        endPoint: UPLOAD_RESELLER_LOGO_API_PATH,
        body: formData,
    });

    const data = await response;
    return data?.url;
};

export const updateCampaignUser = async (userData: {
    emailAddress: string;
    campaignUserName: string;
    uploadedLogoS3Url?: string;
}): Promise<void> => {
    await apiClient.request({
        method: 'POST',
        endPoint: UPDATE_CAMPAIGN_USER_API_PATH,
        headers: {
            'Content-Type': 'application/json',
        },
        body: userData,
    });
};
