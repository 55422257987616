import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { MODAL_IDS } from 'src/enums/EModal'
import { Typography, Space } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { getStripeClientSecret } from '@/api/user'
import toast from 'react-hot-toast'
import { CustomModal } from '../CustomModal'

const { Text, Title } = Typography

export const PlansModal = ({ username }: { username: string }) => {
  const dispatch = useAppDispatch()
  const [plansModalOpen, setPlansModalOpen] = useState(true)
  const [showStripeTable, setShowStripeTable] = useState(false)
  const [customerSecret, setCustomerSecret] = useState<string | null>(null)

  // UseQuery to get Stripe client secret
  const { data, error, refetch } = useQuery({
    queryKey: ['getStripeClientSecret', username],
    queryFn: () => getStripeClientSecret({ username }),
    enabled: plansModalOpen, // Only run the query if the modal is open
    staleTime: 1000 * 60 * 25, // 25 minutes to preempt the 30-minute expiration
    // @ts-ignore
    cacheTime: 1000 * 60 * 30, // Cache for 30 minutes
  })

  // Handle success and error within useEffect
  useEffect(() => {
    if (data) {
      if (data.success && data.customerSecret) {
        setCustomerSecret(data.customerSecret)
        setShowStripeTable(true)
      } else {
        toast.error('Error fetching customer secret. Please try again later.')
      }
    }
    if (error) {
      toast.error('Error fetching customer secret. Please try again later.')
    }
  }, [data, error])

  // Function to refresh customer secret manually
  const refreshCustomerSecret = useCallback(async () => {
    const refreshedData = await refetch()
    if (refreshedData.data?.success && refreshedData.data?.customerSecret) {
      setCustomerSecret(refreshedData.data.customerSecret)
      setShowStripeTable(true)
    } else {
      toast.error('Failed to refresh the session. Please try again.')
    }
  }, [refetch])

  // Set a timer to refresh the client secret before it expires
  useEffect(() => {
    if (showStripeTable && customerSecret) {
      const timer = setTimeout(
        () => {
          refreshCustomerSecret()
        },
        1000 * 60 * 25
      ) // Refresh after 25 minutes

      return () => clearTimeout(timer) // Cleanup the timer on unmount
    }
  }, [showStripeTable, customerSecret, refreshCustomerSecret])

  return (
    <CustomModal
      width={'80%'}
      title={null}
      modalId={MODAL_IDS.PLANS_MODAL}
      onClose={() => {
        dispatch(closeModal())
        setPlansModalOpen(false) // Reset state when modal is closed
        setShowStripeTable(false) // Reset state when modal is closed
      }}
      footer={null}
    >
      {showStripeTable ? (
        // @ts-ignore

        <stripe-pricing-table
          pricing-table-id={import.meta.env.VITE_APP_PRICING_TABLE_KEY}
          publishable-key={import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY}
          customer-session-client-secret={customerSecret || undefined}
        />
      ) : (
        <Space direction="vertical" size="large" style={{ textAlign: 'center', width: '100%' }}>
          <Title level={4}>Plans</Title>
          <Text type="secondary">Fetching plans...</Text>
        </Space>
      )}
    </CustomModal>
  )
}

export default PlansModal
