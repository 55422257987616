import React, { useState, useEffect } from "react";
// import clsx from "clsx";
// import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import { toast } from "react-hot-toast";
// import axios from "axios";
// import Joyride, { STATUS, EVENTS, ACTIONS } from "react-joyride";

import RegisterPageV3 from "./../RegisterPageV3";
import logo from "./../Blue_Logo.png";
import Login from "./CampaignLogin";
import classes from "../Whitelabel/ResellerPortal.module.css";
import WhiteLabelReceptionistDirectory from "../Portal/PortalComponents/WhitelabelReceptionistDirectory";
import { useLocation, useSearchParams } from "react-router-dom";
import { prod } from "../config";
import isEmpty from "../utils/is-empty";
import GuideBanner from "../components/Banner/GuideBanner";
import Outbound from "../components/Tables/OutboundTable";

// const sampleData = [
//   {
//     campaignName: "xyz",
//     event_id:
//       "188d95f40a241e76c2ee45285246347dcac9b0e2a317b7da9275777256693337",
//     outbound_calling_admin_email: "ruchir@myaifrontdesk.com",
//     unique_campaign_identifier:
//       "ruchir@myaifrontdesk.com, payment reminder 1713495162",
//     receipient_number: "+14232334938",
//     variables: [{ Name: "Mr. Alexander Smith" }, { "Amount Due": "$400" }],
//     event_execution_time: 1713495418,
//     event_state: "FAILED",
//     num_tries_so_far: 2,
//     errors: ["Call Failed", "Call Failed"],
//     execution_times: [120, 190],
//     state_histories: ["PENDING", "FAILED"],
//   },
//   {
//     campaignName: "abc",
//     event_id: "e76c2ee45285246347dcac9b0e2a317b7da9275777256693337",
//     outbound_calling_admin_email: "ruchir@dasd.com",
//     unique_campaign_identifier:
//       "ruchir@dsdadsa.com, payment reminder 1713495162",
//     receipient_number: "+14232334938",
//     variables: [{ Name: "Mr. Alexander Smith" }, { "Amount Due": "$400" }],
//     event_execution_time: 1713495418,
//     event_state: "SUCCESSFUL",
//     num_tries_so_far: 2,
//     errors: ["Call Failed", "Call Failed"],
//     execution_times: [78, 20],
//     state_histories: ["PENDING", "SUCCESSFUL"],
//   },
//   // Add more sample data items as needed
// ];

var serverURL = prod
  ? "https://myaifrontdeskadmin.com"
  : "http://localhost:8080";
function CampaignPortal({ debrand = true }) {
  const isMobile = window.innerWidth <= 768;
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegistrationModal, setIsRegistrationModalActive] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("my_campaigns");
  const [imageUrl, setImageUrl] = useState("");
  const [flipThisBooleanToReloadClients, setFlipThisBooleanToReloadClients] =
    useState(false);
  const [uploadedLogoS3Url, setUploadedLogoS3Url] = useState("");
  const [refreshInt, setRefreshInt] = useState(0); // Incrementing this will update getStuff in Campaign User Login
  const [debrandModalActive, setDebrandModalActive] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [isBannerActive, setIsBannerActive] = useState(true);
  const location = useLocation();
  const [color, setColor] = useState("#91bef");
  // campaign states
  const [reservedCampaignLists, setReservedCampaignLists] = useState(0);
  const [campaigns, setCampaigns] = useState([]);

  // const [{ steps, run, step }, setJoyrideState] = useState({
  //   run: false,
  //   step: 0,
  //   steps: [
  //     {
  //       target: "body",
  //       content: (
  //         <div style={{ textAlign: "center", padding: "20px" }}>
  //           <img
  //             src={logo}
  //             alt="My AI Front Desk Logo"
  //             style={{ width: "100px", height: "100px" }}
  //           />
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             👋 Welcome to the Reseller Program!
  //           </h2>
  //           <p style={{ fontSize: "18px" }}>
  //             Purchase whitelabeled receptionists at retail price. Resell them
  //             for whatever price you'd like. Let's begin the tour.
  //           </p>
  //         </div>
  //       ),
  //       locale: { skip: <strong aria-label="skip">Skip Tour</strong> },
  //       placement: "center",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             Create a White Labeled Receptionist
  //           </h2>
  //           <p style={{ fontSize: "18px" }}>
  //             Start by configuring a new receptionist. You will do this manually
  //             for each whitelabeled receptionist you plan to resell.
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "auto",
  //       target: ".register-client",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             View all your Receptionists
  //           </h2>
  //           <p style={{ fontSize: "18px" }}>
  //             View all your receptionists in one place. <br />
  //             <br /> You can click into any of your receptionists and instantly
  //             log-in via popup to manage many receptionists at once.
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "auto",
  //       target: ".view-receptionists",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2
  //             style={{
  //               fontSize: "24px",
  //               fontWeight: "bold",
  //               marginTop: "20px",
  //             }}
  //           >
  //             Whitelabeled Client Dashboard
  //           </h2>
  //           <p style={{ fontSize: "18px", marginTop: "20px" }}>
  //             Your clients can edit their receptionist, view logs, and more in
  //             the whitelabeled dashboard. <br /> <br /> Embed this into your own
  //             website as an iframe to allow your clients to manage their
  //             receptionist.
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "auto",
  //       target: ".client-dashboard",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2
  //             style={{
  //               fontSize: "24px",
  //               fontWeight: "bold",
  //               marginTop: "20px",
  //             }}
  //           >
  //             Whitelabeled Client Registration
  //           </h2>
  //           <p style={{ fontSize: "18px", marginTop: "20px" }}>
  //             Your clients can self-serve register one of your white-labeled
  //             receptionists too! <br /> <br /> Embed this white-labeled
  //             registration page into your own website as an iframe, and send it
  //             to your clients to allow them to register.
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "auto",
  //       target: ".client-registration",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             Branding Settings
  //           </h2>
  //           <p style={{ fontSize: "18px" }}>
  //             Change your company name and logo anytime. <br /> <br /> Any
  //             branding changes will immediately apply to all your receptionists,
  //             dashboards, notifications, and more.
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "auto",
  //       target: ".branding-settings",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             View Tutorial
  //           </h2>
  //           <p style={{ fontSize: "18px" }}>
  //             Access product tutorials here. Replay this tutorial from here as
  //             well.
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "auto",
  //       target: ".view-tutorial",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center" }}>
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             Need help setting up?
  //           </h2>
  //           <p style={{ fontSize: "18px" }}>
  //             Let us guide you through it.{" "}
  //             <a
  //               href="https://calendly.com/myaifrontdesk/partner-consultation"
  //               rel="noreferrer"
  //               target="_blank"
  //             >
  //               Book an onboarding call here for free
  //             </a>
  //           </p>
  //         </div>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       placement: "center",
  //       target: "body",
  //     },
  //     {
  //       content: (
  //         <div style={{ textAlign: "center", padding: "20px" }}>
  //           {!debrand && (
  //             <img
  //               src={logo}
  //               alt="My AI Front Desk Logo"
  //               style={{ width: "100px", height: "100px" }}
  //             />
  //           )}
  //           <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
  //             {" "}
  //             🎉 Tutorial Complete{" "}
  //           </h2>
  //           {!debrand && (
  //             <p style={{ fontSize: "18px" }}>
  //               For help, email{" "}
  //               <a href="mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you">
  //                 {" "}
  //                 contact@myaifrontdesk.com.
  //               </a>{" "}
  //               This concludes the tutorial. <br /> <br /> Start by exploring
  //               the dashboard and registering your first client! You can replay
  //               this tutorial any time in the "tutorials" section.
  //             </p>
  //           )}
  //         </div>
  //       ),
  //       locale: { skip: <strong aria-label="skip">Skip Tour</strong> },
  //       placement: "center",
  //       target: "body",
  //     },
  //   ].filter((step) =>
  //     debrand
  //       ? step.target !== ".client-dashboard" &&
  //         step.target !== ".client-registration"
  //       : true
  //   ),
  // });

  const refreshQuotesAndClients = async () => {
    setFlipThisBooleanToReloadClients(!flipThisBooleanToReloadClients);
    // console.log("Just flipped");
    try {
      const response = await fetch(`${serverURL}/campaigns/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailAddress: user.email_address?.S,
          password: user.password?.S,
        }),
      });

      const data = await response.json();

      if (data.success) {
        const campaignUserObject = data.campaignUser;

        setUser(campaignUserObject);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log("Couldn't refresh campaign user");
      localStorage.removeItem("CAMPAIGN_USER_PORTAL_USER");
      window.location.reload();
    }
  };

  // const handleJoyrideCallback = (data) => {
  //   const { status, type, action, index } = data;
  //   // console.log(data);
  //   if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
  //     const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
  //     let timeoutTime = 0;
  //     timeoutTime = 1000;
  //     if (nextStep === 0) {
  //       setSelectedItem("Receptionist");
  //     }
  //     if (nextStep === 1) {
  //       setSelectedItem("Receptionist");
  //     }
  //     if (nextStep === 2) {
  //       setSelectedItem("Receptionist");
  //     }
  //     if (nextStep === 3) {
  //       setSelectedItem("Embed");
  //     }
  //     if (nextStep === 4) {
  //       setSelectedItem("Embed");
  //     }
  //     if (nextStep === 5) {
  //       setSelectedItem("Branding");
  //     }
  //     if (nextStep === 6) {
  //       setSelectedItem("Tutorials");
  //     }
  //     if (nextStep === 7) {
  //       setSelectedItem("Receptionist");
  //     }
  //     if (nextStep === 4 || nextStep === 3) {
  //       setOpen(true);
  //     } else {
  //       setOpen(false);
  //     }
  //     setTimeout(() => {
  //       setJoyrideState((prev) => ({
  //         ...prev,
  //         step: nextStep,
  //       }));
  //     }, timeoutTime);
  //   } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
  //       localStorage.setItem("HAS_MY_AI_TUTORIAL_BEEN_WATCHED", true);
  //     }
  //     setOpen(false);
  //     setSelectedItem("Receptionist");
  //     setJoyrideState((prev) => ({ ...prev, step: 0, run: false }));
  //   }
  // };

  useEffect(() => {
    if (!isEmpty(user)) {
      if (typeof user === "string")
        setColor(JSON.parse(user)?.theme_color?.S || "#007bf");
      else setColor(user?.theme_color?.S || "#007bf");
    }
  }, [user]);

  useEffect(() => {
    // if (
    //   location.state &&
    //   location.state.emailAddress &&
    //   location.state.password
    // ) {
    // setShowTutorial(true);
    const HAS_MY_AI_TUTORIAL_BEEN_WATCHED = localStorage.getItem(
      "HAS_MY_AI_TUTORIAL_BEEN_WATCHED"
    );
    // if (!HAS_MY_AI_TUTORIAL_BEEN_WATCHED)
    //   setJoyrideState((prev) => ({ ...prev, run: true }));
    // }
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [selectedItem]);

  // Load Campaign User
  useEffect(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    // Download logo
    if (user && user.logo_s3 && user.logo_s3?.S) {
      fetch(`${serverURL}/download_reseller_logo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: user.logo_s3?.S,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          setImageUrl(objectUrl);
        })
        .catch((error) => console.error(error));
    }
  }, [user]);

  const Sidebar = (
    <div
      className="flex flex-col shadow-xl"
      style={{
        flex: "0 0 300px", // Set a fixed width for the sidebar (e.g., 300px)
        position: isMobile ? "fixed" : "sticky",
        top: 0,
        left: 0,
        bottom: 0,
        fontFamily: "Quicksand, sans-serif",
        color: "#333",
        overflowY: "auto", // Enable vertical scrolling if the content overflows
        zIndex: 100,
        height: !isMobile && "100vh",
        maxWidth: "264px",
        overflowX: "hidden",
        background: "#fff",
      }}
    >
      <div className="p-4 flex-grow">
        {/* Sidebar content here */}

        {isMobile && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 200,
            }}
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        )}

        {!debrand ? (
          <img
            className="w-14 mx-auto block"
            src={logo}
            alt="AI Receptionist"
          />
        ) : (
          imageUrl && (
            <img
              className="w-14 mx-auto block"
              src={imageUrl}
              alt="AI Receptionist"
            />
          )
        )}

        <ul className="mt-5 pt-5 border-t border-solid border-gray-200 flex flex-col gap-1">
          <li
            className="view-receptionists"
            style={{
              color: selectedItem === "my_campaigns" ? color : "#333",
              fontWeight: selectedItem === "my_campaigns" ? "bold" : "normal",
              fontSize: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              background:
                selectedItem === "my_campaigns" ? "#F0F0F0" : "transparent",
            }}
            onClick={() => setSelectedItem("my_campaigns")}
          >
            <span style={{ marginRight: "10px" }}>👩‍💼</span>
            My Campaigns
          </li>
          {/* embed list */}
          {/* {!debrand && (
            <li
              style={{
                margin: "8px 0",
                color: selectedItem === "Embed" ? color : "#333",
                fontWeight: selectedItem === "Embed" ? "bold" : "normal",
                fontSize: "16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                background:
                  selectedItem === "Embed" ? "#F0F0F0" : "transparent",
              }}
              onClick={() => setSelectedItem("Embed")}
            >
              <span style={{ marginRight: "10px" }}>🔗</span>
              Embed
            </li>
          )} */}
          <li
            className="branding-settings"
            style={{
              color: selectedItem === "Branding" ? color : "#333",
              fontWeight: selectedItem === "Branding" ? "bold" : "normal",
              fontSize: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              background:
                selectedItem === "Branding" ? "#F0F0F0" : "transparent",
            }}
            onClick={() => setSelectedItem("Branding")}
          >
            <span style={{ marginRight: "10px" }}>🔖</span>
            Branding
          </li>

          <li
            style={{
              color: selectedItem === "outbound" ? color : "#333",
              fontWeight: selectedItem === "outbound" ? "bold" : "normal",
              fontSize: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              background:
                selectedItem === "outbound" ? "#F0F0F0" : "transparent",
            }}
            onClick={() => setSelectedItem("outbound")}
          >
            <span style={{ marginRight: "10px" }}>
              <img className="w-4" src="./outbound.svg" />
            </span>
            Outbound Visualizer
          </li>
          {/* Tutorial List */}
          {/* {!debrand && (
            <li
              className="view-tutorial"
              style={{
                margin: "8px 0",
                color: selectedItem === "Tutorials" ? color : "#333",
                fontWeight: selectedItem === "Tutorials" ? "bold" : "normal",
                fontSize: "16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                background:
                  selectedItem === "Tutorials" ? "#F0F0F0" : "transparent",
              }}
              onClick={() => setSelectedItem("Tutorials")}
            >
              <span style={{ marginRight: "10px" }}>📄</span>
              Tutorials
            </li>
          )} */}
          {/* <li
            style={{
              margin: "8px 0",
              color: selectedItem === "Billing" ? color : "#333",
              fontWeight: selectedItem === "Billing" ? "bold" : "normal",
              fontSize: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              background:
                selectedItem === "Billing" ? "#F0F0F0" : "transparent",
            }}
            onClick={() => setSelectedItem("Billing")}
          >
            <span style={{ marginRight: "10px" }}>💵</span>
            Billing
          </li> */}
          <li
            style={{
              color: color,
              fontWeight: "bold",
              fontSize: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              background: "transparent",
            }}
            onClick={() => {
              localStorage.removeItem("CAMPAIGN_USER_PORTAL_USER");
              window.location.reload();
              setUser({});
              window.history.replaceState({}, "");
            }}
          >
            <span style={{ marginRight: "10px" }}>📤</span>
            Logout
          </li>
        </ul>
      </div>

      {
        <div
          style={{
            margin: "8px 0",
            color: "#333",
            fontSize: "0.6rem",
            textAlign: "center",
            padding: "8px 16px",
            background: "transparent",
            justifyContent: "center",
            boxSizing: "border-box",
            bottom: 0,
            width: "100%",
          }}
        >
          {!debrand && "Copyright © 2024 AI Front Desk, Inc."}
          <br />
          All Rights Reserved.
        </div>
      }
    </div>
  );

  return isEmpty(user) ? (
    <>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      <Login
        title={"Outbound Calls Management Portal"}
        setUser={setUser}
        // debrand={debrand}
      />
    </>
  ) : (
    <>
      {debrandModalActive && (
        <>
          <div
            onClick={async () => {
              await refreshQuotesAndClients();
              setDebrandModalActive(false);
            }}
            className={classes.modalOverlay}
          ></div>
          <div className={classes.modal}>
            <div
              className={classes.close}
              onClick={async () => {
                await refreshQuotesAndClients();
                setDebrandModalActive(false);
              }}
            >
              &times;
            </div>
            <h2>1 New Campaign Created</h2>

            <p
              style={{
                marginBottom: "50px", // Add marginBottom
              }}
            >
              To view, edit, and share your Campaign click the "My Campaigns"
              tab on the left
            </p>

            <button
              style={{
                backgroundColor: color,
                color: "white",
                padding: "8px 16px",
                borderRadius: "4px",
                border: "none",
                textAlign: "left",
                position: "absolute", // Add position absolute to button
                bottom: 0, // Align button to bottom
                right: 30, // Align button to left
                marginBottom: "24px", // Add marginBottom
                marginLeft: "12px", // Add marginLeft
              }}
              onClick={async (e) => {
                e.preventDefault();
                await refreshQuotesAndClients();
                setDebrandModalActive(false);
              }}
            >
              Okay
            </button>
          </div>
        </>
      )}
      {/* {run && (
        <Joyride
          stepIndex={step}
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          scrollToFirstStep
          steps={steps}
          showSkipButton
          showCloseButton={false}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
        />
      )} */}
      <div
        className={classes.mainContainer}
        style={{ display: "flex", minHeight: !isMobile && "100vh" }}
      >
        {/* <Toaster position="top-center" reverseOrder={false} /> */}

        {(!isMobile || isOpen) && Sidebar}

        <div
          style={{
            flex: "1", // Take up the remaining width
            display: "flex",
            flexDirection: "column",
            fontFamily: "Quicksand, sans-serif",
            // width: "100%",
            minHeight: !isMobile && "100vh",
            padding: "16px",
            position: "relative",
          }}
        >
          {!debrand && isBannerActive && (
            <GuideBanner color={color} setIsBannerActive={setIsBannerActive} />
          )}

          {/* Campaign Seller Name */}

          {/* <div className={classes.logoContainer}>
            {imageUrl && (
              <img
                className={classes.logo}
                src={imageUrl}
                alt="logo"
                style={{ borderRadius: "10px" }}
              />
            )}

            <h3
              onClick={() => {
                // setJoyrideState((prev) => ({ ...prev, run: true }));
              }}
            >
              {user.reseller_name?.S}
            </h3>
          </div> */}

          {isMobile && (
            <Hamburger
              style={{ position: "relative", zIndex: 200 }}
              toggled={isOpen}
              toggle={setOpen}
            />
          )}
          <div
            style={{
              width: "100%",
            }}
          >
            {isRegistrationModal && (
              <>
                <div
                  onClick={() => {
                    setIsRegistrationModalActive(false);
                  }}
                  className={classes.modalOverlay}
                ></div>
                <div className={classes.modal + " " + classes.wide}>
                  <div
                    className={classes.close}
                    onClick={() => {
                      setIsRegistrationModalActive(false);
                    }}
                  >
                    &times;
                  </div>

                  <RegisterPageV3
                    isCampaign
                    debrand
                    campaign_user_email={user.email_address?.S}
                    campaign_user_password={user?.password?.S}
                    purchase_quotes={user.purchase_quotes?.S}
                    campaign_user_name={user.campaign_user_name?.S}
                    debranded_registration_modal_active_setter={
                      setIsRegistrationModalActive
                    }
                    debrand_modal_instructions_setter={setDebrandModalActive}
                  />
                </div>
              </>
            )}

            {/* {selectedItem === "Receptionist" && user && user.email_address && ( */}
            {selectedItem === "my_campaigns" && (
              <WhiteLabelReceptionistDirectory
                color={color}
                user={user}
                reservedCampaignLists={reservedCampaignLists}
                setReservedCampaignLists={setReservedCampaignLists}
                isCampaign={true}
                reseller_email={user.email_address?.S}
                reseller_name={user.reseller_name?.S}
                // isRegistrationModal={isRegistrationModal}
                setIsRegistrationModalActive={setIsRegistrationModalActive}
                reloadClients={flipThisBooleanToReloadClients}
                debrand={debrand}
                setCampaigns={setCampaigns}
              />
            )}

            {selectedItem === "Branding" && (
              <Branding
                color={color}
                isMobile={isMobile}
                user={user}
                setUser={setUser}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                imageUrl={imageUrl}
                setDebrandModalActive={setDebrandModalActive}
                debrandModalActive={debrandModalActive}
                setRefreshInt={setRefreshInt}
                setUploadedLogoS3Url={setUploadedLogoS3Url}
                uploadedLogoS3Url={uploadedLogoS3Url}
                showTutorial={showTutorial}
                setShowTutorial={setShowTutorial}
              />
            )}

            {selectedItem === "outbound" && (
              <Outbound
                email_address={user?.email_address?.S}
                campaigns={campaigns}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignPortal;

const Branding = ({
  color,
  user,
  // setUser,
  // selectedItem,
  // setSelectedItem,
  // isMobile,
  imageUrl,
  // setDebrandModalActive,
  // debrandModalActive,
  setRefreshInt,
  setUploadedLogoS3Url,
  uploadedLogoS3Url,
  // showTutorial,
  // setShowTutorial,
}) => {
  // const navigate = useNavigate();

  // const [isBrandingModalActive, setIsBrandingModalActive] = useState(false);
  const [isInfoModalActive, setIsInfoModalActive] = useState(false);
  const [resellerLogo, setResellerLogo] = useState("");
  // const [isRegistrationModal, setIsRegistrationModalActive] = useState(false);
  // const [
  //   showRegisterResellerInstructionsDialog,
  //   setShowRegisterResellerInstructionsDialog,
  // ] = useState(false);
  const [updatedCampaignUserName, setUpdatedCampaignUserName] = useState("");
  const [themeColor, setThemeColor] = useState("#007bff");

  useEffect(() => {
    setUpdatedCampaignUserName(user.campaign_user_name?.S);
    if (user?.theme_color?.S) setThemeColor(user.theme_color?.S);
  }, [user]);

  // const registerResellerInstructionsDialog = (
  //   <div>
  //     <div
  //       style={{
  //         display: showRegisterResellerInstructionsDialog ? "block" : "none",
  //         position: "fixed",
  //         top: "50%",
  //         left: "50%",
  //         transform: "translate(-50%, -50%)",
  //         background: "white",
  //         boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  //         borderRadius: "10px",
  //         padding: "20px",
  //         zIndex: "98",
  //       }}
  //     >
  //       <button
  //         style={{
  //           position: "absolute",
  //           top: "10px",
  //           right: "10px",
  //           background: "transparent",
  //           border: "none",
  //           cursor: "pointer",
  //           fontSize: "18px",
  //         }}
  //         onClick={() => {
  //           setShowRegisterResellerInstructionsDialog(false);
  //         }}
  //       >
  //         X
  //       </button>
  //       <h2> 😁 Let's Purchase an Unbranded Receptionist! </h2>
  //       <ul>
  //         <li>
  //           In the next step, you will purchase a new unbranded receptionist.
  //         </li>
  //         <li>After purchasing, we'll direct you back to this portal.</li>{" "}
  //         <li>
  //           You will be able to create the unbranded receptionist you have just
  //           purchased.{" "}
  //         </li>
  //       </ul>
  //       <br />
  //       <button
  //         onClick={async () => {
  //           setShowRegisterResellerInstructionsDialog(false);

  //           window.gtag("event", "resellerPurchaseClicked", {
  //             event_category: "EditDemo.js",
  //             event_label: "success",
  //           });

  //           try {
  //             const response = await fetch(
  //               `${serverURL}/create-checkout-session-new-resellers`,
  //               {
  //                 method: "POST",
  //                 headers: {
  //                   "Content-Type": "application/json",
  //                 },
  //                 body: JSON.stringify({
  //                   resellerEmail: user.email_address?.S,
  //                 }),
  //               }
  //             );

  //             const data = await response.json();
  //             window.location.href = data.redirectPaymentURL;
  //           } catch (error) {
  //             window.gtag("event", "couldntPurchase", {
  //               event_category: "EditDemo.js",
  //               event_label: "error",
  //             });
  //             console.error(error);
  //           }
  //         }}
  //         style={{
  //           background: "blue",
  //           color: "white",
  //           padding: "10px 20px",
  //           borderRadius: "5px",
  //           border: "none",
  //           cursor: "pointer",
  //           marginTop: "10px",
  //         }}
  //       >
  //         Purchase Now 🎉
  //       </button>
  //     </div>
  //   </div>
  // );

  return (
    <>
      {isInfoModalActive && (
        <>
          <div
            onClick={() => setIsInfoModalActive(false)}
            className={classes.modalOverlay}
          ></div>
          <div className={classes.modal}>
            <div
              className={classes.close}
              onClick={() => setIsInfoModalActive(false)}
            >
              &times;
            </div>
            <h2>Information:</h2>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
              debitis ullam veniam quisquam voluptatem laudantium ut
              reprehenderit nisi earum eius, officiis officia, quidem
              exercitationem provident iste id dolorem minima tempore.
            </p>
          </div>
        </>
      )}

      {/* {registerResellerInstructionsDialog} */}

      <>
        <label htmlFor="business-name">Business name</label>
        <br />
        <input
          id="business-name"
          type="text"
          value={updatedCampaignUserName}
          style={{
            display: "block",
            height: "40px",
            border: "1px solid #ccc",
            outline: "none",
            padding: "0 10px",
            borderRadius: "4px",
          }}
          onChange={(e) => setUpdatedCampaignUserName(e.target.value)}
        />
        <br />

        <label htmlFor="theme-color">Theme color</label>
        <br />
        <input
          id="theme-color"
          type="color"
          value={themeColor}
          style={{
            display: "block",
            width: "100%",
            maxWidth: "280px",
            // height: "40px",
            border: "1px solid #ccc",
            outline: "none",
            // padding: "0 10px",
            borderRadius: "4px",
          }}
          onChange={(e) => setThemeColor(e.target.value)}
        />
        <br />

        <label
          htmlFor="business-logo"
          style={{
            cursor: "pointer",
            display: "block",
            maxWidth: "300px",
          }}
        >
          Business logo
          <br />
          <div
            style={{
              margin: "6px 0 0",
              display: "block",
              textAlign: "center",
              border: "1px solid rgb(223, 225, 228)",
              maxWidth: "300px",
              padding: "10px",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: ".75rem",
            }}
          >
            {resellerLogo?.name ? (
              resellerLogo?.name
            ) : imageUrl ? (
              <img className={classes.w100} src={imageUrl} alt="logo" />
            ) : (
              "Click to attach"
            )}
          </div>
          <input
            id="business-logo"
            type="file"
            onChange={(e) => {
              setResellerLogo(
                e.target.files.length > 0 ? e.target.files[0] : {}
              );
              // setUser((prev) => ({
              //   ...prev,
              //   debrand_name: e.target.debrand_name,
              // }))
            }}
          />
        </label>
        <label
          style={{
            backgroundColor: color,
            color: "white",
            padding: "8px 16px",
            borderRadius: "4px",
            border: "none",
            textAlign: "left",
            marginTop: "16px",
            width: "max-content",
            display: "block",
            cursor: "pointer",
            marginBottom: "12px",
          }}
          className={classes.uploadBtn}
          htmlFor="business-logo"
        >
          Click to upload
        </label>

        <p>
          <em> You will need to refresh the page to view your changes. </em>
        </p>
        <button
          style={{
            backgroundColor: color,
            color: "white",
            padding: "8px 16px",
            borderRadius: "4px",
            border: "none",
            textAlign: "left",
            marginTop: "16px",
            marginLeft: "auto",
            width: "68px",
            display: "block",
            marginBottom: "12px",
          }}
          onClick={async () => {
            let url;
            try {
              if (resellerLogo) {
                const formData = new FormData();
                formData.append("logo", resellerLogo);

                const logoResponse = await fetch(
                  `${serverURL}/upload_reseller_logo`,
                  {
                    method: "POST",
                    body: formData,
                  }
                );

                if (!logoResponse.ok) {
                  toast.error("Logo upload failed");
                } else {
                  toast.success("Logo uploaded successfully");
                  const responseData = await logoResponse.json();
                  url = responseData.url;
                  setUploadedLogoS3Url(responseData.url);
                }
              }

              // const response =
              await fetch(`${serverURL}/api/campaigns/users/update`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  emailAddress: user.email_address?.S,
                  campaignUserName: updatedCampaignUserName,
                  themeColor,
                  uploadedLogoS3Url: uploadedLogoS3Url || url,
                }),
              });

              // const data = await response.json();

              // setIsBrandingModalActive(false);

              setRefreshInt((prev) => prev + 1);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          Save
        </button>
      </>
    </>
  );
};
