export const getGoogleCalendarOAuthURL = () => {
  const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
  const baseUrl = import.meta.env.VITE_APP_API_BASE_URL
  const clientId = import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID

  const redirect_uri = `${baseUrl}/api/oauth/google-calendar-callback`

  const options = {
    redirect_uri: redirect_uri,
    client_id: clientId,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/calendar',
    ].join(' '),
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}
