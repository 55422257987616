import { TAllCallLogs, TAnalytics } from '@/types/TAnalytics'
import { Heatmap } from '@ant-design/plots'
import moment from 'moment'

export const AllCallLogs = ({ analyticsData }: TAnalytics) => {
  const config = {
    data: analyticsData,
    xField: (row: TAllCallLogs) =>
      moment()
        .month(row.month - 1)
        .format('MMM'),
    yField: 'year',
    colorField: 'value',
    mark: 'cell',
    style: { inset: 5 },
    scale: { color: { range: ['#86abe0', '#242f85'] } },
    tooltip: {
      title: (row: TAllCallLogs) =>
        moment()
          .month(row.month - 1)
          .format('MMMM YYYY'),
      items: [
        {
          name: 'Total Calls',
          channel: 'color',
        },
      ],
    },
  }

  return <Heatmap {...config} />
}
