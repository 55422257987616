import React from "react";
import Fade from "react-reveal/Fade";

const PasswordRegisterForm = ({
  classes,
  onChangePassword,
  confirmPassword,
  passwordMatchError,
  setConfirmPassword,
  password,
  setPasswordMatchError,
  termsAndConditions,
  setTermsAndConditions,
  toast,
  handleSubmit,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: "center",
            }}
          >
            Create Password
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: "center",
            }}
          >
            You will use this password to log in to your dashboard
          </div>

          <input
            type="password"
            value={password}
            className={classes.input + " " + classes.centered}
            required
            onChange={onChangePassword}
          />

          {password.length > 0 ? (
            <label
              style={{
                textAlign: "center",
                marginTop: "1.5rem",
                display: "block",
              }}
            >
              <p className={classes.heading} style={{ textAlign: "center" }}>
                *Confirm Password
              </p>
              <p className={classes.subtitle}>
                Please enter your password once again{" "}
              </p>
              <input
                className={classes.input + " " + classes.centered}
                type="password"
                value={confirmPassword}
                required
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                  if (password !== event.target.value) {
                    setPasswordMatchError(true);
                    return;
                  } else {
                    setPasswordMatchError(false);
                  }
                }}
              />
            </label>
          ) : (
            <></>
          )}
          <div
            style={{
              height: "26px",
              width: "100%",
              marginTop: ".5rem",
            }}
          >
            {passwordMatchError ? (
              <p
                className={classes.label}
                style={{
                  color: "red",
                  textAlign: "center",
                  margin: 0,
                }}
              >
                Passwords do not match.
              </p>
            ) : (
              <></>
            )}
          </div>

          <div style={{ textAlign: "center" }}>
            {password && confirmPassword && (
              <div>
                <input
                  type="checkbox"
                  name="termsAndConditions"
                  value={termsAndConditions}
                  onChange={(e) => setTermsAndConditions(e.target.checked)}
                />
                <label htmlFor="termsAndConditions" className="ml-2">
                  I agree to the{" "}
                  <a
                    href="https://assets-global.website-files.com/646676436cb9dc8974098e06/65a60030580c5ed45b310736_Terms%20and%20Conditions.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                </label>
              </div>
            )}
          </div>

          <button
            disabled={
              password === null ||
              password === undefined ||
              password.trim().length === 0 ||
              password !== confirmPassword ||
              !termsAndConditions
            }
            className={classes.button + " " + classes.centered}
            style={{
              marginTop: "2rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (!password || !confirmPassword) {
                toast.error("Please enter a password");
              }

              if (handleSubmit) handleSubmit(e);
              else console.log("No handle submit");
            }}
          >
            Create My Campaign Account!
          </button>
        </Fade>
      </div>
    </>
  );
};

export default PasswordRegisterForm;
