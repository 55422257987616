import { AuthType } from '@/types/TAuth'
import moment from 'moment'
import LocalStorageUtility from './localStorage'
import { LocalStorageKeys } from 'src/enums/ELocalStorage'
import { parsePhoneNumber } from 'libphonenumber-js'

export const formatQuestionnaire = (qa?: string) => {
  if (!qa) return [];

  const qaArray = qa
    .trim()
    .split(/(?:Qn|Q|Question|Que|qn|q|due)[\:\-]/i)
    .slice(1)
    .map(qaBlock => {
      const [question, answer] = qaBlock.split(/(?:^|\n)(?:A|An|Answer|Ans|ans|a)[\:\-]/i);

      return {
        question: question.trim(),
        answer: answer ? answer.trim() : '',
      };
    });

  return qaArray;
};

export const formatQAString = (qaPairs: { question: string; answer: string }[]) => {
  if (!qaPairs) return ''
  return qaPairs.map(({ question, answer }) => `Q: ${question}\nA: ${answer}`).join('\n\n')
}

export const renderPSTDate = (utcDate: string, timeZone: string) => {
  const pstDate = moment.utc(utcDate).tz(timeZone)
  return pstDate.format('M/D/YYYY, h:mm:ss A')
}

export const deepEqual = (obj1: { [key: string]: any }, obj2: { [key: string]: any }): boolean => {
  if (obj1 === obj2) return true

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const ipaToWord = (ipa: string): string => {
  // Define a map of IPA phonemes to their corresponding English word representations
  const phonemeMap: { [key: string]: string } = {
    tʃ: 'ch',
    dʒ: 'j', // Multi-character phonemes first
    ʃ: 'sh',
    ŋ: 'ng',
    θ: 'th',
    ð: 'th',
    ʊ: 'u',
    ʌ: 'u',
    ɑ: 'a',
    æ: 'a',
    ɛ: 'e',
    ɪ: 'i',
    ɔ: 'o',
    ɜː: 'er', // Add 'ɜː' to 'er'
    w: 'w',
    v: 'v',
    z: 'z',
    h: 'h',
    p: 'p',
    b: 'b',
    t: 't',
    d: 'd',
    k: 'k',
    g: 'g',
    m: 'm',
    n: 'n',
    f: 'f',
    s: 's',
    l: 'l',
    r: 'r',
    iː: 'ee',
    uː: 'oo', // Add long vowels
    eɪ: 'ay',
    aɪ: 'ai',
    oʊ: 'o',
    aʊ: 'ow',
    ɔɪ: 'oy',
    ɒ: 'o',
    i: 'i',
    u: 'u',
    ɡ: 'g',
    ʒ: 'zh',
    ː: '', // Additional mappings
    ɾ: 'r',
    x: 'kh', // Additional phonetic mappings
  }

  // Remove IPA markers
  let word = ipa.replace(/\/|\\/g, '') // Remove slashes
  word = word.replace(/[ˈˌ]/g, '') // Remove stress markers
  word = word.replace(/:/g, '') // Remove length markers (ː)
  word = word.replace(/,/g, '-') // Replace commas with dashes

  // Replace IPA phonemes with their corresponding word representations
  Object.keys(phonemeMap)
    .sort((a, b) => b.length - a.length)
    .forEach((phoneme) => {
      word = word.replace(new RegExp(phoneme, 'g'), phonemeMap[phoneme])
    })

  return word
}

export const generateRandomColour = () => {
  const hue = Math.floor(Math.random() * 999)
  const saturation = 50 + Math.random() * 10
  const lightness = 25 + Math.random() * 10
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

export const parseBooleanField = (field: any, defaultCheck?: boolean): boolean => {
  const isTrueOrFalseString = (value: any) => value === 'true' || value === 'false'

  if (isTrueOrFalseString(field?.S)) {
    return field.S === 'true'
  }
  if (isTrueOrFalseString(field)) {
    return field === 'true'
  }
  if (typeof field?.BOOL === 'boolean') {
    return field.BOOL
  }
  if (typeof field === 'boolean') {
    return field
  }

  return defaultCheck === undefined ? false : defaultCheck
}

// Debounce function to prevent rapid API calls
export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout
  return (...args: any[]) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export const getAuthToken = (userType: AuthType) => {
  switch (userType) {
    case 'RECEPTIONIST':
      return LocalStorageUtility.getLocalData(LocalStorageKeys.AUTH_TOKEN)
    case 'RESELLER':
      return LocalStorageUtility.getLocalData(LocalStorageKeys.RESELLER_AUTH_TOKEN)
    default:
      return 'unknown auth type'
  }
}

export const isValidPhone = (phone: string) => {
  // Parse the input number and remove formatting
  const phoneNumber = parsePhoneNumber(phone)

  if (phoneNumber && phoneNumber.isValid()) {
    // Return the standardized E.164 format (e.g., +18011234560)
    return phoneNumber.format('E.164')
  } else {
    return false
  }
}

export const handlePrefixPlusOnPhone = (value: string) => {
  let phone = value
  if (!phone.startsWith('+')) {
    phone = '+' + phone
  }
  return phone
}
