import React from 'react'
import { OverViewCardInfo } from '../OverviewCardInfo'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import {
  AccessAlarmsOutlined,
  DialpadOutlined,
  HourglassBottomOutlined,
  LockClockOutlined,
  RadioButtonCheckedOutlined,
  RecordVoiceOverOutlined,
  VoicemailOutlined,
  WebhookOutlined,
  RingVolume,
  WarningAmberOutlined,
  PinOutlined,
} from '@mui/icons-material'
import './TweakAdvancedSettingsInfo.scss'
import { Badge, Table } from 'antd'
import TweakAdvancedSettingsForm from './TweakAdvancedSettingsForm'
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { closeModal, openModal } from '@/store/modal/slice'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { parseBooleanField } from '@/utils/helper'
import { User } from '@/types/TAccounts'
import { CustomModal } from '@/components/CustomModal'
import ExtensionDigitBuilderForm from './ExtensionDigitBuilderForm/ExtensionDigitBuilderForm'
import TimeControlForm from './TimeControlForm/TimeControlForm'
import AddPronunciationForm from './AddPronunciationForm/AddPronunciationForm'
import MaxUsageLimitForm from './MaxUsageLimit/MaxUsageLimitForm'

export const TweakAdvancedSettingsInfo: React.FC<{
  data: any
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
  debrand: boolean
}> = ({ data, refetch, debrand }) => {
  const dispatch = useAppDispatch()
  const [activeField, setActiveField] = React.useState<string | null>(null)
  const columns = [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
    },
    {
      title: 'Pronunciation',
      dataIndex: 'pronunciation',
      key: 'pronunciation',
    },
  ]

  const timeControlColumns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_: any, record: any) => {
        return <div>{record.start_time || 'NA'}</div>
      },
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      render: (_: any, record: any) => {
        return <div>{record.end_time || 'NA'}</div>
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_: any, record: any) => {
        return <div>{record.phone_number || 'NA'}</div>
      },
    },
  ]

  const handleShowTweakSettingModal = (field: string) => {
    setActiveField(field)
    dispatch(openModal({ modalId: MODAL_IDS.TWEAK_ADVANCE_SETTING_MODAL }))
  }

  const handleEditClick = () => {
    setActiveField(null)
    dispatch(openModal({ modalId: MODAL_IDS.TWEAK_ADVANCE_SETTING_MODAL }))
  }

  const handleConfirm = () => {
    dispatch(closeModal())
  }

  const handleClose = () => {
    dispatch(closeModal())
    window.dispatchEvent(new Event('modalClosed'))
  }

  const handleExtensionDigitsClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.EXTENSION_DIGIT_MODAL }))
  }

  const handleTimeControlClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.TIME_CONTROL_MODAL }))
  }

  const handlePronunciationGuideClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.PRONUNCIATION_GUIDE_MODAL }))
  }

  const handleMaxUsageLimitClick = () => {
    dispatch(openModal({ modalId: MODAL_IDS.MAX_USAGE_LIMIT_MODAL }))
  }

  const InfoComponent = () => (
    <>
      <div className={`${baseClass}__item`} onClick={handleExtensionDigitsClick}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Extension Digits"
          description='You can set up extension codes that your AI Receptionist can handle to reroute the caller. i.e. "press 3 for billing department".'
          icon={<DialpadOutlined className="mr-1 mb-1" />}
        />
      </div>
      <CustomModal
        title={
          <>
            <PinOutlined className="!text-4xl" /> <strong>Extension Digits Builder</strong>
          </>
        }
        children={
          <ExtensionDigitBuilderForm
            extension_digits={data?.extension_digits ? JSON.parse(data.extension_digits) : []}
            refetch={refetch}
          />
        }
        confirmAction={handleConfirm}
        cancelAction={handleClose}
        footer={null}
        modalId={MODAL_IDS.EXTENSION_DIGIT_MODAL}
      />
      <div className={`${baseClass}__item`} onClick={() => handleShowTweakSettingModal('zapierIntegration')}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Zapier Integration"
          description="Your AI Receptionist can connect to other apps."
          icon={<WebhookOutlined className="mr-1 mb-1" />}
        />
        <Badge className={`${baseClass}__item_info mt-1 badge-disabled`} count="Disabled" />
      </div>
      <div className={`${baseClass}__item`} onClick={() => handleShowTweakSettingModal('allowVoicemails')}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Allow Voicemails"
          description="If someone asks to leave a message, the AI Receptionist will collect a voicemail. This message will be shown separately in the voicemail logs."
          icon={<VoicemailOutlined className="mr-1 mb-1" />}
        />
        <Badge
          className={`${baseClass}__item_info mt-1 ${data?.voicemails_enabled === 'true' || data?.voicemails_enabled === true ? 'badge-success' : 'badge-disabled'}`}
          count={data?.voicemails_enabled === 'true' || data?.voicemails_enabled === true ? 'On' : 'Off'}
        />
      </div>
      <div className={`${baseClass}__item`} onClick={() => handleShowTweakSettingModal('callRecording')}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Allow Call Recording"
          description="Control if all the voice calls your Receptionist receives would be should be recorded for review in your dashboard afterwards."
          icon={<RadioButtonCheckedOutlined className="mr-1 mb-1" />}
        />
        <Badge
          className={`${baseClass}__item_info mt-1 ${data?.call_recording_enabled === 'true' || data?.call_recording_enabled === true ? 'badge-success' : 'badge-disabled'}`}
          count={data?.call_recording_enabled === 'true' || data?.call_recording_enabled === true ? 'On' : 'Off'}
        />
      </div>
      <div className={`${baseClass}__item`} onClick={() => handleShowTweakSettingModal('hangupWorkflow')}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Auto Hangup after interaction ends"
          description="Enable this option to automatically end the call when the interaction is clearly over. This helps avoid unnecessary call duration and reduces additional costs."
          icon={<RingVolume className="mr-1 mb-1" />}
        />
        <Badge
          className={`${baseClass}__item_info mt-1 ${data?.autoHangupAfterInteraction ? 'badge-success' : 'badge-disabled'}`}
          count={data?.autoHangupAfterInteraction ? 'On' : 'Off'}
        />
      </div>
      <div className={`${baseClass}__item__with-tbl`} onClick={handleTimeControlClick}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Time Control"
          description="Define specific times to automatically transfer calls to another number"
          icon={<LockClockOutlined className="mr-1 mb-1" />}
        />
        <Table
          pagination={false}
          className={`${baseClass}__item__with-tbl_table mt-4 w-8/12`}
          dataSource={data?.advancedTimeControls ? JSON.parse(data?.advancedTimeControls) : []}
          columns={timeControlColumns}
          scroll={{ y: 120 }}
        />
      </div>
      <CustomModal
        title={
          <>
            <div className="flex flex-col">
              <strong>Time Controls</strong>
              <span className="text-xs">Choose your timezone</span>
            </div>
          </>
        }
        children={
          <TimeControlForm
            advancedTimeControls={
              data?.advancedTimeControls
                ? JSON.parse(data.advancedTimeControls)
                : [{ start_time: '', end_time: '', phone_number: '' }]
            }
            refetch={refetch}
            time_zone={data?.time_zone || 'UTC'}
          />
        }
        modalId={MODAL_IDS.TIME_CONTROL_MODAL}
        confirmAction={handleConfirm}
        footer={null}
      />
      <div className={`${baseClass}__item__with-tbl`} onClick={handlePronunciationGuideClick}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Pronunciation Guides"
          description="Your corresponding pronunciation"
          icon={<RecordVoiceOverOutlined className="mr-1 mb-1" />}
        />
        <Table
          pagination={false}
          className={`${baseClass}__item__with-tbl_table mt-4 w-8/12`}
          dataSource={data?.word_pronunciation}
          columns={columns}
          scroll={{ y: 120 }}
        />
      </div>
      <CustomModal
        title={<strong>Add Word And Pronunciation</strong>}
        children={
          <AddPronunciationForm
            word_pronunciation={data?.word_pronunciation || [{ pronunciation: '', word: '' }]}
            refetch={refetch}
          />
        }
        modalId={MODAL_IDS.PRONUNCIATION_GUIDE_MODAL}
        confirmAction={handleConfirm}
        footer={null}
      />
      {!debrand && (
        <>
          <div className={`${baseClass}__item`} onClick={handleMaxUsageLimitClick}>
            <LabelWithDescription
              customClassName="w-8/12"
              label="Max Usage Limit"
              description="Maximum usage limit (in minutes) for your AI receptionist. Once your usage reaches 50%, 75% or 100% of the limit you will receive an email notification."
              icon={<HourglassBottomOutlined className="mr-1 mb-1" />}
            />
            <Badge
              className={`${baseClass}__item_info mt-1 ${data?.minutesLimitEnabled ? 'badge-success' : 'badge-disabled'}`}
              count={data?.minutesLimitEnabled ? 'Enabled' : 'Disabled'}
            />
          </div>
          <CustomModal
            title={<strong>Set Maximum Usage Limit</strong>}
            children={
              <MaxUsageLimitForm
                refetch={refetch}
                usage_notification_emails={data?.usage_notification_emails || []}
                max_minutes={data?.max_minutes}
                minutesLimitEnabled={data?.minutesLimitEnabled || true}
              />
            }
            modalId={MODAL_IDS.MAX_USAGE_LIMIT_MODAL}
            confirmAction={handleConfirm}
            footer={null}
          />
        </>
      )}
      <div className={`${baseClass}__item `} onClick={() => handleShowTweakSettingModal('maxCallDuration')}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Maximum Call Duration"
          description="Maximum call duration (in minutes) of the AI receptionist before it automatically hang up"
          icon={<AccessAlarmsOutlined className="mr-1 mb-1" />}
        />
        <strong className={`${baseClass}__item_info`}>{data?.maxCallDuration} Minutes</strong>
      </div>

      <div className={`${baseClass}__item `} onClick={() => handleShowTweakSettingModal('temperoryDisabled')}>
        <LabelWithDescription
          customClassName="w-8/12"
          label="Temporary Disable"
          description="Temporarily disable your Receptionist by either setting a default hangup message or specifying a number to automatically forward calls to."
          icon={<WarningAmberOutlined className="mr-1 mb-1" />}
        />
        <strong className={`${baseClass}__item_info text-red-500`}>Danger zone</strong>
      </div>
    </>
  )

  const baseClass = 'tweak-advanced-settings-info'
  return (
    <div className={baseClass}>
      <OverViewCardInfo
        formActionType="Edit"
        formTitle="Tweak Advance Settings"
        info={<InfoComponent />}
        form={
          <TweakAdvancedSettingsForm
            debrand={debrand}
            refetch={refetch}
            // @ts-ignore
            formInitialValues={{
              maxCallDuration: data?.maxCallDuration,
              //@ts-ignore
              extension_digits: data?.extension_digits ? JSON.parse(data.extension_digits) : [],
              usage_notification_emails: data?.usage_notification_emails || [],
              max_minutes: data?.max_minutes,
              voicemails_enabled: parseBooleanField(data?.voicemails_enabled, true),
              call_recording_enabled: parseBooleanField(data?.call_recording_enabled, true),
              word_pronunciation: data?.word_pronunciation || [],
              advancedTimeControls: data?.advancedTimeControls ? JSON.parse(data.advancedTimeControls) : [],
              time_zone: data?.time_zone || 'UTC',
              minutesLimitEnabled: data?.minutesLimitEnabled || true,
              autoHangupAfterInteraction: data?.autoHangupAfterInteraction || false,
              autoHangupAfterInteractionText: data?.autoHangupAfterInteractionText || '',
              disableReceptionistSettings: (() => {
                const settings = JSON.parse(
                  data?.disableReceptionistSettings?.S ?? data?.disableReceptionistSettings ?? '{}'
                )
                return {
                  disabled: !!settings.disabled || !!settings.disableType,
                  disableType: settings.disableType ?? '',
                  forwardingNumber: settings.forwardingNumber ?? '',
                  hangupMessage: settings.hangupMessage ?? '',
                }
              })(),
            }}
            activeField={activeField}
          />
        }
        width={'40%'}
        footer={null}
        modalId={MODAL_IDS.TWEAK_ADVANCE_SETTING_MODAL}
        onEditClick={handleEditClick}
      />
    </div>
  )
}
