import { OverViewCardInfo } from '../OverviewCardInfo'
import { useMutation, useQuery } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { CalendarWorkflowForm } from './CalendarWorkflowForm'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getGoogleCalendarOAuthURL } from './CalendarOauth'
import { Alert, Button } from 'antd'
import { ECalendar } from 'src/enums/ECalendar'
import { configureCalendar, getCalendars } from '@/api/calendar'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { TCalendar } from '@/types/TCalendar'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import Spinner from '@/components/Spinner/Spinner'
import { getCalendarConflictingScenariosNotice } from './helper'
import { TUTORIALS } from 'src/enums/ETutorials'

export const CalendarWorkflowInfo: React.FC<{ clientObject: any }> = ({ clientObject }) => {
  // Instances initialization
  const [searchParams] = useSearchParams()

  const [calendarSelected, setCalendarSelected] = useState<TCalendar | undefined>(undefined)
  const [notice, setNotice] = useState<any>([])

  const dispatch = useAppDispatch()

  const { data, refetch } = useQuery({
    queryKey: ['get-calendar'],
    queryFn: () => getCalendars(),
  })

  const { mutate: configureCalendarFn, isPending } = useMutation({
    mutationKey: ['update-calendar'],
    mutationFn: configureCalendar,
    onSuccess: () => {
      refetch()
      dispatch(closeModal())
    },
  })

  useEffect(() => {
    if (clientObject) {
      const formattedNotice = getCalendarConflictingScenariosNotice(clientObject)
      if (formattedNotice) {
        setNotice(formattedNotice)
      }
    }
  }, [clientObject])

  useEffect(() => {
    const encAcc = searchParams.get('encAcc')
    const encRefr = searchParams.get('encRefr')
    if (encAcc && encRefr) {
      // add/update calendar for user
      setTimeout(() => {
        configureCalendarFn({
          encAcc,
          encRefr,
        })
      }, 3000)
    }
  }, [searchParams])

  useEffect(() => {
    if (data) {
      const cal = data?.data?.calendars.find((item: TCalendar) => item.nameOfCalendar === ECalendar.GOOGLE_CALENDAR)
      if (cal) {
        setCalendarSelected(cal)
      }
    }
  }, [data])

  const connectGoogleCalendar = () => {
    const url = getGoogleCalendarOAuthURL()
    window.location.href = url
  }

  const InfoComponent = () => {
    return (
      <Spinner spinning={isPending}>
        <div className="pl-4 w-full mt-1 pt-2 pb-4">
          {data?.data?.calendars?.length ? (
            <>
              {notice && notice?.length > 0 && (
                <Alert
                  className="mb-4 w-full"
                  type="info"
                  closable={false}
                  message={
                    <span>
                      <span className="mb-2">
                        ⚠️ Heads up! A few of your scenarios are conflicting. The AI may get confused when trying to
                        schedule an appointment in the following configurations:
                      </span>
                      {notice.map((item: any, index: number) => (
                        <div key={index}>
                          {index + 1}. <strong>{item.key}:</strong> {item.message}
                        </div>
                      ))}
                    </span>
                  }
                />
              )}
              <div className="flex flex-col md:flex-row justify-between gap-8">
                <div className="w-1/2 flex flex-col gap-4">
                  <div>
                    <LabelWithDescription
                      customClassName="w-full mb-1"
                      label={<span className="text-sm font-semibold">Calendar Name</span>}
                    />
                    {data.data.calendarToWrite}
                  </div>
                  <div>
                    <LabelWithDescription
                      customClassName="w-full mb-1"
                      label={<span className="text-sm font-semibold">Duration of the meeting (in minutes)</span>}
                    />
                    {calendarSelected?.lengthOfAppointment}
                  </div>
                </div>
                <div className="w-1/2 flex flex-col gap-4">
                  <div>
                    <LabelWithDescription
                      customClassName="w-full mb-1"
                      label={<span className="text-sm font-semibold">Number of concurrent events allowed</span>}
                    />
                    {calendarSelected?.noOfConcurrentEventsAllowed}
                  </div>
                  <div>
                    <LabelWithDescription
                      customClassName="w-full mb-1"
                      label={<span className="text-sm font-semibold">Gap between appointments (in minutes)</span>}
                    />
                    {calendarSelected?.gapBetweenAppointments}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                No calendar attached. Please attach a calendar by clicking
                <span className="font-semibold">Attach Calendar.</span>
              </div>
              <Button type="primary" onClick={connectGoogleCalendar}>
                Attach Calendar
              </Button>
            </div>
          )}
        </div>
      </Spinner>
    )
  }

  return (
    <OverViewCardInfo
      info={<InfoComponent />}
      formActionType="Edit"
      displayFormActionBtn={data?.data !== undefined}
      form={
        <CalendarWorkflowForm
          refetch={refetch}
          onClose={() => {}}
          calendar={calendarSelected}
          askOtherDetails={data?.data?.askOtherDetails || []}
        />
      }
      width={'50%'}
      footer={null}
      modalId={MODAL_IDS.CALENDAR_FORM_MODAL}
      tutorialId={TUTORIALS.GOOGLE_CALENDAR_TUTORIAL}
      displayTutorialActionBtn={true}
    />
  )
}
