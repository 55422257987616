import { Card } from 'antd'
import { OverViewCardInfo } from '../OverviewCardInfo'
import { KnowledgeBaseForm } from './KnowledgeBaseForm'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query'
import { MODAL_IDS } from 'src/enums/EModal'
import { User } from '@/types/TAccounts'

export const KnowledgeBaseInfo: React.FC<{
  data: any
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
}> = ({ data, refetch }) => {
  console.log('data', data)
  const knowledgeBase = data?.knowledge_base || data?.business_information || ''
  const InfoComponent = () => {
    return (
      <Card>
        <div className="border-b">
          <LabelWithDescription
            customClassName="mt-2 w-9/12"
            label="Business Information"
            description="Tell the AI about your business. Please input detailed information about your business such as its history, mission, and services. Don't worry about formatting. The AI can effectively interpret it and build a knowledge base around it. Maximum 20 pages of text!"
          />
          <span
            className="pl-4 w-9/12 mt-1 pt-2 pb-4"
            dangerouslySetInnerHTML={{ __html: knowledgeBase.replace(/\n/g, '<br />') }}
          ></span>
        </div>
      </Card>
    )
  }
  return (
    <OverViewCardInfo
      info={<InfoComponent />}
      formActionType="Edit"
      form={<KnowledgeBaseForm business_information={knowledgeBase} refetch={refetch} />}
      width={'80%'}
      footer={null}
      modalId={MODAL_IDS.KNOWLEDGE_BASE_MODAL}
    />
  )
}
