import { Alert, Button, Flex, Form, Input, notification, Spin } from 'antd';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { OverViewCardForm } from '../../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { updateExtensionDigit } from '@/api/user';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import './ExtensionDigitBuilderForm.scss';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';

const ExtensionDigitBuilderForm: React.FC<{
  extension_digits: {
    extension: string;
    phoneNumber: string;
  }[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ extension_digits, refetch }) => {
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      refetch();
    };
  }, []);

  useEffect(() => {
    const handleModalClose = () => {
      form.resetFields();
    };

    window.addEventListener('modalClosed', handleModalClose);

    return () => {
      window.removeEventListener('modalClosed', handleModalClose);
    };
  }, [form]);

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateExtensionDigit,
    onSuccess: () => {
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const isValidPhoneNumber = (phoneNumber: string): boolean => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    return digitsOnly.length >= 7;
  };

  const handleSubmit = async (values: { extension_digits: { phoneNumber: string; extension: string }[] }) => {
    const extensions = values.extension_digits.map(item => item.extension.trim());
    const duplicates = extensions.filter((item, index) => extensions.indexOf(item) !== index);

    if (duplicates.length > 0) {
      notification.error({
        key: 'form-error',
        message: 'Duplicate Extensions',
        description: `${duplicates.join(', ')} ${duplicates.length > 1 ? 'are' : 'is'} duplicate. Please remove one.`,
      });
      return;
    }

    const invalidEntries = values.extension_digits.filter(
      item => !item.extension.trim() || !isValidPhoneNumber(item.phoneNumber)
    );

    if (invalidEntries.length > 0) {
      notification.error({
        key: 'form-error',
        message: 'Invalid Entries',
        description: 'Please ensure all extensions are filled and phone numbers are complete.',
      });
      return;
    }

    const ext_digits = values.extension_digits.map(item => {
      return {
        ...item,
        phoneNumber: handlePrefixPlusOnPhone(item.phoneNumber),
      };
    });

    await mutation.mutateAsync({ extension_digits: ext_digits });
    refetch();
    dispatch(closeModal());
  };

  const FormItems = () => (
    <Form.List name="extension_digits" initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Flex key={key} gap={12} className="[&_.ant-form-item-explain-error]:text-[11px]">
              <Form.Item
                {...restField}
                name={[name, 'extension']}
                className="flex-auto max-w-28 !mb-2"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter numbers only',
                  },
                  {
                    pattern: /^(?![0]+$)\d+$/,
                    message: 'Invalid extension',
                  },
                  {
                    validator: (_, value) => {
                      if (!value || value.trim().length === 0) {
                        return Promise.reject('Extension is required');
                      }
                      if (value.trim().length > 6) {
                        return Promise.reject('Should not exceed 6 digits');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                label="AI's Extension:"
              >
                <Input
                  placeholder="e.g: 234"
                  onKeyPress={event => {
                    const keyCode = event.keyCode || event.which;
                    const keyValue = String.fromCharCode(keyCode);
                    if (!/^\d+$/.test(keyValue)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Flex gap={8}>
                <Form.Item className="flex-auto !mb-2" label="Route to:">
                  <PhoneNumberInput name={[name, 'phoneNumber']} />
                </Form.Item>
                <Form.Item className="flex items-start pt-8">#</Form.Item>
                <Form.Item
                  name={[name, 'DID']}
                  className="flex-auto !mb-2 max-w-[114px] [&_label]:after:hidden"
                  label=<span className="text-xs">Extension (Optional):</span>
                >
                  <Input
                    placeholder="e.g: 302"
                    onKeyPress={event => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!/^\d+$/.test(keyValue)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                {fields.length > 0 && <MinusCircleOutlined className="pt-2" onClick={() => remove(name)} />}
              </Flex>
            </Flex>
          ))}
          <Form.Item className="mt-2 !mb-0">
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Extension
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );

  const baseClass = 'extension-digit-container';
  return (
    <div className={baseClass}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} spinning={mutation.isPending}>
        <div className="pb-2">
          <p className="text-justify">
            The caller can dial an extension to transfer the call. They can either dial the extension after the original
            greeting phrase, or they can ask the AI to dial an extension anytime. Make sure to use the format
            +1XXXXXXXXXX for the phone number.
          </p>
        </div>
        <Alert
          type="info"
          closable={false}
          message={
            <>
              <div className="flex items-center gap-1">
                <TipsAndUpdatesTwoToneIcon className="text-yellow-500" />
                <span> Tip :</span>
              </div>
              <p className="text-justify mb-0 mt-2">
                If you are using this feature, we recommend you tell callers about it in the greeting phrase. For
                example, "If you already know your party's extension, you can dial it after I finish talking. You can
                also dial it anytime by saying 'dial an extension'".
              </p>
            </>
          }
        />

        <OverViewCardForm
          form={form}
          initialValues={{ extension_digits }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};

export default ExtensionDigitBuilderForm;
