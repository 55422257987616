import { shuffle } from 'lodash'
import React, { useEffect, useState, DragEvent } from 'react'
import { ColumnProps } from '../../../../interfaces/IColumn'
import { useFetchGroups, useUpdateLeadStatusOnKanban } from 'src/Hooks/UseHookForCrmData'
import Task from './Task'
import AddEditGroupModal from '../Modals/AddEditGroupModal'
import { Group } from '@/interfaces/ICrmApisInterface'

const Column: React.FC<ColumnProps> = ({ colIndex, username, statuses }) => {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState<boolean>(false)

  const colors = [
    'bg-red-500',
    'bg-orange-500',
    'bg-blue-500',
    'bg-purple-500',
    'bg-green-500',
    'bg-indigo-500',
    'bg-yellow-500',
    'bg-pink-500',
    'bg-sky-500',
  ]

  const [color, setColor] = useState<string | null>(null)

  const { data, refetch } = useFetchGroups(username)

  useEffect(() => {
    if (data?.columns) {
      setColumns(data.columns)
    }
  }, [data])

  const { mutate: UpdateLeadData } = useUpdateLeadStatusOnKanban()
  const [columns, setColumns] = useState<Group[]>(data?.columns || [])
  const col = columns[colIndex]

  useEffect(() => {
    setColor(shuffle(colors).pop() || null)
  }, [])

  const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    // console.log(JSON.parse(e.dataTransfer.getData('text')), 'tramsfer data')
    const { prevColIndex, taskIndex, leadId, phone_number } = JSON.parse(e.dataTransfer.getData('text'))

    if (colIndex !== prevColIndex) {
      const updatedColumns = [...columns] // Create a copy of the current columns

      // Move the task from the previous column to the new column
      const [movedTask] = updatedColumns[prevColIndex].tasks.splice(taskIndex, 1)
      setColumns(updatedColumns)
      updatedColumns[colIndex].tasks.push(movedTask) // Add the task to the new column

      // Update the local state with the new columns
      setColumns(updatedColumns)
      // Update the lead status in the backend
      UpdateLeadData({
        username,
        leadId: leadId,
        newGroupId: updatedColumns[colIndex].id, // Assuming col.key is the group_id
        current_status: updatedColumns[colIndex].id, // Assuming col.key is the group_id
        lead_id: leadId,
        phone_number,
      })
      refetch()
    }
  }

  const handleOnDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  return (
    <div
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
      className="scrollbar-hide  mx-5 PtClasses  min-w-[400px] min-h-96"
    >
      <p className=" font-semibold flex  items-center capitalize  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
        <div className={`rounded-full w-4 h-4 capitalize ${color} `} />
        {col.name} ({col.tasks.length})
        <svg
          className="w-4 h-4 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setIsBoardModalOpen(true)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </p>
      <div className="Autopilot">
        {col.tasks.length > 0 ? (
          col.tasks.map((task: { lead_id: React.Key | null | undefined }, index: number) => (
            <Task key={task?.lead_id} taskIndex={index} colIndex={colIndex} username={username} statuses={statuses} />
          ))
        ) : (
          <div className="bg-white rounded-lg p-6 border-gray-200">
            <div className="flex flex-col items-center">
              <svg
                className="w-16 h-16 text-gray-400 mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                />
              </svg>
              <h3 className="text-xl font-semibold text-gray-700 mb-2">No leads in this group yet</h3>
              <p className="text-gray-500 text-center mb-4">
                Drag and drop a lead here or wait for the AI to assign one.
              </p>
              <p className="text-sm text-gray-400 italic">
                To modify the conditions for this group, click the edit button above.
              </p>
            </div>
          </div>
        )}
      </div>
      {isBoardModalOpen && (
        <AddEditGroupModal type="edit" setIsBoardModalOpen={setIsBoardModalOpen} boardToEdit={col} />
      )}
    </div>
  )
}

export default Column
