import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  notification,
  Select,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import PlayForWorkOutlinedIcon from '@mui/icons-material/PlayForWorkOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useEffect, useState } from 'react';
import './CallLogs.scss';
import { CustomModal } from '@/components/CustomModal';
import { CallDetails } from './CallDetails';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteCallLog,
  downloadCallLogs,
  getCallLogs,
  getCallRecording,
  updateUserContact,
  updateUserTimeZone,
} from '@/api/logs';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { useDispatch } from 'react-redux';
import { setUserContacts, setUserTimeZone } from '@/store/account/slice';
import {
  DeleteOutlined,
  FacebookOutlined,
  LinkOutlined,
  MailOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  ShareAltOutlined,
  UserAddOutlined,
  WhatsAppOutlined,
  XOutlined,
} from '@ant-design/icons';
import { CallLog } from '@/interfaces/ILogs';
import { renderPSTDate } from '@/utils/helper';
import { CallIntakeResponse } from './CallIntakeResponse';
import { IntakeForm } from '@/types/TCallLogs';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone';
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';

const timeZoneList = momentTimeZone.tz.names();

const CallLogs: React.FC<{ debrand: boolean }> = ({ debrand }) => {
  const dispatch = useDispatch();
  const loggedInUser = useAppSelector(getLoggedInUser);
  const { RangePicker } = DatePicker;
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [script, setScript] = useState<string>('');
  const [callSummary, setCallSummary] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<number | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [selectedLog, setSelectedLog] = useState<{ key: string; callSid: string; date: string } | null>(null);
  const [saveContactModal, setSaveContactModal] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [selectedContactName, setSelectedContactName] = useState<string>('');
  const [contacts, setContacts] = useState<{ [key: string]: string } | null>(
    typeof loggedInUser.contacts === 'string' ? JSON.parse(loggedInUser.contacts) : loggedInUser.contacts
  );
  const { timeZone, setTimeZone } = useLocalTimezone(loggedInUser?.time_zone);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [shareableLink, setShareableLink] = useState<string | null>(null);
  const [intakeResponses, setIntakeResponses] = useState<IntakeForm[]>([]);
  const [intakeModal, setIntakeModal] = useState<boolean>(false);
  const [loadingRecordings, setLoadingRecordings] = useState<{ [key: string]: boolean }>({});

  const { data, refetch, isPending, isRefetching } = useQuery({
    queryKey: ['getCallLogs'],
    queryFn: () => {
      if (startDate && endDate) {
        return getCallLogs({ limit: 50, startDate, endDate });
      } else {
        return getCallLogs({ limit: 50 });
      }
    },
  });

  const { data: provisionedNumbers, isLoading: isLoadingProvisionedNumber } = useQuery({
    queryKey: ['getProvisionedNumbers'],
    queryFn: () => getProvisionedNumber(),
  });

  const { mutate: callRecordingMutation } = useMutation({
    mutationKey: ['getCallRecording'],
    mutationFn: getCallRecording,
    onMutate: callSid => {
      setLoadingRecordings(prev => ({ ...prev, [callSid]: true }));
    },
    onSuccess(data) {
      const updatedCallLogs = callLogs.map((log: CallLog) => {
        if (log.callSid === data.callSid) {
          return { ...log, audio: data.url };
        }
        return log;
      });
      setCallLogs(updatedCallLogs);
      setLoadingRecordings(prev => ({ ...prev, [data.callSid]: false }));
    },
  });

  const { mutate: deleteCallLogMutation, status: deleteCallLogStatus } = useMutation({
    mutationKey: ['deleteCallLog'],
    mutationFn: deleteCallLog,
    onSuccess() {
      const updatedCallLogs = callLogs.filter((log: CallLog) => log.key !== selectedLog?.key);
      setConfirmDelete(false);
      setSelectedLog(null);
      setCallLogs(updatedCallLogs);
      notification.success({ message: 'Call log deleted successfully' });
    },
  });

  const updateUserContactMutation = useMutation({
    mutationKey: ['updateUserContact'],
    mutationFn: updateUserContact,
    onSuccess(data) {
      setContacts(data.contacts);
      dispatch(setUserContacts({ userContacts: data.contacts }));
      setSaveContactModal(false);
    },
  });

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }));
    },
  });

  const { mutate: downloadCallLogsMutation, status: downloadCallLogsStatus } = useMutation({
    mutationKey: ['downloadCallLogs'],
    mutationFn: downloadCallLogs,
  });

  const handleClick = (transcript: string, summary: string | undefined) => {
    setShowModal(true);
    setScript(transcript);
    setCallSummary(summary);
  };
  const handleConfirm = () => {
    setShowModal(false);
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirmDeleteModal = (key: string, callSid: string, date: string) => {
    setSelectedLog({ key, callSid, date });
    setConfirmDelete(true);
  };

  const handleCancelDeleteModal = () => {
    setSelectedLog(null);
    setConfirmDelete(false);
  };

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    updateTimeZoneMutation.mutate({ timeZone: value });
  };

  const handleDateRangeChange = (_: any, dateStrings: [string, string]) => {
    const [start, end] = dateStrings;
    setStartDate(start ? moment(start).valueOf() : null);
    setEndDate(end ? moment(end).valueOf() : null);
  };

  const handleGetCallRecording = (callSid: string) => {
    callRecordingMutation(callSid);
  };

  const handleDeleteCallLog = () => {
    if (selectedLog !== null) {
      deleteCallLogMutation(selectedLog);
    }
  };

  const handleShareModel = (shareableLink: string) => {
    setShareModal(true);
    setShareableLink(shareableLink);
  };

  const handleSaveContact = (from: string) => {
    setSaveContactModal(true);
    setSelectedContact(from);
  };

  const handleCancelSaveContact = () => {
    setSelectedContact(null);
    setSelectedContactName('');
    setSaveContactModal(false);
  };

  const handleSaveUserContact = () => {
    if (selectedContact !== null && selectedContactName.length) {
      updateUserContactMutation.mutate({ contactNumber: selectedContact, contactName: selectedContactName });
    }
  };

  const handleShareLink = (platform: string) => {
    if (!shareableLink) return;

    let baseUrl = import.meta.env.VITE_APP_BASE_URL;

    if (debrand) {
      baseUrl = import.meta.env.VITE_APP_WIHTELABEL_URL;
    }

    const url = `${baseUrl}/call-information/${shareableLink}`;

    if (platform === 'Facebook') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
    } else if (platform === 'Copy') {
      navigator.clipboard.writeText(url).then(() => {
        notification.success({ message: 'Link copied to clipboard' });
      });
    } else if (platform === 'Email') {
      window.open(
        `mailto:?subject=Check out this call recording&body=Hey, I thought you might be interested in this call recording: ${url}`,
        '_blank'
      );
    } else if (platform === 'X') {
      window.open(`https://twitter.com/intent/tweet?text=Check out this call recording: ${url}`, '_blank');
    } else if (platform === 'Whatsapp') {
      window.open(`https://api.whatsapp.com/send?text=Check out this call recording: ${url}`, '_blank');
    }

    setShareModal(false);
  };

  const handleCallLogsDownload = () => {
    if (startDate && endDate) {
      downloadCallLogsMutation({ startDate, endDate });
    } else {
      downloadCallLogsMutation({});
    }
  };

  const parseIntakeResponse = (intakeResponse: string) => {
    try {
      if (!intakeResponse) {
        return [];
      }
      const parsedResponse = JSON.parse(intakeResponse);
      if (Array.isArray(parsedResponse)) {
        const transformedResponse = parsedResponse
          .map(obj => {
            return Object.entries(obj)
              .map(([key, value]) => [
                { key: 'Question', value: key },
                { key: 'Answer', value: value },
              ])
              .flat();
          })
          .flat() as IntakeForm[];
        setIntakeResponses(transformedResponse);
        setIntakeModal(true);
      }
    } catch (error) {
      notification.error({ message: 'Error parsing intake response' });
      return [];
    }
  };

  const handleCancelIntakeModal = () => {
    setIntakeModal(false);
  };

  const columns: TableProps<CallLog>['columns'] = [
    {
      key: 'Date',
      title: 'Dates',
      render: (_, record) => renderPSTDate(record.date, timeZone),
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      render: from => (
        <Space>
          <text>{contacts?.[from] || from}</text>
          <Tooltip title="Save Contact">
            <Button type="text" icon={<UserAddOutlined />} onClick={() => handleSaveContact(from)} size="small" />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Recording',
      key: 'recording',
      render: (_, record) =>
        record.isRecorded === 'true' ? (
          record.audio ? (
            <audio controls className="h-[30px]">
              <source src={record.audio} type="audio/mpeg" />
            </audio>
          ) : (
            <Button
              icon={<PlayCircleOutlined />}
              onClick={() => handleGetCallRecording(record.callSid)}
              loading={loadingRecordings[record.callSid]}
            >
              Listen
            </Button>
          )
        ) : (
          <Tag color="red">No Recording</Tag>
        ),
    },
    {
      title: 'Call Summary',
      dataIndex: 'Call Summary',
      key: 'Call Summary',
      render: (_, { callSummary, audio }) => (
        <Space>{callSummary ? <EllipsisText text={callSummary} length={audio ? 35 : 55} /> : 'NA'}</Space>
      ),
    },
    {
      title: 'Call Transcript',
      dataIndex: 'Call Transcript',
      key: 'Call Transcript',
      render: (_, { transcript, callSummary }) => (
        <Button onClick={() => handleClick(transcript, callSummary)}>Transcript</Button>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Share">
            <Button type="text" icon={<ShareAltOutlined />} onClick={() => handleShareModel(record.shareableLink)} />
          </Tooltip>
          <Dropdown
            menu={{
              items: [
                ...(record.intakeResponses &&
                (() => {
                  try {
                    const parsedIntakeResponses = JSON.parse(record.intakeResponses);
                    return parsedIntakeResponses.length > 0 && Object.keys(parsedIntakeResponses[0]).length > 0;
                  } catch (e) {
                    return false;
                  }
                })()
                  ? [
                      {
                        key: 'intake',
                        label: 'View Intake Response',
                        onClick: () => parseIntakeResponse(record.intakeResponses as string),
                      },
                    ]
                  : []),
              ],
            }}
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
          <Tooltip title="Delete">
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleConfirmDeleteModal(record.key, record.callSid, record.date)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const shareOptions = [
    { key: 'Copy', icon: <LinkOutlined />, color: '#0000EE', label: 'Copy Link' },
    { key: 'Email', icon: <MailOutlined />, color: '#D44638', label: 'Email' },
    { key: 'Facebook', icon: <FacebookOutlined />, color: '#3b5998', label: 'Facebook' },
    { key: 'Twitter', icon: <XOutlined />, color: '#000000', label: 'X' },
    { key: 'WhatsApp', icon: <WhatsAppOutlined />, color: '#25D366', label: 'WhatsApp' },
  ];

  useEffect(() => {
    if (data) {
      setCallLogs(data?.callLogs);
    }
  }, [data?.callLogs]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  const baseClass = 'call-logs';
  return (
    <div className={baseClass}>
      <div className={`${baseClass}_header`}>
        <h1>Call Logs</h1>
        <Button
          type="primary"
          icon={<PlayForWorkOutlinedIcon />}
          onClick={() => handleCallLogsDownload()}
          disabled={
            isLoadingProvisionedNumber ||
            downloadCallLogsStatus === 'pending' ||
            !provisionedNumbers?.provisioned_number
          }
        >
          Download Logs
        </Button>
        <Button
          type="primary"
          icon={<RefreshOutlinedIcon />}
          onClick={() => refetch()}
          loading={isPending || isRefetching}
        >
          Refresh Logs
        </Button>
      </div>
      <div className={`${baseClass}_input-container`}>
        <RangePicker placement="bottomRight" onChange={handleDateRangeChange} />
        <Select
          value={timeZone}
          onChange={handleTimeZoneChange}
          showSearch
          placeholder="Select a time zone"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
        />
      </div>
      <Spin spinning={isPending}>
        <div className={`${baseClass}_table-container`}>
          <Table columns={columns} dataSource={callLogs} pagination={{ pageSize: 10 }} scroll={{ x: 'max-content' }} />
        </div>
      </Spin>
      <CustomModal
        title={<strong>Call Log Details</strong>}
        children={<CallDetails script={script} callSummary={callSummary} />}
        isModalOpen={showModal}
        confirmAction={handleConfirm}
        cancelAction={handleCancel}
      />
      <CustomModal
        title={<strong>Call Intake Form</strong>}
        children={<CallIntakeResponse intakeResponse={intakeResponses} />}
        isModalOpen={intakeModal}
        confirmAction={handleCancelIntakeModal}
        cancelAction={handleCancelIntakeModal}
      />
      <CustomModal
        title={<>Are you sure you want to delete this call log?</>}
        children={<>This action cannot be undone.</>}
        isModalOpen={confirmDelete}
        confirmAction={handleDeleteCallLog}
        cancelAction={handleCancelDeleteModal}
        footer={[
          <Button key="cancel" onClick={handleCancelDeleteModal}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleDeleteCallLog}
            danger
            loading={deleteCallLogStatus === 'pending'}
          >
            Delete
          </Button>,
        ]}
      />
      <CustomModal
        title={<>Save contact</>}
        isModalOpen={saveContactModal}
        confirmAction={handleSaveUserContact}
        cancelAction={handleCancelSaveContact}
      >
        <div>
          <p className="my-2">
            How would you like to save this <strong>{selectedContact}</strong>?
          </p>
          <Input
            placeholder="Enter name"
            onChange={e => setSelectedContactName(e.target.value)}
            value={selectedContactName}
          />
        </div>
      </CustomModal>
      <CustomModal
        title={<>Share Call Log</>}
        isModalOpen={shareModal}
        confirmAction={() => setShareModal(false)}
        cancelAction={() => setShareModal(false)}
        centered
      >
        <div className="flex flex-col items-center space-y-6">
          <div className="flex justify-center space-x-4">
            {shareOptions.map(option => (
              <Tooltip key={option.key} title={option.label}>
                <Button
                  type="default"
                  shape="circle"
                  size="large"
                  icon={option.icon}
                  style={{
                    color: option.color,
                    borderColor: option.color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '48px',
                    height: '48px',
                  }}
                  onClick={() => handleShareLink(option.key)}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default CallLogs;
