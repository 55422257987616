import React, { useEffect, useState } from 'react'
import Header from './Components/Header'
import ListView from './Components/ListView'
import AddEditGroupModal from './Modals/AddEditGroupModal'
import Column from './Components/Column'
import { useFetchGroups } from 'src/Hooks/UseHookForCrmData'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import EmptyBoard from './Components/EmptyBorder'
import { Loading } from '@/components/Loading'
import { Button } from 'antd'

interface HomeProps {
  username: string
  password: string
  leadData: any
  statuses: any
}

const CrmDashboard: React.FC<HomeProps> = ({ password }) => {
  const [, setWindowSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const loggedInUser = useAppSelector(getLoggedInUser)
  const username = loggedInUser?.number || ''

  const { data: groups, isLoading } = useFetchGroups(username as string)
  const columns = groups?.columns
  const [isBoardModalOpen, setIsBoardModalOpen] = useState<boolean>(false)
  const [isOpenListView, setOpenListView] = useState<boolean>(false)
  const [isSideBarOpen] = useState<boolean>(true)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const uniqueStatuses = Array.from(new Set(columns?.map((column) => column.id) || []))

  return (
    <div className="flex flex-col h-screen">
      <main className={`flex-grow overflow-x-scroll  scrollbar-hide ${isSideBarOpen ? 'px-4' : 'px-0'}`}>
        {isLoading ? (
          <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 dark:bg-[#2b2c37] dark:bg-opacity-75 z-10">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <Header
                setIsBoardModalOpen={setIsBoardModalOpen}
                isBoardModalOpen={isBoardModalOpen}
                setOpenListView={setOpenListView}
                isOpenListView={isOpenListView}
                username={username}
                password={password}
                board={{ boardId: '', name: '' }} // board={board}
              />
              {!isOpenListView && (
                <div className="w-[145px]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setIsBoardModalOpen(true)}
                    className="w-[150px] flex justify-center items-center font-semibold text-sm hover:text-[#635FC7] transition duration-300    rounded-lg  "
                  >
                    + New Group
                  </Button>
                </div>
              )}
            </div>

            {isOpenListView && (
              // List View
              <ListView
                setOpenListView={setOpenListView}
                isOpenListView={isOpenListView}
                statuses={uniqueStatuses}
                username={username as string}
              />
            )}

            {!isOpenListView ? (
              columns && columns.length > 0 ? (
                // Grid View
                <div className="flex gap-2">
                  {columns.map((_col, index) => (
                    <Column key={index} colIndex={index} username={username} statuses={uniqueStatuses} />
                  ))}
                </div>
              ) : (
                <EmptyBoard username={username} password={password} type="add" />
              )
            ) : null}

            {isBoardModalOpen && (
              <AddEditGroupModal type="add" setIsBoardModalOpen={setIsBoardModalOpen} boardToEdit={columns} />
            )}
          </>
        )}
      </main>
    </div>
  )
}

export default CrmDashboard
