import React, { useState } from 'react'
import { Badge, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import OverviewCard from './Cards/OverviewCard'
import { ManageNotifications } from './ManageNotifications/ManageNotifications'

export const TabHeadingWithBadge: React.FC<{ heading: string; badgeContent: string; customClassName: string }> = ({
  heading,
  badgeContent,
  customClassName,
}) => {
  return (
    <div className="tab-heading-container text-center">
      <h1 className="text-sm font-bold">{heading}</h1>
      <Badge className={customClassName} count={badgeContent} />
    </div>
  )
}

export const Overview: React.FC<{
  debrand: boolean
  apiWorkflowRef: React.RefObject<HTMLDivElement>
  gcalRef: React.RefObject<HTMLDivElement>
  intakeRef: React.RefObject<HTMLDivElement>
}> = ({ debrand, apiWorkflowRef, gcalRef, intakeRef }) => {
  const [completedItems, setCompletedItems] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const handleCompletionChange = (completed: number, total: number) => {
    setCompletedItems(completed)
    setTotalItems(total)
  }
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <TabHeadingWithBadge
          customClassName="badge-warning"
          heading="Add Content"
          badgeContent={`Completed ${completedItems}/${totalItems}`}
        />
      ),
      children: (
        <OverviewCard
          onCompletionChange={handleCompletionChange}
          debrand={debrand}
          apiWorkflowRef={apiWorkflowRef}
          gcalRef={gcalRef}
          intakeRef={intakeRef}
        />
      ),
    },
    {
      key: '2',
      label: (
        <TabHeadingWithBadge
          customClassName="badge-success"
          heading="Manage Notifications"
          badgeContent={'Recommended'}
        />
      ),
      children: <ManageNotifications />,
    },
  ]
  const onChange = (key: string) => {
    console.log(key)
  }
  return (
    <div className="overview-container text-left">
      <span className="text-2xl text-center font-bold ml-1">Configure Your Receptionist</span>
      <Tabs className="mt-4" defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  )
}
