//@ts-ignore
import TextingPlatformm from '../../../v1/TextingPlatform.jsx'
import { useAppSelector } from '@/store/hooks/useAppSelector.js'
import { getLoggedInUser } from '@/store/account/selector.js'
//@ts-ignore
import { prod } from '../../../v1/config/index.js'
import { FC, useEffect, useState } from 'react'
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone.js'

export const TextingPlatform: FC<{debrand: boolean}> = ({debrand}) => {
  useEffect(() => {
    getReseller()
  }, [])
  const serverURL = import.meta.env.VITE_APP_API_BASE_URL

  const loggedInUser = useAppSelector(getLoggedInUser)
  const { timeZone } = useLocalTimezone(loggedInUser?.time_zone)
  const [resellerObject, setResellerObject] = useState(null)

  const getReseller = async () => {
    console.log('Tryign to get reseller')
    try {
      console.log('Tryign to get reseller')
      const response = await fetch(`${serverURL}/get_reseller_from_receptionist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: loggedInUser.number,
        }),
      })

      console.log('Getting reseller...')
      const data = await response.json()

      if (data.success) {
        const resellerObject = data.reseller
        setResellerObject(resellerObject)
      }
    } catch (error) {
      console.log("Rip this one didn't work")
    }
  }

  return (
    <TextingPlatformm
      themeColor={'#007bff'}
      premium_customer={!loggedInUser.demo}
      theirNumber={loggedInUser.number}
      username={loggedInUser.number}
      selectedTimeZone={timeZone}
      debrand={debrand}
      resellerObject={resellerObject}
    />
  )
}
