import { OverViewCardForm } from '@/pages/ReceptionistDashboard/Overview/Cards/OverviewCardForm'
import { Form, notification, Spin } from 'antd'
import AccountSettingForm from './AccountSettingForm'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { updateUser } from '@/api/user'
import { LoadingOutlined } from '@ant-design/icons'

const AccountSettings: React.FC<{ business_name?: string; isLoading: boolean }> = ({ business_name, isLoading }) => {
  const [form] = Form.useForm<{ business_name?: string }>()
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      notification.success({
        key: 'form-success',
        message: 'Account details has been updated successfully!',
        duration: 3,
      })
      setIsFieldTouchReset(false)
    },
  })

  const handleSubmit = (values: { business_name: string }) => {
    mutation.mutate({
      business_name: values.business_name,
    })
  }

  return (
    <div>
      <Spin
        spinning={mutation.isPending || isLoading}
        indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
      >
        <OverViewCardForm
          isFieldTouchReset={isFieldTouchReset}
          form={form}
          handleSubmit={handleSubmit}
          formItems={<AccountSettingForm initialValues={{ business_name: business_name ?? '' }} />}
          initialValues={{ business_name }}
        />
      </Spin>
    </div>
  )
}

export default AccountSettings
