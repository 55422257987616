import { toast } from "react-toastify";
import apiClient from "@/utils/apiClient";


const NOVI_LEADS_ROUTE = "/api/leads";
// Define types for the input parameters if you know their structure
// For example, if `leads` is an object with specific properties, define an interface for it.
// Adjust the interface below based on the actual structure of `leads`.
interface Lead {
  // Define properties based on the expected shape of a single lead
  name: string;
  email: string;
  // Add other properties as needed
}

// Update the function to use TypeScript
export async function createDataFn(leads: Lead[], campaign_id: string): Promise<void> {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/createData`,
      body: leads
    });

    toast.success("Successfully created leads!");
    return response;
  } catch (error) {
    toast.error("Error creating leads");
    console.error("Error creating leads", error);
  }
}

// Define the type for the file input if needed
export async function uploadCsvFn(file: File, campaign_id: string): Promise<void> {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("campaign_id", campaign_id);

  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/uploadCsv`,
      body: formData,
      // Remove 'Content-Type' header
    });

    toast.success("Upload successful!");
    return response; // Return response data if needed
  } catch (err) {
    toast.error("Error uploading file.");
    console.error("Error uploading file here:", err);
  }
}
export async function getLeadsFn(campaign_id: string): Promise<Lead[]> {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/getLeads`,
    });
    console.log("Leads fetched successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching leads:", error);
    throw error;
  }
}


//Upload Google Sheets
export async function uploadGoogleSheetsFn(link: string, campaign_id: string): Promise<void> {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/uploadGoogleSheets`,
      body: { link, campaign_id }
    });
    toast.success("Upload successful!");
    return response;
  } catch (err) {
    toast.error("Error uploading leads.");
    console.error("Error uploading leads here:", err);
  }
}

//Update lead data
export async function updateLeadDataFn(payload: { campaign_id: string; lead_id: string;[key: string]: any }): Promise<void> {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/leads/${payload.campaign_id}/updateLead/${payload.lead_id}`,
      body: payload
    });
    return response;
  } catch (err) {
    toast.error("Error uploading leads.");
    console.error("Error uploading leads here:", err);
  }
}