import { LoadingOutlined } from '@ant-design/icons'
import './Loading.scss'

export const Loading: React.FC = () => {
  const baseClass = 'loading-screen'
  return (
    <div className={baseClass}>
      <LoadingOutlined />
      <span className={`${baseClass}__description`}>Loading....</span>
    </div>
  )
}
