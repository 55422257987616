import { TCalendarsUser } from '@/types/TCalendar'
import apiClient from '@/utils/apiClient'

const CALENDAR_API_PATH = '/api/calendars'
const CALENDAR_CONFIGURE_API_PATH = '/api/calendars/google-calendar'

export const updateCalendar = async (payload: any): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: CALENDAR_API_PATH,
      body: payload,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const getCalendars = async (): Promise<{ data: TCalendarsUser }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: CALENDAR_API_PATH,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const deleteCalendar = async (): Promise<{ success: boolean }> => {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: CALENDAR_API_PATH,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const configureCalendar = async (payload: { encAcc: string; encRefr: string }): Promise<{}> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: CALENDAR_CONFIGURE_API_PATH,
      body: payload,
    })

    return response
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}
