import { ResellerSiderItem } from 'src/enums/IResellerSiderItems'
import ResellerDashboard from '../ResellerDashboard'
import { Button, Card, ColorPicker, Form, GetProp, Input, notification, Switch, Upload, UploadProps } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UPDATE_RESELLER_LOGO, UpdateResellerBranding, UpdateResellerBrandingToggle } from '@/api/reseller'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getResellerData } from '@/store/reseller/selector'
import { useEffect, useState } from 'react'
import { updateReseller } from '@/store/reseller/slice'
import './ResellerBranding.scss'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const ResellerBranding = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const currentReseller = useAppSelector(getResellerData)
  const [loading, setLoading] = useState<boolean>(false)
  const [previewImageUrl, setPreviewImageUrl] = useState<string>('')

  const queryClient = useQueryClient()

  useEffect(() => {
    if (currentReseller) {
      form.setFieldsValue({
        resellerName: currentReseller.reseller_name,
        themeColor: currentReseller.theme_color,
        privacyPolicyURL: currentReseller.privacyPolicyURL,
        termsOfUseURL: currentReseller.termsOfUseURL,
        uploadedLogoS3Url: currentReseller.logo_s3,
      })
    }
  }, [currentReseller, form])

  const mutation = useMutation({
    mutationKey: ['updateResellerBranding'],
    mutationFn: UpdateResellerBranding,
    onError(data) {
      notification.error({
        message: data.message,
      })
    },
   onSuccess(data) {
     if(data.success){
      queryClient.invalidateQueries({queryKey: ["reseller"]})
      notification.success({
        key: 'update-reseller',
        message: 'Updated successfully',
        duration: 3,
      })
     }
   },
  })

  const handleSubmit = async (values: {
    resellerName: string
    themeColor: string
    uploadedLogoS3Url: string
    emailAddress: string
    privacyPolicyURL: string
    termsOfUseURL: string
  }) => {
    const valuesWithEmail = {
      ...values,
      emailAddress: currentReseller.email_address,
    }
    await mutation.mutateAsync(valuesWithEmail)
    //@ts-ignore
    dispatch(updateReseller({
      logo_s3: values.uploadedLogoS3Url,
      logoUrl: previewImageUrl
    }))
  }

  const updateResellerBrandingToggleMutation = useMutation({
    mutationKey: ['UpdateResellerBrandingToggle'],
    mutationFn: (newBrandedValue: boolean) =>
      UpdateResellerBrandingToggle({
        resellerEmail: currentReseller.email_address,
        branded: newBrandedValue,
      }),
    onSuccess: () => {
      notification.success({
        key: 'update-reseller-branding',
        message: 'Branding off successfully',
        duration: 3,
      })
    },
  })

  const handleResellerBrandingToggle = (checked: boolean) => {
    //@ts-ignore
    dispatch(updateReseller({ branded: checked }))
    updateResellerBrandingToggleMutation.mutate(checked)
  }

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      console.log(info.file.response.url, 'URL')
      form.setFieldValue('uploadedLogoS3Url', info.file.response.url)
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false)
        setPreviewImageUrl(url)
      })
      setLoading(false)
    }
  }

  const uploadButton = (
    <button type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-1 text-gray-500">Upload</div>
    </button>
  )

  const handleColorChange = (value: any) => {
    form.setFieldValue('themeColor', value.toHexString())
  }

  const baseClass = 'reseller-branding-container'
  return (
    <ResellerDashboard activeKey={ResellerSiderItem.BRANDING}>
      <Card className={baseClass}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div className="flex items-center gap-6">
            <Upload
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              action={UPDATE_RESELLER_LOGO}
              onChange={handleChange}
              name="logo"
              accept="image/*"
            >
              {previewImageUrl ? <img className="w-16 h-16 rounded-lg" src={previewImageUrl} alt="logo" /> : uploadButton}
            </Upload>
            <Form.Item label="Business name" name="resellerName" className="w-full">
              <Input placeholder="Please enter business name" />
            </Form.Item>
          </div>
          <Form.Item name="uploadedLogoS3Url"></Form.Item>
          <div className="flex items-center">
            <Form.Item label="Theme color" name="themeColor" className="w-full">
              <ColorPicker onChange={handleColorChange}/>
            </Form.Item>
            <div className="w-full flex justify-between items-center">
              <span className="mr-2 text-[#acaba9]">Toggle to On/Off Branding</span>
              <Switch
                size="default"
                loading={updateResellerBrandingToggleMutation.isPending}
                onChange={handleResellerBrandingToggle}
                checked={currentReseller.branded}
              />
            </div>
          </div>
          <div className="flex items-center gap-4 mb-6">
            <Form.Item label="Privacy Policy URL" name="privacyPolicyURL" className="w-full">
              <Input placeholder="please enter privacy policy url" />
            </Form.Item>
            <Form.Item label="Terms of use URL" name="termsOfUseURL" className="w-full">
              <Input placeholder="please enter terms of use url" />
            </Form.Item>
          </div>
          <Form.Item className="!mb-0">
            <Button loading={loading || mutation.isPending} type="primary" htmlType="submit" className="flex ml-auto">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ResellerDashboard>
  )
}

export default ResellerBranding
