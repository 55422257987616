import { Col, Layout, Spin, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { getShortURLRedirect } from '@/api/shortURL'
import { useQuery } from '@tanstack/react-query'
import './ShortURLRedirect.scss'
import { useEffect } from 'react'

const { Content } = Layout
const { Title } = Typography

const baseClass = 'short-url-redirect'

const ShortURLRedirect = () => {
  const { shortCode } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const encryptedKey = urlParams.get('encryptedKey')

  const { data, error, isLoading } = useQuery({
    queryKey: ['shortURLRedirect', { shortCode, encryptedKey }],
    queryFn: () => {
      if (!shortCode) return
      return getShortURLRedirect(shortCode, encodeURIComponent(encryptedKey || ''))
    },
  })

  useEffect(() => {
    if (data && data.success && data.originalURL) {
      window.location.href = data?.originalURL
    }
  }, [data])

  return (
    <Layout className={baseClass}>
      <Content className={`${baseClass}__content`}>
        <div className={`${baseClass}__body`}>
          {isLoading ? (
            <>
              <Title level={3} className={`${baseClass}__title`}>
                Redirecting...
              </Title>
              <Spin size="large" tip="Loading..." className={`${baseClass}__spinner`} />
            </>
          ) : error ? (
            <Col xs={24} className={`${baseClass}__error`}>
              <Title level={4}>Error redirecting to the URL</Title>
            </Col>
          ) : null}
        </div>
      </Content>
    </Layout>
  )
}

export default ShortURLRedirect
