import { RequestOptions } from '@/interfaces/IHttpRequest'
import { Group, LeadNotesResponse } from '@/interfaces/IColumn'
import {
  CreateGroupData,
  UpdateGroupData,
  UpdateLeadStatusData,
  UpdateLeadData,
  UpdateLeadNotesData,
  DeleteNoteData,
  Lead,
} from '@/interfaces/ICrmApisInterface'
import { toast } from 'react-toastify'
import apiClient from '@/utils/apiClient'

// Helper function to create request options
const createRequestOptions = (
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  endpoint: string,
  data?: any,
  headers?: HeadersInit
): RequestOptions => ({
  method,
  endPoint: endpoint,
  body: data,
  headers: {
    'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
    ...headers,
  },
})

// Fetch groups
export const fetchGroups = async (username: string): Promise<{ groups: Group[]; columns: any[] }> => {
  try {
    const response = await apiClient.request(createRequestOptions('GET', `/api/crm/groups/${username}`))
    const groups = response

    const columns = groups.map((group: Group) => ({
      id: group.group_id,
      name: group.group_name,
      definitionType: group.definition_type,
      definition: group.definition,
      workflow_name: group.workflow_name,
      trigger_action: group.trigger_action,
      tasks: group.leads
        ? group.leads.map((lead: Lead) => ({
            title: lead.phone_number,
            lead_id: lead.lead_id,
            username: lead.username,
            current_status: lead.current_status,
            first_contact: lead.first_contact,
            phone_number: lead.phone_number,
            last_contact: lead.last_contact,
            notes: lead.notes,
            status_history: lead.status_history,
          }))
        : [],
    }))

    return { groups, columns }
  } catch (error) {
    toast.error('Error fetching groups.')
    console.error('Error fetching groups:', error)
    throw error
  }
}

// Create group
export const createGroup = async (groupData: CreateGroupData): Promise<Group> => {
  try {
    const response = await apiClient.request(createRequestOptions('POST', '/api/crm/groups', groupData))
    toast.success('Group created successfully!')
    return response
  } catch (error) {
    toast.error('Error creating group.')
    console.error('Error creating group:', error)
    throw error
  }
}

//  Update group
export const updateGroup = async (data: UpdateGroupData): Promise<Group> => {
  try {
    const { username, groupId, ...updateData } = data
    const response = await apiClient.request(
      createRequestOptions('PUT', `/api/crm/groups/${username}/${groupId}`, updateData)
    )
    toast.success('Group updated successfully!')
    return response
  } catch (error) {
    toast.error('Error updating group.')
    console.error('Error updating group:', error)
    throw error
  }
}

// Delete group
export const deleteGroup = async ({ username, groupId }: { username: string; groupId: string }): Promise<any> => {
  try {
    const response = await apiClient.request(createRequestOptions('DELETE', `/api/crm/groups/${username}/${groupId}`))

    // Check if response is empty
    if (!response) {
      throw new Error('Empty response from server')
    }

    // Check if response is valid JSON
    if (typeof response !== 'object') {
      console.error('Raw response:', response) // Log the raw response for debugging
      throw new Error('Invalid response from server')
    }

    toast.success('Group deleted successfully!')
    return response
  } catch (error: any) {
    console.error('Error deleting group:', error)
    throw error
  }
}

// Update lead status
export const updateLeadStatus = async (data: UpdateLeadStatusData): Promise<any> => {
  try {
    const response = await apiClient.request(
      createRequestOptions('PUT', `/api/crm/leads/updateStatus`, {
        username: data.username,
        lead_id: data.lead_id,
        current_status: data.current_status,
        phone_number: data.phone_number,
      })
    )
    toast.success('Lead status updated successfully!')
    return response
  } catch (error: any) {
    toast.error(error.message || `Error updating lead status. ${JSON.stringify(error)}`)
    console.error('Error updating lead status:', error)
    throw error
  }
}

// Update lead status on Kanban board
export const updateLeadStatusOnKanban = async (data: UpdateLeadStatusData): Promise<any> => {
  try {
    const response = await apiClient.request(
      createRequestOptions('PUT', `/api/crm/leads/updateStatusOnKanban/`, {
        newGroupId: data.newGroupId,
        username: data.username,
        lead_id: data.leadId,
        phone_number: data.phone_number,
      })
    )
    toast.success('Lead status updated on Kanban board successfully!')
    return response
  } catch (error: any) {
    toast.error(error.message || `Error updating lead status on Kanban board: ${JSON.stringify(error)}`)
    console.error('Error updating lead status on Kanban board:', error)
    throw error
  }
}

// Update lead
export const updateLeadApi = async (data: UpdateLeadData): Promise<any> => {
  try {
    const response = await apiClient.request(createRequestOptions('PUT', '/api/crm/leads/updateLead', data))
    toast.success('Lead updated successfully!')
    return response
  } catch (error) {
    toast.error('Error updating lead.')
    console.error('Error updating lead:', error)
    throw error
  }
}

// Update lead notes
export const updateLeadNotesApi = async (data: UpdateLeadNotesData): Promise<any> => {
  try {
    const response = await apiClient.request(
      createRequestOptions('PUT', `/api/crm/leads/notes/${data.username}/${data.lead_id}`, data)
    )
    toast.success('Lead Added successfully!')
    return response
  } catch (error) {
    toast.error('Error updating lead notes.')
    console.error('Error updating lead notes:', error)
    throw error
  }
}

// Delete note
export const deleteNoteApi = async (data: DeleteNoteData): Promise<any> => {
  try {
    const response = await apiClient.request(
      createRequestOptions('DELETE', `/api/crm/leads/notes/${data.username}/${data.lead_id}`, data)
    )
    toast.success('Note deleted successfully!')
    return response
  } catch (error) {
    toast.error('Error deleting note.')
    console.error('Error deleting note:', error)
    throw error
  }
}

// Delete note

// get lead notes
export const getSingleLeadNotesApi = async (username: string, lead_id: string): Promise<LeadNotesResponse> => {
  try {
    const response = await apiClient.request(createRequestOptions('GET', `/api/crm/leads/notes/${username}/${lead_id}`))
    return response
  } catch (error) {
    toast.error('Error updating lead notes.')
    console.error('Error updating lead notes:', error)
    throw error
  }
}

// Fetch workflows
export const fetchWorkflows = async (username: string): Promise<any> => {
  try {
    const response = await apiClient.request(createRequestOptions('GET', `/api/clients/workflows/${username}`))
    return response.data
  } catch (error) {
    toast.error('Error fetching workflows.')
    console.error('Error fetching workflows:', error)
    throw error
  }
}

// Get contacts
export const getContacts = async (username: string): Promise<any> => {
  try {
    const response = await apiClient.request(createRequestOptions('POST', '/get-contacts', { username }))
    return response
  } catch (error) {
    toast.error('Error fetching contacts.')
    console.error('Error fetching contacts:', error)
    throw error
  }
}

// Update contact name
export const updateContactName = async (lead_id: string, name: string) => {
  try {
    const response = await apiClient.request(
      createRequestOptions('POST', '/update-contact', {
        contact: { [lead_id]: name },
        username: 'YOUR_USERNAME_HERE', // Replace with actual username logic
      })
    )
    return response
  } catch (error) {
    toast.error('Error updating contact name.')
    console.error('Error updating contact name:', error)
    throw error
  }
}
