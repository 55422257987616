import React, { useState, ChangeEvent } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Modal, Input, Button, Form } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { useFetchGroups } from 'src/Hooks/UseHookForCrmData'
import { Group } from '@/interfaces/IColumn'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'

// Define interfaces for props
interface Subtask {
  title: string
  isCompleted: boolean
  id: string
}

interface AddEditTaskModalProps {
  type: 'add' | 'edit'
  device: 'mobile' | 'desktop'
  setIsTaskModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsAddTaskModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  taskIndex?: number
  prevColIndex?: number
}

function AddEditTaskModal({
  type,
  setIsTaskModalOpen,
  setIsAddTaskModalOpen,
  taskIndex = 0,
  prevColIndex = 0,
}: AddEditTaskModalProps) {
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [, setStatus] = useState<string>('')
  const [subtasks, setSubtasks] = useState<Subtask[]>([
    { title: '', isCompleted: false, id: uuidv4() },
    { title: '', isCompleted: false, id: uuidv4() },
  ])

  const loggedInUser = useAppSelector(getLoggedInUser)
  const username = loggedInUser?.number
  const { data: columns } = useFetchGroups(username as string)
  const [localColumns] = useState<Group[]>(columns?.columns || [])
  const col = localColumns[prevColIndex]
  const task = col?.tasks?.find((_: any, index: number) => index === taskIndex) || null

  // Initialize values on the first load if editing
  React.useEffect(() => {
    if (type === 'edit' && isFirstLoad) {
      setSubtasks(
        task?.subtasks?.map((subtask: any) => ({
          ...subtask,
          id: uuidv4(),
        })) || []
      )
      setTitle(task?.title || '')
      setDescription(task?.description || '')
      setStatus(task?.status || '')
      setIsFirstLoad(false)
    }
  }, [type, isFirstLoad, task])

  const handleSubtaskChange = (id: string, newValue: string) => {
    setSubtasks((prevState) =>
      prevState?.map((subtask) => (subtask.id === id ? { ...subtask, title: newValue } : subtask))
    )
  }

  // const handleStatusChange = (value: string) => {
  //   setStatus(value);
  // };

  const handleAddSubtask = () => {
    setSubtasks([...subtasks, { title: '', isCompleted: false, id: uuidv4() }])
  }

  const handleDeleteSubtask = (id: string) => {
    setSubtasks(subtasks.filter((subtask) => subtask.id !== id))
  }

  const handleSubmit = () => {
    if (title.trim() === '' || subtasks.some((subtask) => subtask.title.trim() === '')) {
      return // Validation failed, do not submit
    }
    // if (type === "add") {
    //   dispatch(
    //     boardsSlice.actions.addTask({
    //       title,
    //       description,
    //       subtasks,
    //       status,
    //       newColIndex: prevColIndex,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     boardsSlice.actions.editTask({
    //       title,
    //       description,
    //       subtasks,
    //       status,
    //       taskIndex,
    //       prevColIndex,
    //       newColIndex: prevColIndex,
    //     })
    //   );
    // }
    setIsAddTaskModalOpen(false)
    if (type === 'edit') {
      setIsTaskModalOpen(false)
    }
  }

  return (
    <Modal
      visible={true}
      onCancel={() => {
        setIsAddTaskModalOpen(false)
        if (type === 'edit') {
          setIsTaskModalOpen(false)
        }
      }}
      footer={null}
      title={type === 'edit' ? 'Edit Task' : 'Add New Task'}
      className="add-edit-task-modal"
    >
      <Form layout="vertical">
        <Form.Item label="Task Name">
          <Input
            value={title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            placeholder="e.g. Take coffee break"
          />
        </Form.Item>

        <Form.Item label="Description">
          <Input.TextArea
            value={description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
            placeholder="e.g. It's always good to take a break. This 15 minute break will recharge the batteries a little."
            rows={4}
          />
        </Form.Item>

        <Form.Item label="Subtasks">
          {subtasks?.map((subtask) => (
            <Form.Item key={subtask.id} style={{ marginBottom: 8 }}>
              <Input
                value={subtask.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSubtaskChange(subtask.id, e.target.value)}
                placeholder="e.g. Take coffee break"
                style={{ width: 'calc(100% - 32px)', display: 'inline-block' }}
              />
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteSubtask(subtask.id)}
                style={{ marginLeft: 8 }}
              />
            </Form.Item>
          ))}
          <Button type="dashed" icon={<PlusOutlined />} onClick={handleAddSubtask}>
            Add New Subtask
          </Button>
        </Form.Item>

        {/* <Form.Item label="Current Status">
          <Select
            value={status}
            onChange={handleStatusChange}
            placeholder="Select a status"
          >
            {columns?.map((column: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
              <Option key={index} value={column.name}>
                {column.name}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" onClick={handleSubmit} block>
            {type === 'edit' ? 'Save Edit' : 'Create Task'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddEditTaskModal
