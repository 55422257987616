import { LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, notification, Row, Spin, Table, Tooltip } from 'antd'
import { OverViewCardForm } from '../../OverviewCardForm'
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { deletePronunciation, generatePronunciation, updateUser } from '@/api/user'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { DeleteOutline, VolumeUp } from '@mui/icons-material'
import { FaRobot } from 'react-icons/fa'
import { ipaToWord } from '@/utils/helper'
import { User } from '@/types/TAccounts'

const PronunciationGuideForm: React.FC<{
  word_pronunciation: {
    pronunciation: string
    word: string
  }[]
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
}> = ({ word_pronunciation, refetch }) => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const [dataSource, setDataSource] = useState(word_pronunciation)
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)
  const [speaking, setSpeaking] = useState({
    word: false,
    pronunciation: false,
  })

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch()
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      })
      setIsFieldTouchReset(false)
    },
  })

  const { mutateAsync: deletePronunciationFn, isPending } = useMutation({
    mutationKey: ['delete-pronunciation'],
    mutationFn: deletePronunciation,
    onSuccess: () => {
      refetch()
      notification.success({
        key: 'form-success',
        message: 'Pronunciation deleted successfully!',
      })
      setIsFieldTouchReset(false)
    },
  })

  const { mutate: generatePronunciationFn, isPending: isPendingAutoGen } = useMutation({
    mutationKey: ['generate-pronunciation'],
    mutationFn: generatePronunciation,
    onSuccess: ({ data }) => {
      data && form.setFieldsValue({ pronunciation: data })
    },
  })

  const handleSubmit = async (values: { pronunciation: string; word: string }) => {
    const newData = [...dataSource, values]
    const filteredData = newData.filter((item) => item.word && item.pronunciation)
    setDataSource(filteredData)

    await mutation.mutateAsync({ word_pronunciation: values })

    form.resetFields()
    dispatch(closeModal())
  }

  const handleDelete = async (word: string) => {
    await deletePronunciationFn({ word })
    const filteredData = dataSource.filter((item) => item.word !== word)
    setDataSource(filteredData)
    form.resetFields()
    dispatch(closeModal())
  }

  const autoGeneratePhonetic = (word: string) => {
    generatePronunciationFn({ word, phonetic: 'IPA' })
  }

  const speak = (text: string, type: string) => {
    if ('speechSynthesis' in window) {
      setSpeaking((prev) => ({ ...prev, [type]: true }))

      const utterance = new SpeechSynthesisUtterance(type === 'pronunciation' ? ipaToWord(text) : text)

      utterance.onend = () => {
        setSpeaking((prev) => ({ ...prev, [type]: false }))
      }

      speechSynthesis.speak(utterance)
    } else {
      alert("Sorry, your browser doesn't support text to speech!")
    }
  }

  const columns = [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      onHeaderCell: () => ({
        style: { background: '#E8EFFF' },
      }),
    },
    {
      title: 'Pronunciation',
      dataIndex: 'pronunciation',
      key: 'pronunciation',
      onHeaderCell: () => ({
        style: { background: '#E8EFFF' },
      }),
    },
    {
      title: 'Action',
      width: '80px',
      key: 'action',
      render: (_: any, record: any) => (
        <Button type="link" onClick={() => handleDelete(record.word)}>
          <DeleteOutline className="hover:text-red-500" />
        </Button>
      ),
      onHeaderCell: () => ({
        style: { background: '#E8EFFF' },
      }),
    },
  ]

  const FormItems = () => (
    <Row gutter={8} align="top">
      <Col span={11}>
        <Form.Item
          label="Word"
          name="word"
          rules={[
            { required: true, message: 'Please enter a word' },
            {
              validator: (_, value) => {
                const words = dataSource.map((item) => item.word)
                if (words.map((word) => word.toLowerCase()).includes(value.trim().toLowerCase())) {
                  return Promise.reject('This word is already in use')
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
          className="flex-auto"
        >
          <Input
            placeholder="Enter Word"
            suffix={
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.word !== currentValues.word}>
                {({ getFieldValue }) =>
                  getFieldValue('word') && (
                    <VolumeUp
                      fontSize="small"
                      onClick={() => speak(getFieldValue('word') || '', 'word')}
                      className={`${speaking.word ? 'text-[#007bff]' : 'text-gray-400'} cursor-pointer`}
                    />
                  )
                }
              </Form.Item>
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Pronunciation"
          name="pronunciation"
          className="flex-auto"
          rules={[{ required: true, message: 'Please enter pronunciation' }]}
        >
          <Input
            placeholder="Enter Pronunciation"
            suffix={
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.pronunciation !== currentValues.pronunciation}
              >
                {({ getFieldValue }) =>
                  getFieldValue('pronunciation') && (
                    <VolumeUp
                      fontSize="small"
                      onClick={() => speak(getFieldValue('pronunciation') || '', 'pronunciation')}
                      className={`${speaking.pronunciation ? 'text-[#007bff]' : 'text-gray-400'} cursor-pointer`}
                    />
                  )
                }
              </Form.Item>
            }
          />
        </Form.Item>
      </Col>
      <Col span={1} className="mt-8">
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.word !== currentValues.word}>
          {({ getFieldValue }) => (
            <Tooltip
              color="white"
              title={<span className="text-black">Auto-generate your phonetic for the word with AI</span>}
            >
              <FaRobot
                size={30}
                className={`${getFieldValue('word') ? 'cursor-pointer text-blue-500' : 'cursor-not-allowed text-gray-400'}`}
                onClick={() => autoGeneratePhonetic(getFieldValue('word'))}
              />
            </Tooltip>
          )}
        </Form.Item>
      </Col>

      <span className="text-xs mb-4 ml-2">
        <span className="font-bold italic">Examples: </span> <span className="font-semibold">/njuː ˈjɔrk/</span> for{' '}
        <span className="font-semibold text-gray-500">New York</span>, <span className="font-semibold">/ˈpɛrɪs/</span>{' '}
        for <span className="font-semibold text-gray-500">Paris</span>,{' '}
        <span className="font-semibold">/ˈtoʊ.kioʊ/</span> for{' '}
        <span className="font-semibold text-gray-500">Tokyo</span>
      </span>
    </Row>
  )

  return (
    <>
      <Spin
        spinning={mutation.isPending || isPending || isPendingAutoGen}
        indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
      >
        <OverViewCardForm
          form={form}
          initialValues={{
            word_pronunciation,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
        <Table dataSource={dataSource} columns={columns} scroll={{ y: 240 }} pagination={false} />
      </Spin>
    </>
  )
}

export default PronunciationGuideForm
