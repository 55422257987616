import { ResellerTourInitialState } from '@/types/TResellerTour'
import LocalStorageUtility from '@/utils/localStorage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LocalStorageKeys } from 'src/enums/ELocalStorage'

const initialState: ResellerTourInitialState = {
  open: false,
  currentStep: 0,
}

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTour: (state: ResellerTourInitialState, action: PayloadAction<ResellerTourInitialState>) => {
      state.open = action.payload.open
      state.currentStep = action.payload.currentStep

      LocalStorageUtility.setLocalData(LocalStorageKeys.RESELLER_TOUR, action.payload)
    },
    unsetTour: (state: ResellerTourInitialState) => {
      state.open = false
      state.currentStep = 0

      LocalStorageUtility.setLocalData(LocalStorageKeys.RESELLER_TOUR, { open: false, currentStep: 0 })
    },
  },
})

export const { setTour, unsetTour } = tourSlice.actions

export default tourSlice.reducer
