import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  fetchGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  updateLeadStatus,
  updateLeadApi,
  updateLeadStatusOnKanban,
  fetchWorkflows,
  updateLeadNotesApi,
  getContacts,
  updateContactName,
  getSingleLeadNotesApi,
  deleteNoteApi,
} from '../api/fetchUserData'
import { Group, LeadNotesResponse } from '@/interfaces/IColumn'
import {
  CreateGroupData,
  UpdateGroupData,
  UpdateLeadData,
  UpdateLeadStatusData,
  UpdateLeadNotesData,
  DeleteNoteData,
} from '@/interfaces/ICrmApisInterface'
import { toast } from 'react-toastify'
import { Lead } from '@/interfaces/ICrmApisInterface'
import apiClient from '@/utils/apiClient'

// Fetch groups hook
export const useFetchGroups = (username: string) => {
  return useQuery<{ groups: Group[]; columns: any[] }, Error>({
    queryKey: ['groups', username],
    queryFn: () => fetchGroups(username),
  })
}

// Create group hook
export const useCreateGroup = () => {
  const queryClient = useQueryClient()
  return useMutation<Group, Error, CreateGroupData>({
    mutationFn: createGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

// Update group hook
export const useUpdateGroup = () => {
  const queryClient = useQueryClient()
  return useMutation<Group, Error, UpdateGroupData>({
    mutationFn: updateGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

// Delete group hook
export const useDeleteGroup = () => {
  const queryClient = useQueryClient()
  return useMutation<string, Error, { username: string; groupId: string }>({
    mutationFn: deleteGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

// Update lead status hook
export const useUpdateLeadStatus = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateLeadStatusData>({
    mutationFn: updateLeadStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updateLeadStatus'] })
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

// Update lead status on Kanban board hook
export const useUpdateLeadStatusOnKanban = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateLeadStatusData>({
    mutationFn: updateLeadStatusOnKanban,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leadsOnKanban'] })
    },
  })
}

// Update lead hook
export const useUpdateLead = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateLeadData>({
    mutationFn: updateLeadApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leads'] })
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

// Update lead notes hook
export const useUpdateLeadNotes = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateLeadNotesData>({
    mutationFn: updateLeadNotesApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updateLeadNotes'] })
    },
  })
}

// Delete note hook
export const useDeleteNote = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, DeleteNoteData>({
    mutationFn: deleteNoteApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deleteNote'] })
      queryClient.invalidateQueries({ queryKey: ['groups'] })
    },
  })
}

export const useGetSingleLeadNotes = () => {
  const queryClient = useQueryClient()
  return useMutation<LeadNotesResponse, Error, { username: string; lead_id: string }>({
    mutationFn: ({ username, lead_id }) => getSingleLeadNotesApi(username, lead_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getSingleLeadNotes'] })
    },
  })
}

// Fetch workflows hook
export const useFetchWorkflows = (username: string) => {
  return useQuery<any, Error>({
    queryKey: ['workflows', username],
    queryFn: () => fetchWorkflows(username),
    enabled: !!username, // Enable the query only if username is provided
  })
}

export const useGetContacts = (username: string) => {
  return useQuery<any, Error>({
    queryKey: ['contacts', username],
    queryFn: () => getContacts(username),
    enabled: !!username, // Enable the query only if username is provided
  })
}

export const fetchLeads = async (username: string): Promise<Lead[]> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/crm/leads/getLeads',
      body: { username },
    })

    return response.leads || []
  } catch (error) {
    console.error('Error fetching leads:', error)
    throw error
  }
}

export const useUpdateContactName = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { lead_id: string; name: string }) => updateContactName(data.lead_id, data.name),
    onSuccess: () => {
      toast.success('Contact added successfully')
      queryClient.invalidateQueries({ queryKey: ['contacts'] }) // Replace 'contacts' with the actual query key for fetching contacts
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })
}
