import React, { useState, useEffect } from 'react'
import CloneVoice from './CloneVoice'
import VoiceLibraryCard from './voiceLibraryCard'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getUser } from '@/api/user'
import {
  deleteClonedVoiceModel,
  deleteVoiceModel,
  getClonedVoiceModels,
  getVoiceModels,
  updateVoiceModal,
} from '@/api/voice'
import { Button, notification, Spin, Tabs } from 'antd'
import SearchFilter from './searchFilter'
import { TVoiceModel } from '@/types/TVoiceModel'
import { LyricsOutlined, RecordVoiceOverOutlined } from '@mui/icons-material'
import { AdminManageFormAdd } from './AdminManageFormAdd'
import { debounce } from '@/utils/helper'

interface VoiceSelected {
  voiceId: string
  provider: string
}

const VoiceLibrary: React.FC = () => {
  const [provider, setProvider] = useState<string>('All')
  const [searchText, setSearchText] = useState<string>('')
  const [gender, selectedGender] = useState<string>('All')
  const [language, setLanguage] = useState<string>('English')
  const [selectedCard, setSelectedCard] = useState<string | null>(null)
  const [englishVoiceModel, setEnglishVoiceModel] = useState<string>('043cfc81-d69f-4bee-ae1e-7862cb358650') // australian woman
  const [otherLanguageVoiceModel, setOtherLanguageVoiceModel] = useState<string | null>(null)
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState<number | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>(searchText)

  const { data, isPending, refetch } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  })

  const {
    data: voiceModels,
    isPending: isPendingVoiceModels,
    refetch: refetchVoiceModels,
  } = useQuery({
    queryKey: ['voiceModels', debouncedSearchText, provider, gender, language],
    queryFn: () => getVoiceModels({ search: debouncedSearchText, provider, gender, language }),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const {
    data: clonedVoiceModels,
    isPending: isPendingClonedVoiceModels,
    refetch: refetchClonedVoiceModels,
  } = useQuery({
    queryKey: ['clonedVoiceModels'],
    queryFn: () => getClonedVoiceModels({}),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { mutate: deleteClonedVoiceMutation, isPending: isPendingClonedVoiceModelsDelete } = useMutation({
    mutationKey: ['deleteClonedVoice'],
    mutationFn: deleteClonedVoiceModel,
    onSuccess: ({ success, message }) => {
      if (success) {
        notification.success({ message: message || 'Voice deleted successfully!' })
        refetchClonedVoiceModels()
      }
    },
    onError() {
      notification.error({ message: 'Some error occured during voice model updation' })
    },
  })

  const { mutate: deleteVoiceMutation, isPending: isPendingVoiceModelsDelete } = useMutation({
    mutationKey: ['deleteVoice'],
    mutationFn: deleteVoiceModel,
    onSuccess: ({ success }) => {
      if (success) {
        refetchVoiceModels()
      }
    },
    onError() {
      notification.error({ message: 'Some error occured during voice model updation' })
    },
  })

  const { mutate: updateVoiceModalMutation } = useMutation({
    mutationKey: ['updateVoiceModal'],
    mutationFn: updateVoiceModal,
    onError() {
      notification.error({ message: 'Some error occured during voice model updation' })
    },
  })

  useEffect(() => {
    if (data?.english_voice_selected || data?.other_language_voice_selected) {
      let englishVoiceSelected: VoiceSelected | null = null
      let otherLanguageVoiceSelected: VoiceSelected | null = null
      try {
        if (data.english_voice_selected) {
          englishVoiceSelected = JSON.parse(data.english_voice_selected)
        }
        if (data.other_language_voice_selected) {
          otherLanguageVoiceSelected = JSON.parse(data.other_language_voice_selected)
        }
      } catch (err) {
        console.log(err)
        notification.error({
          message: 'Something went wrong, please try again later',
        })
      }
      setEnglishVoiceModel(englishVoiceSelected?.voiceId || 'aura-asteria-en')
      setOtherLanguageVoiceModel(otherLanguageVoiceSelected?.voiceId || null)

      if (language === 'English') {
        setSelectedCard(englishVoiceSelected?.voiceId || null)
      } else {
        setSelectedCard(otherLanguageVoiceSelected?.voiceId || null)
      }
    }
  }, [data])

  useEffect(() => {
    if (language === 'English') {
      setSelectedCard(englishVoiceModel)
    } else {
      setSelectedCard(otherLanguageVoiceModel)
    }
  }, [language, englishVoiceModel, otherLanguageVoiceModel])

  // Debounce the search input (wait for 300ms after user stops typing)
  useEffect(() => {
    const handler = debounce(() => setDebouncedSearchText(searchText), 300)
    handler()
  }, [searchText])

  const sortedVoiceModels = React.useMemo(() => {
    return [...(voiceModels?.data?.data || [])].sort((a, b) => {
      if (a.voiceId === selectedCard) return -1
      if (b.voiceId === selectedCard) return 1
      return 0
    })
  }, [voiceModels?.data.data, selectedCard])

  const sortedClonedVoiceModels = React.useMemo(() => {
    return [...(clonedVoiceModels?.data?.data || [])].sort((a, b) => {
      if (a.voiceId === selectedCard) return -1
      if (b.voiceId === selectedCard) return 1
      return 0
    })
  }, [clonedVoiceModels?.data?.data, selectedCard])

  const handleFilterChange = (filterType: string, value: string) => {
    if (filterType === 'provider') {
      setProvider(value)
    } else if (filterType === 'gender') {
      selectedGender(value)
    } else if (filterType === 'language') {
      setLanguage(value)
    }
  }

  const handleApplyClick = async (cardValue: string, selectedVoiceModel: string, selectedVoiceProvider: string) => {
    if (language === 'English') {
      setEnglishVoiceModel(selectedVoiceModel)
    } else {
      setOtherLanguageVoiceModel(selectedVoiceModel)
    }
    setSelectedCard(cardValue)

    const voiceSelected = JSON.stringify({
      voiceId: selectedVoiceModel,
      provider: selectedVoiceProvider,
    })

    updateVoiceModalMutation({ voiceSelected, language })
  }

  const handlePlayClick = (index: number | null) => {
    setCurrentlyPlayingIndex(index)
  }

  return (
    <Spin spinning={isPending}>
      <Tabs
        defaultActiveKey="2"
        items={[
          {
            key: 'VoiceLibrary',
            label: <span className="font-bold">Voice Library</span>,
            children: (
              <Spin spinning={isPendingVoiceModels || isPendingVoiceModelsDelete}>
                <main className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-4 py-4">
                  <div className="flex border px-8 py-4 rounded-xl justify-between items-center mb-4">
                    <SearchFilter
                      provider={provider}
                      gender={gender}
                      language={language}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      onFilterChange={handleFilterChange}
                    />
                    {data?.isSuperAdmin && (
                      <Button type="primary" onClick={() => setOpenModal(true)}>
                        Add New
                      </Button>
                    )}
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {sortedVoiceModels &&
                      sortedVoiceModels.map((item: TVoiceModel, index: number) => (
                        <VoiceLibraryCard
                          key={item.voiceId}
                          index={index}
                          data={item}
                          isSelected={selectedCard === item.voiceId}
                          onApplyClick={() => handleApplyClick(item.voiceId, item.voiceId, item.provider)}
                          currentlyPlayingIndex={currentlyPlayingIndex}
                          onPlayClick={handlePlayClick}
                          user={data}
                          deleteVoiceMutation={deleteVoiceMutation}
                        />
                      ))}
                  </div>
                </main>
              </Spin>
            ),
            icon: <LyricsOutlined />,
          },
          {
            key: 'CloneVoice',
            label: <span className="font-bold">Clone Voice</span>,
            children: (
              <Spin spinning={isPendingClonedVoiceModels || isPendingClonedVoiceModelsDelete}>
                <CloneVoice
                  data={clonedVoiceModels?.data}
                  refetch={() => {
                    refetch()
                    refetchClonedVoiceModels({})
                  }}
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mx-4 my-4">
                  {sortedClonedVoiceModels &&
                    sortedClonedVoiceModels.map((item: TVoiceModel, index: number) => (
                      <VoiceLibraryCard
                        key={item.voiceId}
                        index={index}
                        data={item}
                        isSelected={selectedCard === item.voiceId}
                        onApplyClick={() => handleApplyClick(item.voiceId, item.voiceId, item.provider)}
                        currentlyPlayingIndex={currentlyPlayingIndex}
                        onPlayClick={handlePlayClick}
                        user={data}
                        deleteVoiceMutation={deleteClonedVoiceMutation}
                      />
                    ))}
                </div>
              </Spin>
            ),
            icon: <RecordVoiceOverOutlined />,
          },
        ]}
      />
      <AdminManageFormAdd
        refetch={refetchVoiceModels}
        addMode={true}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </Spin>
  )
}

export default VoiceLibrary
