import React from 'react'
import { Button } from 'antd'
import { AddOutlined, EditOutlined, OpenInNew } from '@mui/icons-material'
import './OverviewCardInfo.scss'
import { CustomModal } from '@/components/CustomModal'
import classNames from 'classnames'
import { UseMutationResult } from '@tanstack/react-query'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal, openModal } from '@/store/modal/slice'
import { MODAL_IDS } from 'src/enums/EModal'
import { TUTORIALS } from 'src/enums/ETutorials'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'

export const OverViewCardInfo: React.FC<{
  info: React.ReactNode
  form: React.ReactNode
  footer?: React.ReactNode
  formTitle?: React.ReactNode
  formActionType?: 'Add' | 'Edit'
  displayFormActionBtn?: boolean
  displayTutorialActionBtn?: boolean
  customClasses?: string
  width?: number | string
  modalId: MODAL_IDS
  tutorialId?: TUTORIALS
  mutation?: UseMutationResult<{}, Error, any, unknown>
  onEditClick?: () => void
}> = ({
  info,
  form,
  formTitle,
  customClasses,
  formActionType,
  width,
  footer,
  modalId,
  tutorialId,
  onEditClick,
  displayFormActionBtn = true,
  displayTutorialActionBtn = false,
}) => {
  const dispatch = useAppDispatch()

  const loggedInUser = useAppSelector(getLoggedInUser)

  const handleClick = () => {
    if (onEditClick) {
      onEditClick()
    } else {
      dispatch(openModal({ modalId }))
    }
  }
  const handleTutorialClick = () => {
    if (!tutorialId) return
    // open the corresponding OpenInNew in a new tab
    window.open(tutorialId, '_blank')
  }

  const handleConfirm = () => {
    dispatch(closeModal())
  }

  return (
    <div
      className={classNames('overview-card-info', {
        [`${customClasses}`]: !!customClasses,
      })}
    >
      <div className="item-containter">
        <div className="item">
          {info}

          <div className="button-container">
            {displayFormActionBtn && (
              <Button
                type="primary"
                onClick={handleClick}
                icon={formActionType === 'Add' ? <AddOutlined /> : <EditOutlined />}
                className="item-edit mr-3"
              >
                {formActionType}
              </Button>
            )}
            {displayTutorialActionBtn && !loggedInUser.resellerClient && (
              <Button type="primary" onClick={handleTutorialClick} icon={<OpenInNew />} className="item-edit">
                Learn More
              </Button>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        width={width}
        title={formTitle}
        children={form}
        confirmAction={handleConfirm}
        footer={footer}
        modalId={modalId}
      />
    </div>
  )
}
