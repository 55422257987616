import { CustomModal } from "@/components/CustomModal";
import { MODAL_IDS } from "src/enums/EModal";
import ZapierIntegrationInstruction from "../ZapierIntegrationInstructionModal";


const ZapierIntegrationModal = () => {
  return (
    <CustomModal
    title={<h1 className="text-2xl font-bold mb-5">How to Integrate the AI receptionist with Any App using Zapier</h1>}
    children={
      <ZapierIntegrationInstruction/>
    }
    modalId={MODAL_IDS.RESELLER_ZAPIER_INSTRUCTION_MODAL}
    footer={null}
    width={'70%'}
  />
  );
};

export default ZapierIntegrationModal;
