import React from 'react'
import { Card, Typography, Tooltip, notification } from 'antd'
import {
  DeleteFilled,
  CopyOutlined,
  EyeOutlined,
  LockOutlined,
  FieldTimeOutlined,
  ClearOutlined,
} from '@ant-design/icons'

const { Title, Text } = Typography

interface ReceptionistCardProps {
  business_name: string
  number: string
  password: string
  minutesLimitEnabled?: boolean
  max_usage_limit_logs?: Array<{
    month: string
    usage: number
  }>
  onDelete: (number: string) => void
  onPreview: (credentials: { username: string; password: string }) => void
  onChangePassword: (number: string) => void
  onClearLogs: (number: string) => void
  onSetMinutesLimit: (number: string) => void
}

const ReceptionistCard: React.FC<ReceptionistCardProps> = ({
  business_name,
  number,
  password,
  minutesLimitEnabled,
  max_usage_limit_logs,
  onDelete,
  onPreview,
  onChangePassword,
  onClearLogs,
  onSetMinutesLimit,
}) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    notification.success({
      message: 'Copied to clipboard',
      duration: 2,
    })
  }

  const getCurrentMonthUsage = () => {
    const currentMonth = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
    return Math.floor(max_usage_limit_logs?.find((log) => log.month === currentMonth)?.usage || 0)
  }

  return (
    <Card
      className="hover:shadow-lg transition-shadow duration-300"
      actions={[
        <Tooltip title="Delete Receptionist" key="delete">
          <DeleteFilled className="text-red-500 text-lg hover:text-red-700" onClick={() => onDelete(number)} />
        </Tooltip>,
        <Tooltip title="Preview Dashboard" key="preview">
          <EyeOutlined
            className="text-blue-500 text-lg hover:text-blue-700"
            onClick={() => onPreview({ username: number, password })}
          />
        </Tooltip>,
        <Tooltip title="Change Password" key="password">
          <LockOutlined
            className="text-gray-500 text-lg hover:text-gray-700"
            onClick={() => onChangePassword(number)}
          />
        </Tooltip>,
        <Tooltip title="Clear Logs" key="clear">
          <ClearOutlined
            className="text-orange-500 text-lg hover:text-orange-700"
            onClick={() => onClearLogs(number)}
          />
        </Tooltip>,
        <Tooltip title="Minutes Limitation" key="minutes">
          <FieldTimeOutlined
            className="text-green-500 text-lg hover:text-green-700"
            onClick={() => onSetMinutesLimit(number)}
          />
        </Tooltip>,
      ]}
    >
      <div className="space-y-4">
        <Title level={4} className="!mb-1 text-center">
          {business_name}
        </Title>

        <div className="space-y-2">
          <div className="flex items-center justify-between bg-gray-50 p-2 rounded">
            <div>
              <Text type="secondary">Username</Text>
              <div className="font-medium">{number}</div>
            </div>
            <Tooltip title="Copy Username">
              <CopyOutlined
                className="text-gray-500 cursor-pointer hover:text-blue-500"
                onClick={() => copyToClipboard(number)}
              />
            </Tooltip>
          </div>

          <div className="flex items-center justify-between bg-gray-50 p-2 rounded">
            <div>
              <Text type="secondary">Password</Text>
              <div className="font-medium">{password}</div>
            </div>
            <Tooltip title="Copy Password">
              <CopyOutlined
                className="text-gray-500 cursor-pointer hover:text-blue-500"
                onClick={() => copyToClipboard(password)}
              />
            </Tooltip>
          </div>
        </div>

        {minutesLimitEnabled && (
          <div className="mt-4 bg-blue-50 p-2 rounded">
            <Text type="secondary">Monthly Usage (minutes)</Text>
            <div className="font-medium">{getCurrentMonthUsage()}</div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default ReceptionistCard
