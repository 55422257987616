import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import Fade from "react-reveal/Fade";
import classes from "./../RegisterPageV3.module.css";
import debrandResellLogo from "./../shake-hand.png";
import logo from "./../Blue_Logo.png";
import { prod } from "../config";
import Header from "../components/registration/Header";
import WelcomeScreen from "../components/registration/WelcomeScreen";
import BusinessNameScreen from "../components/registration/BusinessNameScreen";
import ThemeColorSelectionScreen from "../components/registration/ThemeColorSelectionScreen";
import PasswordRegisterForm from "../components/registration/PasswordRegisterForm";
import EmailRegisterForm from "../components/registration/EmailRegisterForm";
import UploadLogo from "../components/registration/UploadLogo";
// import classes from "./Campaign.module.css";

const RegisterCampaign = ({ debrand = false }) => {
  const [campaignPassword, setCampaignPassword] = useState("");
  const campaignPWd = "ostrich74!";
  const [showCampaignRegisterForm, setShowCampaignRegisterForm] =
    useState(false);
  const [campaignUserName, setCampaignUserName] = useState("");
  const [themeColor, setThemeColor] = useState("#007bff");
  const [uploadedLogo, setUploadedLogo] = useState("");
  const [uploadedLogoS3Url, setUploadedLogoS3Url] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [stepState, setStepState] = useState(0);

  const [, setIsReferralUser] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!debrand) {
      // Include Zendesk Widget script
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=a1895b38-5ada-4a38-b4b1-580c900fcc1e";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        // Cleanup when component unmounts
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    window.gtag("event", "CampaignUserRegistrationPageView", {
      event_category: "Pageview",
    });
  }, []);

  // use effect for step state changing to fire GTag analytics
  useEffect(() => {
    // just record what step number we are on
    window.gtag("event", "CampaignUserRegistrationStep", {
      event_category: "CampaignUserRegistration.js",
      event_label: "Step # " + stepState,
    });
    console.log("Step State: " + stepState);
  }, [stepState]);

  var serverURL = prod
    ? "https://myaifrontdeskadmin.com"
    : "http://localhost:8080";
  if (prod) {
    console.log = function () {};
    console.error = function () {};
  }
  useEffect(() => {
    (function (w, r) {
      w._rwq = r;
      w[r] =
        w[r] ||
        function () {
          (w[r].q = w[r].q || []).push(arguments);
        };
    })(window, "rewardful");
    let s = document.createElement("script");
    s.async = true;
    s.src = "https://r.wdfl.co/rw.js";
    s.setAttribute("data-rewardful", "30c5c6");
    s.setAttribute(
      "data-domains",
      "myaifrontdesk.com, myaifrontdeskdashboard.com"
    );
    document.head.appendChild(s);

    window.rewardful("ready", function () {
      console.log(window.Rewardful);
      if (window.Rewardful.referral) {
        console.log("Current referral ID: ", window.Rewardful.referral);
      } else {
        console.log("No referral present.");
      }
    });
  }, []);

  useEffect(() => {
    // Create a URLSearchParams object to access the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Get the 'referralCode' parameter
    const referralCode = queryParams.get("referralCode");

    if (referralCode) {
      // Do something with the referralCode
      console.log("Referral Code:", referralCode);

      if (referralCode) {
        // The user has a referral code, so set the state to true
        setIsReferralUser(true);
        setReferralCode(referralCode);
      }
    } else {
      // Handle the case when referralCode is not present in the URL
      console.log("Referral Code is not present in the URL");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      return;
    }

    // Make server call using fetch to /createClient
    let data, response;
    try {
      response = await fetch(`${serverURL}/api/campaigns/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailAddress,
          campaignUserName,
          uploadedLogoS3Url,
          password,
          referralCode,
          themeColor,
        }),
      });
      data = await response.json();
      if (!response.ok) {
        window.gtag("event", "couldnt_create_campaign_user", {
          event_category: "RegisterCampaign.js",
          event_label: "error",
        });
        throw new Error("Network response was not ok");
      }
      const status = data.success;
      if (status) {
        window.gtag("event", "create_campaign_user", {
          event_category: "RegisterCampaign.js",
          event_label: "success",
        });
        if (!debrand) {
          navigate("/campaign_portal", {
            state: { emailAddress: emailAddress, password: password },
          });
        } else {
          navigate("/campaign_portal_unbranded", {
            state: { emailAddress: emailAddress, password: password },
          });
        }
      }
    } catch (error) {
      window.gtag("event", "couldnt_create_campaign_user", {
        event_category: "RegisterCampaign.js",
        event_label: "error",
      });
      console.error(error);
      toast.error(data?.message || "Something went wrong!");
    }
  };

  return (
    <>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      <div
        className={classes.rootPage}
        style={{
          height: "100%",
          overflow: "auto",
          // fontFamily: "Roboto, sans-serif",
          overflowX: "hidden",
          transition: ".4s ease",
        }}
      >
        {!debrand && <Header campaign />}

        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
        {showCampaignRegisterForm && campaignPassword === campaignPWd ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxwidth: "100%",
              padding: "1rem 1rem 3rem",
              borderRadius: "10px",
              // fontFamily: "Quicksand, sans-serif",
              margin: "1rem auto", // Centering the card on the page
              minHeight: "calc(100vh - 163px)",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "rgb(40, 42, 48)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
                // overflow: "auto",
                "@media screen and (max-width: 480px)": {
                  padding: "0.5rem",
                },
                "@media screen and (max-width: 375px)": {
                  padding: "0.25rem",
                },
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                {stepState === 0 && (
                  <WelcomeScreen
                    debrand={debrand}
                    debrandResellLogo={debrandResellLogo}
                    logo={logo}
                    altText={"Campaign"}
                    mainHeading={`
            Welcome to ${
              debrand ? "the" : "My AI Front Desk's"
            } Campaign Program
                  
                  `}
                    classes={classes}
                    setStepState={setStepState}
                    subHeading={"⚡️ Build whitelabeled campaigns in minutes."}
                    buttonText={"Build Now"}
                  />
                )}

                {stepState === 1 && (
                  <BusinessNameScreen
                    businessName="Business Name"
                    classes={classes}
                    infoText={` The name of your business. We will slap this branding on all your
                     campaigns. You can always change this later.`}
                    campaignName={campaignUserName}
                    campaign={true}
                    setCampaignName={setCampaignUserName}
                    toast={toast}
                    placeholderText={
                      "e.g. John's Digital Marketing & AI Agency"
                    }
                    setStepState={setStepState}
                    buttonText={"Continue"}
                  />
                )}

                {stepState === 2 && (
                  <UploadLogo
                    uploadHeading={"Upload Logo"}
                    infoText={`
Your business logo. We will slap this branding on all your
receptionists. You can always change this later.
`}
                    uploadedLogo={uploadedLogo}
                    classes={classes}
                    serverURL={serverURL}
                    toast={toast}
                    onNext={() => {
                      setStepState(3);
                    }}
                    onSkip={() => {
                      setStepState(3);
                    }}
                    setUploadedLogoS3Url={setUploadedLogoS3Url}
                    setStepState={setStepState}
                    onChangeFunc={(event) => {
                      setUploadedLogo(
                        event.target.files.length > 0
                          ? event.target.files[0]
                          : null
                      );
                    }}
                  />

                  // <div className={classes.box}>
                  //   <Fade duration={1000} bottom>
                  //     <h3
                  //       className={classes.heading}
                  //       style={{
                  //         textAlign: "center",
                  //       }}
                  //     >
                  //       Upload Logo
                  //     </h3>

                  //     <div
                  //       className={classes.subtitle}
                  //       style={{
                  //         textAlign: "center",
                  //       }}
                  //     >
                  //       Your business logo. We will slap this branding on all your
                  //       receptionists. You can always change this later.
                  //     </div>

                  //     <div
                  //       style={{
                  //         marginLeft: "auto",
                  //         marginRight: "auto",
                  //         textAlign: "center", // Add this line
                  //         marginBottom: "3rem",
                  //       }}
                  //     >
                  //       <label
                  //         style={{
                  //           border: "2px dashed #ccc",
                  //           padding: "10px",

                  //           borderRadius: "5px",
                  //           cursor: "pointer",
                  //         }}
                  //         htmlFor="file"
                  //       >
                  //         {uploadedLogo?.name || "Click to upload"}
                  //       </label>
                  //     </div>
                  //     <input
                  //       id="file"
                  //       type="file"
                  //       style={{
                  //         display: "none",
                  //       }}
                  //       className={classes.input + " " + classes.centered}
                  //       required
                  //       onChange={(event) => {
                  //         setUploadedLogo(
                  //           event.target.files.length > 0
                  //             ? event.target.files[0]
                  //             : null
                  //         );
                  //       }}
                  //       placeholder="e.g. john@johnsbarbershop.com"
                  //     />

                  //     {
                  //       <button
                  //         type="button"
                  //         onClick={() => {
                  //           const formData = new FormData();
                  //           formData.append("logo", uploadedLogo);
                  //           // Make a server call to upload the logo
                  //           fetch(`${serverURL}/upload_reseller_logo`, {
                  //             method: "POST",
                  //             body: formData,
                  //           })
                  //             .then((logoResponse) => {
                  //               if (!logoResponse.ok) {
                  //                 toast.error("Logo upload failed");
                  //               } else {
                  //                 toast.success("Logo uploaded successfully");
                  //                 logoResponse.json().then((responseData) => {
                  //                   console.log("Logo URL: " + responseData.url);
                  //                   setUploadedLogoS3Url(responseData.url);
                  //                 });
                  //               }
                  //             })
                  //             .catch((error) => {
                  //               console.error(error);
                  //             });
                  //           setStepState(4);
                  //         }}
                  //         className={classes.button + " " + classes.centered}
                  //         style={{
                  //           marginTop: "2rem",
                  //         }}
                  //       >
                  //         Continue
                  //       </button>
                  //     }
                  //     <div
                  //       onClick={() => setStepState(3)}
                  //       className={classes.later}
                  //     >
                  //       I'll do this later
                  //     </div>
                  //   </Fade>
                  // </div>
                )}

                {stepState === 3 && (
                  <ThemeColorSelectionScreen
                    classes={classes}
                    onNext={() => {
                      // navigate("/campaign_portal");
                      setStepState(4);
                    }}
                    heading="Theme color"
                    themeColor={themeColor}
                    buttonText={"Continue"}
                    setStepState={setStepState}
                    onChange={(event) => {
                      setThemeColor(event.target.value);
                    }}
                  />
                )}

                {stepState === 4 && (
                  <EmailRegisterForm
                    classes={classes}
                    onChangeEmail={(event) => {
                      let value = event.target.value;
                      setEmailAddress(value);
                    }}
                    onNext={() => setStepState(5)}
                    toast={toast}
                    emailAddress={emailAddress}
                    setEmailAddress={setEmailAddress}
                  />
                )}

                {stepState === 5 && (
                  <PasswordRegisterForm
                    classes={classes}
                    onChangePassword={(event) =>
                      setPassword(event.target.value)
                    }
                    handleSubmit={handleSubmit}
                    setStepState={setStepState}
                    toast={toast}
                    setConfirmPassword={setConfirmPassword}
                    confirmPassword={confirmPassword}
                    password={password}
                    setPasswordMatchError={setPasswordMatchError}
                    termsAndConditions={termsAndConditions}
                    setTermsAndConditions={setTermsAndConditions}
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    marginTop: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "13px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "1rem",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      marginRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                      opacity: stepState === 0 ? 0.5 : 1,
                      pointerEvents: stepState === 0 ? "none" : "all",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setStepState((prev) => {
                        const newState = --prev;
                        if (newState <= 0) return 0;
                        else return newState;
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                      height="20px"
                      width="20px"
                      version="1.1"
                      id="Capa_1"
                      viewBox="0 0 219.151 219.151"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575   C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575   c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z" />
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008   c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825   c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628   c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z" />
                      </g>
                    </svg>
                  </div>
                  {new Array(6).fill(0).map((el, idx) => {
                    return (
                      <div
                        key={"dot-indicator-" + idx}
                        className={
                          classes.dot +
                          " " +
                          (idx === stepState ? classes.active : "")
                        }
                        style={{}}
                        onClick={() => {
                          if (idx < stepState) setStepState(idx);
                        }}
                      ></div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full">
            <div className="flex mt-[100px] items-center max-w-[400px] mx-auto flex-col w-full gap-7">
              <label htmlFor="campaign_pwd" className="text-3xl">
                Enter Password
              </label>

              <input
                type="password"
                placeholder="password"
                onChange={(e) => setCampaignPassword(e.target.value)}
                className="border-2 outline-none p-2 max-w-[200px] text-center border-[#ccc]"
              />
              <button
                onClick={() =>
                  setShowCampaignRegisterForm(
                    campaignPassword === campaignPWd ? true : false
                  )
                }
                className="bg-[#9e52be] text-white px-7 py-3 rounded-sm"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default RegisterCampaign;
