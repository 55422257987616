import { AIModel } from '@/types/TAIModel'
import { Country, TwilioCountryCode } from '@/types/TCountriesData'

export const getAIModels = async (): Promise<AIModel[]> => {
  try {
    const response = await fetch('/static/aIModel.json')
    return response.json()
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const getLanguages = async (): Promise<string[]> => {
  try {
    const response = await fetch('/static/languages.json')
    return response.json()
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const getCountriesList = async (): Promise<Country[]> => {
  try {
    const response = await fetch('/static/countries.json')
    return response.json()
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}

export const getTwilioSupportedCountries = async (): Promise<TwilioCountryCode[]> => {
  try {
    const response = await fetch('/static/twilioSupportedCountries.json')
    return response.json()
  } catch (err) {
    console.log('Error:: something went wrong', err)
    throw err
  }
}
