import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Typography, CircularProgress, Box, Grid, Card, CardContent, Button } from '@mui/material'
import PublicLayout from '@/components/Layout/PublicLayout'
import {
  APP_LANDING_PAGE,
  AppRoutes,
  BOOK_CONSULTATION_LANDING_PAGE,
  PRIVACY_POLICY_LANDING_PAGE,
  TERMS_OF_USE_LANDING_PAGE,
} from 'src/enums/ERoutes'
import './blog.css'
import ctaImage from '@/assets/images/cta-image.png'
import { useQuery } from '@tanstack/react-query'
import { getBlogs } from '@/api/blog'
import { BlogPost } from '@/interfaces/IBlogs'

const BlogPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const { data: blog, isLoading: loading } = useQuery<BlogPost, Error>({
    queryKey: ['blog', slug],
    queryFn: () => getBlogs({ type: 'blog', slug: slug }),
    enabled: !!slug,
  })

  const items = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
    { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
    { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
  ]

  const getProductContent = (productType: string | undefined) => {
    const productContent = {
      receptionist: {
        title: 'Try Our AI Receptionist Today',
        description: 'Start your free trial for My AI Front Desk today, it takes minutes to setup!',
        cta_1_text: 'TRY FOR FREE',
        cta_1_link: 'https://app.myaifrontdesk.com/register',
        cta_2_text: 'BECOME A RESELLER',
        cta_2_link: 'https://www.myaifrontdesk.com/white-label',
      },
      reseller: {
        title: 'Become a Reseller of My AI Front Desk',
        description: 'Explore our white label solutions and grow your business.',
        cta_1_text: 'BECOME A RESELLER',
        cta_1_link: 'https://www.myaifrontdesk.com/white-label',
        cta_2_text: 'TRY OUR RECEPTIONIST',
        cta_2_link: 'https://app.myaifrontdesk.com/register',
      },
      affiliate: {
        title: 'Join Our Affiliate Program Today',
        description: 'Partner with My AI Front Desk and earn 40% recurring commission right now!',
        cta_1_text: 'AFFILIATE PROGRAM',
        cta_1_link: 'https://www.myaifrontdesk.com/affiliate',
        cta_2_text: 'TRY OUR RECEPTIONIST',
        cta_2_link: 'https://app.myaifrontdesk.com/register',
      },
      novi: {
        title: 'Try Our AI Outbound Dialers Today',
        description: 'Start your free trial for My AI Front Desk today, it takes minutes to setup!',
        cta_1_text: 'TRY FOR FREE',
        cta_1_link: 'https://www.myaifrontdesk.com/novi',
        cta_2_text: 'BECOME A RESELLER',
        cta_2_link: 'https://www.myaifrontdesk.com/white-label',
      },
    }
    return productContent[productType as keyof typeof productContent] || productContent.receptionist
  }

  return (
    <PublicLayout debrand={false} items={items}>
      <Container maxWidth="lg" style={{ marginTop: '80px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
              </Box>
            ) : blog ? (
              <div>
                <Typography variant="h3" component="h1" gutterBottom>
                  {blog.name}
                </Typography>
                {blog['main-image'] && (
                  <Box mb={3}>
                    <img src={blog['main-image']} alt={blog.name} style={{ maxWidth: '100%', height: 'auto' }} />
                  </Box>
                )}
                {blog['post-body'] && (
                  <div
                    className="blog-content"
                    dangerouslySetInnerHTML={{
                      __html: blog['post-body'].replace(/^'|'$/g, ''),
                    }}
                  />
                )}
              </div>
            ) : (
              <Typography variant="h5">Blog post not found</Typography>
            )}
          </Grid>
          {!loading && blog && (
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  background: 'linear-gradient(135deg, #e0e6ff 0%, #d6d0ff 100%)',
                  height: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  position: 'sticky',
                  top: '20px',
                  width: '90%',
                  margin: '0 auto',
                }}
              >
                <CardContent
                  sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                >
                  <Box>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {getProductContent(blog.product_type).title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {getProductContent(blog.product_type).description}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mb: 2 }}
                      href={getProductContent(blog.product_type).cta_1_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getProductContent(blog.product_type).cta_1_text}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      href={getProductContent(blog.product_type).cta_2_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getProductContent(blog.product_type).cta_2_text}
                    </Button>
                  </Box>
                </CardContent>
                <Box sx={{ p: 2, mt: 'auto' }}>
                  <img src={ctaImage} alt="CTA" style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </PublicLayout>
  )
}

export default BlogPage
