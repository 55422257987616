//@ts-ignore
import ResellerPortal from "../../v1/Whitelabel/ResellerPortal";

const UnbrandedResellerPortal = () => {
  return (
    <ResellerPortal debrand={true} />
  )
}

export default UnbrandedResellerPortal
