import React, { useState } from 'react'
import AddEditGroupModal from './AddEditGroupModal'
import { ITopBarProps } from '@/interfaces/ITopBar'

const TopBar: React.FC<ITopBarProps> = ({ setOpenDropdown }) => {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState<boolean>(false)

  return (
    <div
      className="absolute right-0 mt-2 w-60 bg-white dark:bg-[#2b2c37] shadow-md rounded-xl"
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return
        }
        setOpenDropdown(false)
      }}
    >
      {isBoardModalOpen && <AddEditGroupModal type="add" setIsBoardModalOpen={setIsBoardModalOpen} />}
    </div>
  )
}

export default TopBar
