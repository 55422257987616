import React, { useState } from 'react';
import { Tabs, Typography, Space, Spin, Row, Col, Card, Statistic } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import LeadsTable from './Leads/LeadsTable';
import ScheduleForm from './Leads/ScheduleForm';
import { Content } from 'antd/es/layout/layout';
import { useLeadsHooks } from '../hooks/campaginHooks';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';

const { TabPane } = Tabs;
const { Title } = Typography;

type OverviewProps = {
  campaignId: string;
};

const ConfigureCampaigns: React.FC<OverviewProps> = ({ campaignId }) => {
  const campaignDetails = useAppSelector(getSelectedCampaign);
  const [activeTab, setActiveTab] = useState<string>('leads');

  const { data, isLoading, error, refetch, isRefetching } = useLeadsHooks(campaignId);


  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" tip="Loading campaign details..." />
      </div>
    );
  }

  if (error) return <div>Error fetching data</div>;

  // const getStatusColor = (status: string) => {
  //   switch (status.toLowerCase()) {
  //     case 'active': return '#52c41a';
  //     case 'paused': return '#faad14';
  //     default: return '#1890ff';
  //   }
  // };

  return (
    <Space direction="vertical" style={{ width: '100%' }} >

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Space>
          <Title level={3} style={{ margin: 0 }}>{campaignDetails.business_name}</Title>
          {/* <Tag color={getStatusColor(isPaused ? 'Paused' : 'Active')}>{isPaused ? 'Paused' : 'Active'}</Tag> */}
        </Space>
        {/* <Button
          type={isPaused ? "primary" : "default"}
          icon={isPaused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
          onClick={() => setIsPaused(!isPaused)}
        >
          {isPaused ? 'Resume Campaign' : 'Pause Campaign'}
        </Button> */}
      </div >
      <Content style={{ padding: '24px' }}>
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col span={8}>
            <Card>
              <Statistic title="Total Leads" value={data?.leads?.length || 0} prefix={<UserOutlined />} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Contacted" value={data?.leads?.filter((lead: any) => lead.status === 'Contacted').length || 0} prefix={<PhoneOutlined />} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Conversion Rate" value={((data?.leads?.filter((lead: any) => lead.status === 'Contacted').length || 0) / (data?.leads?.length || 1) * 100).toFixed(2)} suffix="%" prefix={<MailOutlined />} />
            </Card>
          </Col>
        </Row>
        <Card>
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            type="card"
            size="large"
            style={{ marginBottom: '16px' }}
          >
            <TabPane tab="Leads" key="leads">
              <LeadsTable
                initialContacts={data?.leads || []}
                campaignId={campaignId}
                refetch={refetch}
                isLoading={isLoading}
                isRefetching={isRefetching}
              />
            </TabPane>
            <TabPane tab="Schedule" key="schedule">
              <ScheduleForm />
            </TabPane>
          </Tabs>
        </Card>
      </Content>
    </Space >
  );
};

export default ConfigureCampaigns;
