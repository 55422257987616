import React from "react";
import { getContrastingColor } from "../utils/TextColorHelper";

const Button = ({
  onClick,
  className,
  themeColor,
  loading = false,
  text,
  type = "button",
  disabled = false,
}) => {
  const buttonStyle = themeColor
    ? {
        backgroundColor: themeColor,
        color: getContrastingColor(themeColor),
        padding: "10px",
        borderRadius: "5px",
        transition: "all 0.2s",
      }
    : {};

  return (
    <button
      type={type}
      onClick={onClick || undefined}
      style={buttonStyle}
      className={`
        hover:bg-opacity-90
        p-3 rounded-md  font-semibold
        transition duration-200 ease-in-out
        ${disabled ? "cursor-not-allowed opacity-75" : ""}
        ${loading ? "opacity-50 cursor-not-allowed" : ""}
        ${className}

        `}
      disabled={disabled || loading}
    >
      {loading ? "Loading..." : text}
    </button>
  );
};

export default Button;
