import React from "react";
import Fade from "react-reveal/Fade";

const BusinessNameScreen = ({
  businessName,
  classes,
  infoText,
  resellerName,
  setResellerName,
  toast,
  setStepState,
  campaign,
  campaignName,
  setCampaignName,
  placeholderText,
  buttonText,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: "center",
            }}
          >
            {businessName}
          </h3>
          <div
            className={classes.subtitle}
            style={{
              textAlign: "center",
            }}
          >
            {infoText}
          </div>

          <input
            value={campaign ? campaignName : resellerName}
            className={classes.input + " " + classes.centered}
            required
            onChange={(event) => {
              let value = event.target.value;
              {
                campaign ? setCampaignName(value) : setResellerName(value);
              }
            }}
            placeholder={placeholderText}
          />

          <button
            type="button"
            onClick={() => {
              if (campaign ? !campaignName : !resellerName)
                toast.error("Please enter the name of the business");
              else campaign ? setStepState(2) : setStepState(3);
            }}
            className={classes.button + " " + classes.centered}
            style={{
              marginTop: "2rem",
            }}
          >
            {buttonText}
          </button>
        </Fade>
      </div>
    </>
  );
};

export default BusinessNameScreen;
