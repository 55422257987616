import React, { useState } from 'react'
import ContactInfo from './ContactInfo'
import ActivityLog from './ActivityLogs'
import Sidebar from './Sidebar'
import '../css/LeadView.css'
import { LeadViewProps } from '@/interfaces/ILeadView'

// Define the component
const LeadView: React.FC<LeadViewProps> = ({ onClose, rowData, statuses, onUpdateLead }) => {
  const [updatedNotes, setUpdatedNotes] = useState<string>(rowData?.notes || '')

  const handleNotesUpdate = (newNotes: string) => {
    setUpdatedNotes(newNotes)
  }

  return (
    <div className="lead-view">
      <div className="left-pane flex">
        <ContactInfo
          onClose={onClose}
          statuses={statuses}
          rowData={{
            username: rowData?.username,
            lead_id: rowData?.lead_id,
            phone_number: rowData?.phone_number,
            current_status: rowData?.current_status,
            notes: rowData?.notes || '',
          }}
        />
        <ActivityLog
          rowData={{ ...rowData, phone_number: rowData?.phone_number, status_history: rowData?.status_history }}
        />
      </div>
      <div className="right-pane">
        <Sidebar
          rowData={{ ...rowData, notes: updatedNotes, username: rowData?.username, lead_id: rowData?.lead_id }}
          onNotesUpdate={handleNotesUpdate}
          onUpdateLead={(lead) => onUpdateLead({ ...lead, notes: updatedNotes })}
        />
      </div>
    </div>
  )
}

export default LeadView
