import { Button, Form, Input, Alert, notification } from 'antd'
import PublicLayout from '@/components/Layout/PublicLayout'
import './ForgotPasswordForm.scss'
import { TForgotPassword } from '@/types/TForgotPassword'
import { useMutation } from '@tanstack/react-query'
import { doesUserExist } from '@/api/user'
import { useState } from 'react'

export const ForgotPasswordForm: React.FC = () => {
  const [form] = Form.useForm<TForgotPassword>()
  const [userExistsResponse, setUserExistsResponse] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutate: userExists, isPending } = useMutation({
    mutationKey: ['userExists'],
    mutationFn: doesUserExist,
    onSuccess(data) {
      console.log('userExists', data)
      if (data.userExists) {
        setUserExistsResponse(true)
        setErrorMessage(null)
      } else {
        setUserExistsResponse(false)
        setErrorMessage('Incorrect username or email')
      }
    },
    onError() {
      setErrorMessage('An error occurred while checking the user')
      notification.error({
        message: 'Error',
        description: 'Please reach out to contact@myaifrontdesk.com for assistance.',
      })
    },
  })

  const submitUsernameOrEmail = (values: TForgotPassword) => {
    userExists({ usernameOrEmail: values.usernameOrEmail })
  }

  const handleInputChange = () => {
    setErrorMessage(null)
  }

  const baseClass = 'forgot-password-form-container'

  return (
    <PublicLayout debrand={false} items={[]}>
      <Form
        onFinish={(values: TForgotPassword) => submitUsernameOrEmail(values)}
        form={form}
        layout="vertical"
        name="login-form"
        className={baseClass}
      >
        <Form.Item className={`${baseClass}__heading`}>
          <h2>Forgot Password</h2>
          <p>Enter your username or email address and we will send you a link with your new password.</p>
        </Form.Item>

        {userExistsResponse === true && (
          <Form.Item className={`${baseClass}__success-response`}>
            <Alert message="Password reset link has been sent to your email." type="success" />
          </Form.Item>
        )}

        {userExistsResponse === false && errorMessage && (
          <Form.Item className={`${baseClass}__error-response`}>
            <Alert message={errorMessage} type="error" />
          </Form.Item>
        )}

        {userExistsResponse === false && (
          <>
            <Form.Item
              label="Username or Email"
              name="usernameOrEmail"
              className={`${baseClass}__input-item`}
              normalize={(value) => value.trim()}
              rules={[
                {
                  required: true,
                  message: 'Username or email is required!',
                },
              ]}
            >
              <Input placeholder="Username or Email" onChange={handleInputChange} />
            </Form.Item>

            <Form.Item>
              <Button loading={isPending} htmlType="submit" className={`${baseClass}__submit-button`}>
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </PublicLayout>
  )
}
