//@ts-ignore
import ResellerRegistrationV1 from "../../v1/Whitelabel/ResellerRegistration";

export const RegisterReseller = () => {
  return (
    <ResellerRegistrationV1 />
  )
}

export default RegisterReseller
