import '../css/ContactInfo.css'
import React, { useState, useCallback, useEffect } from 'react'
import { Button, Input, Select, Spin, Typography, Card, Form, Space } from 'antd'
import { useGetContacts, useUpdateContactName, useUpdateLead, useUpdateLeadStatus } from 'src/Hooks/UseHookForCrmData'
const { Text } = Typography
const { Option } = Select

interface ContactInfoProps {
  onClose: () => void
  rowData: any
  statuses: string[]
}

const ContactInfo: React.FC<ContactInfoProps> = ({ rowData, statuses }) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(rowData?.current_status)
  const [contactName, setContactName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>(rowData?.phone_number)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [savedContacts, setSavedContacts] = useState<any>({})

  const { mutate: updateLead } = useUpdateLead()
  const { mutate: updateLeadStatus } = useUpdateLeadStatus()

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value)
    saveStatusChange(value)
  }
  const saveStatusChange = (newStatus: string) => {
    setIsLoading(true)
    const updatedLead = {
      ...rowData,
      contact_name: contactName,
      current_status: newStatus,
      phone_number: phoneNumber,
    }

    updateLeadStatus(updatedLead, {
      onSuccess: () => {
        setIsEditing(false)
        setIsLoading(false)
      },
      onError: (error) => {
        setIsLoading(false)
        console.error('Error updating lead status:', error)
      },
    })
  }

  const toTitleCase = (str: string) => {
    return str
      ?.split('_')
      ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
      ?.join(' ')
  }

  useEffect(() => {
    if (savedContacts && savedContacts.length > 0) {
      const contactNameFromSaved = savedContacts[rowData?.phone_number] || ''
      setContactName(contactNameFromSaved)
    }
  }, [savedContacts, rowData?.phone_number])

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const updatedLead = {
        ...rowData,
        contact_name: contactName,
        phone_number: phoneNumber, // Ensure phone number is included
      }

      // Log the updated lead data to the console

      try {
        updateLead(
          { ...updatedLead, phone: updatedLead.phone_number },
          {
            onSuccess: () => {
              setIsEditing(false)
              setIsLoading(false)
            },
            onError: () => {
              setIsLoading(false)
            },
          }
        )
      } catch (error) {
        setIsLoading(false)
      }

      if (contactName) {
        updateContactName(rowData.lead_id, contactName)
      }

      setIsEditing(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const toggleEdit = () => {
    if (isEditing) {
      handleSave()
    } else {
      setIsEditing(true)
    }
  }
  const { data: contacts } = useGetContacts(rowData?.username)

  const getContacts = useCallback(async () => {
    setSavedContacts(contacts?.result)
  }, [])

  const { mutate } = useUpdateContactName()

  const updateContactName = (lead_id: string, name: string) => {
    mutate({ lead_id, name })
  }

  useEffect(() => {
    getContacts()
  }, [getContacts])

  return (
    <div className="contact-info">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 dark:bg-[#2b2c37] dark:bg-opacity-75 z-10">
          <Spin size="large" />
        </div>
      )}
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div className='border p-4 border-gray-300 rounded-lg shadow-lg'>
          <Card bordered={false} style={{ width: '100%' }}>
            <h1 className='font-semibold text-lg text-black'>Contact Details</h1>
            <Form layout="vertical" >
              <Form.Item label={<span className='font-semibold text-lg text-black'>Phone Number :</span>}>
                {isEditing ? (
                  <Input
                    type="tel" // Use type="tel" for better handling of phone number formats
                    value={phoneNumber || ''}
                    onChange={(e) => {
                      const newValue = e.target.value

                      // Allow only digits and an optional leading '+'
                      if (/^\+?\d*$/.test(newValue)) {
                        setPhoneNumber(newValue) // Directly set the new string value
                      }
                    }}
                    placeholder="Enter phone number"
                    style={{ minHeight: '40px' }} // Set a minimum height
                  />
                ) : (
                  <Text>{phoneNumber}</Text>
                )}
              </Form.Item>
              {contactName && (
                <Form.Item label={<span className='font-semibold text-lg text-black'>Contact Name :</span>}>
                  {isEditing ? (
                    <Input
                      type="text"
                      value={contactName || ''}
                      onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                        setContactName(e.target.value)
                      }
                      placeholder="Enter contact name"
                      style={{ minHeight: '40px' }} // Set a minimum height
                    />
                  ) : (
                    <Text>{contactName}</Text>
                  )}
                </Form.Item>
              )}
              <hr className='border-gray-300 h-1'/>
              <Form.Item label={<span className='font-semibold text-lg text-black'>Current Status :</span>}>
                <p>{toTitleCase(rowData?.current_status)}</p>
              </Form.Item>
            </Form>
            <Button type={isEditing ? 'default' : 'primary'} onClick={toggleEdit} style={{ marginBottom: 16 }}>
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          </Card>
        </div>
        <div className='border p-4 border-gray-300 rounded-lg shadow-lg'>

          <Card bordered={false} style={{ width: '100%' }}>
            <h1 className='font-semibold text-lg text-black'>Status Details</h1>
            <Form layout="vertical">
              <Form.Item label={<span style={{ color: '#000' }}>Change Status :</span>}>
                <Select
                  id="lead-status"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  placeholder="Select a status"
                  style={{ width: '100%' }}
                >
                  {statuses?.map((status: string, index: React.Key | null | undefined) => (
                    <Option key={index} value={status}>
                      {toTitleCase(status)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Space>
    </div>
  )
}

export default ContactInfo
