import { Button, Col, Form, Image, Input, Modal, notification, Popconfirm, Row, Select, Spin, Upload } from 'antd'
import React, { useState } from 'react'
import { RefetchOptions, QueryObserverResult, useMutation } from '@tanstack/react-query'
import { TVoiceModel } from '@/types/TVoiceModel'
import { uploadVoice } from '@/api/voice'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import { UploadOutlined } from '@mui/icons-material'
import { CloseCircleOutlined } from '@ant-design/icons'
import { GetVoiceModelsResponse } from '@/interfaces/IVoice'

export const AdminManageFormAdd: React.FC<{
  voiceModal?: TVoiceModel
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<GetVoiceModelsResponse, Error>>
  addMode: boolean
  openModal: boolean
  setOpenModal: (val: boolean) => void
}> = ({ voiceModal, refetch, addMode, openModal, setOpenModal }) => {
  const [form] = Form.useForm<TVoiceModel>()
  const currentUser = useAppSelector(getLoggedInUser)

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewImageAvatar, setPreviewImageAvatar] = useState('')
  const [avatarImageName, setAvatarImageName] = useState<string | null>(null)
  const [avatarFile, setAvatarFile] = useState<File | null>(null)
  const [showCloseIconAvatar, setShowCloseIconAvatar] = useState(false)

  // const { mutate: addVoiceFn, isPending } = useMutation({
  //   mutationKey: ['add-custom-voice'],
  //   mutationFn: uploadCustomVoice,
  //   onSuccess: () => {
  //     refetch()
  //     notification.success({
  //       key: 'form-success',
  //       message: 'Greeting Phrase has been saved successfully!',
  //       duration: 3,
  //     })
  //     setIsFieldTouchReset(false)
  //     dispatch(closeModal())
  //   },
  // })

  const { mutate: uploadVoiceMutation, isPending } = useMutation({
    mutationKey: ['upload-voice'],
    mutationFn: uploadVoice,
    onSuccess: (data) => {
      notification.success({
        message: data.message || 'Voice cloned and attached to your receptionist successfully!',
      })

      setSelectedFile(null)
      setAvatarFile(null)
      setAvatarImageName(null)
      setPreviewImageAvatar('')
      form.setFieldsValue({
        name: '',
        description: '',
        voiceId: '',
        accent: '',
        gender: '',
        language: '',
        provider: '',
      })
      refetch()
      setOpenModal(false)
    },
    onError: (error: any) => {
      notification.error({
        message: error.message || 'An error occurred during the upload.',
      })
    },
  })

  const handleUpload = () => {
    const { voiceId, accent, description, gender, language, name, provider } = form.getFieldsValue()
    if (!voiceId || !accent || !description || !gender || !language || !name || !provider) {
      notification.error({ message: 'Please fill in all fields.' })
      return
    }
    if (!selectedFile) {
      notification.error({ message: 'Please select a file first.' })
      return
    }

    // Check file size (1 MB = 1,000,000 bytes)
    const maxSize = 1 * 1000000 // 1 MB
    if (selectedFile.size > maxSize) {
      notification.error({ message: 'File size should not exceed 1 MB.' })
      return
    }
    // Check file type
    const validTypes = ['audio/wav', 'audio/x-wav', 'audio/mpeg'] // .wav or .mp3 MIME types
    if (!validTypes.includes(selectedFile.type)) {
      notification.error({ message: 'Invalid file type. Only .mp3 or .wav files are allowed.' })
      return
    }

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL
    if (!baseUrl) return

    const formData = new FormData()
    formData.append('voiceId', voiceId)
    currentUser?.number && formData.append('username', currentUser.number) // addedBy
    formData.append('accent', accent || '')
    formData.append('avatar', avatarImageName || '')
    avatarFile && formData.append('avatarFile', avatarFile)
    formData.append('name', name)
    formData.append('description', description)
    formData.append('provider', provider)
    formData.append('fileName', selectedFile?.name || '')
    formData.append('gender', gender)
    formData.append('language', language)
    formData.append('file', selectedFile)

    uploadVoiceMutation(formData)
  }

  const handleAvatarImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile = event.target.files && event.target.files[0]
    if (imageFile) {
      // handle file extension
      const allowedFileTypes = ['image/png']
      if (!allowedFileTypes.includes(imageFile.type)) {
        notification.error({
          message: 'Invalid file type. Please choose a .jpeg, .jpg, .png, or .gif file.',
        })
        return
      }

      // handle file size
      const fileSizeLimitInBytes = 102400 //100 KB
      if (imageFile.size > fileSizeLimitInBytes) {
        notification.error({
          message: 'File size exceeds the limit of 100 Kb. Please choose a smaller file.',
        })
        return
      }

      // process/read file
      imageFile?.name && setAvatarImageName(imageFile?.name)
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewImageAvatar(reader.result as string)
      }
      reader.readAsDataURL(imageFile)

      // save file
      event?.target?.files && setAvatarFile(event.target.files[0])
    }
  }

  const handleDropAvatar = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const droppedFile = event.dataTransfer.files[0]
    setAvatarFile(droppedFile)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const FormItems = () => (
    <Spin spinning={isPending}>
      <Form form={form} className="text-md font-normal my-4 rounded w-full" initialValues={addMode ? {} : voiceModal}>
        <Row gutter={8} justify="space-between" align="top">
          <Col span={12}>
            <Form.Item name="name" rules={[{ required: true, message: 'Required' }]}>
              <Input placeholder="Custom Voice Name" />
            </Form.Item>
            <Form.Item name="description" rules={[{ required: true, message: 'Required' }]}>
              <Input.TextArea placeholder="Custom Voice Description" rows={2} />
            </Form.Item>
            <Form.Item name="voiceId" rules={[{ required: true, message: 'Required' }]}>
              <Input placeholder="visit cartesia or 11labs for exact voice id" />
            </Form.Item>
            <Form.Item name="provider" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="select provider"
                options={[
                  { label: 'cartesia', value: 'cartesia' },
                  { label: 'deepgram', value: 'deepgram' },
                  { label: '11labs', value: '11labs' },
                ]}
              />
            </Form.Item>
            <Form.Item name="gender" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="select gender"
                options={[
                  { label: 'Female', value: 'Female' },
                  { label: 'Male', value: 'Male' },
                ]}
              />
            </Form.Item>
            <Form.Item name="language" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="select language"
                options={[
                  { label: 'English', value: 'English' },
                  { label: 'French', value: 'French' },
                  { label: 'German', value: 'German' },
                  { label: 'Multilingual', value: 'Multilingual' },
                ]}
              />
            </Form.Item>
            <Form.Item name="accent" rules={[{ required: true, message: 'Required' }]}>
              <Select
                placeholder="select accent"
                options={[
                  { label: 'English UK', value: 'English UK' },
                  { label: 'English US', value: 'English US' },
                  { label: 'English IN', value: 'English IN' },
                  { label: 'English AU', value: 'English AU' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div
              onDrop={handleDropAvatar}
              onDragOver={handleDragOver}
              className="sm:w-full rounded-lg px-10 py-6 border-dashed bg-white border-2 border-borderColor"
            >
              <label
                htmlFor="dropzone-file-avatar"
                className="flex flex-col items-center justify-center gap-4 cursor-pointer"
              >
                {!avatarFile && (
                  <div className="flex">
                    <div className="rounded-full p-3 bg-grayLight">
                      <UploadOutlined />
                    </div>
                    <div className="px-2">
                      <p className="text-lg font-semibold">Upload Avatar</p>
                      <p className="text-md font-semibold text-gray">
                        {'Must be JPEG/JPG/PNG/GIF image (Max. 100 kb)'}
                      </p>
                    </div>
                  </div>
                )}
                {(avatarFile || previewImageAvatar) && (
                  <div className="relative">
                    <Image
                      width={50}
                      height={50}
                      src={previewImageAvatar}
                      className="z-10"
                      alt="Avatar IMG"
                      onMouseOut={previewImageAvatar === '/Fav.svg' ? () => {} : () => setShowCloseIconAvatar(true)}
                      onMouseOver={previewImageAvatar === '/Fav.svg' ? () => {} : () => setShowCloseIconAvatar(false)}
                    />
                    {showCloseIconAvatar && (
                      <Popconfirm
                        title="Remove Avatar Logo"
                        description="Are you sure, you want to delete this avatar image?"
                        okText="Yes"
                        okButtonProps={{ className: 'bg-blue' }}
                        cancelText="No"
                        onConfirm={() => {
                          setPreviewImageAvatar('')
                          setAvatarImageName(null)
                          setAvatarFile(null)
                        }}
                      >
                        <div
                          className="absolute top-0 right-0"
                          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            event.stopPropagation() // Prevent event from bubbling up to the input element
                            event.preventDefault() // Prevent the default behavior
                          }}
                        >
                          <CloseCircleOutlined size={20} className="cursor-pointer z-20" />
                        </div>
                      </Popconfirm>
                    )}
                  </div>
                )}
                {avatarFile && <p className="text-lg text-textSecondary">{avatarImageName}</p>}
                <input
                  id="dropzone-file-avatar"
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={handleAvatarImageChange}
                  multiple={false}
                  className="hidden"
                />
              </label>
            </div>
            <div className="space-x-2 mt-6">
              <Upload
                beforeUpload={(file) => {
                  setSelectedFile(file as File)
                  return false
                }}
                showUploadList={false}
                accept="audio/wav, audio/mpeg"
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </div>
            {selectedFile && <span className="text-sm text-gray-600">Selected file: {selectedFile.name}</span>}

            <span className="text-xs text-gray-500">(Max file size is 1 MB)</span>
          </Col>
          {/* <Button type="primary" onClick={handleUpload} disabled={isPending} className="w-full mt-2">
            {isPending ? 'Uploading...' : 'Create Voice Clone'}
          </Button> */}
        </Row>
      </Form>
    </Spin>
  )

  return (
    <Modal
      onCancel={() => setOpenModal(false)}
      centered
      title={<div className="text-2xl font-semibold pb-4 text-textPrimary border-b-[1px] border-borderColor">Add</div>}
      width={700}
      open={openModal}
      onOk={handleUpload}
    >
      <div className="max-h-screen h-[40vh] overflow-y-auto overflow-x-hidden">{FormItems()}</div>
    </Modal>
  )
}
