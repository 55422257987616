import React, { ReactNode } from 'react'
import { Modal, ModalProps } from 'antd'
import './CustomModal.scss'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { getModaOpenModalId } from '@/store/modal/selector'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { MODAL_IDS } from 'src/enums/EModal'

export const CustomModal: React.FC<
  {
    title: ReactNode
    children: ReactNode
    isModalOpen?: boolean
    confirmAction?: () => void
    cancelAction?: () => void
    modalId?: MODAL_IDS | null
  } & Omit<ModalProps, 'title' | 'open' | 'onOk' | 'onCancel'>
> = ({ title, children, isModalOpen, modalId, confirmAction, cancelAction, ...rest }) => {
  const dispatch = useAppDispatch()
  const openModalId = useAppSelector(getModaOpenModalId)
  return (
    <div className={'custom-modal'}>
      <Modal
        onCancel={() => (cancelAction ? cancelAction() : dispatch(closeModal()))}
        title={title}
        open={modalId ? !!(openModalId && openModalId === modalId) : isModalOpen}
        onOk={confirmAction}
        {...rest}
      >
        {children}
      </Modal>
    </div>
  )
}
