import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import { Button, DatePicker, notification, Select, Space, Spin, Table, TableProps, Tooltip } from 'antd'
import PlayForWorkOutlinedIcon from '@mui/icons-material/PlayForWorkOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { useEffect, useState } from 'react'
import '../CallLogs/CallLogs.scss'
import { CustomModal } from '@/components/CustomModal'
import { VoiceMailLogDetails } from './VoiceMailLogDetails'
import { deleteVoiceMailLog, getVoiceMailLogs, updateUserTimeZone } from '@/api/logs'
import { useMutation, useQuery } from '@tanstack/react-query'
import { VoiceMailLog } from '@/interfaces/ILogs'
import { renderPSTDate } from '@/utils/helper'
import { useDispatch } from 'react-redux'
import { setUserTimeZone } from '@/store/account/slice'
import { useAppSelector } from '@/store/hooks/useAppSelector'
import { getLoggedInUser } from '@/store/account/selector'
import { getProvisionedNumber } from '@/api/reservedNumbers'
import { useLocalTimezone } from 'src/common/hooks/useLocalTimezone'
import { DeleteOutlined } from '@ant-design/icons'

const timeZoneList = momentTimeZone.tz.names()

const VoiceMailLogs = () => {
  const dispatch = useDispatch()
  const loggedInUser = useAppSelector(getLoggedInUser)
  const { RangePicker } = DatePicker

  const { timeZone, setTimeZone } = useLocalTimezone(loggedInUser?.time_zone)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [voicemailLogs, setVoiceMailLogs] = useState<VoiceMailLog[]>([])
  const [originalVoiceMailLogs, setOriginalVoiceMailLogs] = useState([] as VoiceMailLog[])
  const [selectedVoiceMailLog, setSelectedVoiceMailLog] = useState<VoiceMailLog | null>(null)
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<number | null>(null)
  const [endDate, setEndDate] = useState<number | null>(null)

  const { data, refetch, isPending, isRefetching } = useQuery({
    queryKey: ['getVoiceMailLogs'],
    queryFn: getVoiceMailLogs,
  })

  const { data: provisionedNumbers, isLoading: isLoadingProvisionedNumber } = useQuery({
    queryKey: ['getProvisionedNumbers'],
    queryFn: () => getProvisionedNumber(),
  })

  const { mutate: deleteVoiceMailLogMutation, status: deletingVoiceMailLogs } = useMutation({
    mutationKey: ['deleteTextLog'],
    mutationFn: deleteVoiceMailLog,
    onSuccess(_, values) {
      setVoiceMailLogs(voicemailLogs.filter((log) => log.date !== values.voiceMailLogDate))
      setOriginalVoiceMailLogs(originalVoiceMailLogs.filter((log) => log.date !== values.voiceMailLogDate))
      setSelectedVoiceMailLog(null)
      setConfirmDelete(false)
      notification.success({ message: 'Voicemail log deleted successfully' })
    },
  })

  const updateTimeZoneMutation = useMutation({
    mutationKey: ['updateTimeZone'],
    mutationFn: updateUserTimeZone,
    onSuccess(_, values) {
      dispatch(setUserTimeZone({ timeZone: values.timeZone }))
    },
  })

  const handleClick = (index: number) => {
    setSelectedVoiceMailLog(voicemailLogs[index])
    if (index !== undefined && index !== null) {
      setShowModal(true)
    }
  }
  const handleConfirm = () => {
    setShowModal(false)
  }
  const handleCancel = () => {
    setShowModal(false)
  }

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value)
    updateTimeZoneMutation.mutate({ timeZone: value })
  }

  const handleDeleteVoiceMailLog = (index: number) => {
    setSelectedVoiceMailLog(voicemailLogs[index])
    setConfirmDelete(true)
  }

  const handleCancelDeleteModal = () => {
    setSelectedVoiceMailLog(null)
    setConfirmDelete(false)
  }

  const confimDeleteVoiceMailLog = () => {
    deleteVoiceMailLogMutation({ voiceMailLogDate: selectedVoiceMailLog?.date ?? '' })
  }

  const handleDateRangeChange = (_: any, dateStrings: [string, string]) => {
    const [start, end] = dateStrings
    setStartDate(start ? moment(start).valueOf() : null)
    setEndDate(end ? moment(end).valueOf() : null)
  }

  const handleVoiceMailLogsDownload = () => {
    const headingRow = 'Date,From,User name,Transcript\n'
    const csv = voicemailLogs
      .map((log) => {
        return `${renderPSTDate(log.date, timeZone)},${log.from},${log.forwardedFrom},${log.voicemail_transcript}\n`
      })
      .join('\n')
    const csvData = headingRow + csv
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `voice_mail_logs_${moment().format('YYYY-MM-DD')}.csv`
    a.click()
    URL.revokeObjectURL(url)
  }

  const columns: TableProps<VoiceMailLog>['columns'] = [
    {
      key: 'Date',
      title: 'Dates',
      render: (_, record) => renderPSTDate(record.date, timeZone),
    },
    {
      key: 'from',
      title: 'From',
      dataIndex: 'from',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, __, index) => (
        <Space size="small">
          <Button type="default" onClick={() => handleClick(index)}>
            View
          </Button>
          <Tooltip title="Delete">
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDeleteVoiceMailLog(index)
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    if (data?.voiceMailLogs) {
      setVoiceMailLogs(data?.voiceMailLogs)
      setOriginalVoiceMailLogs(data?.voiceMailLogs)
    }
  }, [data?.voiceMailLogs])

  useEffect(() => {
    if (startDate || endDate) {
      const filteredLogs = originalVoiceMailLogs.filter((log) => {
        const logDate = moment(log.date).valueOf()
        if (startDate && endDate) {
          return logDate >= startDate && logDate <= endDate
        } else if (startDate) {
          return logDate >= startDate
        } else if (endDate) {
          return logDate <= endDate
        }
        return true
      })

      setVoiceMailLogs(filteredLogs)
    } else {
      setVoiceMailLogs(originalVoiceMailLogs)
    }
  }, [startDate, endDate, originalVoiceMailLogs])

  const baseClass = 'call-logs'
  return (
    <div className={baseClass}>
      <div className={`${baseClass}_header`}>
        <h1>Voicemail Logs</h1>
        <Button
          type="primary"
          icon={<PlayForWorkOutlinedIcon />}
          onClick={handleVoiceMailLogsDownload}
          disabled={isLoadingProvisionedNumber || !provisionedNumbers?.provisioned_number}
        >
          Download Logs
        </Button>
        <Button
          type="primary"
          icon={<RefreshOutlinedIcon />}
          onClick={() => {
            refetch()
          }}
          loading={isPending || isRefetching}
        >
          Refresh Logs
        </Button>
      </div>
      <div className={`${baseClass}_input-container`}>
        <RangePicker placement="bottomRight" onChange={handleDateRangeChange} />
        <Select
          value={timeZone}
          onChange={handleTimeZoneChange}
          showSearch
          placeholder="Select a time zone"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={timeZoneList.map((zone) => ({ label: zone, value: zone }))}
        />
      </div>
      <Spin spinning={isPending}>
        <div className={`${baseClass}_table-container`}>
          <Table columns={columns} dataSource={voicemailLogs} rowKey="date" />
        </div>
      </Spin>
      <CustomModal
        title={<strong>Voicemail Log Details</strong>}
        children={<VoiceMailLogDetails voicemailLog={selectedVoiceMailLog as VoiceMailLog} />}
        isModalOpen={showModal}
        confirmAction={handleConfirm}
        cancelAction={handleCancel}
      />
      <CustomModal
        title={<>Are you sure you want to delete this Voicemail log?</>}
        children={<>This action cannot be undone.</>}
        isModalOpen={confirmDelete}
        confirmAction={confimDeleteVoiceMailLog}
        cancelAction={handleCancelDeleteModal}
        footer={[
          <Button key="cancel" onClick={handleCancelDeleteModal}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={confimDeleteVoiceMailLog}
            danger
            loading={deletingVoiceMailLogs === 'pending'}
          >
            Delete
          </Button>,
        ]}
      />
    </div>
  )
}

export default VoiceMailLogs
