import { Alert,Form, notification, Spin } from 'antd'
import { LabelWithDescription } from '@/components/LabelWithdescription'
import { OverViewCardForm } from '../OverviewCardForm'
import React, { useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { RefetchOptions, QueryObserverResult, useMutation } from '@tanstack/react-query'
import { updateUser } from '@/api/user'
import { LoadingOutlined } from '@ant-design/icons'
import { useAppDispatch } from '@/store/hooks/useAppDispatch'
import { closeModal } from '@/store/modal/slice'
import { User } from '@/types/TAccounts'

export const GreetingPhraseForm: React.FC<{
  system_phrase?: string
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>
}> = ({ system_phrase, refetch }) => {
  const [form] = Form.useForm<{
    system_phrase: string
  }>()
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch()
      notification.success({
        key: 'form-success',
        message: 'Greeting Phrase has been saved successfully!',
        duration: 3,
      })
      setIsFieldTouchReset(false)
      dispatch(closeModal())
    },
  })

  const handleSubmit = (value: any) => {
    mutation.mutate({
      system_phrase: value.system_phrase,
    })
  }

  const FormItems = () => (
    <>
      <Form.Item
        label={
          <div>
            <LabelWithDescription
              customClassName="mt-2 w-full "
              label="Greeting Phrase"
              description="This is the opening line your receptionist will use when answering the phone."
            />
          </div>
        }
        name="system_phrase"
        className="!mb-0"
      >
        <TextArea rows={8} placeholder="Please enter your greeting phrase" />
      </Form.Item>{' '}
      <Alert
      className='mt-4'
        type="info"
        closable={false}
        message={
          <>
            <b>Please Note:</b>
            <p className="text-justify">
              Your greeting phrase should be short and concise, like "Welcome to [business_name], how may I help you?".
              If it is too long, people will think the AI is just a voicemail and hang up.
            </p>
          </>
        }
      />
    </>
  )

  return (
    <div>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            system_phrase,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  )
}
